import React, { useState, useEffect, useRef } from "react";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";
import {
  formatEventDate
} from "../utils/function";
const { datesGenerator } = require("dates-generator");

const CustomCalender = ({
  isLoading,
  onMonthChange,
  onDateSelect,
  calenderStartDate = new Date(),
  eventList = [],
  defaultDate = new Date(), 
}) => {
    const translate = useLocalization();
  const months = [
     translate(labels.jan),
     translate(labels.feb),
     translate(labels.mar),
    translate(labels.apr),
   translate(labels.may),
    translate(labels.jun),
    translate(labels.jul),
    translate(labels.aug),
    translate(labels.sep),
   translate(labels.oct),
    translate(labels.nov),
    translate(labels.dec),
  ];
  const days = [
    translate(labels.mon),
    translate(labels.tue),
    translate(labels.wen), 
    translate(labels.thu), 
    translate(labels.fri), 
    translate(labels.sat),
    translate(labels.sun),    
  ];
  console.log("Tu powinny byc sloty");
  console.log(eventList);
  console.log("Tu defaultDate");
  console.log(new Date(defaultDate));  
  const [selectedDate, setSelectedDate] = useState(new Date(defaultDate));
  const [viewDate, setviewDate] = useState(
    new Date(defaultDate).toLocaleString().split(",")[0]
  );

  const [viewdates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });
  const ref = useRef();

  useEffect(() => {
    // initEndHours();

    const body = {
      month: calendar.month,
      year: calendar.year,
      startingDay: 1,
    };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } =
      datesGenerator(body);

    setDates([...dates]);
    setCalendar({
      ...calendar,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickNext = () => {
    const body = { month: calendar.nextMonth, year: calendar.nextYear, startingDay: 1};
    const { dates, nextMonth, nextYear, previousMonth, previousYear } =
      datesGenerator(body);

    const firstDate = new Date(
      dates[0][0].year,
      dates[0][0].month,
      dates[0][0].date
    );
    const lastWeek = dates[dates.length - 1];
    const lDate = lastWeek[lastWeek.length - 1];

    const lastDate = new Date(lDate.year, lDate.month, lDate.date);
    onMonthChange({
      month: nextMonth,
      year: nextYear,
      firstDate: firstDate.toISOString(),
      lastDate: lastDate.toISOString(),
    });
    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.nextMonth,
      year: calendar.nextYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const onClickPrevious = () => {
    const body = { month: calendar.previousMonth, year: calendar.previousYear, startingDay: 1 };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } =
      datesGenerator(body);
    const firstDate = new Date(
      dates[0][0].year,
      dates[0][0].month,
      dates[0][0].date
    );

    const lastWeek = dates[dates.length - 1];
    const lDate = lastWeek[lastWeek.length - 1];

    const lastDate = new Date(lDate.year, lDate.month, lDate.date);
    onMonthChange({
      month: nextMonth,
      year: nextYear,
      firstDate: firstDate.toISOString(),
      lastDate: lastDate.toISOString(),
    });
    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.previousMonth,
      year: calendar.previousYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };
  const checkDateIsGreaterThenNow = (date, isCheck) => {
    var now = calenderStartDate;
    var date2 = isCheck ? date : new Date(date.year, date.month, date.date);

    if (
      date2.toLocaleString("en-GB").split(",")[0] ===
      now.toLocaleString("en-GB").split(",")[0]
    ) {
      return true;
    } else if (date2.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }
    // }
  };

  const onSelectDate = (date) => {

    setSelectedDate(new Date(date.year, date.month, date.date));
    setviewDate(
      new Date(date.year, date.month, date.date)
        .toLocaleString()
        .split(",")[0]
        //"pl-PL"
    );
    onDateSelect(new Date(date.year, date.month, date.date));
  };

  const renderDatePart = () => {
    return (
      <>
        <div className="top_row">
          {viewdates.length > 0 &&
          !checkDateIsGreaterThenNow(
            new Date(
              viewdates[0][0].year,
              viewdates[0][0].month,
              viewdates[0][0].date
            ),
            true
          ) ? (
            <div
              onClick={null}
              className="pre_next_icon"
              style={{ opacity: "0.5" }}
            >
              &#8249;
            </div>
          ) : (
            <div
              onClick={!isLoading ? onClickPrevious : null}
              className="pre_next_icon"
            >
              &#8249;
            </div>
          )}
          <div className="month_text">
            {months[calendar.month]} {calendar.year}
          </div>
          <div
            onClick={!isLoading ? onClickNext : null}
            className="pre_next_icon"
          >
            &#8250;
          </div>
        </div>

        <div>
          <div>
            <table className="calender_table">
              <tbody>
                <tr>
                  {days.map((day) => (
                    <td key={day} style={{ padding: "5px 0" }}>
                      <div
                        style={{
                          textAlign: "center",
                          padding: "5px 0",
                          fontSize: "14px",
                        }}
                      >
                        {day}
                      </div>
                    </td>
                  ))}
                </tr>

                {viewdates.length > 0 &&
                  viewdates.map((week) => (
                    <tr key={JSON.stringify(week[0])}>
                      {week.map((each) => (
                        <td
                          key={JSON.stringify(each)}
                          style={{ padding: "5px 0" }}
                        >
                          <div
                            onClick={() => {
                              if (checkDateIsGreaterThenNow(each)) {
                                onSelectDate(each);
                              }
                            }}
                            className={
                              !checkDateIsGreaterThenNow(each)
                                ? "close_date_text"
                                : viewDate === each["jsDate"].split(",")[0]
                                ? "selected_date_text"
                                : "unselected_date_text"
                            }
                          >
                            <div className="box">{each.date}</div>
                          
                          
                          </div>
                          <div className="dot_box">
                            {eventList.includes(
                            each["date"] < 10 && each["month"] + 1 < 10 ? "0" + each["date"] + "/0" + (parseInt(each["month"]) + 1).toString() + "/" + each["year"] : (each["date"] >= 10 && each["month"] + 1 >= 10 ?  each["date"] + "/" + (parseInt(each["month"]) + 1).toString() + "/" + each["year"] : (each["date"] < 10 ? "0" + each["date"] + "/" + (parseInt(each["month"]) + 1).toString() + "/" + each["year"] : each["date"] + "/0" + (parseInt(each["month"]) + 1).toString() + "/" + each["year"]))
                            ) ? (
                              <div className="dot"></div>
                            ) : (
                              <div className="dot trans"></div>
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      ref={ref}
      className={isLoading ? "custom_calender load_opacity" : "custom_calender"}
    >
      <div className="calender_box">{renderDatePart()}</div>
    </div>
  );
};

export default CustomCalender;
