import React, { useState } from "react";
import { getAllBookings } from "../../services/booking_service";

export const BookingContext = React.createContext();

export const BookingProvider = ({ children }) => {
  const [bookingList, setbookingList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBookings = async (refresh) => {
    if (refresh === true || bookingList.length === 0) {
      console.log("getBookings");
      setLoading(true);
      const bookings = await getAllBookings();
      setbookingList(bookings);
      setLoading(false);
    }
  };

  return (
    <BookingContext.Provider value={{ loading, bookingList, getBookings }}>
      {children}
    </BookingContext.Provider>
  );
};
