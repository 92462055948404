import React, { useEffect, useState } from "react";
import { auth } from "../../firebase/firebase.config";
import { checkProfileisCompleted } from "../../services/auth_service";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setloggedIn] = useState(false);
  const [isProfileCompleted, setisProfileCompleted] = useState(true);
  const [loading, setLoading] = useState(false);

  const [authloading, setauhthloading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // logout();

        checkProfileisCompleted(user.uid).then((isProfileCompleted) => {
          if (!isProfileCompleted) {
            setisProfileCompleted(false);
          }
          setloggedIn(true);
          setauhthloading(false);
        });
      } else {
        setloggedIn(false);
        setauhthloading(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{ loggedIn, isProfileCompleted, loading, setLoading, authloading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
