import React, { useState } from "react";
import Icon from "../Icon";
import Rating from "react-rating";
import { errorAlert, successAlert } from "../../utils/alert";
import { currentLocalDate } from "../../utils/function";
import { getCurrentLoginUserId } from "../../services/auth_service";
import { getServerTimestamp } from "../../services/firestore_service";
import { submitReview } from "../../services/review_service";
import Loader from "../Loader";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";

const AddReviewModal = ({ setshowAddReviewDialog, booking, onDone }) => {
  const { bussinessUserProfile } = booking;
  const [reviewText, setReviewText] = useState("");

  const [rating, setRating] = useState(0);

  const [loading, setLoading] = useState(false);
  const translate = useLocalization();
  return (
    <div className="add-review-modal">
      <div className="add-review-modal-content">
        <span
          className="add-review-modal-close-icon"
          onClick={() => setshowAddReviewDialog(null)}
        >
          <Icon name="cancel" />
        </span>
        {loading ? (
          <Loader marginTop={"180px"} />
        ) : (
          <section className="content-section flex-column">
            <h2 className="heading">{translate(labels.writeReview)}</h2>
            <p className="desc">
              {`How was your experience\nabout ${
                bussinessUserProfile.firstName ?? ""
              } ${bussinessUserProfile.lastName ?? ""} ?`}
            </p>
            <Rating
              initialRating={rating}
              emptySymbol="fa fa-star-o fa-2x"
              fullSymbol="fa fa-star fa-2x"
              onChange={(v) => {
                setRating(v);
              }}
            />
            <textarea
              type="text"
              onChange={(e) => setReviewText(e.target.value)}
              placeholder={translate(labels.writeReview)}
              rows={7}
            />
            <button
              onClick={async () => {
                try {
                  if (rating === 0) {
                    errorAlert(translate(labels.pleaseProvideRating));
                    return;
                  }
                  const review = {
                    bookingId: booking.bookingId,
                    reviewBussinessReply: "",
                    reviewBussinessReported: false,
                    reviewId: currentLocalDate(),
                    reviewStars: Number.parseFloat(rating),
                    reviewText: reviewText,
                    userId: getCurrentLoginUserId(),
                    bussinessUserId: booking.bussinessUserId,
                    timestamp: getServerTimestamp(),
                  };
                  console.log(rating);
                  console.log(review);
                  setLoading(true);
                  await submitReview(review);
                  setLoading(false);
                  setshowAddReviewDialog(null);
                  successAlert(translate(labels.thanksForReview));
                  onDone(true);
                  // getBookings(true);
                } catch (e) {
                  errorAlert(e);
                }
              }}
              className="primary__btn"
            >
              {translate(labels.submit)}
            </button>
          </section>
        )}
      </div>
    </div>
  );
};

export default AddReviewModal;
