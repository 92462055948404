import React, { useEffect, useState } from "react";
import { getCurrentLoginUserId } from "../../../services/auth_service";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import MessageTile from "./MessageTile";
import Loader from "../../Loader";
import ChatFooter from "./ChatFooter";
import { getGroupChatId } from "../../../services/chat_service";
import ChatRoomHeader from "./ChatRoomHeader";

const ChatRoom = ({ bussinessProfile, setChatBoxActive }) => {
  const [message, setmessages] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const myid = getCurrentLoginUserId();
 // const groupChatId = getGroupChatId(myid, bussinessProfile.uid);


  const groupChatId = "nNOZOY1Mx2crsslfZU7yMSf4RqN2-njVXQU3CAgO5JAkZGUx7A13Ju372_B"

  function isNumberInRange(number, start, end) {
    return number >= start && number <= end; // Adjust the range as per your requirements
  }
  function safeSubtract(a, b) {
    console.log(a, b);
    if (b > a) {
      return b - a;
    } else {
      return a - b;
    }
  }
  const handleScroll = (e) => {
    const value = safeSubtract(
      Math.abs(e.target.scrollHeight),
      Math.abs(e.target.scrollTop)
    );
    const clientHeight = Math.abs(e.target.clientHeight);
    const isTop = isNumberInRange(value, clientHeight - 5, clientHeight + 5);

    if (isTop) {
      onLoadMore();
      // setLoadMore(loadMore + 10);
    }
  };
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null); //is used for load more feature to start after the last saved item
  // const [loadMore, setLoadMore] = useState(10); //certain actions are performed in useFirestore.jsx in order to make loadMore properly work

  const onLoadMore = () => {
    const db = getFirestore();
    const docRef = doc(db, "Conversations", groupChatId);
    const colRef = collection(docRef, groupChatId);

    let q = query(
      colRef,
      orderBy("timestamp", "desc"),
      startAfter(lastVisibleDoc),
      limit(10)
    );

    getDocs(q).then((querySnapshot) => {
      const lists = [];
      querySnapshot.forEach((e) => lists.push(e.data()));
      setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      setmessages(message.concat(lists));
    });

    // setmessages(message.concat(newmessages));
  };
  useEffect(() => {
    setmessages([]);
    setisLoading(true);

    const db = getFirestore();
    const docRef = doc(db, "Conversations", groupChatId);
    const colRef = collection(docRef, groupChatId);

    let q = query(colRef, orderBy("timestamp", "desc"), limit(10));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let newmessages = [];
      querySnapshot.forEach((doc) => {
        newmessages.push({ ...doc.data(), id: doc.id });
      });
      setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      setisLoading(false);

      setmessages(newmessages);
    });
    return () => unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bussinessProfile]);

  return (
    <div className="chatroom">
      <ChatRoomHeader
        bussinessProfile={bussinessProfile}
        setChatBoxActive={setChatBoxActive}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div onScroll={handleScroll} className="chatroom__body">
          {message.map((chat, i) => (
            <MessageTile key={chat.id} chat={chat} groupChatId={groupChatId} />
          ))}
        </div>
      )}
      {isLoading ? null : <ChatFooter bussinessProfile={bussinessProfile} />}
    </div>
  );
};

export default ChatRoom;
