import React, { useCallback, useState } from "react";
import SelectInput from "../SelectInput";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import ImagePreview from "./ImagePreview";

const Portfolio = ({ userBusiness }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const translate = useLocalization();

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    // document.body.style.overflow = "hidden";
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    // document.body.style.overflow = "auto";
  };
  // document.addEventListener("mousedown", closeImageViewer);
  return (
    <div className="provider__col">
      <div className="provider-header">
        <h3 className="provider-heading">{translate(labels.portfolio)}</h3>
        <SelectInput id="search-sort-order">
          <option value="newest">
            {translate(labels.newestFirst)}
          </option>
          <option value="oldest">
            {translate(labels.oldestFirst)}
          </option>          
        </SelectInput> 
      </div>

      <div className="portfolio__provider">
        <div className="portfolio">
          {/* <h4 className="portfolio__heading">Wedding photo services</h4>
          <span className="portfolio__date">21.10.2020</span> */}
          <div className="portfolio__images">
            {userBusiness === undefined ||
            userBusiness === null ||
            userBusiness.workResults === undefined ||
            userBusiness.workResults.length === 0 ? (
              <div className="no_review" style={{ width: "100%" }}>
                <h5>{translate(labels.no_portfolio_found)}</h5>
                <p>{translate(labels.new_portfolio_display_here)}</p>
                {/* <ChatIcon
                  width={30}
                  height={30}
                  className="provider-header-icon svg-icons"
                /> */}
              </div>
            ) : (
              userBusiness.workResults.map((photo, i) => (
                <img
                  onClick={() => openImageViewer(i)}
                  key={i}
                  src={photo}
                  alt={"Portfolio Image" + i}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {isViewerOpen && (
        <ImagePreview
          closeImageViewer={closeImageViewer}
          // imageSrc={userBusiness.workResults[currentImage]}
          currentIndex={currentImage}
          list={userBusiness.workResults}
        />
      )}
    </div>
  );
};

export default Portfolio;
