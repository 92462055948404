import React from "react";
import Icon from "../Icon";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  VKShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { successAlert } from "../../utils/alert";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";

const ShareModal = ({ link, openShareModal }) => {
  const shareUrl = link;
  const translate = useLocalization();

  return (
    <div className="share-modal">
      <div className="share-modal-content">
        <span
          className="share-modal-close-icon"
          onClick={() => openShareModal(null)}
        >
          <Icon name="cancel" />
        </span>
        <div className="share-body">
          <h2>{translate(labels.shareuserprofile)}</h2>
          <span>{translate(labels.orcopylink)}</span>
          <div className="button-wrapper">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>

            <VKShareButton url={shareUrl}>
              <VKIcon size={40} round={true} />
            </VKShareButton>

            <EmailShareButton url={shareUrl}>
              <EmailIcon size={40} round={true} />
            </EmailShareButton>

            <FacebookShareButton url={shareUrl}>
              <FacebookMessengerIcon size={40} round={true} />
            </FacebookShareButton>
          </div>
          <div className="copy-link">
            <span>{shareUrl}</span>
            {/* <button>Copy</button> */}
            <CopyToClipboard
              text={shareUrl}
              onCopy={() => {
                successAlert(translate(labels.linkcopied));
              }}
            >
              <button>{translate(labels.copy)}</button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
