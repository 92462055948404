import React from 'react';

const SelectInput = ({ children, id }) => {
  return (
    <div className="select__form-group">
      <img
        src={require('../assets/svg icons/down-caret.svg').default}
        alt="down caret icon"
        className="select__caret"
      />
      <select name={`selector-${id}`} id={`selector-${id}`} className="select">
        {children}
      </select>
    </div>
  );
};

export default SelectInput;
