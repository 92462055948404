import React from "react";
import RecommendedCard from "../../../components/RecommendedCard";
import SelectInput from "../../../components/SelectInput";
import Footer from "../../../components/Footer";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";
const SearchSubSlotPageResult = () => {
  const translate = useLocalization();

  return (
    <div className="main">
      <div className="search__results-section">
        <div className="search__stats">
          <h3 className="tertiary-heading">
            {translate(labels.searchResults)} : 150
          </h3>
          <SelectInput id="search-sort-order">
            <option value="Highest review">
              {translate(labels.highestReview)}
            </option>
          </SelectInput>
          <div className="paging">
            <span className="current__page">1</span>z
            <span className="total__pages">50</span>
          </div>
        </div>
        <RecommendedCard />
        <RecommendedCard />
        <RecommendedCard />
      </div>
      <Footer />
    </div>
  );
};

export default SearchSubSlotPageResult;
