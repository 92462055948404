import React from 'react';

const Divider = ({ title }) => {
  return (
    <div className="divider">
      <span className="divider__text">{title}</span>
    </div>
  );
};

export default Divider;
