import React, { useState } from "react";

const ImagePreview = ({ currentIndex, list, closeImageViewer }) => {
  const [visibleImage, setVisibleImage] = useState(currentIndex);
  return (
    <div className="image-preview">
      <div className="image-preview-content">
        <img
          key={visibleImage}
          src={list[visibleImage]}
          alt={list[visibleImage]}
        />
        <div onClick={() => closeImageViewer()} className="image-preview-close">
          &times;
        </div>
        <div
          onClick={() => {
            const newIndex = visibleImage + 1;

            if (newIndex < list.length) {
              setVisibleImage(newIndex);
            } else {
              setVisibleImage(0);
            }
          }}
          className="arrow-wrapper-right"
        >
          <i className="arrow_right"></i>
        </div>
        <div
          onClick={() => {
            const newIndex = visibleImage - 1;

            if (newIndex >= 0 && newIndex < list.length) {
              setVisibleImage(newIndex);
            } else {
              setVisibleImage(list.length - 1);
            }
          }}
          className="arrow-wrapper-left"
        >
          <i className="arrow_left"></i>
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
