import React, { useEffect, useState } from "react";
import Icon from "../../../../components/Icon";
import TextField from "../../../../components/TextField";
import ImagePicker from "../../../../components/ImagePicker";
import { combinecheckObjectisSame } from "../../../../utils/function";
import { userCollection } from "../../../../utils/collections";
import {
  updateFirestoreDoc,
  uploadSingleImageToStorage,
} from "../../../../services/firestore_service";
import { errorAlert, successAlert } from "../../../../utils/alert";
import { labels } from "../../../../utils/locale";
import {
  checkProfileisCompleted,
  getUserData,
  logout,
} from "../../../../services/auth_service";
import { useForm } from "react-hook-form";
import { useLocalization } from "../../../../helpers/localization";
import AddressAutoCompleteField from "./AddressAutoCompleteField";
import Loader from "../../../../components/Loader";

const ProfileCompleteForm = ({ onSubmitSuccess }) => {
  const [isLoading, setisLoading] = useState(false);
  const isUpdating = true;
  const translate = useLocalization();

  const [selectedAddressList, setselectedAddressList] = useState([]);
  const [addresstoedit, setaddresstoedit] = useState(null);
  const userProfile = getUserData();

  useEffect(() => {
    if (userProfile === null) {
      return;
    }
    if (
      userProfile.address !== undefined &&
      userProfile.address !== null &&
      userProfile.address.length > 0
    ) {
      var list = [];

      userProfile.address.forEach((address) => {
        const a = JSON.parse(address);
        list.push(a);
      });

      setselectedAddressList(list);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormDefaultValues = () => {
    let data = {};
    if (userProfile === null) {
      return;
    }

    if (userProfile?.firstName !== null) {
      data["firstName"] = userProfile.firstName;
    }
    if (userProfile?.lastName !== null) {
      data["lastName"] = userProfile.lastName;
    }
    if (userProfile?.phone !== null) {
      data["phone"] = userProfile.phone;
    }
    if (userProfile?.profilePic !== null) {
      data["profilePic"] = userProfile.profilePic;
    }

    return data;
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: getFormDefaultValues(),
  });

  const onSubmit = async (data) => {
    try {
      var addressList = [];
      selectedAddressList.forEach((address) => {
        addressList.push(JSON.stringify(address));
      });

      var newData = {
        firstName: data.firstName,
        isProfileCompleted: true,
        lastName: data.lastName,
        address: addressList,
        profilePic: data.profilePic,
        phone: data.phone,
      };

      if (combinecheckObjectisSame(userProfile, newData)) {
        return;
      }
      setisLoading(true);

      if (data.profilePic !== null) {
        const profile = await uploadSingleImageToStorage(
          userCollection,
          data.profilePic
        );
        newData["profilePic"] = profile;
      }
      console.log("newData", newData);
      await updateFirestoreDoc(userCollection, userProfile.uid, newData);

      onSubmitSuccess(true);
      successAlert(translate(labels.profileUpdated));
      await checkProfileisCompleted(userProfile.uid);
      window.location.reload();
    } catch (e) {
      errorAlert(e.toString());
    }
    setisLoading(false);
  };
  if (userProfile === null) {
    return <></>;
  } else if (isLoading) {
    return <Loader />;
  }
  return (
    <form className="profile-complete-form" onSubmit={handleSubmit(onSubmit)}>
      <ImagePicker
        register={register}
        watch={watch}
        errors={errors}
        required={!isUpdating}
        fieldKey={"profilePic"}
      />

      <div className="form-body form-items">
        <TextField
          register={register}
          label={translate(labels.firstName)}
          required={true}
          errors={errors}
          fieldKey="firstName"
        />
        <TextField
          register={register}
          label={translate(labels.lastName)}
          required={true}
          errors={errors}
          fieldKey="lastName"
        />
        <TextField
          register={register}
          label={translate(labels.phoneOptional)}
          required={false}
          errors={errors}
          fieldKey="phone"
          type="phone"
        />

        <AddressAutoCompleteField
          selectedAddress={
            addresstoedit !== null
              ? addresstoedit.address
              : selectedAddressList.length === 0
              ? null
              : selectedAddressList.at(-1).address
          }
          onSelect={(address) => {
            if (addresstoedit !== null) {
              const newList = selectedAddressList.map((item) => {
                if (item.address === addresstoedit.address) {
                  item = address;
                  return item;
                }

                return item;
              });

              setselectedAddressList(newList);
              setaddresstoedit(null);
            } else {
              var list = [];

              list.push(address);
              console.log(list);
              setselectedAddressList([...selectedAddressList, address]);
              console.log(selectedAddressList);
            }
          }}
        />
        <div className="address-preview-list">
          {selectedAddressList.map((address, i) => {
            return (
              <span className="item" key={i}>
                {address.address}

                <div className="edit-row">
                  <Icon
                    onClick={() => {
                      setaddresstoedit(address);
                    }}
                    name="edit"
                    className="edit-icon"
                  />
                  <Icon
                    onClick={() => {
                      console.log("ds");
                      const newList = selectedAddressList.filter(
                        (item) => item.address !== address.address
                      );

                      setselectedAddressList(newList);
                    }}
                    name="cancel-circle"
                    className="edit-icon"
                  />
                </div>
              </span>
            );
          })}
        </div>
        <div
          style={{
            marginTop: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="submit"
            className="primary__btn"
            style={{ padding: "20px", fontSize: "18px", width: "150px" }}
          ></input>
          {/* <button
                className="primary__btn"
                style={{ padding: "20px", fontSize: "18px" }}
              >
                Submit
              </button> */}
        </div>
      </div>
    </form>
  );
};

export default ProfileCompleteForm;
