import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { errorAlert } from "../../utils/alert";
import { getTranslatedText } from "../../services/google_translate_service";
import { updateTranslateMessage } from "../../services/chat_service";

const BasicTranslateTile = ({ conversation, groupChatId }) => {
  const { locale = "en" } = useParams();
  const [isTranslating, setisTranslating] = useState(false);
  const currentlanguage = locale;
  const basicmessage = conversation.content ?? "";
  const checkBasicMessageisSameWithTranslatiedOne =
    conversation.base_language === currentlanguage;
  const translatedMessage = conversation["translated_message"];

  const transMessage = async () => {
    try {
      setisTranslating(true);
      console.log("fromCode", Object.keys(translatedMessage));

      let fromCode;
      if (translatedMessage != null) {
        var key = Object.keys(translatedMessage).find(
          (k) => translatedMessage[k] === basicmessage
        );
        if (key != null) {
          fromCode = key;
        }
      }
      console.log("fromCode", fromCode);
      const translatedText = await getTranslatedText(
        basicmessage,
        fromCode,
        locale
      );

      await updateTranslateMessage(
        translatedText,
        groupChatId,
        conversation.id,
        locale
      );
    } catch (e) {
      errorAlert("Error in translating");
    }
    setisTranslating(false);
  };
  if (isTranslating) {
    return <span>Translating...</span>;
  } else if (checkBasicMessageisSameWithTranslatiedOne) {
    return null;
  } else if (
    translatedMessage === undefined ||
    translatedMessage === null ||
    (currentlanguage in translatedMessage &&
      translatedMessage[currentlanguage] === null)
  ) {
    return <span onClick={transMessage}>Translate to {currentlanguage}</span>;
  }
  return <span className="translated_in">Translated in {currentlanguage}</span>;
};

export default BasicTranslateTile;
