import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer';
import { useLocalization } from '../../helpers/localization';
import { labels } from '../../utils/locale';
import { useParams } from 'react-router-dom';
const RegistrationTwo = (props) => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [password, setPassword] = useState('');
  const translate = useLocalization();
  const { locale = 'en' } = useParams();
  return (
    <div className='registration'>
      <form action='#' className='primary-form'>
        <h3 className='secondary-heading mg-bottom-medium'>
          {translate(labels.enterYourDetails)}
        </h3>
        <div className='form__group'>
          <label htmlFor='name-input' className='primary-label'>
            {translate(labels.firstAndLastName)}
          </label>
          <div className='primary__input'>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type='text'
              className='primary__input-content'
              id='name-input'
              placeholder='First and last name'
            />
          </div>
        </div>
        <div className='form__group'>
          <label htmlFor='dob-input' className='primary-label'>
            {translate(labels.dateOfBirth)}
          </label>
          <div className='primary__input'>
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type='date'
              className='primary__input-content'
              id='dob-input'
            />
          </div>
        </div>
        <div className='form__group'>
          <label htmlFor='password-input' className='primary-label'>
            {translate(labels.password)}
          </label>
          <div className='primary__input'>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              className='primary__input-content'
              id='password-input'
            />
          </div>
        </div>
        <NavLink to={`/${locale}/client`} className='primary__btn'>
          {translate(labels.createAnAccount)}
        </NavLink>
      </form>
      <Footer />
    </div>
  );
};

export default RegistrationTwo;
