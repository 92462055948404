import { httpsCallable } from "firebase/functions";
import { cloudFunction } from "../firebase/firebase.config";

export const getSearchSuggestion = async (text, locale) => {
  try {
    const callable = httpsCallable(cloudFunction, "getSuggestions");

    const input = {
      inputText: text,
      langCode: locale,
    };
    console.log("input", input);
    const result = await callable(input);
    const suggestions = result.data ?? {};
    console.log("slots", suggestions);
    return suggestions;
  } catch (e) {
    throw Error(e);
  }
};
