import imageCompression from "browser-image-compression";
import _ from "lodash";
import { labels } from "./locale";
import moment from "moment";


export const serviceDurations = [
  { name: "30 min", value: 30 },
  { name: "45 min", value: 45 },
  { name: "1 h", value: 60 },
  { name: "1 h 30 min", value: 90 },
  { name: "2 h", value: 120 },
  { name: "2 h 30 min", value: 150 },
  { name: "3 h", value: 180 },
  { name: "3 h 30 min", value: 210 },
  { name: "4 h", value: 240 },
  { name: "4 h 30 min", value: 270 },
  { name: "5 h", value: 300 },
  { name: "5 h 30 min", value: 330 },
  { name: "6 h", value: 360 },
  { name: "6 h 30 min", value: 390 },
  { name: "7 h", value: 420 },
  { name: "7 h 30 min", value: 350 },
  { name: "8 h", value: 480 },
];

export const getDurationsText = (value) => {
  return serviceDurations.find((e) => e.value === value)?.name ?? "";
};

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const getUserFullName = (profile) => {
  if (profile === undefined || profile === null) return "";
  return `${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`;
};
export const stringToLangMap = (query) => {
  let data = {};
  languagesList.forEach((language) => {
    data[language] = query;
  });

  return data;
};

export const formatLocalTime = (time) => {
  if (time === undefined || time === null) return "";
  return time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
};

export const hashCode = (s) => {
  if (s === undefined) return 0;
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

export const languagesList = ["en", "pl", "uk", "ru"];

export const combinecheckObjectisSame = (initialData, newData) => {
  let validationAfterCombine = { ...initialData, ...newData };
  console.log("newData", newData);
  // let validationAfterCombine = _.merge(newData, initialData);
  console.log(initialData);
  console.log(validationAfterCombine);
  console.log(JSON.stringify(initialData).length);
  console.log(JSON.stringify(validationAfterCombine).length);
  console.log(_.isEqual(initialData, validationAfterCombine));
  // console.log(diff(initialData, validationAfterCombine));
  if (_.isEqual(initialData, validationAfterCombine)) {
    console.log("sssdsme");
    // return true;
  }
  if (JSON.stringify(initialData) === JSON.stringify(validationAfterCombine)) {
    console.log("dsme");
    return true;
  }
  return false;
};

export const compressImageFile = async (image) => {
  console.log("image", image);
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  };
  try {
    const compressedFile = await imageCompression(image, options);
    console.log(compressedFile.size / 1024 / 1024);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};

export function getExtension(filename) {
  return filename.split(".").pop();
}

export const combineName = (profile) => {
  if (profile === undefined || profile === null) return "";
  const { firstName, lastName } = profile;
  const first = firstName === undefined || firstName === null ? "" : firstName;
  const last = lastName === undefined || lastName === null ? "" : lastName;
  return first + " " + last;
};

export const minutuestoHoursString = (minutues, translate) => {
  let text = "";
  const hourText = translate(labels.godz);
  const minutuesText = translate(labels.min);

  switch (minutues) {
    case 15:
      text = `15 ${minutuesText}`;
      break;
    case 30:
      text = `30 ${minutuesText}`;
      break;
    case 45:
      text = `45 ${minutuesText}`;
      break;
    case 60:
      text = `1 ${hourText}`;
      break;
    case 90:
      text = `1 ${hourText} 30 ${minutuesText}`;
      break;
    case 120:
      text = `2 ${hourText}`;
      break;
    case 180:
      text = `3 ${hourText}`;
      break;
    case 240:
      text = `4 ${hourText}`;
      break;
    case 300:
      text = `5 ${hourText}`;
      break;
    case 360:
      text = `6 ${hourText}`;
      break;
    case 420:
      text = `7 ${hourText}`;
      break;
    case 480:
      text = `8 ${hourText}`;
      break;
    default:
      text = `O`;
      break;
  }
  return text;
};

export const getTwoDatesDifferenceinSeconds = (start, end) => {
  console.log("start", start, end);
  var duration = moment.duration(moment(start).diff(moment(end)));
  var seconds = duration.asSeconds();
  return seconds;
};
export function currentLocalDate(format) {
  var formatted = moment(new Date()).format(format ?? "YYYY-MM-DDTHH:mm:ss");
  return formatted;
}
export function currentUtcDate(format) {
  var formatted = moment(new Date())
    .utc()
    .format(format ?? "YYYY-MM-DDTHH:mm:ss");
  return formatted;
}

export function formatDate(date, format, utc) {
  if (utc === true) {
    return moment(date)
      .utc()
      .format(format ?? "YYYY-MM-DDTHH:mm:ss");
  }
  var formatted = moment(date).format(format ?? "YYYY-MM-DDTHH:mm:ss");
  return formatted;
}
export function addMinutuesinDate(date, minutues, format) {
  var newTime = moment(date)
    .add(minutues ?? 30, "minutes")
    .format(format ?? "YYYY-MM-DDTHH:mm:ss");
  return newTime;
}
export function addMinutuesinDateInUtc(date, minutues, format) {
  return moment(date)
    .utc()
    .add(minutues ?? 30, "minutes")
    .format(format ?? "YYYY-MM-DDTHH:mm:ss");
}
export function subtractDurationinDate(type, date, value, format) {
  var newTime = moment(date)
    .subtract(value ?? 0, type)
    .format(format ?? "YYYY-MM-DDTHH:mm:ss");
  return newTime;
}
export function subtractDurationinDateInUtc(type, date, value, format) {
  return moment(date)
    .utc()
    .subtract(value ?? 0, type)
    .format(format ?? "YYYY-MM-DDTHH:mm:ss");
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  console.log("laty!!!!!!");
  console.log("lat1: ", lat1);
  console.log("lon1: ", lon1);
  console.log("lat2: ", lat2);
  console.log("lon2: ", lon2);
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
export function wait(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}
function getDateDay(date) {
  console.log("date", date);
  var day = moment(date).format("dddd");
  return day;
}

export function checkUserHaveWorkingHours(workingHours, date) {
  if (workingHours !== undefined) {
    var day = getDateDay(date);
    console.log("day", day);
    const index = getIndexOfDay(day);

    const workingHoursDay = workingHours[index];

    if (workingHoursDay !== undefined && workingHoursDay.isWorking) {
      return true;
    }
  }
  return false;
}

function getIndexOfDay(day) {
  switch (day) {
    case "Monday":
      return 0;
    case "Tuesday":
      return 1;
    case "Wednesday":
      return 2;
    case "Thursday":
      return 3;
    case "Friday":
      return 4;
    case "Saturday":
      return 5;
    case "Sunday":
      return 6;
    default:
      return -1;
  }
}

export function formatTimeAgo(timestamp) {
  const currentTimestamp = Date.now();
  const diffInMilliseconds = currentTimestamp - timestamp;

  const millisecondsPerMinute = 60 * 1000;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;

  if (isSameDay(currentTimestamp, timestamp)) {
    const formattedTime = new Date(timestamp).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedTime;
  } else if (diffInMilliseconds < millisecondsPerHour) {
    const diffInMinutes = Math.floor(
      diffInMilliseconds / millisecondsPerMinute
    );
    return diffInMinutes + " minutes ago";
  } else if (diffInMilliseconds < millisecondsPerDay) {
    const diffInHours = Math.floor(diffInMilliseconds / millisecondsPerHour);
    return diffInHours + " hours ago";
  } else {
    // const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay);
    return formatDate(timestamp, "lll");
  }
}

function isSameDay(timestamp1, timestamp2) {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const shuffleMonthDayDate = (date) => {
  const d = date.split("/");
  const day = d[1];
  const month = d[0];
  const year = d[2];
  return `${day}/${month}/${year}`;
};

export const shuffleMonthDayDateDash = (date) => {
  const d = date.split("-");
  const day = d[2];
  const month = d[1];
  const year = d[0];
  return `${day}/${month}/${year}`;
};

export function formatEventDate(date, format) {
  var formatted = moment(date, "DD/MM/YYYY").format(format ?? "YYYY-MM-DD");
  return formatted;
}
