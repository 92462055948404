import React, { useEffect, useState } from "react";
import { languagesList } from "../../utils/function";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import {
  getServerTimestamp,
  uploadSingleImageToStorage,
} from "../../services/firestore_service";

import {
  getCurrentLoginUserId,
  getUserData,
} from "../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { sendMessage } from "../../services/chat_service";

const BasicChatFooter = ({ bussinessProfile, regularProfile, groupChatId }) => {
  const myProfile = getUserData();
  //const myid = getCurrentLoginUserId();
  const [input, setinput] = useState("");
  const { locale = "en" } = useParams();
  const translate = useLocalization();

 // const groupChatId = getGroupChatId(regularProfile.uid, bussinessProfile.uid);

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [sendingMessage, setsendingMessage] = useState(false);
  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  const getTranslationMap = (message) => {
    var appLanguages = languagesList;
    let data = {};
    for (var language in appLanguages) {
      if (locale === language) {
        data[language] = message;
        continue;
      } else {
        data[language] = null;
      }
    }
    return data;
  };

  const SendMessage = async (event) => {
    event.preventDefault();
    const disable = sendingMessage
      ? true
      : selectedFile || input
      ? false
      : true;
    if (disable) {
      return;
    }
    let type = 0;

    var messageData = {
      id: "",
      idFrom: regularProfile.uid,
      idTo: bussinessProfile.uid,
      timestamp: getServerTimestamp(),
      content: input,
      type: type,
      senderName: regularProfile.firstName + " " + regularProfile.lastName,
      receiverToken:
        bussinessProfile.pushToken === undefined ? null : bussinessProfile.pushToken,
      translated_message: type === 0 ? getTranslationMap(input) : null,
      base_language: locale,
    };
    messageData[regularProfile.uid] = "unread";
    messageData[bussinessProfile.uid] = "unread";
    console.log("messageData ", messageData);
    if (selectedFile) {
      setsendingMessage(true);
      type = 1;
      const imageUrl = await uploadSingleImageToStorage(
        "Conversations/" + groupChatId,
        [selectedFile]
      );

      messageData["content"] = imageUrl;
      messageData["type"] = 1;
      setSelectedFile();
      setsendingMessage(false);
    } else {
      setinput("");
    }
    console.log(messageData, groupChatId);

    await sendMessage(messageData, groupChatId, [regularProfile.uid, bussinessProfile.uid]);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <div className="chatroom__footer">
      <form>
       <div className="chat_image_pick" style={{ marginLeft: "10px" }}>
          <input type="file" accept="image/*" onChange={onSelectImage}></input>
        </div>
     
        <div className="chatroom_input_image">
          {selectedFile && (
            <div className="chat_image_preview">
              <div className="image_tile">
                <img src={preview} alt="imagePreview" />
                <div
                  onClick={() => {
                    setSelectedFile();
                    // setPreview(null);
                  }}
                  className="image_remove"
                ></div>
              </div>
            </div>
          )}
          <input
            value={input}
            onChange={(event) => setinput(event.target.value)}
            placeholder={translate(labels.typeMessageHere)}
          />
        </div>
        {
          <button
            disabled={
              sendingMessage ? true : selectedFile || input ? false : true
            }
            style={{
              marginLeft: "0.5rem",
              // marginTop: "5px",
              marginBottom: "10px",
              marginRight: "1.5rem",
              //   backgroundColor: `${sendingMessage && "grey"}`,
            }}
            onClick={SendMessage}
            type="submit"
          >
            <img
              // style={{ height: "1.5rem", width: "1.5rem" }}
              src={require("../../assets/icon-send.png")}
              alt="favourite icon"
            />
          </button>
        }
      </form>
    </div>
  );
};

export default BasicChatFooter;
