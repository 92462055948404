/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { getSearchSuggestion } from "../../services/search_service";
import { ActionState } from "../../utils/enum";
import { labels } from "../../utils/locale";
import { useLocalization } from '../../helpers/localization';

export const SearchContext = React.createContext();

export const SearchProvider = ({ children }) => {
  const translate = useLocalization();
  const [suggestionChipsList, setsuggestionChipsList] = useState([]);
  const [searchList, setsearchList] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [searchState, setsearchState] = useState(ActionState.Initial);

  const getSuggestionChips = async (locale) => {
    try {
      if (suggestionChipsList.length === 0) {
        setsearchState(ActionState.Loading);
        const data = await getSearchSuggestion("", locale ?? "en");
        const categories = data.categories ?? [];

        setsuggestionChipsList(categories);
        setsearchState(ActionState.Loaded);
      }
    } catch (e) {
      setsearchState(ActionState.Error);
    }
  };

  const getSearchList = async (text, locale) => {
    try {
      setsearchList([]);
      if (text.length > 0) {
        setsearchState(ActionState.Loading);
        const suggestion = await getSearchSuggestion(text, locale ?? "en");
        let list = [];
        if (suggestion?.names != null) {
          list.push({ name: translate(labels.serviceProviders), list: suggestion?.names ?? [] });
        }
        if (suggestion?.services != null) {
          list.push({ name: translate(labels.services), list: suggestion?.services ?? [] });
        }
        if (suggestion?.categories != null) {
          list.push({ name: translate(labels.categories), list: suggestion?.categories ?? [] });
        }
        setsearchList(list);
        setsearchState(ActionState.Loaded);
      }
    } catch (e) {
      setsearchState(ActionState.Error);
    }
  };

  const onChipSelect = (chip) => {
    onSearch(chip);
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const onSearchCallback = useCallback(
    debounce((text, locale) => {
      getSearchList(text, locale);
    }),
    []
  );
  const onSearch = (text, locale) => {
    setsearchText(text);
    onSearchCallback(text, locale);
  };

  return (
    <SearchContext.Provider
      value={{
        suggestionChipsList,
        getSuggestionChips,
        getSearchList,
        searchList,
        onChipSelect,
        onSearch,
        searchText,
        setsearchText,
        searchState,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
