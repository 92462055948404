export const labels = {
  contactUs: {
    en: "contact us",
    pl: "Kontakt",
  },
  stillhaveQuestion: {
    en: "Still have questions? Get in touch with us.",
    pl: "Nadal masz pytania? Skontaktuj się z nami.",
  },
  address: {
    en: "Hauzy, Ogrodowa 12, 61-821 Poznan, Poland",
    pl: "Hauzy, Ogrodowa 12, 61-821 Poznań, Polska",
  },
  sendemail: {
    en: "info@hauzy.app",
    pl: "info@hauzy.app ",
  },
  firtAndLastName: {
    en: "First and last name",
    pl: "Imię i Nazwisko",
  },
  eEmailAddress: {
    en: "E-mail address",
    pl: "Adres e-mail",
  },
  subject: {
    en: "Subject",
    pl: "Temat",
  },
  message: {
    en: "Message",
    pl: "Wiadomość",
  },
  termAndCondition: {
    en: "Terms and conditions",
    pl: "Regulamin",
  },
  privacyPolicy: {
    en: "Privacy Policy",
    pl: "Polityka Prywatności",
  },
  send: {
    en: "Send",
    pl: "Wyślij",
  },
  firstAndLastName: {
    en: "First and last name",
    pl: "Imię i Nazwisko",
  },
  dateOfBirth: {
    en: "Date of birth",
    pl: "Data urodzenia",
  },
  email: {
    en: "Email",
    pl: "Adres e-mail",
  },
  city: {
    en: "City",
    pl: "Miasto",
  },
  saveChanges: {
    en: "Save changes",
    pl: "Zapisz zmiany",
  },
  yourDetails: {
    en: "Your details",
    pl: "Twój profil",
  },
  favourites: {
    en: "Favourites",
    pl: "Ulubione",
  },
  favouritesCatalog: {
    en: "Favourites catalog",
    pl: "Ulubione katalog",
  },
  accountSettings: {
    en: "Account settings",
    pl: "Ustawienia konta",
  },
  currentPassword: {
    en: "Current Password",
    pl: "Obecne hasło",
  },
  newPassword: {
    en: "New password",
    pl: "Nowe hasło",
  },
  repeatNewPassword: { en: "Repeat new password", pl: "Powtórz nowe hasło" },
  createAnAccount: {
    en: "Create a free account",
    pl: "Załóż darmowe konto",
  },
  selectDateOfTheEvent: {
    en: "Select date of the event",
    pl: "Wybierz datę",
  },
  selectServices: {
    en: "Select services",
    pl: "Wybierz usługę",
  },
  where: {
    en: "Where?",
    pl: "Gdzie?",
  },
  search: {
    en: "search",
    pl: "szukaj",
  },
  findAFreeSlot: {
    en: "Find a free slot",
    pl: "Znajdź wolny termin",
  },
  searchResults: {
    en: "Search results",
    pl: "Szukaj",
  },
  highestReview: {
    en: "Highest review",
    pl: "Najwyżej oceniane",
  },
  termsAndConditionsOfTheHauzyApp: {
    en: "Terms and conditions of the hauzy app",
    pl: "Regulamin Hauzy",
  },
  enterYourDetails: {
    en: "Enter your details",
    pl: "Wprowadź szczegóły",
  },
  signIn: {
    en: "Sign in",
    pl: "Zaloguj się",
  },
  mobileNumber: {
    en: "Mobile number *",
    pl: "Numer telefonu",
  },
  sendCode: {
    en: "Send Code",
    pl: "Wyśłij kod",
  },
  enterSmsCode: {
    en: "Enter SMS code",
    pl: "Wprowadź kod SMS",
  },
  invalidVerificationCode: {
    en: "Invalid Verification Code",
    pl: "Nieprawidłowy Kod Weryfikacyjny",
  },
  details: {
    en: "Details",
    pl: "Szczegóły",
  },
  services: {
    en: "services",
    pl: "usługi",
  },
  book: {
    en: "Book",
    pl: "Zarezerwuj",
  },
  description: {
    en: "Description",
    pl: "Opis",
  },
  chat: {
    en: "Contact the Service Provider",
    pl: "Skontaktuj się z Wykonawcą",
  },
  anyQuestions: {
    en: "Any Questions?",
    pl: "Masz pytania? Rozpocznij rozmowę z Wykonawcą",
  },
  shareProfile: {
    en: "Share profile",
    pl: "Udostępnij profil",
  },
  invalidBusinessUser: {
    en: "Invalid business user",
    pl: "Nieprawidłowy użytkownik",
  },
  backToMainPage: {
    en: "Back to main page",
    pl: "Powrót na stronę główną",
  },
  location: {
    en: "Location",
    pl: "Lokalizacja",
  },
  checkHowToGetThere: {
    en: "Check how to get there",
    pl: "Sprawdź, jak dojechać",
  },
  opinions: {
    en: "Opinions",
    pl: "Opinie",
  },
  highestRated: {
    en: "Highest rated",
    pl: "Najwyżej oceniane",
  },
  addYourOpinion: {
    en: "Add your opinion",
    pl: "Dodaj swoją opinie",
  },
  stars: {
    en: "stars",
    pl: "gwiazdki",
  },
  portfolio: {
    en: "Portfolio",
    pl: "Portfolio",
  },
  newestFirst: {
    en: "Newest first",
    pl: "Najnowsze najpierw",
  },
  allBookings: {
    en: "All Bookings",
    pl: "Wszystkie rezerwacje",
  },
  pending: {
    en: "Pending",
    pl: "Oczekująca",
  },
  confirmed: {
    en: "Confirmed",
    pl: "Potwierdzona",
  },
  cancelled: {
    en: "Cancelled",
    pl: "Odwołana",
  },
  cancel: {
    en: "Cancel",
    pl: "Anulowana",
  },
  generateReport: {
    en: "Generate Report",
    pl: "",
  },
  nameOfBussiness: {
    en: "Name of Bussiness",
    pl: "Nazwa Wykonawcy",
  },
  dateOrder: {
    en: "Date order",
    pl: "Data",
  },
  status: {
    en: "Status",
    pl: "Status",
  },
  showingData: {
    en: "Showing 10 from 46 data",
    pl: "Pokazuje 10 z 46 wyników",
  },
  previous: {
    en: "Previous",
    pl: "Poprzednia",
  },
  next: {
    en: "Next",
    pl: "Następna",
  },
  helpFor: {
    en: " Help For",
    pl: "Pomoc dla",
  },
  logout: {
    en: "Logout",
    pl: "Wyloguj się",
  },
  aboutUs: {
    en: "About us",
    pl: "O nas",
  },
  howDoesItWork: {
    en: "How does it work?",
    pl: "Jak to działa?",
  },
  termsOfService: {
    en: "Terms of service",
    pl: "Regulamin",
  },
  contact: {
    en: "Contact",
    pl: "Kontakt",
  },
  forBusiness: {
    en: "For Business",
    pl: "Dla Biznesu",
  },
  forClients: {
    en: "FOR ClIENTS",
    pl: "Dla klientów",
  },
  viewAllCategories: {
    en: "View all categories",
    pl: "Pokaż wszystkie kategorie",
  },
  allReviews: {
    en: "All Reviews",
    pl: "Wszystkie Opinie",
  },
  published: {
    en: "Published",
    pl: "Opublikowane",
  },
  deleted: {
    en: "Deleted",
    pl: "Usuniętę",
  },
  sortByRate: {
    en: "Sort by rate:",
    pl: "Sortuj wg daty",
  },
  showMoreDetails: {
    en: "Show more details",
    pl: "Pokaż więcej",
  },
  showLess: {
    en: "Show less",
    pl: "Pokaż mniej",
  },
  alreadyRegistered: {
    en: "Already registered ?",
    pl: "Masz już konto?",
  },

  recieveASMSCode: {
    en: "Recieve a SMS code",
    pl: "Otrzymaj wiadomość SMS",
  },
  yourBookings: {
    en: "Your bookings",
    pl: "Twoje rezerwacje",
  },
  welcomeToHauzy: {
    en: "Welcome to Hauzy",
    pl: "Witamy w Hauzy",
  },
  completeYourProfile: {
    en: "Complete your profile to make it attractive for users and increase the number of bookings.",
    pl: "",
  },
  noteForDescription: {
    en: "Note that the description and photos are crucial.",
    pl: "",
  },
  daysForFreeRemaining: {
    en: "days for free remaining",
    pl: "",
  },
  noSlotFound: {
    en: "No Slot found",
    pl: "Brak wolnych terminów",
  },
  save: {
    en: "Save",
    pl: "Zapisz",
  },
  addAddress: {
    en: "Add Address",
    pl: "Dodaj adres",
  },
  service: {
    en: "Service",
    pl: "Usługa",
  },
  avalibletime: {
    en: "Avalible time",
    pl: "Wolny termin",
  },
  reminder: {
    en: "Reminder",
    pl: "Przypomnienie",
  },
  noRecommendationsFound: {
    en: "No recommendations found in this category.",
    pl: "Nie znaleziono Wykonawcy w wybranej kategorii",
  },
  showOnlyAvailableToday: {
    en: "Show only available today",
    pl: "Pokaż tylko dostępne dzisiaj",
  },
  hours: {
    en: "Hours",
    pl: "Godziny",
  },
  minutues: {
    en: "Minutues",
    pl: "Minuty",
  },
  eightHoursebeforebookingStart: {
    en: "8 hours before booking start",
    pl: "8 godz. przed rozpoczęciem rezerwacji",
  },
  fourHoursebeforebookingStart: {
    en: "4 hours before booking start",
    pl: "4 godz. przed rozpoczęciem rezerwacji",
  },
  twoHoursebeforebookingStart: {
    en: "2 hours before booking start",
    pl: "2 godz. przed rozpoczęciem rezerwacji",
  },
  oneHoursebeforebookingStart: {
    en: "1 Hour before booking start",
    pl: "1 godz. przed rozpoczęciem rezerwacji",
  },
  halfHoursebeforebookingStart: {
    en: "30 minutes before booking start",
    pl: "30 min. przed rozpoczęciem rezerwacji",
  },
  invalidPhoneNumber: {
    en: "Invalid phone number",
    pl: "Nieprawidłowy numer telefonu",
  },
  errorOccurs: {
    en: "An error occurs",
    pl: "Wystąpił błąd",
  },
  thisFieldRequired: {
    en: "This field is required",
    pl: "To pole jest obowiązkowe",
  },
  enter: {
    en: "Enter",
    pl: "Wprowadź",
  },
  errorFetchingAddress: {
    en: "Error fetching address",
    pl: "Błąd adresu",
  },
  addressHeading: {
    en: "Address",
    pl: "Adres",
  },
  firstName: {
    en: "First Name",
    pl: "Imię",
  },
  lastName: {
    en: "Last Name",
    pl: "Nazwisko",
  },
  profileUpdated: {
    en: "Profile Updated",
    pl: "Profil zaktualizowany",
  },
  profileDetails: {
    en: "Profile Details",
    pl: "Szczegóły profilu",
  },
  pleasecompleteprofileDetails: {
    en: "Please complete your profile",
    pl: "Uzupełnij swój profil",
  },
  imageRequired: {
    en: "Image is required",
    pl: "Zdjęcie jest wymagane",
  },
  loading: {
    en: "Loading..",
    pl: "Ładowanie..",
  },
  bookingPostedFor: {
    en: "Booking posted for",
    pl: "Rezerwacja dokonana",
  },
  accept: {
    en: "Accept",
    pl: "Akceptuj",
  },
  clientAddress: {
    en: "Client's Address",
    pl: "Adres klienta",
  },
  date_time: {
    en: "Date & Time",
    pl: "Data i czas",
  },
  waiting: {
    en: "Waiting",
    pl: "Oczekiwanie",
  },
  addtoGoogleCalender: {
    en: "Add to Google Calendar",
    pl: "Dodaj do kalendarza Google",
  },
  cancelBooking: {
    en: "Cancel Booking",
    pl: "Anuluj rezerwacje",
  },
  close: {
    en: "Close",
    pl: "Zamknij",
  },
  addReview: {
    en: "Add Review",
    pl: "Dodaj opinie",
  },
  completed: {
    en: "Completed",
    pl: "Zakończone",
  },
  accepted: {
    en: "Accepted",
    pl: "Potwierdzona",
  },
  dashboard: {
    en: "Dashboard",
    pl: "Twój profil",
  },
  writeReview: {
    en: "Write a review",
    pl: "Wystaw opinie",
  },
  pleaseProvideRating: {
    en: "Please Provide Rating",
    pl: "Wystaw opinie wykonawcy",
  },
  thanksForReview: {
    en: "Thanks for the review",
    pl: "Dziękujemy za wystawienie opinii",
  },
  submit: {
    en: "Submit",
    pl: "Wprowadź",
  },
  preview: {
    en: "Preview",
    pl: "Podgląd",
  },
  statusChanged: {
    en: "Status Changed",
    pl: "Zmiana statusu",
  },
  changeBooking: {
    en: "Change Booking",
    pl: "Zmień rezerwacje",
  },
  emailOptional: {
    en: "E-mail (Optional)",
    pl: "E-mail (opcjonalnie)",
  },
  signUp: {
    en: "Sign Up",
    pl: "Zarejestruj się",
  },
  noAccount: {
    en: "No account?",
    pl: "Nie masz konta?",
  },
  no_review_yet: {
    en: "No Review Yet",
    pl: "Brak wystawionych opinii",
  },
  new_review_display_here: {
    en: "New reviews will be displayed here\nafter the job is done",
    pl: "Nowa opinia pojawi się po zakończeniu rezerwacji",
  },
  no_service_found: {
    en: "No Service Found",
    pl: "Nie znaleziono usług",
  },
  show_more_service: {
    en: "Show more services",
    pl: "Pokaż więcej usług",
  },
  showMore: {
    en: "Show more",
    pl: "Pokaż więcej",
  },
  createFreeAccount: {
    en: "CREATE A FREE ACCOUNT",
    pl: "Załóż darmowe konto",
  },
  createFreeAccountDetail: {
    en: "Find mobile service that you need.",
    pl: "Znajdź Wykonawców z dojazdem do domu.",
  },
  shareYourAvailbility: {
    en: "SERVICE PROVIDER",
    pl: "WYKONAWCA",
  },
  shareYourAvailbilityDetail: {
    en: "Receive a link to the Service Provider's profile and check available dates.",
    pl: "Otrzymaj link do profilu Wykonawcy i sprawdź wolne terminy.",
  },
  getMoreOrders: {
    en: "RESERVATION",
    pl: "REZERWACJA",
  },
  getMoreOrdersDetails: {
    en: "Make a reservation at your convenience.",
    pl: "Zarezerwuj wizytę w dogodnym dla siebie terminie.",
  },
  hauzyDetail: {
    en: "is an app that you can make a reservation for at home or office visit of cleaners, babysitters, personal trainers, and other professionals.",
    pl: "to narzędzie, które pozwoli Ci umówić wizytę w domu lub biurze - opiekunki do dzieci, trenera personalnego albo innego Wykonawcy, który świadczy usługi z dojazdem do klienta.",
  },
  faq: {
    en: "FAQ",
    pl: "FAQ",
  },
  faqList: [
    {
      title: {
        en: "What is the Hauzy app?",
        pl: "Na czym polega Hauzy?",
      },
      desc: {
        en: "The Hauzy tool is an online booking system for mobile visits with travel to the client. The contractor provides you with his profile, which includes his available dates. You complete the booking form and book a home visit. All visits take place at your home or office or at the address provided, so you don't have to travel anywhere.",
        pl: "Narzędzie Hauzy to system rezerwacji online wizyt mobilnych z dojazdem do klienta. Wykonawca udostępnia Tobie swój profil, w którym znajdują się jego wolne terminy. Wypełniasz formularz rezerwacyjny i dokonujesz rezerwacji wizyty domowej. Wszystkie wizyty odbywają się u Ciebie w domu czy w biurze lub pod wskazanym adresem, tak abyś nie musiał nigdzie jeździć.",
      },
    },
    {
      title: {
        en: "How do I book a service?",
        pl: "Jak mogę dokonać rezerwacji?",
      },
      desc: {
        en: "You can make a reservation through the Service Provider's profile, which he provided to you. Just fill out the booking form. You will receive an email confirming your appointment. You can revoke it at any time via the link in the e-mail.",
        pl: "Rezerwacji możesz dokonać przez profil Wykonawcy, który Tobie udostępnił. Wystarczy, że wypełnisz formularz rezerwacyjny. Otrzymasz wiadomość e-mail z potwierdzeniem wizyty. W każdym momencie możesz ją odwołać przez link w wiadomości e-mail.",
      },
    },
    {
      title: {
        en: "Can I talk to the service provider before he/she comes to my home?",
        pl: "Czy mogę porozmawiać z Wykonawcą zanim dokonam rezerwacji?",
      },
      desc: {
        en: "Yes, the app has a chat feature that allows you to talk and clarify the details of a visit to your home, office, or other specified address. There is also a video chat so you can specify all the details before booking.",
        pl: "Tak, Hauzy ma czat, który pozwala na rozmowę i ustalenie szczegółów wizyty mobilnej z dojazdem do Ciebie do domu, biurze lub pod innym wskazanym adresem, dzięki któremu możesz przeprowadzić rozmowę i ustalić szczegóły wizyty przed jej rezerwacją.",
      },
    },
    {
      title: {
        en: "How do I pay for the service?",
        pl: "W jakiej formie płaci się za wizytę?",
      },
      desc: {
        en: "You must agree on the payment with the service provider. The application does not act as an intermediate between you and the service provider and does not affect the selected payment method.",
        pl: "Forma płatności ustalana jest między Tobą a klientem, Hauzy nie jest pośrednikiem płatności i nie ma wpływu na ustalenia formy płatności.",
      },
    },
    {
      title: {
        en: "Can I create my own profile if I offer outbound services?",
        pl: "Czy mogę założyć konto biznesowe, jeśli również oferuje usługę z dojazdem do klienta?",
      },
      desc: {
        en: 'Yes! If you provide an "at home" services, you need to set up your profile in the app and post your work schedule. The client will then be able to order your service. Go to the Hauzy B business website to find out the details of the partnership.',
        pl: "Tak! Jeśli świadczysz usługi mobilne z dojazdem do klienta, możesz założyć swój profil w narzędziu i udostępnić kalendarz, aby każdy mógł zrobić rezerwację Twoich usług. Przejdź do Hauzy B dla biznesu, aby poznać szczegóły narzędzia dla usługodawców.",
      },
    },
  ],
  no_portfolio_found: {
    en: "No Portfolio Found",
    pl: "Brak dodanych zdjęć",
  },
  new_portfolio_display_here: {
    en: "New Portfolio Images will be displayed here",
    pl: "Nowe zdjęcia w Portfolio zostaną dodane tutaj",
  },
  password: {
    en: "Password",
    pl: "Hasło",
  },
  confirmpassword: {
    en: "Confirm Password",
    pl: "Potwierdź hasło",
  },
  enterPassword: {
    en: "Enter Password",
    pl: "Wprowadź hasło",
  },
  enterEmail: {
    en: "Enter E-mail",
    pl: "Wprowadź E-mail",
  },
  enterConfirmPassword: {
    en: "Enter Confirm Password",
    pl: "Potwierdź hasło",
  },
  signin: {
    en: "Sign In",
    pl: "Zaloguj się",
  },
  signup: {
    en: "Sign Up",
    pl: "Zarejestruj się",
  },
  orSigninWith: {
    en: "Or Login With",
    pl: "Lub zaloguj się z",
  },
  orSignUpWith: {
    en: "Or Sign Up With",
    pl: "Lub zarejestruj się z",
  },
  new_user: {
    en: "New user?",
    pl: "Nowy użytkownik?",
  },
  already_have_account: {
    en: "Already have an account?",
    pl: "Masz już konto?",
  },
  please_enter_email: {
    en: "Please Enter Email",
    pl: "Wprowadź adres e-mail",
  },
  please_enter_valid_email: {
    en: "Please Enter Valid Email",
    pl: "Wprowadź poprawny adres e-mail",
  },
  password_should_6: {
    en: "Password Should be of six characters",
    pl: "Hasło musi mieć min. 6 znaków",
  },
  forgot_password: {
    en: "Forgot Password?",
    pl: "Nie pamiętasz hasła?",
  },
  phoneOptional: {
    en: "Phone (Optional)",
    pl: "Numer telefonu (Opcjonalne)",
  },
  passwordnotmatch: {
    en: "Password not match",
    pl: "Hasła nie są takie same",
  },
  wrongemailpassword: {
    en: "Wrong email or password",
    pl: "Niepoprawny adres e-mail lub hasło",
  },
  errorinAuthetication: {
    en: "Error in authentication",
    pl: "Błąd w weryfikacji",
  },
  forgot: {
    en: "Forgot",
    pl: "Nie pamiętam",
  },
  pleaseCheckYourEmail: {
    en: "Please check your e-mail. We send you e-mail to reset your password",
    pl: "Sprawdź skrzynkę e-mail. Wyslaliśmy wiadomość e-mail do resetowania hasła.",
  },
  businessHeaderSlogan1: {
    en: "You offer mobile<br/>services and customer visits?",
    pl: "Oferujesz usługę mobilną<br/>z dojazdem do klienta?",
  },   
  businessHeaderSlogan2: {
    en: "Create your business account in Hauzy B<br />and use it for free.",
    pl: "Załóż konto i korzystaj z narzędzia Hauzy B<br/>za darmo.",
  },   
  businessHeaderSlogan3: {
    en: "Create a profile, share your free dates<br/>and let your clients make bookings.",
    pl: "Załóż profil, udostępnij swoje wolne terminy<br/> i pozwól swoim klientom robić rezerwacje.",
  },   
  getService: {
    en: "SERVICE",
    pl: "USŁUGA",
  },
  getServiceDetails: {
    en: "Get the selected and booked service at your home or office.",
    pl: "Ciesz się zarezerwowaną wizytą u siebie w domu lub biurze.",
  },
  serviceProviders: {
    en: "Service Providers",
    pl: "Wykonawcy",
  },  
  headerSlogan1: {
    en: "Make reservation of home visit<br/>with online system Hauzy.",
    pl: "Zrób rezerwacje wizyty domowej<br/>z systemem online Hauzy.",
  },   
  headerSlogan2: {
    en: "Check the available free slots of the mobile service provider and make reservations through the online system.",
    pl: "Sprawdź wolne terminy Wykonawcy usług mobilnych i zrób rezerwacje przez system online.",
  },   
  headerSlogan3: {
    en: "",
    pl: "",
  },  
  findFreeSlots: {
    en: "Find a service",
    pl: "Znajdź Wykonawcę",
  }, 
  suggestions: {
    en: "Suggestions",
    pl: "Sugestie",
  },      
  categories: {
    en: "Categories",
    pl: "Kategorie",
  },   
  aboutHauzy: {
    en: "About Hauzy",
    pl: "O Hauzy",
  },
  setLocation: {
    en: "Set location",
    pl: "Wybierz lokalizację",
  },  
  hoursShort: {
    en: "hours",
    pl: "godz.",
  },  
  today: {
    en: "Today",
    pl: "Dzisiaj",
  },    
  tomorrow: {
    en: "Tomorrow",
    pl: "Jutro",
  },   
  jan: {
    en: "Jan",
    pl: "Sty"
  },     
  feb: {
    en: "Feb",
    pl: "Lut"
  },
  mar: {
    en: "Mar",
    pl: "Mar"
  },   
  apr: {
    en: "Apr",
    pl: "Kwi"
  },  
  may: {
    en: "May",
    pl: "Maj"
  },  
  jun: {
    en: "Jun",
    pl: "Cze"
  },  
  jul: {
    en: "Jul",
    pl: "Lip"
  },  
  aug: {
    en: "Aug",
    pl: "Sie"
  },  
  sep: {
    en: "Sep",
    pl: "Wrz"
  },  
  oct: {
    en: "Oct",
    pl: "Paź"
  },  
  nov: {
    en: "Nov",
    pl: "Lis"
  },  
  dec: {
    en: "Dec",
    pl: "Gru"
  },  
  sun: {
    en: "Sun",
    pl: "Nie"
  },     
  mon: {
    en: "Mon",
    pl: "Pon"
  },     
  tue: {
    en: "Tue",
    pl: "Wt"
  },     
  wen: {
    en: "Wen",
    pl: "Śr"
  },     
  thu: {
    en: "Thu",
    pl: "Czw"
  },     
  fri: {
    en: "Fri",
    pl: "Pią"
  },     
  sat: {
    en: "Sat",
    pl: "Sob"
  },     
daysOfWeek: 
  [
   {
    en: "Sunday",
    pl: "Niedziela"
  },     
  {
    en: "Monday",
    pl: "Poniedziałek"
  },     
  {
    en: "Tuesday",
    pl: "Wtorek"
  },     
  {
    en: "Wednesday",
    pl: "Środa"
  },     
  {
    en: "Thursday",
    pl: "Czwartek"
  },     
  {
    en: "Friday",
    pl: "Piątek"
  },  
  {
    en: "Saturday",
    pl: "Sobota"
  }, 
    {
    en: "Saturday",
    pl: "Sobota"
  },
  ],
  bookYourVisit: {
    en: "Book your visit",
    pl: "Umów wizytę"
  }, 
  startConversation: {
    en: "Start a Conversation...",
    pl: "Napisz wiadomość..."
  },   
  oldestFirst: {
    en: "Oldest first",
    pl: "Najstarsze najpierw",
  },  
  lowestRated: {
    en: "Lowest rating first",
    pl: "Od najniższej oceny",
  },  
  phone: {
    en: "Phone number",
    pl: "Numer telefonu",
  },
  firstName: {
    en: "First name",
    pl: "Imię",
  },  
  lastName: {
    en: "Last name",
    pl: "Nazwisko",
  },    
  postalCode: {
    en: "Postal code",
    pl: "Kod pocztowy",
  },   
  city: {
    en: "City",
    pl: "Miasto",
  },     
  street: {
    en: "Street",
    pl: "Ulica",
  },  
  houseNo: {
    en: "House number",
    pl: "Numer domu",
  },       
  apartmentNo: {
    en: "Apartment number",
    pl: "Numer mieszkania",
  },       
  enterFirstName: {
    en: "Enter First Name",
    pl: "Wprowadź imię",
  },  
  enterLastName: {
    en: "Enter Last Name",
    pl: "Wprowadź nazwisko",
  },  
  enterStreet: {
    en: "Enter Street",
    pl: "Wprowadź ulicę",
  },  
  enterHouseNumber: {
    en: "Enter House Number",
    pl: "Wprowadź numer domu",
  },
  enterApartmentNumber: {
    en: "Enter Apartnment Number",
    pl: "Wprowadź numer mieszkania",
  },
  enterPostalCode: {
    en: "Enter Postal Code",
    pl: "Wprowadź kod pocztowy",
  },       
  enterCity: {
    en: "Enter City",
    pl: "Wprowadź miasto",
  }, 
  termAndConditionText: {
    en: "I agree to the ",
    pl: "Zgadzam się z ",
  },     
  termAndConditionLink: {
    en: "Terms and conditions",
    pl: "Regulaminem",
  },  
  pendingSubheader: {
    en: "Your booking is pending approval by the Service Provider.",
    pl: "Rezerwacja oczekuje na potwierdzenie przez Wykonawcę."
  },  
  confirmedSubheader: {
    en: "Your booking was confirmed by the Service Provider.",
    pl: "Rezerwacja została potwierdzona przez Wykonawcę."
  },  
  cancelledSubheader: {
    en: "Your booking was cancelled by the Service Provider.",
    pl: "Rezerwacja została anulowana przez Wykonawcę."
  },  
  waitingSubheader: {
    en: "Your booking is awaiting approval by the Service Provider.",
    pl: "Rezerwacja oczekuje na akceptację przez Wykonawcę."
  },  
  completedSubheader: {
    en: "Your booking has been completed.",
    pl: "Usługa została wykonana."
  },  
  acceptedSubheader: {
    en: "Your booking was accepted by the Service Provider.",
    pl: "Rezerwacja została zaakceptowana przez Wykonawcę."
  },  
  typeMessageHere: {
    en: "Type your message here",
    pl: "Napisz wiadomość"
  },  
  addedToGoogleCalendar: {
    en: "Booking added to Google Calendar",
    pl: "Rezerwacja dodana do kalendarza Google"
  },  
  messageSent: {
    en: "Message has been sent",
    pl: "Wiadomość wysłana"
  },  
  hereIsMessageLink: {
    en: "You can follow up on your conversation under this link ",
    pl: "Możesz kontynuować rozmowę pod następującym linkiem "
  },
  ok: {
    en: "ok",
    pl: "ok"
  },
      shareuserprofile: {
    en: "Share this user profile",
    pl: "Udostępnij profil tego użytkownika",
  },
  orcopylink: {
    en: "Or copy link",
    pl: "lub skopiuj link",
  },
  linkcopied: {
    en: "Link copied",
    pl: "Link skopiowany",
  },
  copy: {
    en: "Copy",
    pl: "Kopiuj",
  },
    min: {
    en: "min.",
    pl: "min.",
  },
  godz: {
    en: "h",
    pl: "godz.",
  },
    confirmAndBook: {
    en: "Confirm and book",
    pl: "Potwierdzam i rezerwuję",
  },
};
