import React, { useState } from "react";
import { getAllReviews } from "../../services/review_service";

export const ReviewsContext = React.createContext();

export const ReviewsProvider = ({ children }) => {
  const [reviewsList, setreviewsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReviews = async (refresh) => {
    if (refresh === true || reviewsList.length === 0) {
      console.log("getReviews");
      setLoading(true);
      const reviews = await getAllReviews();
      setreviewsList(reviews);
      setLoading(false);
    }
  };

  return (
    <ReviewsContext.Provider value={{ loading, reviewsList, getReviews }}>
      {children}
    </ReviewsContext.Provider>
  );
};
