import React from "react";
import CollapseTile from "./CollapseTile";

const CollapseContainer = ({ list }) => {
  const [faqOpenedIndex, setFaqOpenedIndex] = React.useState(false);

  if (
    list === undefined ||
    list === null ||
    !Array.isArray(list) ||
    list.length === 0
  ) {
    return null;
  }

  return (
    <div className="faq_box">
      {list.map((item, index) => {
        return (
          <CollapseTile
            key={index}
            counter={index + 1}
            title={item.title}
            desc={item.desc}
            faqOpened={faqOpenedIndex === index}
            onOpen={() => {
              console.log(index);
              setFaqOpenedIndex(index);
            }}
            onClose={() => {
              setFaqOpenedIndex(null);
              console.log("close");
            }}
          />
        );
      })}
    </div>
  );
};

export default CollapseContainer;
