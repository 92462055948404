import React, { Fragment, useContext } from "react";
import { useEffect, useState } from "react";
import RecommendedCard from "../../../components/RecommendedCard";
import { useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";

import Navbar from "../../../components/Navbar";
import { CategoryContext } from "../../../context/category";
import { ActionState } from "../../../utils/enum";
import { checkUserHaveWorkingHours } from "../../../utils/function";
const Index = () => {
  const {
    getAllBussinessByCategories,
    categoryBussinessList,
    categoryBussinessListState,
  } = useContext(CategoryContext);

  // const [usersBusiness, setUsersBusiness] = useState([]);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const { categoryName } = useParams();
  // const [loading, setLoading] = useState(false);
  const translate = useLocalization();
  // const viewlist = showOnlyAvailablecategoryBussiness
  //   ? availablecategoryBussinessList
  //   : categoryBussinessList;

  // const getCollectionData = async () => {
  //   setLoading(true);
  //   const bussinessDocs = await getCollection("users_business");

  //   let filteredBusiness = bussinessDocs.filter(
  //     ({ isVisible, category }) =>
  //       isVisible &&
  //       (categoryName === "all" ||
  //         !categoryName ||
  //         category?.replaceAll(" ", "-")?.toLowerCase() === categoryName)
  //   );

  //   if (showOnlyAvailable) {
  //     const today = new Date().toLocaleString("en-us", { weekday: "long" });
  //     filteredBusiness = filteredBusiness.filter((business) => {
  //       const todayWorkingHours = business.workingHours.find(
  //         ({ day }) => day === today
  //       );
  //       return (
  //         todayWorkingHours &&
  //         todayWorkingHours.isWorking &&
  //         todayWorkingHours.startTime !== ""
  //       );
  //     });
  //   }
  //   setUsersBusiness(filteredBusiness);
  //   setLoading(false);
  // };

  useEffect(() => {
    getAllBussinessByCategories(categoryName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  const handleAvailableChange = (event) => {
    setShowOnlyAvailable(event.target.checked);
    // filterTodaysAvailableBussiness();
  };

  // console.log("userBusiness", usersBusiness);

  const viewList = showOnlyAvailable
    ? categoryBussinessList.filter((business) => {
        return checkUserHaveWorkingHours(business.workingHours, new Date());
      })
    : categoryBussinessList;
  return (
    <div className="main">
      <Navbar />
      <Fragment>
        <div className="category__section">
          <section className="recommended-section">
            <div className="available__today">
              <h3 className="available__today__text">
                {translate(labels.showOnlyAvailableToday)}
              </h3>
              <label className="switch">
                <input onChange={handleAvailableChange} type="checkbox" />
                <span className="category__toggle round"></span>
              </label>
            </div>
            {categoryBussinessListState === ActionState.Loading ? (
              <div className="loader"></div>
            ) : viewList?.length > 0 ? (
              <Fragment>
                <div>
                  <h1 className="category__name">
                    {categoryName.replaceAll("-", " ")}
                  </h1>
                  <p className="category__description">
                    This is the category description.
                  </p>
                </div>
                <div className="recommended">
                  {viewList?.map((userBusiness) => {
                    return (
                      <RecommendedCard
                        key={userBusiness.uid}
                        userBusiness={userBusiness}
                        // showOnlyAvailable={showOnlyAvailable}
                        // setShowOnlyAvailable={setShowOnlyAvailable}
                      />
                    );
                  })}
                </div>
              </Fragment>
            ) : (
              <div className="no__recommended">
                {translate(labels.noRecommendationsFound)}
              </div>
            )}
          </section>
        </div>
      </Fragment>
      <Footer />
    </div>
  );
};

export default Index;
