import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";
const Footer = () => {
  const translate = useLocalization();

  const { locale = "en" } = useParams();
  return (
    <footer className="footer">
      <div className="divider"></div>
      <div>
        <div className="footer__links">
          <div className="footer__links__left">
            <NavLink to={`/${locale}/`}>
              <img
                className="nav__logo"
                src={require("../assets/client-logo.svg").default}
                alt="main indivisual logo"
              />
            </NavLink>
            <div className="footer__page-links">
           {/*   <NavLink to={`/${locale}/aboutus`} className="nav__top-link">
                {translate(labels.aboutUs)}
              </NavLink>
              <NavLink to={`/${locale}/aboutus`} className="nav__top-link">
                {translate(labels.howDoesItWork)}
              </NavLink> */}
              <NavLink to={`/${locale}/terms`} className="nav__top-link">
                {translate(labels.termsOfService)}
              </NavLink>
              <NavLink
                to={`/${locale}/privacy-policy`}
                className="nav__top-link"
              >
                {translate(labels.privacyPolicy)}
              </NavLink>
              <NavLink to={`/${locale}/contact`} className="nav__top-link">
                {translate(labels.contact)}
              </NavLink>
            </div>
          </div>
          <div className="footer__social-links">
            <a href="https://www.facebook.com/hauzyapp/" className="nav__top-link so">
              <img
                src={require("../assets/svg icons/facebook.svg").default}
                alt="facebook icon"
                className="social-icon"
              />
            </a>
            <a href="https://instagram.com/hauzyapp" className="nav__top-link so">
              <img
                src={require("../assets/svg icons/instagram.svg").default}
                alt="instagram icon"
                className="social-icon"
              />
            </a>
           { /*
            <div className="store_links">
              <img
                src={require("../assets/app-store-badge.svg").default}
                alt="apple playstore button"
                className="apple-icon"
              />
              <img
                src={require("../assets/google-play-badge.png").default}
                alt="playstore button"
                className="google-icon"
              />
            </div>
          */}
          </div>
        </div>
      </div>

      <span className="policy__text">
        Copyright&nbsp;<p>&#169;</p>&nbsp; 2023 Hauzy
      </span>
    </footer>
  );
};

export default Footer;
