import React from 'react';

const CheckboxInput = props => {
  return (
    <div className="checkbox__form-group">
      <input
        type="checkbox"
        name={props.id}
        id={props.id}
        className="primary-checkbox"
        {...props.checkboxProps}
      />
      <label htmlFor={props.id} className="primary-checkbox-label">
        <span className="primary-checkbox-replacer"></span>
        {props.labelText}
      </label>
    </div>
  );
};

export default CheckboxInput;
