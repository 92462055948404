import React from "react";
import { NavLink } from "react-router-dom";

import InfoSection from "../../../components/InfoSection";

import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";

const BussinessInfo = () => {
  const translate = useLocalization();

  return (
    <InfoSection
      paddingTop={"5rem"}
      slogan1={translate(labels.businessHeaderSlogan1)}
      slogan2={translate(labels.businessHeaderSlogan2)}
      para={translate(labels.businessHeaderSlogan3)}
      buttons={[
        <a
          href={`${process.env.REACT_APP_BIZNES_URL}`}
          className="business__btn primary__btn"
          key="btn"
          style={{ marginTop: "30px" }}
        >
          {translate(labels.createAnAccount)}
        </a>,
       /* <div className="store_links" style={{ margin: 0 }}>
          <img
            key="btn-1"
            src={require("../../../assets/app-store-badge.svg").default}
            alt="apple play store btn"
            className="apple-icon"
          />
          <img
            key="btn-2"
            src={require("../../../assets/google-play-badge.png").default}
            alt="play store btn"
            className="google-icon"
          />
        </div>, */
        // <img
        //   key="btn-1"
        //   src={require("../../../assets/app-store-badge.svg").default}
        //   alt="apple play store btn"
        //   className="apple"
        //   // width={150}
        //   // height={40}
        // />,
        // <img
        //   key="btn-2"
        //   src={require("../../../assets/google-play-badge.png").default}
        //   alt="play store btn"
        //   className="google"
        //   // width={150}
        //   // height={40}
        // />,
      ]}
      image={require("../../../assets/HauzyB_graphic.svg").default}
      imageAlt="a man and woman thinking"
    />
  );
};

export default BussinessInfo;
