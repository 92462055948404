import React from "react";
import Ratings from "../Ratings";
import { formatTimeAgo, getUserFullName } from "../../utils/function";

const OpinionTile = ({ opinion }) => {
  const {
    bussinessUserProfile,
    bookingId,
    timestamp,
    reviewStars,
    reviewText,
  } = opinion;
  return (
    <div className="bookings__row">
      <div className="bookings__row-group">
        <img
          src={bussinessUserProfile.profilePic}
          alt="guest"
          className="review__user-image mg-right-medium"
        />
        <div className="review__meta">
          <span className="guest__link guest__link-blue">
            #{bookingId.substring(0, 8)}
          </span>
          <span className="review__name">
            {getUserFullName(bussinessUserProfile)}
          </span>
          <span className="review__date mg-top-small">
            {formatTimeAgo(timestamp.toDate())}
          </span>
        </div>
      </div>

      <div className="review__text-group bookings__row-group bookings__row-group-large">
        <div className="review">
          <div className="recommended__ratings">
            <Ratings
              className="recommended-icon mg-bottom-small"
              filled={reviewStars}
            />
          </div>
          <p className="review__text">{reviewText}</p>
        </div>
      </div>

      {/* <div className="bookings__row-group">
        <div className="review__actions">
          <Icon
            name="check-mark"
            className="review__action-btn review__action-btn-approve mg-right-small"
          />
          <Icon
            name="cancel-circle"
            className="review__action-btn review__action-btn-delete"
          />
        </div>
      </div> */}
    </div>
  );
};

export default OpinionTile;
