import React, { useState } from "react";
import CheckboxInput from "./CheckboxInput";

import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";
import BookingTile from "./booking/BookingTile";
import { combineName } from "../utils/function";

const BookingsSection = ({ bookingsDetails }) => {
  const [search, setSearch] = useState("");

  const translate = useLocalization();
  const [activeBookingTab, setactiveBookingTab] = useState("all");

  const renderBookingRows = () => {
    if (activeBookingTab === "all") {
      if (search.length > 0) {
        return getListByName(bookingsDetails, search).map((item, i) => {
          return <BookingTile key={i} booking={item} index={i} />;
        });
      }
      return bookingsDetails?.map((item, i) => {
        return <BookingTile key={i} booking={item} index={i} />;
      });
    } else if (activeBookingTab === "pending") {
      const items = getListWithStatus("Pending");
      if (search.length > 0) {
        return getListByName(items, search).map((item, i) => {
          return <BookingTile key={i} booking={item} index={i} />;
        });
      }
      return items.map((item, i) => {
        return <BookingTile key={i} booking={item} index={i} />;
      });
    } else if (activeBookingTab === "confirmed") {
      const items = getListWithStatus("Confirmed");
      if (search.length > 0) {
        return getListByName(items, search).map((item, i) => {
          return <BookingTile key={i} booking={item} index={i} />;
        });
      }
      return items.map((item, i) => {
        return <BookingTile key={i} booking={item} index={i} />;
      });
    } else if (activeBookingTab === "canceled") {
      const items = getListWithStatus("Canceled");
      if (search.length > 0) {
        return getListByName(items, search).map((item, i) => {
          return <BookingTile key={i} booking={item} index={i} />;
        });
      }
      return items.map((item, i) => {
        return <BookingTile key={i} booking={item} index={i} />;
      });
    }
  };

  const getListWithStatus = (status) => {
    return bookingsDetails.filter((booking) => booking?.status === status);
  };
  const getListByName = (list, name) => {
    const l = list.filter((booking) => {
      const { bussinessUserProfile } = booking;

      const username = combineName(bussinessUserProfile).toLowerCase();
      console.log(username.includes(name.toLowerCase()), name);
      return username.includes(name.toLowerCase());
    });
    console.log("l", l);
    return l;
  };

  return (
    <section className="client-account-bookings-section">
      <div className="bookings__container">
        <div className="bookings__nav mg-bottom-small">
          <ul className="bookings__filters">
            <li
              onClick={() => {
                if (activeBookingTab !== "all") {
                  setSearch("");
                  setactiveBookingTab("all");
                }
              }}
              className={
                "bookings__filter" +
                (activeBookingTab === "all" ? " bookings__filter-active" : "")
              }
            >
              {translate(labels.allBookings)} ({bookingsDetails.length})
            </li>
            <li
              onClick={() => {
                if (activeBookingTab !== "pending") {
                  setSearch("");
                  setactiveBookingTab("pending");
                }
              }}
              className={
                "bookings__filter" +
                (activeBookingTab === "pending"
                  ? " bookings__filter-active"
                  : "")
              }
            >
              {translate(labels.pending)}
            </li>
            <li
              onClick={() => {
                if (activeBookingTab !== "confirmed") {
                  setSearch("");
                  setactiveBookingTab("confirmed");
                }
              }}
              className={
                "bookings__filter" +
                (activeBookingTab === "confirmed"
                  ? " bookings__filter-active"
                  : "")
              }
            >
              {translate(labels.confirmed)}
            </li>
            <li
              onClick={() => {
                if (activeBookingTab !== "canceled") {
                  setSearch("");
                  setactiveBookingTab("canceled");
                }
              }}
              className={
                "bookings__filter" +
                (activeBookingTab === "canceled"
                  ? " bookings__filter-active"
                  : "")
              }
            >
              {translate(labels.cancelled)}
            </li>
          </ul>
          <div className="bookings__search">
            <div className="form__group">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="bookings__search-input"
                placeholder="Search here"
              />
              <img
                src={require("../assets/svg icons/search.svg").default}
                alt="search icon"
                className="bookings__nav-icon"
              />
            </div>
          </div>
          <button className="primary__btn">
            <img
              src={require("../assets/svg icons/document.svg").default}
              alt="document icon"
              className="bookings__nav-icon mg-right-small"
            />
            <span>{translate(labels.generateReport)}</span>
          </button>
        </div>

        <div className="bookings">
          <div className="bookings__row mg-bottom-small">
            <CheckboxInput id="select__all-checkbox" />

            <div className="bookings__row-group bookings__row-group-large">
              {translate(labels.nameOfBussiness)}
              <img
                src={require("../assets/svg icons/sort-arrows.svg").default}
                alt="sort icon"
                className="sort__icon mg-left-small"
              />
            </div>
            <div className="bookings__row-group bookings__row-group-medium">
              {translate(labels.dateOrder)}
              <img
                src={require("../assets/svg icons/sort-arrows.svg").default}
                alt="sort icon"
                className="sort__icon mg-left-small"
              />
            </div>

            <div className="bookings__row-group">
              {translate(labels.status)}
              <img
                src={require("../assets/svg icons/sort-arrows.svg").default}
                alt="sort icon"
                className="sort__icon mg-left-small"
              />
            </div>
            <div className="bookings__row-group"></div>
          </div>

          {renderBookingRows()}

          {/* <div className="bookings__row paging__row mg-top-small">
            <div className="display__indicator">
              {translate(labels.showingData)}
            </div>
            <div className="paging">
              <button className="select__btn">
                <img
                  src={require("../assets/svg icons/left-caret.svg").default}
                  alt="left caret icon"
                  className="mg-right-small"
                />
                {translate(labels.previous)}
              </button>
              <div className="total__pages">
                <span className="page page-active">1</span>
                <span className="page">2</span>
                <span className="page">3</span>
                <span className="page">4</span>
              </div>
              <button className="select__btn">
                {translate(labels.next)}
                <img
                  src={require("../assets/svg icons/right-caret.svg").default}
                  alt="right caret icon"
                  className="mg-left-small"
                />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default BookingsSection;
