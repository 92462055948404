import React from "react";

import Image from "./image";
import { isValidUrl } from "../utils/function";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";

const ImagePicker = ({
  register,
  watch,
  errors,
  required,
  fieldKey,
  onRemove,
}) => {
  const watchFile = watch([fieldKey]); // you can also target specific fields by their names
  const translate = useLocalization();
  const selectedFiles = watchFile ?? [];
  let pickFile;
  if (selectedFiles.length > 0) {
    pickFile = selectedFiles[0];
  }

  return (
    <div className="image-input">
      {isValidUrl(selectedFiles) ? (
        <div className="form-image">
          <Image
            src={selectedFiles} // Route of the image file
            height={120}
            width={120}
            alt="User"
          />
        </div>
      ) : pickFile !== undefined && pickFile.length > 0 ? (
        <div className="form-image">
          <Image
            src={URL.createObjectURL(pickFile[0])} // Route of the image file
            height={120}
            width={120}
            alt="User"
          />
        </div>
      ) : null}
      {/* {pickFile != null  ? (
        <div className="form-image">
          <Image
            src={URL.createObjectURL(pickFile)} // Route of the image file
            height={120}
            width={120}
            alt="User"
          />
        </div>
      ) : baseImage == null ? null : (
        <div className="form-image">
          <Image
            src={baseImage} // Route of the image file
            height={120}
            width={120}
            alt="User"
          />
        </div>
      )} */}
      <input
        type="file"
        accept="image/*"
        onChange={(e) => {
          console.log(e);
        }}
        {...register(fieldKey, { required: required ?? false })}
      />
      {errors[fieldKey]?.type === "required" && (
        <p className="error-image">{translate(labels.imageRequired)}</p>
      )}
      {onRemove === undefined || onRemove === null ? null : (
        <div onClick={onRemove} className="remove-image"></div>
      )}
    </div>
  );
};

export default ImagePicker;
