import React, { useState, useEffect } from "react";
import Icon from "./Icon";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";

import { getUserData, logout } from "../services/auth_service";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const SideUserNav = (props) => {
  const [userNavOpened, setUserNavOpened] = useState(false);
  const translate = useLocalization();
  const { locale = "en" } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();

  useEffect(() => {
    setUserNavOpened(false);
  }, [props.activeScreen]);

  let accountCreatedDate = "";
  let month = "";
  let year = "";

  const getRouteName = (route) => {
    return "/" + locale + "/client/" + route;
  };

  const checkIsActive = (route) => {
    return currentPath.endsWith(`${route}`) || currentPath.endsWith(`${route}/`)
      ? true
      : false;
  };

  const user = getUserData();
  if (
    user?.accountCreatedDate === undefined ||
    user?.accountCreatedDate === null
  ) {
  } else {
    accountCreatedDate = new Date(user.accountCreatedDate);
    month = accountCreatedDate.toLocaleString("default", {
      month: "short",
    });
    year = accountCreatedDate.getFullYear();
  }

  // const handleLogout = () => {
  //   auth
  //     .signOut()
  //     .then(() => {
  //       removeItemLocal(userlocalDbKey);
  //       window.location.href = "/";
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div
      className={`user__nav ${userNavOpened ? "opened" : ""} ${
        props.responsiveUserNav ? "responsive" : ""
      }`}
    >
      <button
        className={`user__nav-opener ${
          props.userNavOpenerDisplayed ? "displayed" : ""
        }`}
        id="user__nav-opener"
        onClick={() => setUserNavOpened((prevValue) => !prevValue)}
      >
        <img
          src={require("../assets/svg icons/user-green.svg").default}
          alt="user nav icon"
        />
      </button>
      <div className="user__meta-info">
        <Icon
          onClick={props.openProfileDialog}
          name="edit"
          className="user__account-edit-icon"
        />
        {user?.profilePic != null ? (
          <img
            src={user?.profilePic}
            alt="user"
            className="user__profile-image border-radius-50"
          />
        ) : null}
        <h3 className="user__name">{`${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        }`}</h3>
        <h2>{user?.phone ?? ""}</h2>
        <span className="user__registration-date">
          {`You are with us since ${month} ${year}`}
        </span>
      </div>
      <ul className="user__nav-items">
        <Link
          to={getRouteName("")}
          className={`user__nav-item ${
            checkIsActive("/client") ? "user__nav-item-active" : ""
          }`}
          onClick={() => props.setActiveScreen("details")}
        >
          <Icon name="user-circle" />
          {translate(labels.yourDetails)}
        </Link>
        <Link
          to={getRouteName("messages")}
          className={`user__nav-item ${
            checkIsActive("messages") ? "user__nav-item-active" : ""
          }`}
          onClick={() => props.setActiveScreen("messages")}
        >
          <Icon name="message" />
          {translate(labels.message)}
        </Link>
        <Link
          to={getRouteName("bookings")}
          className={`user__nav-item ${
            checkIsActive("bookings") ? "user__nav-item-active" : ""
          }`}
          onClick={() => props.setActiveScreen("bookings")}
        >
          <Icon name="calendar" />
          {translate(labels.yourBookings)}
        </Link>
        <Link
          to={getRouteName("opinions")}
          className={`user__nav-item ${
            checkIsActive("opinions") ? "user__nav-item-active" : ""
          }`}
          onClick={() => props.setActiveScreen("opinions")}
        >
          <Icon name="opinion" />
          {translate(labels.opinions)}
        </Link>
        <Link
          to={getRouteName("favs")}
          className={`user__nav-item ${
            checkIsActive("favs") ? "user__nav-item-active" : ""
          }`}
          onClick={() => props.setActiveScreen("favs")}
        >
          <Icon name="heart" />
          {translate(labels.favourites)}
        </Link>
        <Link
          to={getRouteName("settings")}
          className={`user__nav-item ${
            checkIsActive("settings") ? "user__nav-item-active" : ""
          }`}
          onClick={() => props.setActiveScreen("settings")}
        >
          <Icon name="cogwheel" />
          {translate(labels.accountSettings)}
        </Link>
        <li
          to={getRouteName("opinions")}
          className="user__nav-item user__nav-item-active"
          // onClick={handleLogout}
          onClick={() => {
            logout();
            history.push(`/${locale}/`);
          }}
        >
          <Icon name="user-circle" />

          {/* className={`user__nav-item`} */}

          {/* <Icon name="logout" /> */}

          {translate(labels.logout)}
        </li>
      </ul>
    </div>
  );
};

export default SideUserNav;
