import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDebounce } from "../../../../hooks/use-debounce-hook";
import Image from "../../../../components/image";
import { useLocalization } from "../../../../helpers/localization";
import { labels } from "../../../../utils/locale";
import { errorAlert } from "../../../../utils/alert";
import { getPlaceAddress } from "../../../../services/map_service";

const AddressAutoCompleteField = ({ onSelect, selectedAddress }) => {
  const [isSearching, setisSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleFocus = (event) => event.target.select();
  const translate = useLocalization();
  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    clearSuggestions();
    // setSelected({ lat, lng, address });
    setisSearching(false);
    setSearchTerm("");
    onSelect({ latitude: lat, longitude: lng, address: address });
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        console.log("this---");
        if (searchTerm.length > 0) {
          setValue(searchTerm);
        }
      }
    },
    [debouncedSearchTerm, searchTerm, setValue] // Only call effect if debounced search term changes
  );

  const getCurrentLocation = async () => {
    if ("geolocation" in navigator) {
      console.log("Available");
      navigator.geolocation.getCurrentPosition(async function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        try {
          const address = await getPlaceAddress(
            position.coords.latitude,
            position.coords.longitude
          );
          console.log("Address is :", address);
          clearSuggestions();
          // setSelected({ lat, lng, address });
          setisSearching(false);
          setSearchTerm("");
          onSelect({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: address,
          });
        } catch (e) {
          errorAlert(translate(labels.errorFetchingAddress));
        }
      });
    }
  };

  return (
    <div className="address-select-text-field">
      <div className="text-field">
        <label>{translate(labels.addressHeading)}</label>
        <input
          type="text"
          onFocus={handleFocus}
          placeholder={
            translate(labels.enter) + " " + translate(labels.addressHeading)
          }
          required
          autoComplete="false"
          value={searchTerm.length === 0 ? selectedAddress : null}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              if (isSearching) {
                setisSearching(false);
              }
            } else {
              if (!isSearching) {
                setisSearching(true);

                // fetchSuggestions("mamji hospital");
              }
              setSearchTerm(e.target.value);
              //   setValue(e.target.value);
            }
          }}
        />
        <Image
          className="current-location-icon"
          src={require("../../../../assets/current-location.png").default}
          alt="Current Locaiton"
          onClick={getCurrentLocation}
        />
      </div>
      {isSearching ? (
        <div className="address-auto-complete">
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <span
                onClick={() => handleSelect(description)}
                key={place_id}
                className="address-item"
              >
                {description}
              </span>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default AddressAutoCompleteField;
