import React, { useState } from "react";
import { getCurrentLoginUserId } from "../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatLocalTime, formatTimeAgo } from "../../utils/function";
import BasicTranslateTile from "./BasicTranslateTile";

const BasicMessageTile = ({ chat, groupChatId, myid }) => {


  const translatedMessage = chat["translated_message"];
  const idFrom = chat.idFrom;
  const lastMessage = chat.content;
  const timestamp = chat.timestamp;
  const type = chat.type;
  const [imageModal, setImageModal] = useState(false);

  const { locale = "en" } = useParams();

  let message = "";

  if (translatedMessage == null) {
    message = lastMessage ?? "";
  } else {
    if (locale in translatedMessage) {
      message = translatedMessage[locale] ?? lastMessage ?? "";
    } else {
      message = lastMessage ?? "";
    }
  }

  const handleShowDialog = () => {
    if (imageModal) setImageModal(false);
    else setImageModal(true);
    console.log("cliked");
  };

  return (
    <div
      key={chat.id}
      className={
        myid === idFrom
          ? "flex-column align-item-end gap5"
          : "flex-column align-item-start gap5"
      }
    >
      <p
        className={`chatroom__message ${
          myid === idFrom && `chatroom__messagerecierver`
        }`}
        style={{
          padding: type === 1 && 0,
          backgroundColor: type === 1 && "transparent",
          background: type === 1 && "transparent",
        }}
      >
        {/* <span className='chatroom__username'>{message.by}</span> */}
        {type === 0 && message}
        {type === 1 && <img src={message} alt="imagePreview" onClick={handleShowDialog} />}
      </p>
      <span className="chatroom__messagetimestamp">
        {timestamp === null || timestamp === undefined
          ? formatLocalTime(new Date())
          : formatTimeAgo(timestamp.toDate())}
        {/* formatLocalTime(timestamp.toDate()) */}
      </span>
              {imageModal && (
          <dialog
            className="dialog"
            style={{ margin: "auto", position: "absolute", top: "100px", zIndex: "100" }}
            open
            onClick={handleShowDialog}
          >
            <img
              className="image"
              src={message} 
              onClick={handleShowDialog}
              style={{ margin: "auto", maxWidth: "100%", maxHeight: "100%" }}
              alt="no image"
            />
          </dialog>
        )}
      {myid !== idFrom && type === 0 && (
        <BasicTranslateTile conversation={chat} groupChatId={groupChatId} />
      )}
      {/* <div ref={messagesEndRef} /> */}
    </div>
  );
};

export default BasicMessageTile;
