import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";

import {
  businessPrimaryColor,
  clientPrimaryColor,
  businessButtonDisabled,
  clientButtonDisabled,
  businessButtonBackground,
  clientButtonBackground,
} from "./config";

import "./styles/main.scss";

// ! BASE PAGES
import RegisterationOne from "./components/Registration/RegisterationOne";
import RegisterationTwo from "./components/Registration/RegistrationTwo";
import SignIn from "./components/Registration/SignIn";

// ! INDIVIDUAL PAGES

import Home from "./pages/Individual/Home";
import SearchSubSlotPage from "./pages/Individual/Search/SearchSubSlotPage";
import SearchSubSlotPageResult from "./pages/Individual/Search/SearchSubSlotPageResult";
import ServiceProvider from "./pages/Individual/ServiceProvider";
import ClientPage from "./pages/Individual/ClientPage";
import AboutUs from "./pages/Individual/Home/AboutUs";
import Contact from "./pages/Individual/Contact";
import TermsAndConditions from "./pages/Individual/TermsAndConditions";
import PrivacyPolicy from "./pages/Individual/PrivacyPolicy";
import SimpleChat from "./pages/Individual/SimpleChat";
import BookingDetails from "./pages/Individual/BookingDetails";

import Category from "./pages/Individual/Category";
import { AuthProvider } from "./context/auth";
import { BookingProvider } from "./context/booking";
import { CategoryProvider } from "./context/category";
import { BussinessProvider } from "./context/bussiness";
import { SearchProvider } from "./context/search";
import { ReviewsProvider } from "./context/reviews";
import { FavouritesProvider } from "./context/favourites";
import ForgotPassword from "./components/Registration/ForgotPassword";

import usePageTracking from "./utils/usePageTracking";

const App = () => {
  usePageTracking();
  const [activeScreen, setActiveScreen] = useState("details");
  
  const routes = [
    { path: "/:locale/", component: Home },
    { path: "/:locale/searchslotsub", component: SearchSubSlotPage },
    {
      path: "/:locale/searchslotsub/result",
      component: SearchSubSlotPageResult,
    },
    { path: "/:locale/serviceprovider/:id", component: ServiceProvider },
    { path: "/:locale/booking-chat/:id", component: SimpleChat },
    { path: "/:locale/booking-details/:id", component: BookingDetails },    
    // {
    //   path: "/:locale/client",
    //   component: () => (
    //     <ClientPage
    //       activeScreen={activeScreen}
    //       setActiveScreen={setActiveScreen}
    //     />
    //   ),
    // },
    { path: "/:locale/registration/1", component: RegisterationOne },
    { path: "/:locale/registration/2", component: RegisterationTwo },
    { path: "/:locale/signin", component: SignIn },
    { path: "/:locale/forgot-password", component: ForgotPassword },
    { path: "/:locale/aboutus", component: AboutUs },
    { path: "/:locale/contact", component: Contact },
    { path: "/:locale/terms", component: TermsAndConditions },
    { path: "/:locale/privacy-policy", component: PrivacyPolicy },
    { path: "/:locale/category/:categoryName", component: Category },
  ];

  const location = useLocation();
  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--primary-color",
      location.pathname.includes("business")
        ? businessPrimaryColor
        : clientPrimaryColor
    );
    root?.style.setProperty(
      "--button-background",
      location.pathname.includes("business")
        ? businessButtonBackground
        : clientButtonBackground
    );
    root?.style.setProperty(
      "--button-disabled-color",
      location.pathname.includes("business")
        ? businessButtonDisabled
        : clientButtonDisabled
    );
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  // const user = getItemLocal("client");

  return (
    //     <Navbar user={JSON.parse(user)} setActiveScreen={setActiveScreen}>
    //       <Switch>
    //         <Route exact path='/'>
    //           <Redirect to='/en' />
    //         </Route>
    //         {routes.map((props, i) => (
    //           <Route key={i} exact {...props} />
    //         ))}
    //       </Switch>
    //     </Navbar>

    <AuthProvider>
      <CategoryProvider>
        <BookingProvider>
          <BussinessProvider>
            <SearchProvider>
              <ReviewsProvider>
                <FavouritesProvider>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/pl" />
                    </Route>
                    <Route
                      path="/:locale/client"
                      component={() => (
                        <ClientPage
                          activeScreen={activeScreen}
                          setActiveScreen={setActiveScreen}
                        />
                      )}
                    >
                      {/* <Route index element={<YourDetail />} />
                  <Route path="messages" element={<ChatScreen />} />
                  <Route path="bookings" element={<YourBooking />} />
                  <Route path="favs" element={<YourFavourite />} />
                  <Route path="opinions" element={<YourReviews />} />
                  <Route path="settings" element={<YourSetting />} /> */}
                      {/* <Route path="*" element={<NoMatch />} /> */}
                    </Route>
                    {routes.map((props, i) => (
                      <Route key={i} exact {...props} />
                    ))}
                  </Switch>
                </FavouritesProvider>
              </ReviewsProvider>

              <Toaster
                position="top-center"
                toastOptions={{
                  style: {
                    fontSize: "16px",
                    padding: "14px",
                  },
                }}
              />
            </SearchProvider>
          </BussinessProvider>
        </BookingProvider>
      </CategoryProvider>
    </AuthProvider>
  );
};

export default App;
