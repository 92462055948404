import React, { useRef } from "react";
import { labels } from "../../../../utils/locale";
import { useLocalization } from "../../../../helpers/localization";

const SlotsList = ({
  slotsLoading,
  userslots,
  selectedSlot,
  setselectedSlot,
}) => {
  const translate = useLocalization();

  const [slideLeft, setSlideLeft] = React.useState(0);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const ref = useRef(null);

  const moveRight = () => {
    const el = document.getElementById(`hscroll`);
    setSlideLeft((el.scrollLeft += 50));
  };

  const moveLeft = () => {
    const el = document.getElementById(`hscroll`);
    setSlideLeft((el.scrollLeft -= 50));
  };

  if (isScrolling) {
    setTimeout(() => setIsScrolling(false), 3000);
  }
  return (
    <div>
      <div className="flex-row gap5 justify-space-between">
        <label>{translate(labels.avalibletime)}</label>
        {userslots.length !== 0 && (
          <div className="flex-row gap5">
            <div
              onClick={slideLeft <= 0 ? null : moveLeft}
              className="arrow_wrapper"
            >
              <i className="arrow_left"></i>
            </div>
            <div onClick={moveRight} className="arrow_wrapper">
              <i className="arrow_right"></i>
            </div>
          </div>
        )}
      </div>
      <div ref={ref} id={`hscroll`} className="time">
        {slotsLoading ? (
          <div>{translate(labels.loading)}</div>
        ) : userslots.length === 0 ? (
          <span>{translate(labels.noSlotFound)}</span>
        ) : (
          userslots.map((option) => (
            <div
              key={option}
              className={`chip ${selectedSlot === option ? "selected" : ""}`}
              onClick={() => setselectedSlot(option)}
            >
              {option}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SlotsList;
