import React from "react";
import { getCurrentLoginUserId } from "../../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatLocalTime, formatTimeAgo } from "../../../utils/function";
import TranslateTile from "./TranslateTile";

const MessageTile = ({ chat, groupChatId }) => {
  const myid = getCurrentLoginUserId();

  const translatedMessage = chat["translated_message"];
  const idFrom = chat.idFrom;
  const lastMessage = chat.content;
  const timestamp = chat.timestamp;
  const type = chat.type;

  const { locale = "en" } = useParams();

  let message = "";

  if (translatedMessage == null) {
    message = lastMessage ?? "";
  } else {
    if (locale in translatedMessage) {
      message = translatedMessage[locale] ?? lastMessage ?? "";
    } else {
      message = lastMessage ?? "";
    }
  }
  return (
    <div key={chat.id}>
      <p
        className={`chatroom__message ${
          myid === idFrom && `chatroom__messagerecierver`
        }`}
      >
        {/* <span className='chatroom__username'>{message.by}</span> */}
        {type === 0 && message}
        {type === 1 && <img src={message} alt="imagePreview" />}
        <span className="chatroom__messagetimestamp ">
          {timestamp === null || timestamp === undefined
            ? formatLocalTime(new Date())
            : formatTimeAgo(timestamp.toDate())}
          {/* formatLocalTime(timestamp.toDate()) */}
        </span>
      </p>
      {myid !== idFrom && type === 0 && (
        <TranslateTile conversation={chat} groupChatId={groupChatId} />
      )}
      {/* <div ref={messagesEndRef} /> */}
    </div>
  );
};

export default MessageTile;
