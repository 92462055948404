import React, { useState, useEffect } from "react";
import ChatList from "./components/ChatList";
import ChatRoom from "./components/ChatRoom";
import responsiveNavHelper from "../../helpers/responsiveNavHelper";
import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getCurrentLoginUserId } from "../../services/auth_service";

const ChatScreen = () => {
  const [chatBoxActive, setChatBoxActive] = useState(false);
  const [responsiveChatbox, setResponsiveChatbox] = useState(false);
  const [sender, setSender] = useState();

  const userId = getCurrentLoginUserId();
  const [rooms, setRooms] = useState([]);
  // const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const opts = {
      breakpoint: 600,
      condition: responsiveChatbox,
      callback: setResponsiveChatbox,
    };

    responsiveNavHelper({ action: "add", ...opts });

    //ChatRoom GET
    // setisLoading(true);
    const db = getFirestore();

    const colRef = collection(db, "Conversations");

    const q = query(
      colRef,
      orderBy("lastMessage.timestamp", "desc"),
      where("users", "array-contains", userId),
      limit(10)
    );
    // const unsubscribe =
    onSnapshot(
      q,
      (querySnapshot) => {
        // setisLoading(false);
        let convo = [];
        querySnapshot.forEach((doc) => {
          convo.push({ ...doc.data(), id: doc.id });
        });
        setRooms(convo);
      },
      (e) => {
        // setisLoading(true);
      }
    );

    return () => {
      // unsubscribe();
      responsiveNavHelper({ action: "remove", ...opts });
    };
  }, [userId, responsiveChatbox, setResponsiveChatbox]);

  const renderChatbox = () => {
    const jsx = chatBoxActive ? (
      <ChatRoom
        chatBoxActive={chatBoxActive}
        setChatBoxActive={setChatBoxActive}
        bussinessProfile={sender}
      />
    ) : (
      <div className="chatroom">
        <img
          src={require("../../assets/chat-image.jpg").default}
          alt="chat"
          height="100%"
          width="100%"
          style={{ marginRight: "5px" }}
        />
      </div>
    );

    if (chatBoxActive) return jsx;
    if (!responsiveChatbox) return jsx;
    else return null;
  };
  // if (isLoading) {
  //   return <Loader />;
  // }
  return (
    <div className="chat__container">
      <ChatList
        chatBoxActive={chatBoxActive}
        // setChatBoxActive={setChatBoxActive}
        // setSenderId={setSender}
        rooms={rooms}
        senderId={sender}
        onSelect={(profile) => {
          setChatBoxActive(true);
          setSender(profile);
        }}
      />
      {renderChatbox()}
    </div>
  );
};

export default ChatScreen;
