import {
  reviewsCollection,
  userBussinessCollection,
  userCollection,
} from "../utils/collections";
import { limit, orderBy, where } from "firebase/firestore";
import {
  getFirestoreDocsFromChildCollection,
  setFirestoreDoctoChildPath,
  updateFirestoreDoc,
} from "./firestore_service";
import { getCurrentLoginUserId } from "./auth_service";
import { getBussinessProfile } from "./bussiness_service";
import { getUserProfile } from "./user_service";

export const submitReview = async (review) => {
  try {
    await setFirestoreDoctoChildPath(
      userCollection,
      review.userId,
      reviewsCollection,
      review.reviewId,
      review
    );
    await setFirestoreDoctoChildPath(
      userBussinessCollection,
      review.bussinessUserId,
      reviewsCollection,
      review.reviewId,
      review
    );
    const reviewsList = await getFirestoreDocsFromChildCollection(
      userBussinessCollection,
      review.bussinessUserId,
      reviewsCollection
    );

    let totalRating = 0;
    const reviewsLength = reviewsList.length;
    for (var doc of reviewsList) {
      var data = doc;
      const reviewStars = Number.parseFloat(data["reviewStars"]);
      totalRating = totalRating + reviewStars;
    }

    const averageRating = totalRating / reviewsLength;

    await updateFirestoreDoc(userBussinessCollection, review.bussinessUserId, {
      rating: averageRating,
    });
  } catch (e) {
    throw Error(e);
  }
};
export const checkAlreadyReviewed = async (id) => {
  try {
    const reviewsList = await getFirestoreDocsFromChildCollection(
      userCollection,
      getCurrentLoginUserId(),
      reviewsCollection,
      [where("bookingId", "==", id)]
    );

    return reviewsList.length > 0 ? true : false;
  } catch (e) {
    return false;
  }
};

export const getAllReviews = async () => {
  try {
    const userId = getCurrentLoginUserId();
    const list = await getFirestoreDocsFromChildCollection(
      userCollection,
      userId,
      reviewsCollection,
      [orderBy("timestamp", "desc")]
    );

    var reviewsList = [];

    for await (const item of list) {
      const review = item;

      const profile = await getBussinessProfile(review?.bussinessUserId);
      const data = { bussinessUserProfile: profile, ...review };

      reviewsList.push(data);
    }

    return reviewsList;
  } catch (e) {
    throw Error(e);
  }
};
export const getBussinessRecentReviews = async (id) => {
  try {
    const list = await getFirestoreDocsFromChildCollection(
      userBussinessCollection,
      id,
      reviewsCollection,
      [orderBy("timestamp", "desc"), limit(10)]
    );

    var reviewsList = [];

    for await (const item of list) {
      const review = item;

      const profile = await getUserProfile(review?.userId);
      const data = { userProfile: profile, ...review };

      reviewsList.push(data);
    }

    return reviewsList;
  } catch (e) {
    throw Error(e);
  }
};
