import React, { useEffect, useRef } from "react";

import MakeBooking from "./MakeBooking";
import FreeSlot from "./FreeSlot";
import Recommended from "./Recommended";
import BussinessInfo from "./BusinessInfo";
import Divider from "../../../components/Divider";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";
import AboutUs from "./AboutUs";
import Faq from "./Faq";

const Home = () => {
  const MessengerRef = useRef();
  useEffect(() => {

    MessengerRef.current.setAttribute("page_id", "108613761055132");
    MessengerRef.current.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
        window.FB.init({
            xfbml: true,
            version: 'v16.0',
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}, []);
  const translate = useLocalization();
  return (
    <div className="main">
      <Navbar />
      <div className="main_content">
        <MakeBooking />
       {/* <Divider title={translate(labels.findAFreeSlot)} />
        <FreeSlot /> */}
        {/* <Divider title="Categories" /> */}
        {/* <Categories /> */}
        {/* <div className="mg-top-large"></div> */}
        {/*<Divider title={translate(labels.serviceProviders)} />
        <Recommended />*/}
        <AboutUs id="for_business"/>
        <Divider title={translate(labels.forBusiness)} />
        <BussinessInfo />
        <Divider title={translate(labels.faq)} />
        <Faq />
      </div>

      <Footer />
      <div id="fb-root"></div>
        <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat">
        </div>
    </div>
  );
};

export default Home;
