import React, { useContext } from "react";
import { facebookSignin, googleSignin } from "../../services/auth_service";
import { AuthContext } from "../../context/auth";
import { errorAlert } from "../../utils/alert";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { labels } from "../../utils/locale";
import { useLocalization } from "../../helpers/localization";

const SocialLogin = () => {
  const history = useHistory();
  const { locale = "en" } = useParams();
  const translate = useLocalization();

  const { loading, setLoading } = useContext(AuthContext);
  return (
    <div className="flex-row gap10 justify-center">
      <img
        height="45px"
        width="45px"
        onClick={async () => {
          try {
            if (!loading) {
              setLoading(true);
              await googleSignin(locale);
              setLoading(false);
              history.push(`/${locale}/client`);
              window.location.reload();
            }
          } catch (e) {
            console.log(e);
            errorAlert(translate(labels.errorinAuthetication));
            setLoading(false);
          }
        }}
        src={require("../../assets/google.png").default}
        alt="Google Login"
        className="auth-social-icon"
        style={{ opacity: loading ? "0.5" : "1" }}
      ></img>
      <img
        height="45px"
        width="45px"
        onClick={async () => {
          try {
            if (!loading) {
              setLoading(true);
              await facebookSignin(locale);
              setLoading(false);
              history.push(`/${locale}/client`);
              window.location.reload();
            }
          } catch (e) {
            console.log(e);
            errorAlert(e + "  " + translate(labels.errorinAuthetication));
            setLoading(false);
          }
        }}
        src={require("../../assets/fb.png").default}
        alt="Google Login"
        className="auth-social-icon"
        style={{ opacity: loading ? "0.5" : "1" }}
      ></img>
      {/* <img
        height="45px"
        width="45px"
        onClick={() => {}}
        src={require("../../assets/apple_ic.png").default}
        alt="Google Login"
        className="auth-social-icon"
      ></img> */}
    </div>
  );
};

export default SocialLogin;
