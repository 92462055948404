import React from "react";

const InfoSection = (props) => {
  return (
    <section className="info" style={{ paddingTop: props.paddingTop }}>
      <div className="info__content">
        <h1
          className="info__slogan mg-bottom-small"
          dangerouslySetInnerHTML={{ __html: props.slogan1 }}
        />
        <h2
          className="info__subheader mg-bottom-small"
          dangerouslySetInnerHTML={{ __html: props.slogan2 }}
        />
        <p
          className="info__para mg-bottom-small"
          dangerouslySetInnerHTML={{ __html: props.para }}
        ></p>
        <div className="info__btns">{props.buttons}</div>
      </div>
      <img src={props.image} alt={props.imageAlt} className="info-cover-img" />
    </section>
  );
};

export default InfoSection;
