/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { ActionState } from "../../utils/enum";
import {
  getAllBussinessUsers,
  getBussinessUseWithInKM,
  getUserLocation,
} from "../../services/bussiness_service";
import { checkUserHaveWorkingHours } from "../../utils/function";
import { getUserData } from "../../services/auth_service";
import { getPlaceAddress } from "../../services/map_service";

export const BussinessContext = React.createContext();

export const BussinessProvider = ({ children }) => {
  const [recommendedBussinessList, setrecommendedBussinessList] = useState([]);
  const [initialBussinessList, setinitialBussinessList] = useState([]);

  const [bussinessActionState, setbussinessActionState] = useState(
    ActionState.Initial
  );
  const [
    bussinessActionStateErrorMessage,
    setbussinessActionStateErrorMessage,
  ] = useState(null);

  const [selectCategory, setselectCategory] = useState(null);

  const [selectDate, setselectDate] = useState(null);

  const [selectAddress, setselectAddress] = useState(null);

  const [userAddressList, setuserAddressList] = useState([]);

  const isAddressChange = useRef();
  const isDateChange = useRef();

  const isCategoryChange = useRef();

  const getUserAddressList = () => {
    const userProfile = getUserData();
    if (
      userProfile !== null &&
      userProfile.address !== undefined &&
      userProfile.address !== null &&
      userProfile.address.length > 0
    ) {
      var list = [];

      userProfile.address.forEach((address) => {
        // const a = JSON.parse(address);
        list.push(address);
      });

      setuserAddressList(list);
    }
  };
  const getRecommendedBussinessProfile = async (refresh) => {
    if (refresh === true || recommendedBussinessList?.length === 0) {
      try {
        console.log("getRecommendedBussinessProfile");
        setbussinessActionState(ActionState.Loading);
        const userProfile = getUserData();
        let curentLongitude;
        let curentLatitude;

        if (
          userProfile !== null &&
          userProfile.address !== undefined &&
          userProfile.address !== null &&
          userProfile.address.length > 0
        ) {
          const firstLocation = JSON.parse(userProfile.address.at(-1));
          curentLongitude = firstLocation.longitude;
          curentLatitude = firstLocation.latitude;
        } else {
          const currentLocation = await getUserLocation();
          let locationObject;
          if (currentLocation !== null) {
            const address = await getPlaceAddress(
              currentLocation.coords.latitude,
              currentLocation.coords.longitude
            );

            curentLongitude = currentLocation.coords.longitude;
            curentLatitude = currentLocation.coords.latitude;
            locationObject = {
              address: address,
              longitude: curentLongitude,
              latitude: curentLatitude,
            };
          } else {
            locationObject = {
              address: "",
              longitude: 0,
              latitude: 0,
            };
          }

          setselectAddress(JSON.stringify(locationObject));
          setuserAddressList([JSON.stringify(locationObject)]);
        }
        let users = [];
        if (curentLatitude === undefined || curentLongitude === undefined) {
          users = await getAllBussinessUsers();
        } else {
          users = await getBussinessUseWithInKM(
            curentLongitude,
            curentLatitude
          );
        }

        // const users = await getBussinessUseWithInKM(
        //   curentLongitude,
        //   curentLatitude
        // );

        // const users = await getInitailBusinessesUserWithInKM();
        setrecommendedBussinessList(users);
        setinitialBussinessList(users);
        setbussinessActionState(ActionState.Loaded);
      } catch (e) {
        setbussinessActionStateErrorMessage(e);
        setbussinessActionState(ActionState.Error);
      }
    }
  };

  const changeCategory = (category, fetch) => {
    setselectCategory(category);
    isCategoryChange.current = true;
    if (fetch) {
      if (category.toLowerCase() === "all") {
        let list = initialBussinessList;
        if (selectDate !== null) {
          list = list.filter((business) => {
            var workingHours = business.workingHours;
            console.log(workingHours);
            return checkUserHaveWorkingHours(workingHours, selectDate);
          });
        }

        setrecommendedBussinessList(list);
      } else {
        let list = initialBussinessList.filter(
          (element) =>
            element.category.toString().toLowerCase() === category.toLowerCase()
        );
        if (selectDate !== null) {
          list = list.filter((business) => {
            var workingHours = business.workingHours;
            console.log(workingHours);
            return checkUserHaveWorkingHours(workingHours, selectDate);
          });
        }
        setrecommendedBussinessList(list);
      }
    }
  };

  const changeDate = (date, fetch) => {
    setselectDate(date === "" ? null : date);
    isDateChange.current = true;
    if (fetch) {
      if (date === "" || date === null) {
        setrecommendedBussinessList(initialBussinessList);
      } else {
        let list = initialBussinessList.filter((business) => {
          var workingHours = business.workingHours;
          console.log(workingHours);
          return checkUserHaveWorkingHours(workingHours, date);
        });
        if (selectCategory !== "All") {
          list = list.filter((element) => element.category === selectCategory);
        }
        setrecommendedBussinessList(list);
      }
    }
  };

  const changeLocation = async (location, fetch) => {
    setselectAddress(location);
    isAddressChange.current = true;
    if (fetch) {
      const parseLocation = JSON.parse(location);
      try {
        setbussinessActionState(ActionState.Loading);
        const users = await getBussinessUseWithInKM(
          parseLocation.longitude,
          parseLocation.latitude
        );

        setinitialBussinessList(users);
        let list = [];
        const sCategory = selectCategory ?? "All";
        if (selectDate !== null && sCategory !== "All") {
          list = users.filter((business) => {
            var workingHours = business.workingHours;
            console.log(workingHours);
            return (
              checkUserHaveWorkingHours(workingHours, selectDate) &&
              business.category === sCategory
            );
          });
        } else if (selectDate !== null) {
          list = users.filter((business) => {
            var workingHours = business.workingHours;
            console.log(workingHours);
            return checkUserHaveWorkingHours(workingHours, selectDate);
          });
        } else if (sCategory !== "All") {
          list = users.filter((business) => {
            return business.category === sCategory;
          });
        } else {
          list = users;
        }
        setrecommendedBussinessList(list);
        setbussinessActionState(ActionState.Loaded);
      } catch (e) {
        setbussinessActionStateErrorMessage(e);
        setbussinessActionState(ActionState.Error);
      }
    }
  };

  const onSearch = async () => {
    if (isAddressChange.current === true) {
      await changeLocation(selectAddress, true);
      isAddressChange.current = false;
    }
    if (isDateChange.current === true) {
      changeDate(selectDate, true);
      isDateChange.current = false;
    }
    if (isCategoryChange.current === true) {
      changeCategory(selectCategory, true);
      isCategoryChange.current = false;
    }
  };

  return (
    <BussinessContext.Provider
      value={{
        bussinessActionState,
        recommendedBussinessList,
        bussinessActionStateErrorMessage,
        getRecommendedBussinessProfile,
        changeCategory,
        selectCategory,
        selectDate,
        changeDate,
        selectAddress,
        changeLocation,
        getUserAddressList,
        userAddressList,
        onSearch,
      }}
    >
      {children}
    </BussinessContext.Provider>
  );
};
