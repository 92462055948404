import { doc, getDoc, getFirestore, where, orderBy } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { cloudFunction } from "../firebase/firebase.config";
import { getFirestoreDocs, updateFirestoreDoc } from "./firestore_service";
import { bookingsCollection } from "../utils/collections";
import { updateNotification } from "./notification_service";
import { getCurrentLoginUserId } from "./auth_service";
import { getBussinessProfile } from "./bussiness_service";
import { errorAlert, successAlert } from "../utils/alert";
import { loadGapiInsideDOM } from "gapi-script";

let loadedBooking = [];


export const getBussinessUserEmptySlots = async (
  bussinessUserId,
  datetoFetch
) => {
  try {
    const callable = httpsCallable(cloudFunction, "getBussinessUserEmptySlots");

    const input = {
      bussinessUserId: bussinessUserId,
      datetoFetch: datetoFetch,
      currentTime: new Date().toISOString(),
    };
    console.log("input", input);
    const result = await callable(input);
    const slots = result.data ?? [];
    console.log("slots", slots, result);
    return slots;
  } catch (e) {
    throw Error(e);
  }
};

export const submitBooking = async (data) => {
  try {
    const callable = httpsCallable(cloudFunction, "submitBooking");
    console.log("Submit Booking data: ", data);
    const bookingResult = await callable(data);
    return bookingResult;
  } catch (e) {
    throw Error(e);
  }
};

export const changeBookingStatus = async (id, status) => {
  try {
    await updateFirestoreDoc(bookingsCollection, id, { status: status });
  } catch (e) {
    throw Error(e);
  }
};

export const changeBookingStatusAndNotify = async (
  id,
  status,
  notificationData
) => {
  try {
    await changeBookingStatus(id, status);
    await updateNotification(notificationData);
  } catch (e) {
    throw Error(e);
  }
};
export const getNextBookingFromBusiness = async (userId, bussinessUserId) => {
  let dateNow = new Date();
  try {
    const list = await getFirestoreDocs(bookingsCollection, [
      where("userId", "==", userId),
      where("startDate", ">=", dateNow),
      orderBy("startDate", "asc"),
    ]);
    var emptyBooking = {};

    for await (const item of list) {
      const booking = item;

      if (booking?.bussinessUserId == bussinessUserId) {

        const profile = await getBussinessProfile(booking?.bussinessUserId);
        const data = { bussinessUserProfile: profile, ...booking };

        return data;
      }
    }

    return emptyBooking;
  } catch (e) {
    throw Error(e);
  }
};

export const getAllBookings = async () => {
  try {
    const userId = getCurrentLoginUserId();
    const list = await getFirestoreDocs(bookingsCollection, [
      where("userId", "==", userId),
      orderBy("startDate", "desc"),
    ]);
    var bookings = [];

    for await (const item of list) {
      const booking = item;

      const profile = await getBussinessProfile(booking?.bussinessUserId);
      const data = { bussinessUserProfile: profile, ...booking };

      bookings.push(data);
    }

    return bookings;
  } catch (e) {
    throw Error(e);
  }
};

export const getGoogleEventsData = async (booking, successText) => {
  return new Promise(async (resolve, reject) => {
    const gapi = await loadGapiInsideDOM();
    var CLIENT_ID = process.env.REACT_APP_CLIENT_KEY;
    var API_KEY = process.env.REACT_APP_API_KEY;
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ];
    var SCOPES = "https://www.googleapis.com/auth/calendar.events";
    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });
      gapi.client.load("calendar", "v3", () => {});
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: "Hauzy Event",
            location: booking.userAddress,
            start: {
              dateTime: booking.startDateUtc,
              timeZone: "UTC+05:00",
            },
            end: {
              dateTime: booking.endDateUtc,
              timeZone: "UTC+05:00",
            },
          };
          gapi.client.calendar.events
            .insert({
              calendarId: "primary",
              resource: event,
            })
            .then(() => {
              successAlert(successText);
              resolve(true);
            })
            .catch((err) => {
              resolve(err);
              console.log(err);
              errorAlert(err.message);
            });

          // get events
        })
        .catch((err) => {
          resolve([]);
          // reject(err);
          errorAlert(err.message);
        });
    });
  });
};

const checkBookingAlreadyAdded = (id) => {
  const check = loadedBooking.find((element) => element.uid === id);
  if (check === undefined || check === null) {
    return false;
  }
  return true;
};
const getLoadedBooking = (id) => {
  const getbooking = loadedBooking.find(
    (element) => element.uid === id
  );

  return getbooking;
};
const addtoloaded = (booking) => {
  if (!checkBookingAlreadyAdded(booking.uid)) {
    loadedBooking.push(booking);
  } else {
    const index = loadedBooking.findIndex(
      (element) => element.uid === booking.uid
    );

    if (index >= 0) {
      loadedBooking[index] = booking;
    }
  }
  //   console.log(loadedBussinessProfile);
};

export const getBooking = async (id) => {
  if (checkBookingAlreadyAdded(id)) {
    return getLoadedBooking(id);
  } else {
    console.log("firestore for GetBooking");
    const db = getFirestore();

    const docRef = doc(db, bookingsCollection, id);
    const docSnap = await getDoc(docRef);
    const booking = docSnap.data();
    console.log("booking", booking);
    addtoloaded(booking);
    return booking;
  }
};


export const getUsersFromBooking = async (id) => {
  const booking = await getBooking(id);
  //const ids = {user: booking.userID, business: booking.businessUserID }
  //console.log("ids");
  //console.log(booking.bussinessUserId);
  //console.log(booking.userId);
  return {
        business: booking.bussinessUserId,
        regular: booking.userId,
    }
};
