import React, { useContext, useEffect } from "react";

import OpinionSection from "../../../../components/opinions/OpinionSection";
import { ReviewsContext } from "../../../../context/reviews";

const YourReviews = () => {
  const { loading, reviewsList, getReviews } = useContext(ReviewsContext);
  useEffect(() => {
    getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <div className="loader"></div>;
  // alert(reviewsList.length);
  return <OpinionSection reviewsList={reviewsList} />;
};

export default YourReviews;
