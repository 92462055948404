import axios from "axios";
import { languagesList } from "../utils/function";

const baseurl = "https://translation.googleapis.com/language/translate/v2";
const TRANSLATIONKEY = process.env.REACT_APP_TRANSLATION_API_KEY;
export const getTranslatedText = async (query, from, to) => {
  try {
    var url = "";
    if (from === undefined || from === null) {
      url = `${baseurl}?target=${to}&key=${TRANSLATIONKEY}&q=${query}`;
    } else {
      url = `${baseurl}?source=${from}&target=${to}&key=${TRANSLATIONKEY}&q=${query}`;
    }

    const responce = await axios.get(url);

    const respoceData = responce.data["data"]["translations"][0];

    return respoceData.translatedText;
    // return GoogleTranslateData.fromMap(respoceData);
  } catch (e) {
    throw e;
  }
};

export const getTextinAllSupportedLangauges = async (
  currentLangCode,
  query
) => {
  try {
    let data = {};

    for (var language of languagesList) {
      if (currentLangCode === language) {
        data[language] = query;
        continue;
      }
      const item = await getTranslatedText(query, currentLangCode, language);

      if (item != null) {
        if (data[language] === language) {
          data[language] = query;
        } else {
          data[language] = item;
        }
      }
    }
    console.log("data", data);
    return data;
  } catch (e) {
    const data = {};

    for (var l of languagesList) {
      data[l] = query;
    }
    return data;
  }
};
