import React from "react";
import Modal from "../Modal";
import AuthForm from "./AuthForm";

import { labels } from "../../utils/locale";
import { useLocalization } from "../../helpers/localization";
import Icon from "../Icon";

const AuthModal = ({ onDone, setShowModal }) => {
  const translate = useLocalization();

  return (
    <Modal>
      <div className="modal-content calender__modal">
        <span className="modal-close-icon" onClick={() => setShowModal(null)}>
          <Icon name="cancel" />
        </span>
        <AuthForm onDone={onDone}>
          <h3 className="secondary-heading mg-bottom-medium">
            {translate(labels.signIn)}
          </h3>
          <span>{translate(labels.registerToBook)}</span>
        </AuthForm>
      </div>
    </Modal>
  );
};

export default AuthModal;
