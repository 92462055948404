import React, { useContext, useState } from "react";

import { useHistory } from "react-router-dom";

import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { useParams } from "react-router-dom";
import {
  checkProfileisCompleted,
  resetPassword,
  signin,
  signup,
} from "../../services/auth_service";
import { errorAlert, successAlert } from "../../utils/alert";
import SocialLogin from "./SocialLogin";
import { AuthContext } from "../../context/auth";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const AuthForm = ({ children, isRegister, onDone, forgot }) => {
  // const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const [code, setCode] = useState("+48");

  const { loading, setLoading } = useContext(AuthContext);

  const translate = useLocalization();
  const history = useHistory();
  const { locale = "en" } = useParams();

  async function onSignup(e) {
    e.preventDefault();
    if (forgot) {
      try {
        setLoading(true);
        await resetPassword(email);
        setLoading(false);
        successAlert(translate(labels.pleaseCheckYourEmail));
      } catch (e) {
        errorAlert(translate(labels.errorOccurs));
        setLoading(false);
      }
      return;
    }
    try {
      let userID;

      if (isRegister) {
        if (password !== confirmpassword) {
          errorAlert(translate(labels.passwordnotmatch));
          return;
        }
        setLoading(true);
        const formatPh = code + phone;

        userID = await signup(
          email,
          password,
          phone.length > 0 ? formatPh : undefined
        );
      } else {
        setLoading(true);
        userID = await signin(email, password);
      }
      if (onDone === undefined || onDone == null) {
        history.push(`/${locale}/client`);
        window.location.reload();
      } else {
        await checkProfileisCompleted(userID);
        onDone(true);
      }
    } catch (e) {
      errorAlert(e + translate(labels.wrongemailpassword));
    }
    setLoading(false);
  }

  return (
    <form onSubmit={onSignup} className="primary-form auth-form">
      {children}
      <div className="form__group">
        <div className="form__group" style={{ marginTop: "2rem" }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.email)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <input
              name="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="primary__input-content"
              type="email"
              placeholder={translate(labels.enterEmail)}
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </div>
          {!forgot && (
            <div>
              <label htmlFor="mobile-input" className="primary-label">
                {translate(labels.password)}
              </label>
              <div
                className="primary__input"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <input
                  name="password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="primary__input-content"
                  type="password"
                  placeholder={translate(labels.enterPassword)}
                  pattern="^[a-zA-Z0-9]{6,}$"
                  title={translate(labels.password_should_6)}
                />
              </div>
            </div>
          )}
        </div>
        {isRegister && (
          <div>
            <label htmlFor="mobile-input" className="primary-label">
              {translate(labels.confirmpassword)}
            </label>
            <div
              className="primary__input"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <input
                name="password"
                value={confirmpassword}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="primary__input-content"
                type="password"
                placeholder={translate(labels.enterConfirmPassword)}
                pattern="^[a-zA-Z0-9]{6,}$"
                title={translate(labels.password_should_6)}
              />
            </div>
          </div>
        )}
        {isRegister ? (
          <div className="form__group">
            <label htmlFor="mobile-input" className="primary-label">
              {translate(labels.phoneOptional)}
            </label>
            <div className="primary__input">
              <select
                name="phone-identity-select"
                id="phone-identity-select"
                className="phone-identity-select"
                required
                onChange={(e) => setCode(e.target.value)}
              >
                <option value="+48">+48</option>
                <option value="+92">+92</option>
              </select>
              <input
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="primary__input-content"
                type="number"
              />
            </div>
          </div>
        ) : null}
        {!forgot && (
          <NavLink
            to={`/${locale}/forgot-password`}
            className="flex-row justify-flex-end forgot"
          >
            {translate(labels.forgot_password)}
          </NavLink>
        )}
        {loading ? (
          <div style={{ marginTop: "2rem" }} className="loader"></div>
        ) : (
          <button
            style={{
              margin: "auto",
              marginTop: "3rem",
              marginBottom: forgot ? "10rem" : "0",
            }}
            // onClick={onSignup}
            className="primary__btn"
          >
            <span>
              {translate(
                isRegister
                  ? labels.signUp
                  : forgot
                  ? labels.forgot
                  : labels.signIn
              )}
            </span>
          </button>
        )}
        {!forgot && (
          <span className="flex-row justify-center login-with">
            {translate(labels.orSigninWith)}
          </span>
        )}
        {!forgot && <SocialLogin />}
      </div>
    </form>
  );
};

export default AuthForm;
