import React from "react";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import OpinionTile from "./OpinionTile";

const OpinionSection = ({ reviewsList }) => {
  const translate = useLocalization();

  console.log(reviewsList);
  return (
    <section className="client-account-bookings-section">
      <div className="reviews__container">
        <div className="bookings__nav mg-bottom-small">
          <ul className="bookings__filters">
            <li className="bookings__filter bookings__filter-active">
              {translate(labels.allReviews)}
            </li>
            {/* <li className="bookings__filter">{translate(labels.published)}</li>
            <li className="bookings__filter">{translate(labels.deleted)}</li> */}
          </ul>
          {/* <div className="review__sort-by mg-right-medium">
            {translate(labels.sortByRate)}
          </div>
          <div className="recommended__ratings">
            <Ratings className="recommended-icon" />
          </div> */}
        </div>

        {reviewsList.length === 0 ? (
          <></>
        ) : (
          <div className="bookings">
            {reviewsList.map((opinion, i) => (
              <OpinionTile key={i} opinion={opinion} />
            ))}
            {/* {!props.dashboard ? (
            <div className="bookings__row mg-top-small">
              <div className="display__indicator">
                {translate(labels.showingData)}
              </div>
              <div className="paging">
                <button className="select__btn">
                  <img
                    src={require("../assets/svg icons/left-caret.svg").default}
                    alt="left caret icon"
                    className="mg-right-small"
                  />
                  {translate(labels.previous)}
                </button>
                <div className="total__pages">
                  <span className="page page-active">1</span>
                  <span className="page">2</span>
                  <span className="page">3</span>
                  <span className="page">4</span>
                </div>
                <button className="select__btn">
                  {translate(labels.next)}
                  <img
                    src={require("../assets/svg icons/right-caret.svg").default}
                    alt="right caret icon"
                    className="mg-left-small"
                  />
                </button>
              </div>
            </div>
          ) : null} */}
          </div>
        )}
      </div>
    </section>
  );
};

export default OpinionSection;
