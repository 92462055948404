/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import BookingsSection from "../../../../components/BookingsSection";

import { BookingContext } from "../../../../context/booking";

const YourBooking = () => {
  const { loading, bookingList, getBookings } = useContext(BookingContext);
  useEffect(() => {
    getBookings();
  }, []);
  if (loading) return <div className="loader"></div>;

  return (
    <BookingsSection
      bookingsDetails={bookingList.sort((a, b) => b.startDate - a.startDate)}
    />
  );
};

export default YourBooking;
