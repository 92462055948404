import { useState, useEffect } from "react";
import RecommendedCard from "../RecommendedCard";
import BasicInfo from "./BasicInfo";
import Portfolio from "./Portfolio";
import BasicChatProvider from "./BasicChatProvider";
import Opinions from "./Opinions";

import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { ActionState } from "../../utils/enum";
import Loader from "../Loader";
import { getBussinessProfile } from "../../services/bussiness_service";
import { getBussinessRecentReviews } from "../../services/review_service";

const Index = ({ business, children }) => {
  const { id } = useParams();
  const translate = useLocalization();

  const [userBusiness, setUserBusiness] = useState([]);
  const [loadingState, setloadingState] = useState(ActionState.Initial);
  const [reviewsloadingState, setreviewsloadingState] = useState(
    ActionState.Initial
  );
  const [reviews, setreviews] = useState([]);
  const getCollectionData = async () => {
    setloadingState(ActionState.Loading);

    const bussiness = await getBussinessProfile(id);
    setUserBusiness(bussiness);
    setloadingState(ActionState.Loaded);
  };

  const getReviews = async () => {
    setreviewsloadingState(ActionState.Loading);

    const response = await getBussinessRecentReviews(id);
    setreviews(response);
    setreviewsloadingState(ActionState.Loaded);
  };
  useEffect(() => {
    getCollectionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    getCollectionData();
    getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loadingState === ActionState.Loading) {
    return <Loader />;
  } else if (loadingState === ActionState.Error) {
    return <span>Error Occurs</span>;
  }
  return (
    <section className="service-provider-section">
      {userBusiness ? (
        <>
          {/* {!business ? (
            <button className="share__profiler">
              <ShareIcon
                className="svg-icons mg-right-small"
                width={26.63}
                height={26.63}
              />
              {translate(labels.shareProfile)}
            </button>
          ) : null} */}
          <RecommendedCard userBusiness={userBusiness} hideService={true} />
          <BasicInfo userBusiness={userBusiness} />

          <div className="portfolio_opinions provider__cols">
            <Portfolio userBusiness={userBusiness} />
            <Opinions
              userBusiness={userBusiness}
              reviewsloadingState={reviewsloadingState}
              reviews={reviews}
            />
          </div>
          {/* <div className="provider__cols"> */}
          {<BasicChatProvider bussinessProfile={userBusiness} />}
          {/* </div> */}

          {children}
        </>
      ) : (
        <>
          <h1 className="invalid__business">
            {translate(labels.invalidBusinessUser)}
          </h1>
          <NavLink to="/" className="primary__btn home__btn">
            {translate(labels.backToMainPage)}
          </NavLink>
        </>
      )}
    </section>
  );
};

export default Index;
