import { useState } from "react";
import { useEffect, useRef } from "react";
import { ReactComponent as PinIcon } from "../../../assets/svg icons/pin.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg icons/email.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/svg icons/facebook-svgrepo.svg";
import { ReactComponent as InstaIcon } from "../../../assets/svg icons/instagram-svgrepo.svg";
import { labels } from "../../../utils/locale";
import { useLocalization } from "../../../helpers/localization";
import Footer from '../../../components/Footer';
import Navbar from "../../../components/Navbar";

import { useParams } from "react-router-dom";
const ContactUs = () => {
  const { locale = "en" } = useParams();
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("input", inputs);
  };

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    subject: "",
    messageTextarea: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const translate = useLocalization();
  const MessengerRef = useRef();
  useEffect(() => {

    MessengerRef.current.setAttribute("page_id", "108613761055132");
    MessengerRef.current.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
        window.FB.init({
            xfbml: true,
            version: 'v16.0',
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}, []);
  return (
          <>
          <Navbar />  
          <div className="main_content">
    
      <div className="contact__container">
        <div className="contact__main">
          <h3 className="contact text__center">
            {translate(labels.contact)}
          </h3>
          <p className="still__text text__center">
            {translate(labels.stillhaveQuestion)}
          </p>
          <div className="grid-container">
            <div className="form__container">
              <form style={{ marginBottom: "5rem" }} onSubmit={handleSubmit}>
                <div className="contact__form__content">
                  <div className="user__input">
                    <label htmlFor="mobile-input" className="primary-label">
                      {translate(labels.firtAndLastName)}
                    </label>
                    <div
                      className="primary__input"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <input
                        value={inputs.firstName}
                        type="text"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        //placeholder={translate(labels.firtAndLastName)}
                        className="primary__input-content contact_form_input"
                      />
                    </div>
                  </div>
                  <div className="user__input">
                    <label htmlFor="mobile-input" className="primary-label">
                      {translate(labels.email)}
                    </label>
                    <div
                      className="primary__input contact_form_input"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <input
                        value={inputs.email}
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleChange}
                       // placeholder={translate(labels.email)}
                        className="primary__input-content"
                      />
                    </div>
                  </div>
                  <div className="user__input">
                    <label htmlFor="mobile-input" className="primary-label">
                      {translate(labels.subject)}
                    </label>
                    <div
                      className="primary__input"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <input
                        className="primary__input-content contact_form_input"
                        value={inputs.message}
                        type="text"
                        id="subject"
                        name="subject"
                        onChange={handleChange}
                     //   placeholder={translate(labels.subject)}
                      />
                    </div>
                  </div>

                  <div className="user__input">
                    <label htmlFor="mobile-input" className="primary-label">
                      {translate(labels.message)}
                    </label>
                    <div
                      className="primary__input"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <textarea
                        className="primary__input-content"
                        id="messageTextarea"
                        name="messageTextarea"
                        style={{ width: "97%" }}
                        value={inputs.messageTextarea}
                        onChange={handleChange}
                       // placeholder={translate(labels.message)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-row justify-center">
                  <button className="primary__btn contact-send-btn contact_send_button" onClick={handleSubmit}>
                    {translate(labels.send)}
                  </button>
                </div>
              </form>
            </div>
            <div className="details_container">
              <div className="flex__start bottom_20">
                <PinIcon
                  className="nav__search-icon contact-icons"
                  width={30}
                  height={30}
                />
                <div className="contact__text">{translate(labels.address)}</div>
              </div>
              <div className="flex__start bottom_20">
                <EmailIcon
                  className="svg-icons nav__search-icon contact-icons "
                  width={30}
                  height={30}
                />
                <a className="contact__text " href="mailto:info@hauzy.app">
                  {translate(labels.sendemail)}
                </a>
              </div>



            </div>
          </div>
        </div>
      </div>
   
    </div>
     <Footer />
           <div id="fb-root"></div>
        <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat">
        </div>
      </>
  );
};
export default ContactUs;
