import React from "react";
import Collapsible from "react-collapsible";

const CollapseTile = ({ title, desc, counter, onClose, onOpen, faqOpened }) => {
  // const [faqOpened, setFaqOpened] = React.useState(false);
  return (
    <Collapsible
      open={faqOpened}
      // tabIndex={0}
      // contentHiddenWhenClosed={true}
      // onOpen={
      //   () => {
      //     onOpen();
      //   }
      //   //   () => {
      //   //   setFaqOpened(true);
      //   // }
      // }
      onTriggerOpening={() => {
        onOpen();
        console.log("onTriggerOpening");
      }}
      onTriggerClosing={() => {
        console.log("onTriggerClosing");
        onClose();
      }}
      // onClose={
      //   () => {
      //     onClose();
      //   }
      //   //   () => {
      //   //   setFaqOpened(false);
      //   // }
      // }
      trigger={
        <div className="collapse_tile">
          <div className="box">
            <div>
              {/* <div className={faqOpened ? "counter_box_active" : "counter_box"}>
                <span>{counter}</span>
              </div> */}
              <div class={faqOpened ? "plusminus active" : "plusminus"}></div>
            </div>

            <p className={faqOpened ? "text_opened" : "text_closed"}>{title}</p>
          </div>
        </div>
      }
    >
      <div
        dangerouslySetInnerHTML={{ __html: desc }}
        className="collapse_desc"
      ></div>
    </Collapsible>
  );
};

export default CollapseTile;
