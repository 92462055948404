import React, { useState, useRef, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import Modal from "./Modal";
import Icon from "./Icon";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/svg icons/search.svg";
import { ReactComponent as ChatIcon } from "../assets/svg icons/chat-green.svg";
import { ReactComponent as NotificationIcon } from "../assets/svg icons/bell-green.svg";
import { ReactComponent as FavIcon } from "../assets/svg icons/heart.svg";
import { ReactComponent as TranslateIcon } from "../assets/svg icons/translate-icon.svg";
import { labels } from "../utils/locale";
import { HashLink } from "react-router-hash-link";

import { getUserData } from "../services/auth_service";
import { CategoryContext } from "../context/category";
import { AuthContext } from "../context/auth";
import SearchBox from "./SearchBox";
import { SearchContext } from "../context/search";

const Header = (props) => {
  const [opened, setOpened] = useState(false);
  const [showModal, setShowModal] = useState(null);
  // const [search, setSearch] = useState("");
  const [openLanguageDropDown, setLanguageDropDown] = useState(false);
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  // const [categories, setCategories] = useState([]);
  // const [randomcategories, setRandomcategories] = useState([]);
  const toggling = () => setLanguageDropDown(!openLanguageDropDown);
  const menuRef = useRef(null);
  const searchRef = useRef(null);
  const { locale = "en" } = useParams();
  const [selectedLocale, setLocale] = useState(locale);
  const { loggedIn } = useContext(AuthContext);

  const [searchBox, showSearchBox] = useState(false);

  const translate = (lable) => {
    return lable?.[locale];
  };

  useEffect(() => {
    if (locale !== selectedLocale) {
      const path = location?.pathname?.replace(locale, selectedLocale);
      window.location.replace(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const closeOpenMenus = (e) => {
    if (
      menuRef.current &&
      openLanguageDropDown &&
      !menuRef.current.contains(e.target)
    ) {
      setLanguageDropDown(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const closeOpenSearchBox = (e) => {
    if (
      searchRef.current &&
      searchBox &&
      !searchRef.current.contains(e.target)
    ) {
      showSearchBox(false);
    }
  };
  document.addEventListener("mousedown", closeOpenSearchBox);
  // const getCollectionData = async () => {
  //   console.log("dsdsdsdsdsd--------");
  //   const categoriesDocs = await getCollection("Categories");
  //   setCategories(categoriesDocs);
  //   setRandomcategories(getMultipleRandom(categoriesDocs, 7));
  // };
  // useEffect(() => {
  //   getCollectionData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const { categoryloading, randomcategoryList } = useContext(CategoryContext);

  // setRandomcategories(getMultipleRandom(categoryList, 7));
  const user = getUserData();
  const { onSearch, searchText } = useContext(SearchContext);
  // const innerRef = useOuterClick((e) => {
  //   showSearchBox(false);
  // });

  return (
    <>
      {showModal ? (
        <Modal>
          <div className="modal-content">
            <span
              className="modal-close-icon"
              onClick={() => setShowModal(false)}
            >
              <Icon name="cancel" />
            </span>
            <h3 className="secondary-heading mg-top-medium">{showModal}</h3>
          </div>
        </Modal>
      ) : null}

      <div className="main__navigation">
        <nav className="nav-top">
          <NavLink to={`/${locale}/`}>
            <img
              className="nav__logo"
              src={require("../assets/client-logo.svg").default}
              alt="main indivisual logo"
            />
          </NavLink>
         {/* <div className="nav__search n-search">
            <div className="nav__text-search">
              <SearchIcon
                onClick={() => setShowModal("Find free slots")}
                className="nav__search-icon svg-icons"
                width={20}
                height={20}
              />
              <input
                value={searchText}
                onChange={(e) => {
                  if (searchBox === false) {
                    showSearchBox(true);
                  }
                  onSearch(e.target.value, locale);
                }}
                onFocus={() => {
                  if (searchBox === false) {
                    showSearchBox(true);
                  }
                }}
                onClick={() => {
                  if (searchBox === false) {
                    showSearchBox(true);
                  }
                }}
                type="text"
                className="nav__search-input"
                placeholder={translate(labels.findFreeSlots)}
              />
            </div>

            <div
              className="nav__location-search"
              onClick={() => setShowModal("Where ?")}
            >
              <PinIcon
                className="nav__search-icon svg-icons"
                width={20}
                height={20}
              />
              <span className="nav__location-label">
                {translate(labels.where)}
              </span>
            </div> 

            searchBox ? (
              <SearchBox innerRef={searchRef} onClose={showSearchBox} />
            ) : null
          </div> */}

          {props?.user ? (
         /*   <ul className="nav__top-links top-nav-links-user">
              <li
                className="nav__user-link"
                onClick={() => setShowModal("Profile")}
              >
                {user?.profilePic !== null ? (
                  <img
                    src={user?.profilePic}
                    alt="user icon"
                    className="nav__user-icon border-radius-50"
                  />
                ) : null}
              </li>
              <li className="nav__user-link user-name">{`${
                user?.firstName ?? ""
              } ${user?.lastName ?? ""}`}</li>
              <li className="nav__user-link">
                <span className="alert-badge"></span>
                <NavLink to={`/${locale}/client`}>
                  <ChatIcon
                    width={30}
                    height={30}
                    className="nav__user-icon svg-icons"
                    onClick={() => props?.setActiveScreen("messages")}
                  />
                </NavLink>
              </li>
              <li className="nav__user-link">
                <span className="alert-badge"></span>

                <NotificationIcon
                  width={30}
                  height={30}
                  className="nav__user-icon svg-icons"
                />
              </li>
              <li className="nav__user-link">
                <span className="alert-badge"></span>
                <NavLink to={`/${locale}/client`}>
                  <FavIcon
                    width={30}
                    height={30}
                    className="nav__user-icon svg-icons"
                    onClick={() => {
                      props.setActiveScreen("favs");
                    }}
                  />
                </NavLink>
              </li>
            </ul> */
            null
          ) : (
            <div className="nav__top-links">
             {/* <a
                href={`${process.env.REACT_APP_BIZNES_URL}/${locale}`}
                target="_blank"
                rel="noreferrer"
                className="nav__top-link"
                style={{ background: "none" }}
              >
            
                {translate(labels.howDoesItWork)} 
              </a> */}
              {/*loggedIn ? (
                <NavLink to={`/${locale}/client/`} className="nav__top-link">
                  {translate(labels.dashboard)}
                </NavLink>
              ) : (
                <NavLink to={`/${locale}/signin`} className="nav__top-link">
                  {translate(labels.signIn)}
                </NavLink>
              )*/}

              <a
                className="primary__btn business__btn"
                href={`${process.env.REACT_APP_BIZNES_URL}${locale}`}
                target="_blank"
                rel="noreferrer"
                style={{ margin: 0, marginRight: "2rem" }}
              >
                {translate(labels.forBusiness)}
              </a>
              <div className="nav-bottom-opener-mobile">
                <div
                  className="dropdown"
                  ref={menuRef}
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "30px",
                  }}
                >
                  <TranslateIcon
                    onClick={toggling}
                    style={{ cursor: "pointer", fill: "#535353" }}
                    className="svg-icons"
                    width={30}
                    height={30}
                  />
                  {openLanguageDropDown && (
                    <div className="dropdown-box">
                      <ul className="dropdown-content">
                        {locale === "pl" ? (
                          <li
                            onClick={() => {
                              setLocale("en");
                            }}
                          >
                            English
                          </li>
                        ) : (
                          <li
                            onClick={() => {
                              setLocale("pl");
                            }}
                          >
                            Polski
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
               <img
                  src={require("../assets/svg icons/menu_dark.svg").default}
                  className="nav__bottom-opener"
                  alt="Navbar"
                  id="nav-opener"
                  onClick={() => setOpened((prevValue) => !prevValue)}
                /> 
              </div>
            </div>
          )}
        </nav>


            <nav className="nav__bottom">
              <ul
                className={`nav__bottom-links ${
                  opened ? "nav__bottom-links-opened" : ""
                }`}
              >
                <li className="hidden-tile-hash">
                  <HashLink
                    to={`/${locale}/#about_us`}
                    className="nav__bottom-link nav__bottom-link-all"
                  >
                    {translate(labels.aboutHauzy)}
                  </HashLink>
                </li>
                <li className="hidden-tile-hash">
                  <HashLink
                    to={`/${locale}/#for_business`}
                    className="nav__bottom-link nav__bottom-link-all"
                  >
                    {translate(labels.forBusiness)}
                  </HashLink>
                </li>


                <li className="hidden-tile-hash">
                  <HashLink
                    to={`/${locale}/#faq`}
                    className="nav__bottom-link nav__bottom-link-all"
                  >
                    {translate(labels.faq)}
                  </HashLink>
                </li>
                <li className="hidden-tile-hash">
                  <HashLink
                    to={`/${locale}/contact`}
                    className="nav__bottom-link nav__bottom-link-all"
                  >
                    {translate(labels.contact)}
                  </HashLink>
                </li>


              </ul>
            </nav>



       {/* <nav className="nav__bottom">
          {categoryloading ? (
            <></>
          ) : (
            <ul
              className={`nav__bottom-links ${
                opened ? "nav__bottom-links-opened" : ""
              }`}
            >
              {randomcategoryList?.map((category) => {
                return (
                  <li className="category" key={category.id}>
                    <NavLink
                      to={`/${locale}/category/${category.key
                        .replaceAll(/[/" "]/g, "-")
                        .toLowerCase()}`}
                      className="nav__bottom-link"
                    >
                      {locale == "en" ? category.name.en : (locale == "pl" ? category.name.pl : "")}
                    </NavLink>
                  </li>
                );
              })}

              <li>
                <NavLink
                  to={`/${locale}/category/all`}
                  className="nav__bottom-link nav__bottom-link-all"
                >
                  {translate(labels.viewAllCategories)}
                </NavLink>
              </li>
            </ul>
          )}
          <img
            src={require("../assets/svg icons/menu.svg").default}
            className="nav__bottom-opener"
            alt="Navbar"
            id="nav-opener"
            onClick={() => setOpened((prevValue) => !prevValue)}
          /> 
        </nav>*/}
      </div>
      {props.children}
    </>
  );
};

export default Header;
