import React, { useState } from "react";

import { useLocalization } from "../../../../helpers/localization";
import { labels } from "../../../../utils/locale";
const YourSetting = () => {
  const translate = useLocalization();

  const [currentPassword, setcurrentPassword] = useState("");

  const [newPassword, setnewPassword] = useState("");

  const [repeatnewPassword, setRNPassword] = useState("");

  return (
    <section className="client-account-form-section">
      <form action="#" className="primary-form">
        <h3 className="secondary-heading mg-bottom-medium">
          {translate(labels.accountSettings)}
        </h3>
        <div className="form__group">
          <label htmlFor="currentpw-input" className="primary-label">
            {translate(labels.currentPassword)}
          </label>
          <div className="primary__input">
            <input
              value={currentPassword}
              onChange={(e) => setcurrentPassword(e.target.value)}
              type="password"
              className="primary__input-content"
              id="currentpw-input"
            />
          </div>
        </div>
        <div className="form__group">
          <label htmlFor="newpw-input" className="primary-label">
            {translate(labels.newPassword)}
          </label>
          <div className="primary__input">
            <input
              value={newPassword}
              onChange={(e) => setnewPassword(e.target.value)}
              type="password"
              className="primary__input-content"
              id="newpw-input"
            />
          </div>
        </div>
        <div className="form__group">
          <label htmlFor="repearnewpw-input" className="primary-label">
            {translate(labels.repeatNewPassword)}
          </label>
          <div className="primary__input">
            <input
              value={repeatnewPassword}
              onChange={(e) => setRNPassword(e.target.value)}
              type="password"
              className="primary__input-content"
              id="repearnewpw-input"
            />
          </div>
        </div>
        <button className="primary__btn">
          {translate(labels.saveChanges)}
        </button>
      </form>
    </section>
  );
};

export default YourSetting;
