import React from "react";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";

const SelectField = ({
  label,
  // register,
  required,
  // errors,
  // fieldKey,
  options,
  onChange,
  value,
  prefix,
  style,
}) => {
  const translate = useLocalization();

  return (
    <div
      className={prefix === undefined ? "text-field" : "text-field-with-prefix"}
    >
      {label === undefined ? null : <label>{label}</label>}
      {options.length === 0 ? null : (
        <div className="select-wrap">
          {prefix === undefined ? null : (
            <div className="prefix-icon">{prefix}</div>
          )}
          <select
            value={value === null ? options[0].value : value}
            required={required}
            onChange={(e) => onChange(e.target.value)}
            style={style}

            // {...register(fieldKey, {
            //   required: required ?? false,
            // })}
          >
            {options === undefined
              ? null
              : options.map((opt, i) => {
                  return (
                    <option key={i} value={opt.value}>
                      {opt.name}
                    </option>
                  );
                })}
          </select>
        </div>
      )}
      {value === null && <p>{translate(labels.thisFieldRequired)}</p>}
    </div>
  );
};

export default SelectField;
