import React from "react";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import ServiceList from "./ServiceList";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const BasicInfo = ({ userBusiness }) => {
  const translate = useLocalization();
  const { locale = "en" } = useParams();
  return (
    <div className="provider">
      <div className="details__cols">
        <div className="details__col" style={{ flex: "1" }}>
          <h4 className="provider-heading mg-bottom-small">
            {translate(labels.services)}
          </h4>
          <ServiceList userBusiness={userBusiness} pr={true} />
        </div>
        <div className="details__col" style={{ flex: "1" }}>
          <h4 className="provider-heading mg-bottom-small">
            {translate(labels.description)}
          </h4>
          <p className="provider-info">{locale === "en" ? userBusiness?.description?.en : userBusiness?.description?.pl}</p>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
