import { labels } from "./locale";

export const getbookingStutusLocaleText = (translate, languagekey) => {
  let text = "";
  switch (languagekey) {
    case "Pending":
      text = translate(labels.pending);
      break;
    case "Confirmed":
      text = translate(labels.confirmed);
      break;

    case "Canceled":
      text = translate(labels.cancelled);
      break;
    case "Waiting":
      text = translate(labels.waiting);
      break;
    case "Completed":
      text = translate(labels.completed);
      break;
    case "Accepted":
      text = translate(labels.accepted);
      break;

    default:
      text = "";
      break;
  }
  return text;
};
export const getbookingStatusLocaleSubheader = (translate, languagekey) => {
  let text = "";
  switch (languagekey) {
    case "Pending":
      text = translate(labels.pendingSubheader);
      break;
    case "Confirmed":
      text = translate(labels.confirmedSubheader);
      break;

    case "Canceled":
      text = translate(labels.cancelledSubheader);
      break;
    case "Waiting":
      text = translate(labels.waitingSubheader);
      break;
    case "Completed":
      text = translate(labels.completedSubheader);
      break;
    case "Accepted":
      text = translate(labels.acceptedSubheader);
      break;

    default:
      text = "";
      break;
  }
  return text;
};
export const getreminderLocaleText = (translate, value) => {
  let text = "";
  switch (value) {
    case 480:
      text = translate(labels.eightHoursebeforebookingStart);
      break;
    case 240:
      text = translate(labels.fourHoursebeforebookingStart);
      break;
    case 120:
      text = translate(labels.twoHoursebeforebookingStart);
      break;
    case 60:
      text = translate(labels.oneHoursebeforebookingStart);
      break;
    case 30:
      text = translate(labels.halfHoursebeforebookingStart);
      break;

    default:
      text = "";
      break;
  }
  return text;
};
