const index = ({ action, breakpoint, condition, callback }) => {
  const handleResize = () => {
    if (window.innerWidth <= breakpoint && !condition) {
      callback(true);
    } else if (window.innerWidth >= breakpoint && condition) callback(false);
  };

  handleResize();

  if (action === 'add') window.addEventListener('resize', handleResize);

  if (action === 'remove') {
    window.removeEventListener('resize', handleResize);
  }
};

export default index;
