import React from "react";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";

const TextField = ({ label, register, required, errors, fieldKey, type }) => {
  const translate = useLocalization();
  return (
    <div className="text-field">
      <label>{label}</label>

      <input
        type={type ?? "text"}
        placeholder={translate(labels.enter) + " " + label}
        {...register(fieldKey, {
          required: required ?? false,
        })}
      />
      {required && errors?.[fieldKey]?.type === "required" && (
        <p>{translate(labels.thisFieldRequired)}</p>
      )}
    </div>
  );
};

export default TextField;
