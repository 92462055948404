import React, { useState } from "react";
import { formatDate } from "../../utils/function";
import CheckboxInput from "../CheckboxInput";

import BookingPreviewModal from "./BookingPreviewModal";
import { labels } from "../../utils/locale";
import { useLocalization } from "../../helpers/localization";

const BookingTile = ({ booking }) => {
  const { bussinessUserProfile, bookingId, startDate } = booking;
  const date = formatDate(startDate, "YYYY-MM-DD");
  const time = formatDate(startDate, "HH:mm a");
  const [openBooking, setopenBooking] = useState(null);
  const translate = useLocalization();

  return (
    <div className="bookings__row" key={bookingId}>
      <CheckboxInput id={`booking__checkbox-${bookingId}`} />

      {bussinessUserProfile === null ? (
        <></>
      ) : (
        <div className="bookings__row-group bookings__row-group-large">
          <img
            src={bussinessUserProfile?.profilePic}
            alt="Guest"
            className="guest__image mg-right-small"
          />
          <div className="guest__meta">
            <span className="guest__link guest__link-blue">{bookingId}</span>
            <span className="guest__name">
              {(bussinessUserProfile?.firstName ?? "") +
                " " +
                (bussinessUserProfile?.lastName ?? "")}
            </span>
          </div>
        </div>
      )}

      <div className="bookings__row-group bookings__row-group-medium">
        <div className="guest__date">
          <span className="guest__day">{date}</span>
          <span className="guest__time">{time}</span>
        </div>
      </div>

      <div className="bookings__row-group">
        <span
          className={`${
            booking.status === "Confirmed"
              ? "guest__link-green"
              : "guest__link-red"
          }`}
        >
          {booking?.status}
        </span>
      </div>

      {/* <Icon
        onClick={() => {
          setopenBooking(booking);
        }}
        name="ellipsis"
        className="opts-icon"
      /> */}
      <div className="bookings__row-group">
        {" "}
        <button
          onClick={() => {
            setopenBooking(booking);
          }}
          className="preview-btn"
        >
          {translate(labels.preview)}
        </button>
      </div>

      {openBooking !== null ? (
        <BookingPreviewModal
          setbookingPreviewDialog={setopenBooking}
          booking={openBooking}
        />
      ) : null}
    </div>
  );
};

export default BookingTile;
