import React from "react";

import icons from "../assets/svg sprite/sprite.svg";

const Icon = (props) => {
  return (
    <svg
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={`icon icon-${props.name} ${props.className}`}
      style={props.style}
    >
      <use xlinkHref={`${icons}#${props.name}`} />
    </svg>
  );
};

Icon.defaultProps = {
  className: "",
  style: {},
  onClick: () => {},
};

export default Icon;
