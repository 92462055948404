import {
  getFirestore,
  doc,
  deleteDoc,
  addDoc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  getDoc,
  query,
} from "firebase/firestore";

import {
  ref,
  getDownloadURL,
  getStorage,
  uploadBytesResumable,
} from "firebase/storage";

import { compressImageFile, getExtension, isValidUrl } from "../utils/function";

import { v4 as uuidv4 } from "uuid";
import { errorAlert } from "../utils/alert";

export const deleteFirestoreDoc = async (collection, docId) => {
  try {
    if (window.confirm("Are you sure you want to delete?")) {
      const db = getFirestore();

      const docRef = doc(db, collection, docId);

      await deleteDoc(docRef);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteFirestoreDoctoChildPath = async (
  collection,
  docId,
  childCollection,
  childId
) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, collection, docId);
    const colRef = doc(docRef, childCollection, childId);
    await deleteDoc(colRef);
  } catch (error) {
    throw error;
  }
};

export const getFirestoreDocs = async (path, queries) => {
  try {
    const db = getFirestore();

    const docRef = collection(db, path);
    let querySnapshot;
    if (queries !== undefined) {
      const queryRef = query(docRef, ...queries);
      querySnapshot = await getDocs(queryRef);
    } else {
      querySnapshot = await getDocs(docRef);
    }

    var docs = [];
    querySnapshot.forEach((e) => docs.push(e.data()));
    console.log("fetch", docs);
    return docs;
  } catch (error) {
    errorAlert("Error");
    throw error;
  }
};
export const getFirestoreDocsFromChildCollection = async (
  path,
  pathId,
  childPath,
  queries
) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, path, pathId);
    const colRef = collection(docRef, childPath);
    let querySnapshot;
    if (queries !== undefined) {
      const queryRef = query(colRef, ...queries);
      querySnapshot = await getDocs(queryRef);
    } else {
      querySnapshot = await getDocs(colRef);
    }

    var docs = [];
    querySnapshot.forEach((e) => docs.push(e.data()));
    console.log("fetch", docs);
    return docs;
  } catch (error) {
    throw error;
  }
};
export const getFirestoreDoc = async (path, id) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, path, id);

    const querySnapshot = await getDoc(docRef);

    return querySnapshot.data();
  } catch (error) {
    errorAlert("Error");
    throw error;
  }
};

export const getFirestoreDocofChildCollection = async (
  collection,
  docId,
  childCollection,
  childId
) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, collection, docId);
    const colRef = doc(docRef, childCollection, childId);
    return await getDoc(colRef);
  } catch (error) {
    throw error;
  }
};
export const updateFirestoreDoc = async (collection, docId, data) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, collection, docId);
    console.log("data", data);
    await setDoc(docRef, data, { merge: true });
  } catch (error) {
    throw error;
  }
};
export const setFirestoreDoc = async (collection, docId, data) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, collection, docId);
    console.log("data", data);
    await setDoc(docRef, data);
  } catch (error) {
    throw error;
  }
};
export const setFirestoreDoctoChildPath = async (
  collection,
  docId,
  childCollection,
  childId,
  data
) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, collection, docId);
    const colRef = doc(docRef, childCollection, childId);
    await setDoc(colRef, data);
  } catch (error) {
    throw error;
  }
};
export const uploadMultipleImagesToStorage = async (imageFolder, images) => {
  try {
    if (images === undefined || images === null || images.length === 0) {
      return [];
    }
    var urlList = [];
    await Promise.all(
      images.map(async (image) => {
        const isFile = isValidUrl(image) ? false : true;

        if (isFile) {
          console.log("imageimageimage", image);
          if (image.length > 0) {
            const compressImage = await compressImageFile(image[0]);

            const imageUrl = await uploadImageToFirebase(imageFolder, [
              compressImage,
            ]);
            urlList.push(imageUrl[0]);
          }
        } else {
          urlList.push(image);
        }
      })
    );

    return urlList;
  } catch (e) {
    throw e;
  }
};
export const uploadSingleImageToStorage = async (imageFolder, file) => {
  console.log("imageFolder", imageFolder);
  try {
    if (file === undefined || file === null) {
      return "";
    }
    let url = null;
    const isFile = isValidUrl(file) ? false : true;

    if (isFile) {
      if (file.length > 0) {
        const compressImage = await compressImageFile(file[0]);

        const imageUrl = await uploadImageToFirebase(imageFolder, [
          compressImage,
        ]);
        url = imageUrl[0];
      }
    } else {
      url = file;
    }

    return url;
  } catch (e) {
    throw e;
  }
};
export const updateFirestoreDocWithMultipleImages = async (
  collection,
  docId,
  data,
  images,
  imageDocKey
) => {
  try {
    if (images === undefined || images === null || images.length === 0) return;
    var urlList = [];
    await Promise.all(
      images.map(async (image) => {
        const isFile = isValidUrl(image) ? false : true;

        if (isFile) {
          const compressImage = await compressImageFile(image[0]);

          const imageUrl = await uploadImageToFirebase(collection, [
            compressImage,
          ]);
          urlList.push(imageUrl[0]);
        } else {
          urlList.push(image);
        }
      })
    );

    data[imageDocKey] = urlList;

    await updateFirestoreDoc(collection, docId, data);
  } catch (e) {
    throw e;
  }
};
export const updateFirestoreDocWithImage = async (
  collection,
  docId,
  data,
  image,
  imageDocKey
) => {
  try {
    if (image === undefined || image === null) return;
    const compressImage = await compressImageFile(image);

    const imageUrl = await uploadImageToFirebase(collection, [compressImage]);
    data[imageDocKey] = imageUrl[0];

    await updateFirestoreDoc(collection, docId, data);
  } catch (e) {
    throw e;
  }
};
export const addFirestoreDoc = async (path, data) => {
  try {
    const db = getFirestore();

    const collectionRef = collection(db, path);
    console.log("data", data);
    const response = await addDoc(collectionRef, data);
    await updateFirestoreDoc(path, response.id, { id: response.id });
  } catch (error) {
    throw error;
  }
};

export const addFirestoreDocWithImage = async (
  collection,
  data,
  image,
  imageDocKey
) => {
  try {
    if (image === undefined || image === null) return;
    const compressImage = await compressImageFile(image);
    console.log("compressImage", compressImage);
    const imageUrl = await uploadImageToFirebase(collection, [compressImage]);
    data[imageDocKey] = imageUrl[0];

    await addFirestoreDoc(collection, data);
  } catch (e) {
    throw e;
  }
};

export const addFirestoreDocWithMultipleImages = async (
  collection,
  data,
  images,
  imageDocKey
) => {
  try {
    if (images === undefined || images === null || images.length === 0) return;

    var urlList = [];
    await Promise.all(
      images.map(async (image) => {
        const compressImage = await compressImageFile(image[0]);

        const imageUrl = await uploadImageToFirebase(collection, [
          compressImage,
        ]);
        urlList.push(imageUrl[0]);
      })
    );

    data[imageDocKey] = urlList;

    await addFirestoreDoc(collection, data);
  } catch (e) {
    throw e;
  }
};

export const uploadImageToFirebase = async (path, files) => {
  const promises = [];
  var url = [];
  const storage = getStorage();

  files.map(async (file) => {
    const fileExtension = getExtension(file["name"]).toLowerCase();
    const id = uuidv4();
    console.log("sss  ", path + "/" + id + "." + fileExtension);
    const storageRef = ref(storage, path + "/" + id + "." + fileExtension);
    const uploadTask = uploadBytesResumable(storageRef, file).then(async () => {
      const downloadURL = await getDownloadURL(storageRef);
      url.push(downloadURL);
    });
    promises.push(uploadTask);
  });

  await Promise.all(promises);

  return url;
};

export const getServerTimestamp = () => {
  return serverTimestamp();
};
