import React, { useState } from "react";
import {
  addFavouriteBussinessUser,
  getMyAllFavouriteUsers,
  removeFavouriteBussinessUser,
} from "../../services/favourite_service";
import { getBussinessProfile } from "../../services/bussiness_service";
import { errorAlert } from "../../utils/alert";
import { checkIsLoggedIn } from "../../services/auth_service";

export const FavouritesContext = React.createContext();

export const FavouritesProvider = ({ children }) => {
  const [myFavoutiesProfileList, setmyFavoutiesProfileList] = useState([]);
  const [myFavoutiesList, setmyFavoutiesList] = useState([]);

  const [loading, setLoading] = useState(false);

  let isgetMyAllFavouriteFetched = false;

  const getMyAllFavourite = async (refresh) => {
    if (refresh === true || myFavoutiesList.length === 0) {
      console.log("getMyAllFavourite");
      setLoading(true);
      const fav = await getMyAllFavouriteUsers();
      setmyFavoutiesList(fav);
      setLoading(false);
      isgetMyAllFavouriteFetched = true;
      return fav;
    }
  };

  const getMyAllFavouriteWithProfile = async (refresh) => {
    if (refresh === true || myFavoutiesList.length === 0) {
      try {
        let idList = myFavoutiesList;
        if (isgetMyAllFavouriteFetched === false) {
          const list = await getMyAllFavourite();
          idList = list;
        }
        setLoading(true);
        var favourites = [];
        console.log("myFavoutiesList", idList);
        for await (const item of idList) {
          const fav = item;

          const profile = await getBussinessProfile(fav?.id);
          console.log("profile ", profile);
          const data = { bussinessUserProfile: profile, ...fav };

          favourites.push(data);
        }

        setmyFavoutiesProfileList(favourites);
        setLoading(false);
      } catch (e) {
        throw Error(e);
      }
    }
  };
  const isthatBussinessFavourite = (bussinessUserid) => {
    if (
      checkIsLoggedIn() &&
      myFavoutiesList.find((e) => e.id === bussinessUserid)
    ) {
      return true;
    }
  };

  const addToFavourites = async (bussinessUserid, bussinessProfile) => {
    try {
      await addFavouriteBussinessUser(bussinessUserid);
      setmyFavoutiesList([...myFavoutiesList, { id: bussinessUserid }]);
      setmyFavoutiesProfileList([
        ...myFavoutiesProfileList,
        { id: bussinessUserid, bussinessUserProfile: bussinessProfile },
      ]);
    } catch (e) {
      errorAlert(e);
    }
  };
  const removeFromFavourites = (bussinessUserid) => {
    try {
      removeFavouriteBussinessUser(bussinessUserid);
      setmyFavoutiesList((list) =>
        list.filter((user) => user.id !== bussinessUserid)
      );
      setmyFavoutiesProfileList((list) =>
        list.filter((user) => user.id !== bussinessUserid)
      );
    } catch (e) {
      errorAlert(e);
    }
  };
  return (
    <FavouritesContext.Provider
      value={{
        loading,
        myFavoutiesProfileList,
        getMyAllFavouriteWithProfile,
        getMyAllFavourite,
        isthatBussinessFavourite,
        addToFavourites,
        removeFromFavourites,
      }}
    >
      {children}
    </FavouritesContext.Provider>
  );
};
