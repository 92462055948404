import React from "react";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";

const MessageSentModal = ({ onChange, link }) => {
  const translate = useLocalization();

  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <h2 className="title">
          { translate(labels.messageSent)}
        </h2>
        <h2 className="title">
          { translate(labels.hereIsMessageLink)} <a href={link}>{link}</a>
        </h2>
          <div className="btn__s">
            <button
              onClick={() => {
                onChange(true);
              }}
              className="btn"
            >
              {translate(labels.ok)}
            </button>
          </div>
      </div>
    </div>
  );
};

export default MessageSentModal;
