import React from "react";
import Icon from "./Icon";

const Ratings = ({ filled, fillColor, emptyColor, className }) => {
  return (
    <>
      {Array.from(Array(5).keys()).map((_, i) => (
        <Icon
          key={i}
          name="star"
          className={className}
          style={{ fill: i < filled ? fillColor : emptyColor }}
        />
      ))}
    </>
  );
};

Ratings.defaultProps = {
  filled: 5,
  emptyColor: "#cccccc",
};

export default Ratings;
