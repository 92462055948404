import React from "react";
import Divider from "../../../components/Divider";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";

const AboutUs = () => {
  const translate = useLocalization();
  const businessServices = [
    {
      title: translate(labels.shareYourAvailbility),
      desc: translate(labels.shareYourAvailbilityDetail),
      image: require("../../../assets/svg icons/search_service.svg").default,
    },
    {
      title: translate(labels.getMoreOrders),
      desc: translate(labels.getMoreOrdersDetails),
      image: require("../../../assets/svg icons/event.svg").default,
    },
    {
      title: translate(labels.getService),
      desc: translate(labels.getServiceDetails),
      image: require("../../../assets/svg icons/bookmark.svg").default,
    },
  ];

  const renderServiceCard = () => {
    return businessServices.map((service, i) => (
      <div className="aboutus-detail-card" key={i}>
        <div className="aboutus-detail-card-image">
          <img src={service.image} alt="card icon" />
        </div>
        <h4 className="aboutus-detail-card-title">{service.title}</h4>
        <p className="aboutus-detail-card-desc">{service.desc}</p>
      </div>
    ));
  };

  return (
    <div className="aboutus" >
      <Divider title={translate(labels.aboutHauzy)} />
      <p className="aboutus__desc">
        <span>Hauzy</span> {translate(labels.hauzyDetail)}
      </p>
      <div className="aboutus__wrapper">{renderServiceCard()}</div>
    </div>
  );
};

export default AboutUs;
