import React from "react";

import Navbar from "../Navbar";
import Footer from "../Footer";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { useParams } from "react-router-dom";
import AuthForm from "./AuthForm";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
const SignIn = () => {
  const translate = useLocalization();
  const { locale = "en" } = useParams();

  // if (loggedIn) {
  //   // history.push(`/${locale}/client`);
  //   return <></>;
  // } else {
  return (
    <div className="page-container">
      <Navbar />
      <div className="registration page-content">
        <AuthForm>
          <h3 className="secondary-heading mg-bottom-small">
            {translate(labels.signIn)}
          </h3>
          <p className="alert-text">
            {translate(labels.noAccount)}{" "}
            <NavLink to={`/${locale}/registration/1`}>
              {translate(labels.signUp)}
            </NavLink>
          </p>
        </AuthForm>

        <div>
          <div id="recaptcha-container"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
  // }
};

export default SignIn;
