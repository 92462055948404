import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useContext,
} from "react";

import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Index = () => {
  const { locale = "en" } = useParams();
  const translate = useLocalization();

  const privacyData = [
    {
      title: "Privacy Policy - Hauzy",
      descriptions: [
        {
          content:
            "Users of our portal remain anonymous until they decide otherwise. The information contained in the system logs (e.g. IP address) resulting from the general rules of Internet connections is used by Hauzy B for technical purposes related to the administration of our servers. In addition, IP addresses are used to collect general, statistical demographic information (e.g. about the region from which the connection is made). Subscribing to Hauzy B requires filling out a form in which you must provide information enabling you to contact the user (e.g. telephone number or e-mail address) and demographic information (e.g. gender). The information provided in the form is used by us for the necessary contacts with our users and in order to better adapt the content we present to their needs and interests.",
        },
      ],
    },
    {
      title: "What is Hauzy's cookie policy?",
      descriptions: [
        {
          content:
            "Your web browser may store text files (cookies) on your computer's hard drive. Cookies contain information necessary for the proper functioning of the portal, in particular those requiring authorization. The content of cookies does not allow the identification of the user. Personal data is not processed or stored using cookies.",
        },
      ],
    },
  ];
  const MessengerRef = useRef();
  useEffect(() => {

    MessengerRef.current.setAttribute("page_id", "108613761055132");
    MessengerRef.current.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
        window.FB.init({
            xfbml: true,
            version: 'v16.0',
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}, []);
  return (
      <>
    <Navbar />  
    <div className="main_content">
    <div className="privacy_container">
      <h4 className="privacy_heading">
        {translate(labels.termsAndConditionsOfTheHauzyApp)}
      </h4>
      <div className="main__privacy">



{ locale == "en" ? 
<div class="legalContent">
  
<h1><strong>Privacy Policy - Hauzy</strong></h1>
<h2><strong>What personal information is collected and used by Hauzy?</strong></h2>
<p>Users of our portal remain anonymous until they decide otherwise. The information contained in the system logs (e.g. IP address) resulting from the general rules of Internet connections is used by Hauzy for technical purposes related to the administration of our servers. In addition, IP addresses are used to collect general, statistical demographic information (e.g. about the region from which the connection is made). Subscribing to Hauzy requires the completion of a form in which you must provide information enabling you to contact the user (e.g. telephone number or e-mail address) and demographic information (e.g. gender). The information provided in the form is used by us for the necessary contacts with our users and in order to better adapt the content we present to their needs and interests.</p>
<h2><strong>What is Hauzy's cookie policy?</strong></h2>
<p>Your web browser may store text files (cookies) on your computer's hard drive. Cookies contain information necessary for the proper functioning of the portal, in particular those requiring authorization. The content of cookies does not allow the identification of the user. Personal data is not processed or stored using cookies.</p>
<p>Hauzy stores cookies on users' computers for the purpose of:</p>
<ul>
     <li>maintaining the user's session (after logging in), thanks to which the user does not have to enter the username and password on each page;</li>
     <li>better tailoring of services to users' needs;</li>
     <li>creating viewership statistics to improve the functioning of the portal;</li>
</ul>
<p>It is possible to configure your web browser in such a way that the possibility of storing cookies on your computer's hard drive will be completely disabled. Remember that the effect of such a change may be the loss of the ability to use some functions of the Hauzy website (login, photo preview, etc.).</p>
<h2><strong>Detailed information on "cookies"</strong></h2>
<p>The Hauzy system uses the so-called cookie files (cookies), i.e. text files used to save and store information on how the user uses the System. Cookies are saved on the user's device and sent by the user's web browser to the System each time the user uses it.</p>
<p>Hauzy also uses information contained in system logs (e.g. IP address).</p>
<p>Hauzy uses the following types of cookies:</p>
<ul>
     <li>session cookies&nbsp;- automatically deleted after closing the web browser;</li>
     <li>persistent cookies&nbsp;– are stored on the device for a specified period of time;</li>
     <li>own cookies&nbsp;- set by the Hauzy System;</li>
     <li>third party cookies&nbsp;- set by websites other than System Hauzy, e.g. Google Analytics, Facebook, etc.</li>
</ul>
<p>Hauzy uses information collected using cookies and from system logs in order to:</p>
<ul>
     <li>adjusting the content, appearance, size and other parameters of the System to a specific device and user preferences;</li>
     <li>gathering general, statistical demographic information (e.g. about the region from which the connection is made).</li>
</ul>
<p>Cookies used by the Hauzy System do not allow Hauzy to identify specific users using the System.</p>
<p>By using the System, the user agrees to place the cookies described above on his device.</p>
<p>The user can remove or block the installation of cookies by changing the settings of the web browser. Detailed information on the possibility of modifying browser settings, blocking and filtering cookies can be found at:&nbsp;<a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</ a>&nbsp;or&nbsp;<a href="http://www.cookiecentral.com/faq/">http://www.cookiecentral.com/faq/</a>.</p>
<p>Removing or blocking cookies may affect the availability of the System's functionality, the correctness of the System's display and the loss of the System's preferred website settings.</p>
<h2><strong>How is information protected?</strong></h2>
<p>The form completed by the user when signing up for Hauzy, as well as the authorization process (logging in) to our resources is carried out using coded sessions, which will significantly increase the protection of data transmission over the Internet. Data such as profile access passwords are encrypted using one-way encryption algorithms, preventing their subsequent decryption. Information is stored and processed by Hauzy with appropriate security measures that meet the requirements of Polish law.</p>
<h2><strong>Is the personal information provided shared with third parties?</strong></h2>
<p>Hauzy includes or will include features (e.g. reviews) where user-entered content (e.g. review text and caption) becomes publicly available. The user may publish his content on these websites only after authorization and decides how it should be signed.</p>
<h2><strong>Right to view, correct and delete user information</strong></h2>
<p>Hauzy provides each user with a profile page, available after authorization. This website allows you to view, modify and delete personal data about the user held by Hauzy. In the event that the user considers such a solution to be insufficient, he may write to Hauzy.</p>
<h2><strong>Right to choose</strong></h2>
<p>Hauzy gives its users the opportunity to choose whether and to what extent they want to use our features and share information about themselves. Most of our features are available without having to provide information about yourself, only some features require you to sign up. Subscribing to Hauzy is a voluntary decision of the user and he may resign and delete his profile at any time.</p>

<h2><strong>Questions</strong></h2>
<p>Our goal is to provide the most advanced protection possible. The development of technology and the development of Hauzy's offer mean that our privacy policy may change, which we will inform about on these pages.</p>
<p>Hauzy is open to any opinions, comments and questions from its users regarding the confidentiality of information. Please send them to Hauzy by e-mail:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>&nbsp;or in writing by traditional letter to Hauzy's address.</p >
<h2><strong>Personal data</strong></h2>
<p>Information on the legal grounds and purposes of Hauzy's processing of Customers' personal data can be found in Appendix No. 1 to the Privacy Policy "Legal basis and purposes of processing Customers' personal data by Hauzy", and the personal data of Service Providers in Appendix No. 2 to the Privacy Policy "Basics legal and purposes of processing personal data of Service Providers by Hauzy".</p>
<h2><strong>Appendix 1</strong></h2>
<h2><strong>Legal basis and purposes of Hauzy's processing of Customers' personal data.</strong></h2>
<ol>
     <li>Hauzy attaches particular importance to respecting customer privacy.</li>
     <li>The Customer is obliged to update his personal data, in particular those contained in his Profile, Account or in the System.</li>
     <li>Customer by entering their data in the System:<ol>
             <li>agrees that his name and photo provided by him in the Profile will be available to all Internet users;</li>
             <li>Partners will contact him directly to the e-mail address or telephone number provided by him during Registration.</li>
         </ol>
     </li>
     <li>When creating an Account, the Customer may express appropriate consents regarding the processing of personal data and electronic communication for other purposes, including the performance of the contract concluded with Hauzy.</li>
     <li>The essence of the System and Application for Business is to enable Service Providers to provide Services to Customers and to enable Customers to use the Services of Service Providers, while promoting the services offered by Hauzy and the Services of Service Providers. Therefore, Hauzy and the Service Provider process Customers' personal data for their own purposes, therefore they are separate administrators of their personal data. When the Customer, using the Application, makes a Reservation of the Service Provider's service: <ol>
             <li>a contract is concluded between the Customer and the Service Provider for the provision of Services by the Service Provider to the Customer,</li>
             <li>The Customer may additionally grant consent to the Service Provider in the Application for the processing of personal data by him (for electronic communication, for profiling) for purposes other than for the performance of the contract with the Customer,</li>
             <li>Hauzy provides the Service Provider with the use of the Application of the Customer's personal data needed to provide Services to that Customer,</li>
             <li>The Service Provider becomes the administrator of such Customer's personal data, and therefore is obliged to comply with the rules set out in the GDPR and bears responsibility for it resulting from the regulations.</li>
         </ol>
     </li>
     <li>Due to the fact that the essence of the functioning of the System is to make a Reservation of Services, which involves the need to confirm the Reservation and the date of Service performance, the Customer, after making the Reservation, will receive via the System e-mail/push correspondence or text messages containing the above-mentioned . confirmation. These confirmations do not contain marketing or commercial content, only information regarding the Reservation.</li>
     <li>When the Service Provider uses the tools available as part of Hauzy's services (e.g. calendar of visits, reminders, marketing, chat) - Hauzy will, to the extent necessary, process the Customer's data on behalf of the Service Provider on the terms set out in the agreement concluded with the Service Provider - in accordance with article 28 of the GDPR - an agreement to entrust the processing of personal data, and the Service Provider will continue to be the administrator of his personal data.</li>
     <li>The purposes for which the Service Provider will be able to process the Customer's data will depend on whether and what consents the Customer has given to the Service Provider for the processing of his personal data, whether he has not withdrawn these consents or has not objected to the processing of his personal data by the Service Provider.</li >
     <li>Detailed information on the legal grounds and purposes of Hauzy's processing of Customers' personal data is available in the table below.</li>
</ol>
<table>
     <tbody><tr>
         <td colspan="2" class="table-border">
             <p><strong>INFORMATION OBLIGATION FOR THE CUSTOMER</strong></p>
         </td>
     </tr>
     <tr>
         <td colspan="2" class="table-border">
             <p><strong>We inform you that we process your personal data - detailed information can be found below:</strong></p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Administrator of personal data</strong></p>
         </td>
         <td class="table-border">
             <p><strong>The administrator of your personal data is:</strong></p>
             <p>Inigra sp. z o. o. with its registered office in Zawiercie, ul. Technologiczna 15, 42-400 Zawiercie, KRS: 0000798049, NIP 6492316515;</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Administrator contact details</strong></p>
         </td>
         <td class="table-border">
             <p><strong>You can contact us</strong>:</p>
             <ol>
                 <li>to the e-mail address:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>;</li>
                 <li>in writing, to the address: Hauzy, ul. Ogrodowa 12, 61-821 Poznań;</li>
                 <li>via the electronic form at:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</li >
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Contact details of Hauzy's personal data protection officer</strong></p>
         </td>
         <td class="table-border">
             <p>We have appointed a person responsible for the protection of personal data, i.e.&nbsp;<strong>the personal data protection officer</strong>, who can be contacted:</p>
             <ol>
                 <li>to the e-mail address:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a></li>
                 <li>in writing, to the address: Hauzy, ul. Ogrodowa 12, 61-821 Poznań.</li>
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Scope of processed personal data</strong></p>
         </td>
         <td class="table-border">
             <p>In order for you to register an account on the Hauzy website and to be able to fully use the website's functions, we will process the following <strong>categories of your personal data</strong>:</p>
             <ol>
                 <li>Name and surname,</li>
                 <li>E-mail address,</li>
                 <li>Phone number,</li>
                 <li>Residence address,</li>
                 <li>IP of the device;</li>
                 <li>Date of birth,</li>
                 <li>Your photo,</li>
                 <li>Your gender.</li>
             </ol>
         </td>
     </tr>
    <tr>
<td class="table-border">
             <p><strong>Purposes and legal basis for data processing</strong></p>
         </td>
         <td class="table-border">
             <p>We process your personal data&nbsp;for the following purposes:</p>
             <ol>
                 <li><strong>concluding and performing a contract&nbsp;for using the Hauzy website</strong> to enable you to use the services of entities cooperating with Hauzy ("<strong>Service Providers</strong>"), in particular by providing your data personal data of the Service Provider when you submit a request to book the Service Provider's services using the Hauzy application - such processing of your personal data is necessary for the performance of the contract (Article 6(1)(b) of the GDPR);</li>
                 <li><strong>marketing of Hauzy products or services (excluding direct marketing)</strong>&nbsp;– the processing of your personal data for this purpose will take place in the implementation of Hauzy's legitimate interest (Article 6(1)(f) GDPR), which is the possibility of marketing your own products and services;</li>
                 <li><strong>direct marketing of Hauzy's products or services</strong>&nbsp; - such processing of your personal data is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is the possibility of conducting marketing own products and services, consisting in direct communication with you in order to advertise and promote Hauzy's services;</li>
                 <li><strong>profiling/grouping</strong>&nbsp;(i.e. conducting analyzes that may also apply to personal data, and grouping customers - in order to adapt our products or services and their marketing to your preferences, as well as to your preferences specific customer groups identified by us) - the processing of your personal data for this purpose will take place in the implementation of our legitimate interest (Article 6(1)(f) of the GDPR);</li>
                 <li><strong>preference research regarding the demand for Hauzy's or Service Providers' services</strong> (e.g. in the form of telephone, SMS or e-mail surveys)&nbsp;- which is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is the possibility to determine the catalog of products and services of interest to customers and the level of their satisfaction with the services of Hauzy or the services of Service Providers;</li>
                 <li><strong>determining, investigating or defending against claims related to the concluded contract or the processing of your personal data</strong> - the processing of your personal data in this respect is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR) 1 letter f of the GDPR), which is the possibility of establishing, investigating or defending against possible claims;</li>
                 <li><strong>fulfilment of legal obligations imposed on Hauzy, resulting from the relevant provisions of law</strong> - the processing of your personal data in this case is necessary to meet the legal requirements to which Hauzy is subject (Article 6(1)(c) GDPR);</li>
                 <li><strong>creating lists, analyses, statistics for Hauzy's internal needs</strong>&nbsp;(including reporting, service development planning, development work in the Hauzy system, creating statistical models) - such processing of your personal data is necessary for implementation of Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is the analysis and development of business;</li>
                 <li><strong>implementation of new or development of solutions/functionalities of Hauzy systems/applications</strong>&nbsp;(development of application functionalities, testing of new solutions introduced, analysis of the type of necessary new functionalities, creation of analytical tools/reports, etc.) - such processing of your personal data is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is to develop and ensure the security of Hauzy's systems/applications;</li>
                 <li><strong>in order to respond to your message and communicate with you when you send us a message</strong> - such processing of your personal data is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) GDPR), which is the ability to reply to your message and communicate with you.</li>
             </ol>
         </td>
     </tr>
<tr>
         <td class="table-border">
             <p><strong>Data processing by Service Providers</strong></p>
         </td>
         <td class="table-border">
             <p>If you decide to use the services of entities cooperating with Hauzy (Service Providers) and book a visit/service with the Service Provider via the Hauzy website, Hauzy - performing the contract concluded with you for the use of the Hauzy website - will provide the given Service Provider with your data regarding necessary for it to be able to perform the service you have chosen. The service provider will then process your data as a separate administrator in order to perform the contract concluded with you for the provision of the service selected by you and will bear the related responsibility on its own.</p>
             <p>You will also be able to separately grant consent to the Service Provider to process your data for other purposes. The service provider, pursuing its own goals (e.g. marketing), will also be able to use the tools provided to it by Hauzy. Then Hauzy will send you marketing, recommendations or information about promotions on behalf of such a Service Provider.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Profiling/grouping</strong></p>
         </td>
         <td class="table-border">
             <p>Please be advised that we &nbsp;profil/group customers in order to provide our services and direct advertisements, reminders, recommendations and promotions as much as possible to them and your preferences. Such profiling/grouping may be performed on the basis of the following data:</p>
             <ol>
                 <li>activity on the website and in the Hauzy app;</li>
                 <li>geolocation;</li>
                 <li>gender;</li>
                 <li>hours of using Hauzy's services;</li>
                 <li>time of last activity on the Hauzy website or app;</li>
                 <li>analysis of photos on Hauzy that you liked.</li>
             </ol>
             <p>We clarify that Hauzy does not use invasive profiling and tracking practices for marketing or advertising purposes.</p>
             <p>You can object to such processing of your personal data by sending us an e-mail to:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, or by contacting via the electronic form available at:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Categories of data recipients</strong></p>
         </td>
         <td class="table-border">
             <p><strong>The recipients of your personal data may be:</strong></p>
             <ol>
                 <li>Service Providers;</li>
                 <li>companies from the Hauzy capital group;</li>
                 <li>entities authorized under the law (courts, state authorities);</li>
                 <li>entities providing accounting, IT, marketing, communication and analytical, legal and debt collection services;</li>
                 <li>our subcontractors and other entities with whom we cooperate.</li>
             </ol>
             <p>We confirm that all entities to which we transfer your personal data provide at least the same protection for your personal data as set out in this privacy policy and as required by the guidelines of Apple Inc.</p>
         </td>
    </tr>
<tr>
         <td class="table-border">
             <p><strong>Transfer of data outside the European Economic Area</strong></p>
         </td>
         <td class="table-border">
             <p>Your data&nbsp;will not be transferred to entities based outside&nbsp;European Economic Area.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Data retention period</strong></p>
         </td>
         <td class="table-border">
             <p>We will process your data:</p>
             <ol>
                 <li><strong>in order to conclude and perform the contract</strong>&nbsp;- until the contract is terminated or expires, and then until the claims expire;</li>
                 <li><strong>for the purpose of marketing products or services (excluding direct marketing)</strong>&nbsp;- until the contract is terminated or expires or until you object to data processing for this purpose;</li>
                 <li><strong>for the purpose of direct marketing of Hauzy products or services</strong>&nbsp;- until the termination or expiration of the contract or until an objection to data processing for this purpose is raised;</li>
                 <li><strong>for profiling/grouping</strong>&nbsp;– until the contract is terminated or expires, or until you object to data processing for this purpose;</li>
                 <li><strong>preference research in terms of the demand for services</strong>&nbsp;- until the contract is terminated or expires, or until you object to data processing for this purpose;</li>
                 <li><strong>in order to establish, pursue or defend against claims</strong>&nbsp;- until these claims expire;</li>
                 <li><strong>fulfilment of legal obligations</strong>&nbsp;- until the expiry of the obligations to store data under the law;</li>
                 <li><strong>to create summaries, analyses, statistics for internal needs</strong>&nbsp;– for the duration of the contract, and then until the claims under the contract expire or until you object to data processing for this purpose; </li>
                 <li><strong>in order to implement new or develop solutions/functionalities of Hauzy's systems/applications</strong>&nbsp;– until the contract is terminated or expires or until an objection is raised against data processing for this purpose;</li>
                 <li><strong>in order to respond to your message and communicate with you when you send us a message</strong> - for the duration of communication with you, and then until the claims expire.</li>
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Your rights</strong></p>
         </td>
         <td class="table-border">
             <p><strong>According to the GDPR, you are entitled to:</strong></p>
             <ol>
                 <li>the right&nbsp;to&nbsp;access&nbsp;your data and receive a copy thereof;</li>
                 <li>the right to&nbsp;rectification (correction, supplementation)&nbsp;your data;</li>
                 <li>right to&nbsp;deletion of data, limitation of&nbsp;data processing;</li>
                 <li>right to&nbsp;data portability; in order to exercise the above rights, contact Hauzy: via e-mail&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>&nbsp;via the electronic form available at:&nbsp; <a href="https://hauzy.app/en/#contact">https://hauzy.app/en/#contact</a></li>
                 <li>the right to lodge a&nbsp;complaint with the supervisory body (Office for Personal Data Protection).</li>
             </ol>
         </td>
     </tr>
    <tr>
<td class="table-border">
             <p><strong>Right to withdraw consent</strong></p>
         </td>
         <td class="table-border">
             <p>You can withdraw your consent to the processing of personal data at any time - this applies only to the processing of data that Hauzy will process on the basis of your consent. For this purpose, you can send us an e-mail to:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, &nbsp;or contact us via the form electronic available at:&nbsp;<a href="https://hauzy.app/en/contact">https://hauzy.app/en/contact</a>.</p>
             <p>Withdrawal of consent will not affect the lawfulness of data processing prior to withdrawal of consent.</p>
             <p>If you use the Hauzy application, you can check what consents you have given at any time and you can edit them by entering the "Profile" tab in your Hauzy application and then the "Privacy Settings" tab.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Right to object</strong></p>
         </td>
         <td class="table-border">
             <p>You can object to the processing of your personal data processed by Hauzy for the purpose of pursuing a legitimate interest (Article 6(1)(f) of the GDPR) at any time by sending us an e-mail to the following address:&nbsp;< a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a> by calling:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;or by contacting via the electronic form available at:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact </a>.</p>
             <p>Hauzy will then no longer be allowed to process your personal data, unless Hauzy demonstrates the existence of valid legitimate grounds for processing, overriding your interests, rights and freedoms, or grounds for establishing, pursuing or defending claims.</p>
             <p>If you object to data processing for direct marketing purposes, Hauzy will stop processing your personal data for this purpose in each case.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Right to erasure</strong></p>
         </td>
         <td class="table-border">
             <p>You can request the removal of your personal data from the Hauzy website at any time. You can do this by submitting a request to delete your personal data to the following address: <a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>. </p>
             <p>If you use the Hauzy application on iOS, you can delete your account by entering the "Profile" tab in your Hauzy application and then clicking on the "Delete my account" button. Deleting an account in the Hauzy application in iOS is tantamount to deleting personal data from the Hauzy application.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Information on the requirement or voluntary provision of data and the consequences of not providing them</strong></p>
         </td>
         <td class="table-border">
             <p>Providing your personal data is voluntary, but necessary for the conclusion and performance of the contract, for the use of the Hauzy website and for the implementation of the other above-mentioned purposes. purposes. </p>
             <p>You can check or edit your personal data that you have provided to us at any time by entering the "Profile" tab in your Hauzy application.</p>
         </td>
     </tr>
</tbody></table>
<h2><strong>Appendix 2</strong></h2>
<h2><strong>Legal basis and purposes of processing personal data of Service Providers by Hauzy.</strong></h2>
<ol>
     <li>The administrator of personal data of Service Providers who are natural persons is Hauzy. The information obligation referred to in Art. 13 of the GDPR Hauzy fulfills towards the Service Providers when registering the Account. The content of the information clause is available in the table at the end of the annex.</li>
     <li>Hauzy and the Service Provider process Customers' personal data for their own purposes, therefore they are separate administrators of their personal data.</li>
     <li>When the Customer makes a Reservation of the Service Provider's service using the Application:<ol>
             <li>a contract is concluded between the Customer and the Partner for the provision of Services by the Service Provider to the Customer,</li>
             <li>The Customer may provide the Service Provider with consent to the processing of personal data (e.g. for electronic communication) in the Application for purposes other than the performance of the contract with the Customer,</li>
             <li>Hauzy provides the Service Provider with the use of the Application of the Customer's personal data needed to provide Services to that Customer.</li>
             <li>The Service Provider becomes the administrator of such Customer's personal data, and therefore is obliged to comply with the rules set out in the GDPR and bears responsibility for it resulting from the regulations.</li>
         </ol>
     </li>
     <li>In order to enable Hauzy to provide services to the Service Provider as part of the Application, for which processing is necessary:<ol>
             <li>personal data of Customers who have made a Reservation of the Service Provider's services,</li>
             <li>personal data of persons used by the Service Provider using the Application (employees, contractors, etc.),</li>
             <li>it is necessary for the Service Provider to entrust the processing of personal data of such persons, therefore the Service Provider and Hauzy conclude an Agreement for entrusting the processing of personal data.</li>
         </ol>
     </li>
     <li>The Service Provider is aware that the termination of the Agreement for entrusting the processing of personal data will result in the inability of Hauzy to provide services to the Service Provider under the Agreement to the extent that data processing is required, the administrator of which is the Service Provider, therefore, together with the termination of the Agreement for entrusting the processing of personal data – in accordance with Art. 475 §1 of the Civil Code - Hauzy's obligations in this respect expire.</li>
     <li>When the Service Provider uses the tools available as part of Hauzy's services (e.g. calendar of visits, reminders, marketing, chat) - Hauzy will process the Customer's data on behalf of the Service Provider on the terms set out in the Personal Data Processing Agreement, and the Service Provider will continue to will be the administrator of his personal data.</li>
     <li>The purposes for which the Service Provider will be able to process the Customer's data will depend on whether and what consents the Customer has given to the Service Provider for the processing of his personal data, whether he has not withdrawn these consents or has not objected to the processing of his personal data by the Service Provider.</li >
     <li>The Service Provider is also the administrator of the Customer's personal data with whom he will conclude a contract outside the Application (e.g. in the salon). If he enters these data into the Application, he is still their administrator, and Hauzy processes them on behalf of the Service Provider on the basis of the Personal Data Processing Agreement.</li>
     <li>Hauzy processes data, including personal data, of the Service Provider only to the extent that enables the proper provision of services and proper performance of the Agreement. Hauzy is not responsible for the scope of Customers' personal data collected by Service Providers and for the legality of collecting such data by Service Providers. Detailed obligations of the parties related to this are included in the Agreement for entrusting the processing of personal data.</li>
</ol>
<table>
     <tbody><tr>
         <td colspan="2" class="table-border">
             <p><strong>INFORMATION OBLIGATION FOR THE SERVICE PROVIDER</strong></p>
         </td>
     </tr>
     <tr>
         <td colspan="2" class="table-border">
             <p><strong>We inform you that we process your personal data - detailed information can be found below:</strong></p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Administrator of personal data</strong></p>
         </td>
         <td class="table-border">
             <p><strong>The administrator of your personal data is:</strong></p>
             <p>Inigra sp. z o. o. with its registered office in Zawiercie, ul. Technologiczna 15, 42-400 Zawiercie, KRS: 0000798049, NIP 6492316515;</p>
         </td>
     </tr>
<tr>
         <td class="table-border">
             <p><strong>Administrator contact details</strong></p>
         </td>
         <td class="table-border">
             <p><strong>You can contact us:</strong></p>
             <ol>
                 <li>to the e-mail address:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>;</li>
                 <li>in writing, to the address: Hauzy, ul. Ogrodowa 12, 61-821 Poznań;</li>
                 <li>via the electronic form at:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</li >
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Contact details of Hauzy's personal data protection officer</strong></p>
         </td>
         <td class="table-border">
             <p>We have appointed a person responsible for the protection of personal data, i.e. a personal data protection officer who can be contacted:</p>
             <ol>
                 <li>to the e-mail address:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a></li>
                 <li>in writing, to the address: Hauzy, ul. Ogrodowa 12, 61-821 Poznań.</li>
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Scope of processed personal data</strong></p>
         </td>
         <td class="table-border">
             <p>In order for you to register an account on the Hauzy website and to be able to fully use the website's functions, we will process the following categories of your personal data:</p>
             <ol>
                 <li>Name and surname,</li>
                 <li>E-mail address,</li>
                 <li>Phone number,</li>
                 <li>Residential address/Business address,</li>
                 <li>IP of the device;</li>
                 <li>Date of birth</li>
                 <li>Logo,</li>
                 <li>Profile picture.</li>
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Purposes and legal basis for data processing</strong></p>
         </td>
         <td class="table-border">
             <p>We process your personal data&nbsp;for the following purposes:</p>
            <ol>
<li><strong>conclusion and performance of the contract&nbsp;for the use of the Hauzy website</strong> - such processing of your personal data is necessary for the performance of the contract (Article 6(1)(b) of the GDPR);</li>
                 <li><strong>marketing of Hauzy products or services (excluding direct marketing)</strong>&nbsp;– the processing of your personal data for this purpose will take place in the implementation of Hauzy's legitimate interest (Article 6(1)(f) GDPR), which is the possibility of marketing your own products and services;</li>
                 <li><strong>direct marketing of Hauzy's products or services&nbsp;</strong> - such processing of your personal data is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is the possibility of marketing your own products and services, consisting in direct communication with you in order to advertise and promote Hauzy's services;</li>
                 <li><strong>profiling/grouping</strong>&nbsp;(i.e. conducting analyzes that may also apply to personal data, and grouping customers - in order to adapt our products or services and their marketing to your preferences, as well as to your preferences specific customer groups identified by us) - the processing of your personal data for this purpose will take place in the implementation of our legitimate interest (Article 6(1)(f) of the GDPR);</li>
                 <li><strong>preference research regarding the demand for Hauzy's or Service Providers' services</strong> (e.g. in the form of telephone, SMS or e-mail surveys)&nbsp;- which is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is the possibility to determine the catalog of products and services of interest to customers and the level of their satisfaction with the services of Hauzy or the services of Service Providers;</li>
                 <li><strong>determining, investigating or defending against claims related to the concluded contract or the processing of your personal data</strong> - the processing of your personal data in this respect is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR) 1 letter f of the GDPR), which is the possibility of establishing, investigating or defending against possible claims;</li>
                 <li><strong>fulfilment of legal obligations imposed on Hauzy, resulting from the relevant provisions of law</strong> - the processing of your personal data in this case is necessary to meet the legal requirements to which Hauzy is subject (Article 6(1)(c) GDPR);</li>
                 <li><strong>creating lists, analyses, statistics for Hauzy's internal needs</strong>&nbsp;(including reporting, service development planning, development work in the Hauzy system, creating statistical models) - such processing of your personal data is necessary for implementation of Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is the analysis and development of business;</li>
                 <li><strong>implementation of new or development of solutions/functionalities of Hauzy systems/applications</strong>&nbsp;(development of application functionalities, testing of new solutions introduced, analysis of the type of necessary new functionalities, creation of analytical tools/reports, etc.) - such processing of your personal data is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) of the GDPR), which is to develop and ensure the security of Hauzy's systems/applications;</li>
                 <li><strong>in order to respond to your message and communicate with you when you send us a message</strong> - such processing of your personal data is necessary to implement Hauzy's legitimate interest (Article 6(1)(f) GDPR), which is the ability to reply to your message and communicate with you.</li>
             </ol>
         </td>
     </tr>
    <tr>
<td class="table-border">
             <p><strong>Profiling/grouping</strong></p>
         </td>
         <td class="table-border">
             <p>Please be advised that we &nbsp;profil/group customers in order to provide our services and direct advertisements, reminders, recommendations and promotions as much as possible to them and your preferences. Such profiling/grouping may be performed on the basis of the following data:</p>
             <ol>
                 <li>activity on the website and in the Hauzy app;</li>
                 <li>geolocation;</li>
                 <li>gender;</li>
                 <li>hours of using Hauzy's services;</li>
                 <li>time of last activity on the Hauzy website or app;</li>
                 <li>analysis of photos on Hauzy that you liked.</li>
             </ol>
             <p>We clarify that Hauzy does not use invasive profiling and tracking practices for marketing or advertising purposes.</p>
             <p>You can object to such processing of your personal data by sending us an e-mail to:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, &nbsp;or by contacting via the electronic form available at:&nbsp;<a href="https://hauzy.app/en/contact">https://hauzy.app/en/contact</a >.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Categories of data recipients</strong></p>
         </td>
         <td class="table-border">
             <p>The recipients of your personal data may be:</p>
             <ol>
                 <li>companies from the Hauzy capital group;</li>
                 <li>entities authorized under the law (courts, state authorities);</li>
                 <li>entities providing accounting, IT, marketing, communication and analytical, legal and debt collection services;</li>
                 <li>our subcontractors and other entities with whom we cooperate.</li>
             </ol>
             <p>We confirm that all entities to which we transfer your personal data provide at least the same protection for your personal data as set out in this privacy policy and as required by the guidelines of Apple Inc.</p>
         </td>
     </tr>
    <tr>
<td class="table-border">
             <p><strong>Transfer of data outside the European Economic Area</strong></p>
         </td>
         <td class="table-border">
             <p>Your data&nbsp;will not be transferred to entities based outside&nbsp;European Economic Area.</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Data retention period</strong></p>
         </td>
         <td class="table-border">
             <p>We will process your data:</p>
             <ol>
<li><strong>in order to conclude and perform the contract&nbsp;-</strong> until the contract is terminated or expires, and then until the claims expire;</li>
                 <li><strong>for the purpose of marketing products or services</strong> (excluding direct marketing)&nbsp;- until the contract is terminated or expires or until you object to data processing for this purpose;</li>
                 <li><strong>for the purpose of direct marketing of Hauzy products or services&nbsp;-</strong> until the contract is terminated or expires or until you object to data processing for this purpose;</li>
                 <li><strong>for profiling/grouping</strong>&nbsp;– until the contract is terminated or expires, or until you object to data processing for this purpose;</li>
                 <li><strong>preference research in terms of the demand for services</strong>&nbsp;- until the contract is terminated or expires, or until you object to data processing for this purpose;</li>
                 <li><strong>in order to establish, pursue or defend against claims</strong>&nbsp;- until these claims expire;</li>
                 <li><strong>fulfilment of legal obligations</strong>&nbsp;- until the expiry of the obligations to store data under the law;</li>
                 <li><strong>to create summaries, analyses, statistics for internal needs</strong>&nbsp;– for the duration of the contract, and then until the claims under the contract expire or until you object to data processing for this purpose; </li>
<li><strong>in order to implement new or develop solutions/functionalities of Hauzy's systems/applications</strong>&nbsp;– until the contract is terminated or expires or until an objection is raised against data processing for this purpose;</li>
                 <li><strong>in order to respond to your message and communicate with you when you send us a message</strong> - for the duration of communication with you, and then until the claims expire.</li>
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Your rights</strong></p>
         </td>
         <td class="table-border">
             <p><strong>According to the GDPR, you are entitled to:</strong></p>
             <ol>
<li>the right&nbsp;to&nbsp;access&nbsp;your data and receive a copy thereof;</li>
                 <li>the right to&nbsp;rectification (correction, supplementation)&nbsp;your data;</li>
                 <li>right to&nbsp;deletion of data, limitation of&nbsp;data processing;</li>
                 <li>right to&nbsp;data portability; in order to exercise the above rights, contact Hauzy: via e-mail&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>&nbsp;via the electronic form available at:&nbsp; <a href="https://hauzy.app/en/#contact">https://hauzy.app/en/#contact</a></li>
                 <li>the right to lodge a&nbsp;complaint with the supervisory body (Office for Personal Data Protection).</li>
             </ol>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Right to withdraw consent</strong></p>
         </td>
<td class="table-border">
             <p>You can withdraw your consent to the processing of personal data at any time - this applies only to the processing of data that Hauzy will process on the basis of your consent. For this purpose, you can send us an e-mail to:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, &nbsp;or contact us via the form electronic available at:&nbsp;<a href="https://hauzy.app/en/contact">https://hauzy.app/en/contact</a>.</p>
             <p>Withdrawal of consent will not affect the lawfulness of data processing prior to withdrawal of consent. </p>
             <p>You can check what consents you have given at any time and you can edit them by entering the "Profile" tab in your Hauzy application, then the "Settings" tab, then the "Hauzy Application" tab, and then the "Protection" tab your data".</p>
         </td>
     </tr>
     <tr>
         <td class="table-border">
             <p><strong>Right to object</strong></p>
         </td>
 <td class="table-border">
             <p>You can object to the processing of your personal data processed by Hauzy for the purpose of pursuing a legitimate interest (Article 6(1)(f) of the GDPR) at any time by sending us an e-mail to the following address:&nbsp;< a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, or by contacting us via the electronic form available at:&nbsp;<a href="https://hauzy.app/en/contact">https://hauzy.app/en/contact</a>.</p>
             <p>Hauzy will then no longer be allowed to process your personal data, unless Hauzy demonstrates the existence of valid legitimate grounds for processing, overriding your interests, rights and freedoms, or grounds for establishing, pursuing or defending claims.</p>
             <p>If you object to data processing for direct marketing purposes, Hauzy will stop processing your personal data for this purpose in each case.</p>
         </td>
     </tr>
    <tr>
<td class="table-border">
             <p><strong>Right to erasure</strong></p>
         </td>
         <td class="table-border">
             <p>You can request the removal of your personal data from the Hauzy website at any time. You can do this by submitting a request to delete your personal data to the following address: <a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>.</p>
             <p>If you use the Hauzy application on iOS, you can delete your account by entering the "Profile" tab in your Hauzy application, then the "Settings" tab, then the "Hauzy Application" tab, and then the "Delete Account" tab. Deleting an account in the Hauzy application in iOS is tantamount to deleting personal data from the Hauzy application.</p>
         </td>
     </tr>
     <tr>
<td class="table-border">
             <p><strong>Information on the requirement or voluntary provision of data and the consequences of not providing them</strong></p>
         </td>
         <td class="table-border">
             <p>Providing your personal data is voluntary, but necessary for the conclusion and performance of the contract, for the use of the Hauzy website and for the implementation of the other above-mentioned purposes. purposes. </p>
             <p>You can check or edit your personal data at any time by entering the "Profile" tab in your Hauzy application, and then the "Settings" tab..</p>
         </td>
     </tr>
</tbody></table>

<p><small class="lastUpdate">Last update: March 15, 2023</small></p>
    
</div>
: 
<div class="legalContent" data-v-620932dc="">
  
<h1><strong>Polityka prywatności - Hauzy</strong></h1>
<h2><strong>Jakie informacje osobiste są gromadzone i wykorzystywane przez Hauzy?</strong></h2>
<p>Korzystający z naszego portalu pozostają anonimowi tak długo, aż sami nie zdecydują inaczej. Wynikające z ogólnych zasad połączeń realizowanych w Internecie informacje zawarte w logach systemowych (np. adres IP) są przez Hauzy wykorzystywane w celach technicznych, związanych z administracją naszymi serwerami. Poza tym adresy IP są wykorzystywane do zbierania ogólnych, statystycznych informacji demograficznych (np. o regionie, z którego następuje połączenie). Zapisanie się do Hauzy wymaga wypełnienia formularza, w którym należy podać informacje umożliwiające skontaktowanie się z użytkownikiem (np. numer telefonu lub adres e-mail) oraz informacje demograficzne (np. płeć). Informacje podane w formularzu są przez nas wykorzystywane do niezbędnych kontaktów z naszymi użytkownikami oraz w celu lepszego dostosowywania prezentowanych przez nas treści do ich potrzeb i zainteresowań.</p>
<h2><strong>Jaka jest polityka Hauzy dotycząca plików „cookies”?</strong></h2>
<p>Twoja przeglądarka internetowa może przechowywać pliki tekstowe (ang. „cookies”) na dysku Twojego komputera. W plikach „cookies” znajdują się informacje niezbędne do prawidłowego funkcjonowania portalu, w szczególności tych wymagających autoryzacji. Zawartość plików cookies nie pozwala na identyfikację użytkownika. Za pomocą plików cookies nie są przetwarzane lub przechowywane dane osobowe.</p>
<p>Hauzy przechowuje pliki cookies na komputerach użytkowników w celu:</p>
<ul>
    <li>utrzymania sesji użytkownika (po zalogowaniu), dzięki której użytkownik nie musi na każdej stronie wpisywać nazwy użytkownika i hasła;</li>
    <li>lepszego dopasowania serwisów do potrzeb użytkowników;</li>
    <li>tworzenia statystyk oglądalności dla poprawienia funkcjonowania portalu;</li>
</ul>
<p>Istnieje możliwość takiego skonfigurowania Twojej przeglądarki internetowej, która spowoduje, że całkowicie zostanie wyłączona możliwość przechowywania plików cookies na dysku twardym Twojego komputera. Pamiętaj, że efektem takiej zmiany może być utrata możliwości korzystania z niektórych funkcji serwisu Hauzy (logowanie, podgląd zdjęć itp.).</p>
<h2><strong>Szczegółowe informacja na temat plików „cookies”</strong></h2>
<p>System Hauzy wykorzystuje tzw. pliki cookies (ciasteczka), tj. pliki tekstowe służące do zapisywania i przechowywania informacji o sposobie wykorzystywania Systemu przez użytkownika. Pliki cookies są zapisywane na urządzeniu użytkownika oraz przesyłane przez przeglądarkę internetową użytkownika do Systemu za każdym razem, gdy użytkownik z niego korzysta.</p>
<p>Hauzy wykorzystuje również informacje zawarte w logach systemowych (np. adres IP).</p>
<p>Hauzy wykorzystuje następujące rodzaje plików cookies:</p>
<ul>
    <li>cookies sesyjne&nbsp;- automatycznie usuwane po zamknięciu przeglądarki internetowej;</li>
    <li>cookies trwałe&nbsp;– przechowywane są w urządzeniu przez określony czas;</li>
    <li>cookies własne&nbsp;- ustawiane przez System Hauzy;</li>
    <li>cookies podmiotów trzecich&nbsp;- ustawiane przez inne serwisy niż System Hauzy, np. Google Analytics, Facebook, itp.</li>
</ul>
<p>Hauzy wykorzystuje informacje gromadzone przy użyciu plików cookies oraz pochodzące z logów systemowych w celu:</p>
<ul>
    <li>dostosowania treści, wyglądu, rozmiaru oraz innych parametrów Systemu do konkretnego urządzenia i preferencji użytkownika;</li>
    <li>zbierania ogólnych, statystycznych informacji demograficznych (np. o regionie, z którego następuje połączenie).</li>
</ul>
<p>Pliki cookies wykorzystywane przez System Hauzy nie pozwalają Hauzy na identyfikację konkretnych użytkowników korzystających z Systemu.</p>
<p>Korzystając z Systemu użytkownik wyraża zgodę na umieszczanie opisanych powyżej plików cookies w swoim urządzeniu.</p>
<p>Użytkownik może usunąć lub zablokować instalację plików cookies za pośrednictwem zmiany ustawień przeglądarki internetowej. Szczegółowe informacje na temat możliwości modyfikacji ustawień przeglądarki, blokowania i filtrowania plików cookies można znaleźć pod adresem:&nbsp;<a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a>&nbsp;lub&nbsp;<a href="http://www.cookiecentral.com/faq/">http://www.cookiecentral.com/faq/</a>.</p>
<p>Usunięcie lub zablokowanie plików cookies może wpłynąć na dostępność funkcjonalności Systemu, prawidłowości wyświetlania Systemu oraz utratę preferowanych ustawień stron internetowych Systemu.</p>
<h2><strong>W jaki sposób chronione są informacje?</strong></h2>
<p>Formularz wypełniany przez użytkownika w trakcie zapisywania się do Hauzy, a także sam proces autoryzacji (logowania) do naszych zasobów realizowany jest za pomocą kodowanych sesji, które istotnie zwiększą ochronę transmisji danych w sieci Internet. Dane takie jak hasła dostępu do profili są zaszyfrowane za pomocą jednokierunkowych algorytmów szyfrowania, uniemożliwiających ich późniejsze odszyfrowanie. Informacje przechowywane są i przetwarzane przez Hauzy z zachowaniem odpowiednich środków bezpieczeństwa, spełniających wymagania polskiego prawa.</p>
<h2><strong>Czy podane informacje osobiste są udostępniane innym podmiotom?</strong></h2>
<p>Hauzy zawiera lub będzie zawierał funkcje (np. recenzje), w których wprowadzane przez użytkownika treści (np. treść i podpis opinii) stają się publicznie dostępne. Użytkownik może publikować swoje treści w tych serwisach jedynie po dokonaniu autoryzacji i sam decyduje o tym, jak mają one być podpisywane.</p>
<h2><strong>Prawo do wglądu, poprawienia i usunięcia informacji o użytkowniku</strong></h2>
<p>Hauzy udostępnia każdemu użytkownikowi stronę profilową, dostępną po autoryzacji. Strona ta pozwala na wgląd, modyfikację i usunięcie posiadanych przez Hauzy danych osobowych o użytkowniku. W przypadku, gdyby użytkownik uznał takie rozwiązanie za niewystarczające, może zwrócić się pisemnie do Hauzy.</p>
<h2><strong>Prawo wyboru</strong></h2>
<p>Hauzy daje swoim użytkownikom możliwość wyboru, czy i w jakim zakresie chcą korzystać z naszych funkcji i udostępniać o sobie informacje. Większość naszych funkcji jest dostępna bez konieczności podawania informacji o sobie, jedynie niektóre funkcje wymagają zapisania się. Zapisanie się do Hauzy jest dobrowolną decyzją użytkownika i może on w każdej chwili zrezygnować i usunąć swój profil.</p>

<h2><strong>Pytania</strong></h2>
<p>Naszym celem jest zapewnienie jak najdalej posuniętej ochrony. Rozwój technologii oraz rozwój oferty Hauzy powodują, że nasza polityka ochrony prywatności może ulegać zmianom, o których będziemy informować na tych stronach.</p>
<p>Hauzy jest otwarty na wszelkie opinie, uwagi i pytania swoich użytkowników dotyczące zachowania poufności informacji. Prosimy o ich kierowanie do Hauzy na adres e-mail:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>&nbsp;lub pisemnie listem tradycyjnym na adres Hauzy.</p>
<h2><strong>Dane osobowe</strong></h2>
<p>Informacje na temat podstaw prawnych i celów przetwarzania przez Hauzy danych osobowych Klientów znajdziesz w Załączniku nr 1 do Polityki prywatności „Podstawy prawne i cele przetwarzania przez Hauzy danych osobowych Klientów”, a danych osobowych Usługodawców w Załączniku nr 2 do Polityki prywatności „Podstawy prawne i cele przetwarzania przez Hauzy danych osobowych Usługodawców”.</p>
<h2><strong>Załącznik nr 1</strong></h2>
<h2><strong>Podstawy prawne i cele przetwarzania przez Hauzy danych osobowych Klientów.</strong></h2>
<ol>
    <li>Hauzy przywiązuje szczególną wagę do poszanowania prywatności Klientów.</li>
    <li>Klient jest zobowiązany do aktualizacji swoich danych osobowych, w szczególności znajdujących się na jego Profilu, Koncie albo w Systemie.</li>
    <li>Klient, zamieszczając swoje dane w Systemie:<ol>
            <li>wyraża zgodę na to, że jego imię oraz zdjęcie udostępnione przez niego w Profilu będą dostępne dla wszystkich użytkowników sieci Internet;</li>
            <li>Usługodawcy będą kontaktować się z nim bezpośrednio na podany przez niego podczas Rejestracji adres e-mail lub nr telefonu.</li>
        </ol>
    </li>
    <li>Klient przy tworzeniu Konta może wyrazić odpowiednie zgody dotyczące przetwarzania danych osobowych i komunikacji elektronicznej w innych celach nich wykonanie umowy zawartej z Hauzy.</li>
    <li>Istotą Systemu oraz Aplikacji dla Biznesu jest umożliwienie Usługodawcom świadczenia Usług na rzecz Klientów oraz umożliwienie Klientom korzystania z Usług Usługodawców, promując jednocześnie oferowane usługi przez Hauzy oraz Usługi Usługodawców. W związku z tym Hauzy i Usługodawca przetwarzają dane osobowe Klientów we własnych celach, zatem są odrębnymi administratorami ich danych osobowych. Gdy Klient za pomocą Aplikacji dokonuje Rezerwacji usługi Usługodawcy:<ol>
            <li>dochodzi do zawarcia umowy między Klientem a Usługodawcą o świadczenie przez Usługodawcę Usług na rzecz Klienta,</li>
            <li>Klient może w Aplikacji dodatkowo udzielić Usługodawcy zgód na przetwarzanie przez niego danych osobowych (na komunikację elektroniczną, na profilowanie) w innych celach niż w celu wykonania umowy z Klientem,</li>
            <li>Hauzy udostępnia Usługodawcy za pomocą Aplikacji dane osobowe Klienta potrzebne do świadczenia przez niego Usług na rzecz tego Klienta,</li>
            <li>Usługodawca staje się administratorem danych osobowych takiego Klienta, w związku z czym zobowiązany jest do przestrzegania zasad określonych w RODO i ponosi za to odpowiedzialność wynikającą z przepisów.</li>
        </ol>
    </li>
    <li>Z uwagi na fakt, że istotą funkcjonowania Systemu jest dokonywanie Rezerwacji Usług, która wiąże się z koniecznością potwierdzenia Rezerwacji i terminu wykonania Usługi, Klient po dokonaniu Rezerwacji będzie otrzymywał za pośrednictwem Systemu, korespondencję e- mail/push lub wiadomości sms zawierające ww. potwierdzenia. Potwierdzenia te nie zawierają treści marketingowych ani handlowych, a jedynie informacje dotyczące Rezerwacji.</li>
    <li>Gdy Usługodawca będzie korzystać z narzędzi dostępnych w ramach usług Hauzy (m.in. kalendarz wizyt, przypomnienia, marketing, czat) – Hauzy będzie w niezbędnym zakresie w imieniu Usługodawcy przetwarzać dane Klienta na zasadach określonych w zawartej z Usługodawcą – zgodnie z art. 28 RODO – umowie powierzenia przetwarzania danych osobowych, a Usługodawca nadal będzie administratorem jego danych osobowych.</li>
    <li>Cele w jakich Usługodawca będzie mógł przetwarzać dane Klienta będą uzależnione od tego, czy i jakich zgód Klient udzielił Usługodawcy na przetwarzanie jego danych osobowych, czy tych zgód nie wycofał lub nie zgłosił sprzeciwu wobec przetwarzania przez Usługodawcę jego danych osobowych.</li>
    <li>Szczegółowe informacje na temat podstaw prawnych i celów przetwarzania przez Hauzy danych osobowych Klientów dostępne są w poniższej tabeli.</li>
</ol>
<table>
    <tbody><tr>
        <td colspan="2" class="table-border">
            <p><strong>OBOWIĄZEK INFORMACYJNY DLA KLIENTA</strong></p>
        </td>
    </tr>
    <tr>
        <td colspan="2" class="table-border">
            <p><strong>Informujemy Cię, że przetwarzamy Twoje dane osobowe - szczegółowe informacje znajdziesz poniżej:</strong></p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Administrator danych osobowych</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Administratorem Twoich danych osobowych jest:</strong></p>
            <p>Inigra sp. z o. o. z siedzibą w Zawierciu, ul. Technologiczna 15, 42-400 Zawiercie, KRS: 0000798049, NIP 6492316515;</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Dane kontaktowe administratora</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Można się z nami skontaktować</strong>:</p>
            <ol>
                <li>na adres e-mail:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>;</li>
                <li>pisemnie, na adres: Hauzy, ul. Ogrodowa 12, 61-821 Poznań;</li>
                <li>poprzez formularz elektroniczny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Dane kontaktowe inspektora ochrony danych osobowych Hauzy</strong></p>
        </td>
        <td class="table-border">
            <p>Wyznaczyliśmy osobę odpowiedzialną za ochronę danych osobowych, tj.&nbsp;<strong>inspektora ochrony danych osobowych</strong>, z którym można się skontaktować:</p>
            <ol>
                <li>na adres e-mail:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a></li>
                <li>pisemnie, na adres: Hauzy, ul. Ogrodowa 12, 61-821 Poznań.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Zakres przetwarzanych danych osobowych</strong></p>
        </td>
        <td class="table-border">
            <p>Abyś mógł zarejestrować konto w serwisie Hauzy oraz mógł w pełni korzystać z funkcji serwisu będziemy przetwarzać następujące <strong>kategorie Twoich danych osobowych</strong>:</p>
            <ol>
                <li>Imię i nazwisko,</li>
                <li>Adres e-mail,</li>
                <li>Numer telefonu,</li>
                <li>Adres zamieszkania,</li>
                <li>IP urządzenia;</li>
                <li>Data urodzenia,</li>
                <li>Twoje zdjęcie,</li>
                <li>Twoją płeć.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Cele i podstawy prawne przetwarzania danych</strong></p>
        </td>
        <td class="table-border">
            <p>Przetwarzamy Twoje dane osobowe&nbsp;w następujących celach:</p>
            <ol>
                <li><strong>zawarcia i wykonania umowy&nbsp;o korzystanie z serwisu Hauzy</strong>, aby umożliwić Ci korzystanie z usług podmiotów współpracujących z Hauzy („<strong>Usługodawców</strong>”), w szczególności poprzez udostępnienie Twoich danych osobowych Usługodawcy, gdy złożysz żądanie rezerwacji usług Usługodawcy za pomocą aplikacji Hauzy – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji umowy (art. 6 ust. 1 lit. b RODO);</li>
                <li><strong>marketingu produktów lub usług Hauzy (z wyłączeniem marketingu bezpośredniego)</strong>&nbsp;– przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest możliwość prowadzenia marketingu własnych produktów i usług;</li>
                <li><strong>marketingu bezpośredniego produktów lub usług Hauzy</strong>&nbsp;– takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), jakim jest możliwość prowadzenia marketingu własnych produktów i usług, polegającego na bezpośredniej komunikacji z Tobą w celu reklamy i promocji usług Hauzy;</li>
                <li><strong>profilowania/grupowania</strong>&nbsp;(tj. prowadzenia analiz, które mogą dotyczyć również danych osobowych, oraz grupowania klientów – w celu dostosowania naszych produktów lub usług oraz ich marketingu do Twoich preferencji, a także do preferencji zidentyfikowanych przez nas określonych grup klientów) – przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji naszego prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO);</li>
                <li><strong>badania preferencji w zakresie zapotrzebowania na usługi Hauzy lub Usługodawców</strong> (m.in. w postaci ankiet telefonicznych, sms-owych lub e-mailowych)&nbsp;- co jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest możliwość ustalenia katalogu produktów i usług interesujących klientów oraz poziomu ich zadowolenia z usług Hauzy lub usług Usługodawców;</li>
                <li><strong>ustalenia, dochodzenia lub obrony przed roszczeniami, związanymi z zawartą umową lub z przetwarzaniem Twoich danych osobowych</strong> - przetwarzanie Twoich danych osobowych w tym zakresie jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust.1 lit. f RODO), jakim jest możliwość ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami;</li>
                <li><strong>wypełniania ciążących na Hauzy obowiązków prawnych, wynikających z właściwych przepisów prawa</strong> – przetwarzanie Twoich danych osobowych w tym przypadku jest niezbędne do wypełnienia wymogów prawnych, którym podlega Hauzy (art. 6 ust. 1 lit. c RODO);</li>
                <li><strong>tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby Hauzy</strong>&nbsp;(w tym raportowanie, planowanie rozwoju usług, prace rozwojowe w systemie Hauzy, tworzenie modeli statystycznych) - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest analiza i rozwój działalności;</li>
                <li><strong>wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji Hauzy</strong>&nbsp;(rozwój funkcjonalności aplikacji, testowanie wprowadzanych nowych rozwiązań, analiza rodzaju koniecznych do wprowadzenia nowych funkcjonalności, tworzenie narzędzi/raportów analitycznych itp.) – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest rozwijanie i zapewnianie bezpieczeństwa działania systemów/aplikacji Hauzy;</li>
                <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), jakim jest możliwość odpowiedzi na Twoją wiadomość i komunikacji z Tobą.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Przetwarzanie danych przez Usługodawców</strong></p>
        </td>
        <td class="table-border">
            <p>Jeśli zdecydujesz się na skorzystanie z usług podmiotów współpracujących z Hauzy (Usługodawców) i za pomocą serwisu Hauzy&nbsp;zarezerwujesz wizytę/usługę u Usługodawcy, Hauzy – wykonując zawartą z Tobą umowę o korzystanie z serwisu Hauzy – udostępni danemu Usługodawcy Twoje dane w zakresie niezbędnym do tego, by mógł on wykonać wybraną przez Ciebie usługę. Usługodawca będzie wtedy przetwarzać Twoje dane jako osobny administrator w celu wykonania zawartej przez niego z Tobą umowy o świadczenie wybranej przez Ciebie usługi i samodzielnie będzie ponosić odpowiedzialność z tym związaną.</p>
            <p>Będziesz mógł też odrębnie udzielić Usługodawcy zgód na przetwarzanie przez niego Twoich danych w innych celach. Usługodawca, realizując własne cele (np. marketing), będzie mógł też korzystać z narzędzi udostępnianych mu przez Hauzy. Wtedy Hauzy w imieniu takiego Usługodawcy będzie kierować do Ciebie marketing, rekomendacje lub informacje o promocjach.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Profilowanie/grupowanie</strong></p>
        </td>
        <td class="table-border">
            <p>Informujemy, że dokonujemy&nbsp;profilowania/grupowania klientów, aby w sposób możliwie odpowiadający ich i Twoim preferencjom świadczyć nasze usługi oraz kierować reklamy, przypomnienia, rekomendacje i promocje. Takie profilowanie/grupowanie może być dokonywane na podstawie następujących danych:</p>
            <ol>
                <li>aktywności na stronie oraz w aplikacji Hauzy;</li>
                <li>geolokalizacji;</li>
                <li>płci;</li>
                <li>godzin korzystania z usług Hauzy;</li>
                <li>czasu ostatniej aktywności na stronie lub w aplikacji Hauzy;</li>
                <li>analizy zdjęć w serwisie Hauzy, które Ci się podobały.</li>
            </ol>
            <p>Wyjaśniamy przy tym, że Hauzy nie stosuje inwazyjnych praktyk profilowania i śledzenia w celach marketingowych lub reklamowych.</p>
            <p>Możesz zgłosić sprzeciw wobec takiego przetwarzania Twoich danych osobowych, wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>,lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Kategorie odbiorców danych</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Odbiorcami Twoich danych osobowych mogą być:</strong></p>
            <ol>
                <li>Usługodawcy;</li>
                <li>spółki z grupy kapitałowej Hauzy;</li>
                <li>podmioty upoważnione na podstawie przepisów prawa (sądy, organy państwowe);</li>
                <li>podmioty świadczące usługi księgowe, informatyczne, marketingowe, komunikacyjne i analityczne, prawne i windykacyjne;</li>
                <li>nasi podwykonawcy i inne podmioty, z którymi współpracujemy.</li>
            </ol>
            <p>Potwierdzamy, że wszystkie podmioty, na rzecz których przekazujemy Twoje dane osobowe, zapewniają co najmniej taką samą ochronę Twoich danych osobowych, jaka jest określona w niniejszej polityce prywatności i jaka wymagana jest przez wytyczne Apple Inc.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Przekazywanie danych poza Europejski Obszar Gospodarczy</strong></p>
        </td>
        <td class="table-border">
            <p>Twoje dane&nbsp;nie będą przekazywane do podmiotów mających siedzibę poza&nbsp;Europejskim Obszarem Gospodarczym.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Okres przechowywania danych</strong></p>
        </td>
        <td class="table-border">
            <p>Twoje dane będziemy przetwarzać:</p>
            <ol>
                <li><strong>w celu zawarcia i wykonania umowy</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy, a następnie do momentu przedawnienia roszczeń;</li>
                <li><strong>w celu marketingu produktów lub usług (z wyłączeniem marketingu bezpośredniego)</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu marketingu bezpośredniego produktów lub usług Hauzy</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu profilowania/grupowania</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>badania preferencji w zakresie zapotrzebowania na usługi</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu ustalenia, dochodzenia lub obrony przed roszczeniami</strong>&nbsp;- do momentu przedawnienia tych roszczeń;</li>
                <li><strong>wypełniania obowiązków prawnych</strong>&nbsp;- do momentu wygaśnięcia obowiązków przechowywania danych wynikających z przepisów prawa;</li>
                <li><strong>w celu tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby</strong>&nbsp;– przez czas trwania umowy, a następnie do czasu przedawnienia roszczeń wynikających z umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji Hauzy</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - przez czas komunikacji z Tobą, a następnie do momentu przedawnienia roszczeń.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Twoje prawa</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Zgodnie z RODO, przysługuje Ci:</strong></p>
            <ol>
                <li>prawo&nbsp;dostępu&nbsp;do swoich danych oraz otrzymania ich kopii;</li>
                <li>prawo do&nbsp;sprostowania (poprawiania, uzupełniania)&nbsp;swoich danych;</li>
                <li>prawo do&nbsp;usunięcia danych, ograniczenia przetwarzania&nbsp;danych;</li>
                <li>prawo do&nbsp;przenoszenia danych; w celu skorzystania z powyższych praw skontaktuj się z Hauzy: poprzez e-mail&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>&nbsp;poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a></li>
                <li>prawo do wniesienia&nbsp;skargi do organu nadzorczego (Urzędu Ochrony Danych Osobowych).</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Prawo do wycofania zgody</strong></p>
        </td>
        <td class="table-border">
            <p>Możesz w dowolnym momencie&nbsp;wycofać zgodę&nbsp;na przetwarzanie danych osobowych – dotyczy to tylko przetwarzania danych, które Hauzy będzie przetwarzać na podstawie udzielonej przez Ciebie zgody. W tym celu możesz wysłać do nas e-mail na adres:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, &nbsp;lub skontaktować się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
            <p>Wycofanie zgody nie wpłynie na zgodność z prawem przetwarzania danych przed wycofaniem zgody.</p>
            <p>Jeśli korzystasz z aplikacji Hauzy, w każdej chwili możesz sprawdzić, jakich zgód udzieliłeś oraz możesz edytować je wchodząc w swojej aplikacji Hauzy w zakładkę „Profil” a następnie w zakładkę „Ustawienia Prywatności”.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Prawo do sprzeciwu</strong></p>
        </td>
        <td class="table-border">
            <p>Możesz w każdym czasie&nbsp;wyrazić sprzeciw wobec przetwarzania&nbsp;Twoich danych osobowych przetwarzanych przez Hauzy w celu realizacji prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO), wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, dzwoniąc na numer:&nbsp;<a href="tel:%2B48%20570%20027%20321">+48 570 027 321</a>&nbsp;lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
            <p>Hauzy nie będzie wtedy wolno już przetwarzać Twoich danych osobowych, chyba że Hauzy wykaże istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Twoich interesów, praw i wolności, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.</p>
            <p>W razie zgłoszenia sprzeciwu wobec przetwarzania danych w celu marketingu bezpośredniego – w każdym przypadku Hauzy zaprzestanie przetwarzania Twoich danych osobowych w tym celu.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Prawo do usunięcia danych</strong></p>
        </td>
        <td class="table-border">
            <p>W każdej chwili możesz żądać usunięcia Twoich danych osobowych z serwisu Hauzy. Możesz to zrobić zgłaszając do nas żądanie usunięcia Twoich danych osobowych na adres: <a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>. </p>
            <p>Jeżeli korzystasz z aplikacji Hauzy w systemie iOS możesz usunąć swoje konto wchodząc w swojej aplikacji Hauzy w zakładkę „Profil” a następnie klikając w przycisk „Usuń moje konto”. Usunięcie konta w aplikacji Hauzy w systemie iOS jest jednoznaczne z usunięciem danych osobowych z aplikacji Hauzy.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Informacja o wymogu lub dobrowolności podania danych i konsekwencjach ich niepodania</strong></p>
        </td>
        <td class="table-border">
            <p>Podanie Twoich danych osobowych jest&nbsp;dobrowolne, ale niezbędne do zawarcia i wykonywania umowy, na korzystanie z serwisu Hauzy oraz realizacji pozostałych ww. celów. </p>
            <p>W każdej chwili możesz sprawdzić lub edytować swoje dane osobowe, które nam przekazałeś, wchodząc w swojej aplikacji Hauzy w zakładkę „Profil”.</p>
        </td>
    </tr>
</tbody></table>
<h2><strong>Załącznik nr 2</strong></h2>
<h2><strong>Podstawy prawne i cele przetwarzania przez Hauzy danych osobowych Usługodawców.</strong></h2>
<ol>
    <li>Administratorem danych osobowych Usługodawców, będących osobami fizycznymi, jest Hauzy. Obowiązek informacyjny, o którym mowa w art. 13 RODO Hauzy wypełnia wobec Usługodawców przy Rejestracji Konta. Treść klauzuli informacyjnej dostępna jest w tabeli na końcu załącznika.</li>
    <li>Hauzy i Usługodawca przetwarzają dane osobowe Klientów we własnych celach, zatem są odrębnymi administratorami ich danych osobowych.</li>
    <li>Gdy Klient za pomocą Aplikacji dokonuje Rezerwacji usługi Usługodawcy:<ol>
            <li>dochodzi do zawarcia umowy między Klientem a Usługodawcą o świadczenie przez Usługodawcę Usług na rzecz Klienta,</li>
            <li>Klient może w Aplikacji udzielić Usługodawcy zgód na przetwarzanie przez niego danych osobowych (np. na komunikację elektroniczną) w innych celach niż w celu wykonania umowy z Klientem,</li>
            <li>Hauzy udostępnia Usługodawcy za pomocą Aplikacji dane osobowe Klienta potrzebne do świadczenia przez niego Usług na rzecz tego Klienta.</li>
            <li>Usługodawca staje się administratorem danych osobowych takiego Klienta, w związku z czym zobowiązany jest do przestrzegania zasad określonych w RODO i ponosi za to odpowiedzialność wynikającą z przepisów.</li>
        </ol>
    </li>
    <li>W celu umożliwienia Hauzy świadczenia na rzecz Usługodawcy usług w ramach Aplikacji, do których niezbędne jest przetwarzanie:<ol>
            <li>danych osobowych Klientów, którzy dokonali Rezerwacji usług Usługodawcy,</li>
            <li>danych osobowych osób, którymi Usługodawca posługuje się korzystając z Aplikacji (pracowników, zleceniobiorców itp.),</li>
            <li>konieczne jest powierzenie przez Usługodawcę przetwarzania danych osobowych takich osób, w związku z czym Usługodawca i Hauzy zawierają Umowę powierzenia przetwarzania danych osobowych.</li>
        </ol>
    </li>
    <li>Usługodawca jest świadomy, że rozwiązanie Umowy powierzenia przetwarzania danych osobowych skutkować będzie niemożliwością świadczenia przez Hauzy usług na rzecz Usługodawcy na podstawie Umowy w zakresie wymagającym przetwarzania danych, których administratorem jest Usługodawca, w związku z czym wraz z rozwiązaniem Umowy powierzenia przetwarzania danych osobowych – zgodnie z art. 475 §1 Kodeksu cywilnego – wygasają zobowiązania Hauzy w tym zakresie.</li>
    <li>Gdy Usługodawca będzie korzystać z narzędzi dostępnych w ramach usług Hauzy (m.in. kalendarz wizyt, przypomnienia, marketing,czat) – Hauzy będzie w imieniu Usługodawcy przetwarzać dane Klienta na zasadach określonych w Umowie powierzenia przetwarzania danych osobowych, a Usługodawca nadal będzie administratorem jego danych osobowych.</li>
    <li>Cele w jakich Usługodawca będzie mógł przetwarzać dane Klienta będą uzależnione od tego, czy i jakich zgód Klient udzielił Usługodawcy na przetwarzanie jego danych osobowych, czy tych zgód nie wycofał lub nie zgłosił sprzeciwu wobec przetwarzania przez Usługodawcy jego danych osobowych.</li>
    <li>Usługodawca jest także administratorem danych osobowych Klienta, z którym zawrze umowę poza Aplikacją (np. w salonie). Jeśli wprowadzi te dane do Aplikacji nadal jest ich administratorem, a Hauzy przetwarza je w imieniu Usługodawcy na podstawie Umowy powierzenia przetwarzania danych osobowych.</li>
    <li>Hauzy przetwarza dane, w tym dane osobowe, Usługodawcy jedynie w zakresie umożliwiającym prawidłowe świadczenie usług i prawidłową realizację Umowy. Hauzy nie odpowiada za zakres danych osobowych Klientów zbieranych przez Usługodawców i za legalność zbierania tych danych przez Usługodawców. Szczegółowe zobowiązania stron z tym związane zawiera Umowa powierzenia przetwarzania danych osobowych.</li>
</ol>
<table>
    <tbody><tr>
        <td colspan="2" class="table-border">
            <p><strong>OBOWIĄZEK INFORMACYJNY DLA USŁUGODAWCY</strong></p>
        </td>
    </tr>
    <tr>
        <td colspan="2" class="table-border">
            <p><strong>Informujemy Cię, że przetwarzamy Twoje dane osobowe - szczegółowe informacje znajdziesz poniżej:</strong></p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Administrator danych osobowych</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Administratorem Twoich danych osobowych jest:</strong></p>
            <p>Inigra sp. z o. o. z siedzibą w Zawierciu, ul. Technologiczna 15, 42-400 Zawiercie, KRS: 0000798049, NIP 6492316515;</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Dane kontaktowe administratora</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Można się z nami skontaktować:</strong></p>
            <ol>
                <li>na adres e-mail:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>;</li>
                <li>pisemnie, na adres: Hauzy, ul. Ogrodowa 12, 61-821 Poznań;</li>
                <li>poprzez formularz elektroniczny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Dane kontaktowe inspektora ochrony danych osobowych Hauzy</strong></p>
        </td>
        <td class="table-border">
            <p>Wyznaczyliśmy osobę odpowiedzialną za ochronę danych osobowych, tj.&nbsp;inspektora ochrony danych osobowych, z którym można się skontaktować:</p>
            <ol>
                <li>na adres e-mail:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a></li>
                <li>pisemnie, na adres: Hauzy, ul. Ogrodowa 12, 61-821 Poznań.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Zakres przetwarzanych danych osobowych</strong></p>
        </td>
        <td class="table-border">
            <p>Abyś mógł zarejestrować konto w serwisie Hauzy oraz mógł w pełni korzystać z funkcji serwisu będziemy przetwarzać następujące kategorie Twoich danych osobowych:</p>
            <ol>
                <li>Imię i nazwisko,</li>
                <li>Adres e-mail,</li>
                <li>Numer telefonu,</li>
                <li>Adres zamieszkania/Adres prowadzenia biznesu,</li>
                <li>IP urządzenia;</li>
                <li>Data urodzenia</li>
                <li>Logo,</li>
                <li>Zdjęcie profilowe.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Cele i podstawy prawne przetwarzania danych</strong></p>
        </td>
        <td class="table-border">
            <p>Przetwarzamy Twoje dane osobowe&nbsp;w następujących celach:</p>
            <ol>
                <li><strong>zawarcia i wykonania umowy&nbsp;o korzystanie z serwisu Hauzy</strong>– takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji umowy (art. 6 ust. 1 lit. b RODO);</li>
                <li><strong>marketingu produktów lub usług Hauzy (z wyłączeniem marketingu bezpośredniego)</strong>&nbsp;– przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest możliwość prowadzenia marketingu własnych produktów i usług;</li>
                <li><strong>marketingu bezpośredniego produktów lub usług Hauzy&nbsp;</strong>– takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), jakim jest możliwość prowadzenia marketingu własnych produktów i usług, polegającego na bezpośredniej komunikacji z Tobą w celu reklamy i promocji usług Hauzy;</li>
                <li><strong>profilowania/grupowania</strong>&nbsp;(tj. prowadzenia analiz, które mogą dotyczyć również danych osobowych, oraz grupowania klientów – w celu dostosowania naszych produktów lub usług oraz ich marketingu do Twoich preferencji, a także do preferencji zidentyfikowanych przez nas określonych grup klientów) – przetwarzanie Twoich danych osobowych w tym celu odbywać się będzie w realizacji naszego prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO);</li>
                <li><strong>badania preferencji w zakresie zapotrzebowania na usługi Hauzy lub Usługodawców</strong> (m.in. w postaci ankiet telefonicznych, sms-owych lub e-mailowych)&nbsp;- co jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest możliwość ustalenia katalogu produktów i usług interesujących klientów oraz poziomu ich zadowolenia z usług Hauzy lub usług Usługodawców;</li>
                <li><strong>ustalenia, dochodzenia lub obrony przed roszczeniami, związanymi z zawartą umową lub z przetwarzaniem Twoich danych osobowych</strong> - przetwarzanie Twoich danych osobowych w tym zakresie jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust.1 lit. f RODO), jakim jest możliwość ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami;</li>
                <li><strong>wypełniania ciążących na Hauzy obowiązków prawnych, wynikających z właściwych przepisów prawa</strong> – przetwarzanie Twoich danych osobowych w tym przypadku jest niezbędne do wypełnienia wymogów prawnych, którym podlega Hauzy (art. 6 ust. 1 lit. c RODO);</li>
                <li><strong>tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby Hauzy</strong>&nbsp;(w tym raportowanie, planowanie rozwoju usług, prace rozwojowe w systemie Hauzy, tworzenie modeli statystycznych) - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest analiza i rozwój działalności;</li>
                <li><strong>wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji Hauzy</strong>&nbsp;(rozwój funkcjonalności aplikacji, testowanie wprowadzanych nowych rozwiązań, analiza rodzaju koniecznych do wprowadzenia nowych funkcjonalności, tworzenie narzędzi/raportów analitycznych itp.) – takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), którym jest rozwijanie i zapewnianie bezpieczeństwa działania systemów/aplikacji Hauzy;</li>
                <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - takie przetwarzanie Twoich danych osobowych jest niezbędne do realizacji prawnie uzasadnionego interesu Hauzy (art. 6 ust. 1 lit. f RODO), jakim jest możliwość odpowiedzi na Twoją wiadomość i komunikacji z Tobą.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Profilowanie/grupowanie</strong></p>
        </td>
        <td class="table-border">
            <p>Informujemy, że dokonujemy&nbsp;profilowania/grupowania klientów, aby w sposób możliwie odpowiadający ich i Twoim preferencjom świadczyć nasze usługi oraz kierować reklamy, przypomnienia, rekomendacje i promocje. Takie profilowanie/grupowanie może być dokonywane na podstawie następujących danych:</p>
            <ol>
                <li>aktywności na stronie oraz w aplikacji Hauzy;</li>
                <li>geolokalizacji;</li>
                <li>płci;</li>
                <li>godzin korzystania z usług Hauzy;</li>
                <li>czasu ostatniej aktywności na stronie lub w aplikacji Hauzy;</li>
                <li>analizy zdjęć w serwisie Hauzy, które Ci się podobały.</li>
            </ol>
            <p>Wyjaśniamy przy tym, że Hauzy nie stosuje inwazyjnych praktyk profilowania i śledzenia w celach marketingowych lub reklamowych.</p>
            <p>Możesz zgłosić sprzeciw wobec takiego przetwarzania Twoich danych osobowych, wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, &nbsp;lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Kategorie odbiorców danych</strong></p>
        </td>
        <td class="table-border">
            <p>Odbiorcami Twoich danych osobowych mogą być:</p>
            <ol>
                <li>spółki z grupy kapitałowej Hauzy;</li>
                <li>podmioty upoważnione na podstawie przepisów prawa (sądy, organy państwowe);</li>
                <li>podmioty świadczące usługi księgowe, informatyczne, marketingowe, komunikacyjne i analityczne, prawne i windykacyjne;</li>
                <li>nasi podwykonawcy i inne podmioty, z którymi współpracujemy.</li>
            </ol>
            <p>Potwierdzamy, że wszystkie podmioty, na rzecz których przekazujemy Twoje dane osobowe, zapewniają co najmniej taką samą ochronę Twoich danych osobowych, jaka jest określona w niniejszej polityce prywatności i jaka wymagana jest przez wytyczne Apple Inc.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Przekazywanie danych poza Europejski Obszar Gospodarczy</strong></p>
        </td>
        <td class="table-border">
            <p>Twoje dane&nbsp;nie będą przekazywane do podmiotów mających siedzibę poza&nbsp;Europejskim Obszarem Gospodarczym.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Okres przechowywania danych</strong></p>
        </td>
        <td class="table-border">
            <p>Twoje dane będziemy przetwarzać:</p>
            <ol>
                <li><strong>w celu zawarcia i wykonania umowy&nbsp;-</strong> do momentu rozwiązania lub wygaśnięcia umowy, a następnie do momentu przedawnienia roszczeń;</li>
                <li><strong>w celu marketingu produktów lub usług</strong> (z wyłączeniem marketingu bezpośredniego)&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu marketingu bezpośredniego produktów lub usług Hauzy&nbsp;-</strong> do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu profilowania/grupowania</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>badania preferencji w zakresie zapotrzebowania na usługi</strong>&nbsp;- do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu ustalenia, dochodzenia lub obrony przed roszczeniami</strong>&nbsp;- do momentu przedawnienia tych roszczeń;</li>
                <li><strong>wypełniania obowiązków prawnych</strong>&nbsp;- do momentu wygaśnięcia obowiązków przechowywania danych wynikających z przepisów prawa;</li>
                <li><strong>w celu tworzenia zestawień, analiz, statystyk na wewnętrzne potrzeby</strong>&nbsp;– przez czas trwania umowy, a następnie do czasu przedawnienia roszczeń wynikających z umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu wdrażania nowych lub rozwijania rozwiązań/funkcjonalności systemów/aplikacji Hauzy</strong>&nbsp;– do momentu rozwiązania lub wygaśnięcia umowy albo do momentu zgłoszenia sprzeciwu wobec przetwarzania danych w tym celu;</li>
                <li><strong>w celu odpowiedzi na Twoją wiadomość i komunikacji z Tobą, gdy wyślesz do nas wiadomość</strong> - przez czas komunikacji z Tobą, a następnie do momentu przedawnienia roszczeń.</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Twoje prawa</strong></p>
        </td>
        <td class="table-border">
            <p><strong>Zgodnie z RODO, przysługuje Ci:</strong></p>
            <ol>
                <li>prawo&nbsp;dostępu&nbsp;do swoich danych oraz otrzymania ich kopii;</li>
                <li>prawo do&nbsp;sprostowania (poprawiania, uzupełniania)&nbsp;swoich danych;</li>
                <li>prawo do&nbsp;usunięcia danych, ograniczenia przetwarzania&nbsp;danych;</li>
                <li>prawo do&nbsp;przenoszenia danych; w celu skorzystania z powyższych praw skontaktuj się z Hauzy: poprzez e-mail&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>&nbsp;poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a></li>
                <li>prawo do wniesienia&nbsp;skargi do organu nadzorczego (Urzędu Ochrony Danych Osobowych).</li>
            </ol>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Prawo do wycofania zgody</strong></p>
        </td>
        <td class="table-border">
            <p>Możesz w dowolnym momencie&nbsp;wycofać zgodę&nbsp;na przetwarzanie danych osobowych – dotyczy to tylko przetwarzania danych, które Hauzy będzie przetwarzać na podstawie udzielonej przez Ciebie zgody. W tym celu możesz wysłać do nas e-mail na adres:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, &nbsp;lub skontaktować się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
            <p>Wycofanie zgody nie wpłynie na zgodność z prawem przetwarzania danych przed wycofaniem zgody. </p>
            <p>W każdej chwili możesz sprawdzić, jakich zgód udzieliłeś oraz możesz edytować je wchodząc w swojej aplikacji Hauzy w zakładkę „Profil”, a następnie w zakładkę „Ustawienia”, a następnie w zakładkę „Aplikacja Hauzy”, a następnie w zakładkę „Ochrona Twoich danych”.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Prawo do sprzeciwu</strong></p>
        </td>
        <td class="table-border">
            <p>Możesz w każdym czasie&nbsp;wyrazić sprzeciw wobec przetwarzania&nbsp;Twoich danych osobowych przetwarzanych przez Hauzy w celu realizacji prawnie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO), wysyłając do nas e-mail na adres:&nbsp;<a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>, lub kontaktując się poprzez formularz elektroniczny dostępny na stronie:&nbsp;<a href="https://hauzy.app/pl/#contact">https://hauzy.app/pl/#contact</a>.</p>
            <p>Hauzy nie będzie wtedy wolno już przetwarzać Twoich danych osobowych, chyba że Hauzy wykaże istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Twoich interesów, praw i wolności, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.</p>
            <p>W razie zgłoszenia sprzeciwu wobec przetwarzania danych w celu marketingu bezpośredniego – w każdym przypadku Hauzy zaprzestanie przetwarzania Twoich danych osobowych w tym celu.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Prawo do usunięcia danych</strong></p>
        </td>
        <td class="table-border">
            <p>W każdej chwili możesz żądać usunięcia Twoich danych osobowych z serwisu Hauzy. Możesz to zrobić zgłaszając do nas żądanie usunięcia Twoich danych osobowych na adres: <a href="mailto:rodo@hauzy.app">rodo@hauzy.app</a>.</p>
            <p>Jeżeli korzystasz z aplikacji Hauzy w systemie iOS możesz usunąć swoje konto wchodząc w swojej aplikacji Hauzy w zakładkę „Profil” a następnie w zakładkę „Ustawienia”, a następnie w zakładkę „Aplikacja Hauzy” a następnie w zakładkę „Usuń konto”. Usunięcie konta w aplikacji Hauzy w systemie iOS jest jednoznaczne z usunięciem danych osobowych z aplikacji Hauzy.</p>
        </td>
    </tr>
    <tr>
        <td class="table-border">
            <p><strong>Informacja o wymogu lub dobrowolności podania danych i konsekwencjach ich niepodania</strong></p>
        </td>
        <td class="table-border">
            <p>Podanie Twoich danych osobowych jest&nbsp;dobrowolne, ale niezbędne do zawarcia i wykonywania umowy, na korzystanie z serwisu Hauzy oraz realizacji pozostałych ww. celów. </p>
            <p>W każdej chwili możesz sprawdzić lub edytować swoje dane osobowe wchodząc w swojej aplikacji Hauzy w zakładkę „Profil”, a następnie w zakładkę „Ustawienia"..</p>
        </td>
    </tr>
</tbody></table>

<p><small class="lastUpdate">Ostatnia aktualizacja: 15 marca 2023</small></p>
    
</div>

  }

      </div>
    </div>
    <Footer />
          <div id="fb-root"></div>
        <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat">
        </div>
     </div>   
     </>
  );
};

export default Index;
