import React from "react";
import RecommendedInfo from "./RecommendedInfo";
import RecommendedCalender from "./RecommendedCalender";
const RecommendedCard = ({ userBusiness, hideService }) => {
  console.log("userBusiness ", userBusiness);
  return (
    <div className="recommended__card">
      <RecommendedInfo userBusiness={userBusiness} hideService={hideService} />
      <RecommendedCalender userBusiness={userBusiness} />
    </div>
  );
};

export default RecommendedCard;
