import React, { useEffect, useState } from "react";
import Icon from "../../../../components/Icon";
import { useLocalization } from "../../../../helpers/localization";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { labels } from "../../../../utils/locale";
import SelectField from "../../../../components/SelectField";
import CustomCalender from "../../../../components/CustomCalender";

import SimpleBookingPreviewModal from "../../../../components/booking/SimpleBookingPreviewModal";
import {
  addMinutuesinDate,
  currentLocalDate,
  currentUtcDate,
  formatDate,
  getDistanceFromLatLonInKm,
  minutuestoHoursString,
  shuffleMonthDayDateDash,
  subtractDurationinDateInUtc,
} from "../../../../utils/function";
import { submitBooking, getBooking } from "../../../../services/booking_service";
import { getLatitudeLongitude } from "../../../../services/map_service";


import {
  checkProfileisCompleted,
  getCurrentLoginUserId,
  getUserData,
  signUpWhileBooking
} from "../../../../services/auth_service";
import { errorAlert, successAlert } from "../../../../utils/alert";
import Loader from "../../../../components/Loader";
import SlotsList from "./SlotsList";

import { getBussinessSlotinRange } from "../../../../services/bussiness_service";

const BookingModel = ({ setShowModal, userBusiness, initialDate }) => {




  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [apartmentNo, setApartmentNo] = useState("");  
    const [openBooking, setOpenBooking] = useState(null);
  const [postalCode, setPostalCode] = useState("");    
  const [city, setCity] = useState("");  

  const [code, setCode] = useState("+48");

  const [isPosting, setisPosting] = useState(false);
  const { locale = "en" } = useParams();
  const translate = useLocalization();
  const [selectedDate, onDateChange] = useState(
    initialDate === undefined || initialDate === null
      ? formatDate(new Date(), "YYYY-MM-DD")
      : formatDate(new Date(initialDate), "YYYY-MM-DD")
  );
  // const currentUser = getUserData();

  const [selectedSlot, setselectedSlot] = useState(
    initialDate === undefined || initialDate === null
      ? null
      : initialDate.split("T")[1]
  );
  const [selectedService, setSelectedService] = useState(
    JSON.stringify(userBusiness.services[0])
  );
  const [userslots, setuserslots] = useState([]);
  const [slotsLoading, setslotsLoading] = useState(false);
  const remindList = [
    { value: 30, name: translate(labels.halfHoursebeforebookingStart) },
    { value: 60, name: translate(labels.oneHoursebeforebookingStart) },
    { value: 120, name: translate(labels.twoHoursebeforebookingStart) },
    { value: 240, name: translate(labels.fourHoursebeforebookingStart) },
    { value: 480, name: translate(labels.eightHoursebeforebookingStart) },
  ];
  const [selectedReminder, setSelectedReminder] = useState(remindList[0].value);

  const [monthlySlots, setMonthlySlots] = useState([]);
  const [eventsList, seteventsList] = useState([]);

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const endDateUtc = new Date();
  endDateUtc.setDate(endDateUtc.getDate() + 30);

  const filterSelectedSlots = (slots, date) => {
    return slots.find((d) => d["date"] === formatDate(date, "YYYY-MM-DD"));
  };

  useEffect(() => {
    (async () => {
      if (userBusiness !== undefined && userBusiness !== null) {
        try {
          setslotsLoading(true);

          const data = await getBussinessSlotinRange(
            userBusiness?.uid,
            true,
            30
          );
          const todaysSlots = filterSelectedSlots(data, selectedDate);

          if (todaysSlots?.slots !== undefined && todaysSlots?.slots !== null) {
            setuserslots(todaysSlots.slots);
          }
          setMonthlySlots(data);
          const eList = [];
          data.forEach((e) => {
            if (e.slots.length > 0) {
              eList.push(shuffleMonthDayDateDash(e.date));
            }
          });
          seteventsList(eList);


        } catch (error) {
          errorAlert("Error in fetching slots");
        } finally {
          setslotsLoading(false);
        }
      }
    })();
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBusiness]);

  if (userBusiness === undefined || userBusiness === null) {
    return <></>;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const latLong = await getLatitudeLongitude(
      street, 
      houseNo, 
      postalCode, 
      city, 
      "Polska"
    );


    const { location, booking_settings } = userBusiness;
    const kmRange =
      booking_settings.areaWhereServiceProvided === 0
        ? 50
        : booking_settings.areaWhereServiceProvided;
    var bussinesslocation = JSON.parse(location);

    const businesslat = Number.parseFloat(bussinesslocation["latitude"]);
    const businesslng = Number.parseFloat(bussinesslocation["longitude"]);
    const currentUserLongitude = Number.parseFloat(latLong["lng"]);
    const currentUserLatitude = Number.parseFloat(latLong["lat"]);

    console.log("lat", Number.parseFloat(latLong["lat"]));
    console.log("lng", Number.parseFloat(latLong["lng"]));

    console.log("lat", Number.parseFloat(bussinesslocation["latitude"]));
    console.log("lng", Number.parseFloat(bussinesslocation["longitude"]));


    const distance = getDistanceFromLatLonInKm(
      currentUserLatitude,
      currentUserLongitude,
      businesslat,
      businesslng
    );
    console.log("Distance", distance);
    var userIDHauzy;
    if (distance <= kmRange) {
      userIDHauzy = await signUpWhileBooking(email, phone, firstName, lastName, currentUserLatitude, currentUserLongitude, street, houseNo, apartmentNo, postalCode, city, "Polska");
      console.log("User ID: " + userIDHauzy);
     var address;
     var addressNoLongLat;
    if (apartmentNo == "")
    {
      addressNoLongLat = street + " " + houseNo + ", " + postalCode + " " + city + ", " + city;
      address = {"latitude":currentUserLatitude,"longitude":currentUserLongitude,"address": addressNoLongLat};
    }
    else
    {
      addressNoLongLat = street + " " + houseNo + "/" + apartmentNo + ", " + postalCode + " " + city + ", " + "Polska";
      address = {"latitude":currentUserLatitude,"longitude":currentUserLongitude,"address": addressNoLongLat};
    }
      const selectedOnlyDate = formatDate(selectedDate, "YYYY-MM-DD");
      const startdate = selectedOnlyDate + "T" + selectedSlot + ":00";

      const selectedServiceParse = JSON.parse(selectedService);

      const endDate = addMinutuesinDate(startdate, selectedServiceParse.time);

      const startViewDate = formatDate(startdate, "DD/MM/YYYY HH:mm");
      const endViewDate = formatDate(endDate, "DD/MM/YYYY HH:mm");

      const startDateUtc = formatDate(startdate, "YYYY-MM-DDTHH:mm:ss", true);
      const endDateUtc = formatDate(endDate, "YYYY-MM-DDTHH:mm:ss", true);

      const bussinessUserReminder = booking_settings?.reminder ?? 30;

      const bussinessUserAutoConfirmationHour =
        booking_settings?.bookingConfirmMode ?? 0;
      const bussinessUserisAutoConfirmation =
        booking_settings?.autoConfirmed ?? false;

      let bussinessAutConfirmTime = null;

      if (bussinessUserisAutoConfirmation === true) {
        if (bussinessUserAutoConfirmationHour === 0) {
          bussinessAutConfirmTime = currentUtcDate();
        } else {
          bussinessAutConfirmTime = subtractDurationinDateInUtc(
            "hours",
            startdate,
            bussinessUserAutoConfirmationHour
          );
        }
      }

      const data = {
        booking: {
          bookingId: "",
          startDate: startdate,
          endDate: endDate,
          service: selectedService,
          status: "Pending",
          bussinessUserId: userBusiness.uid,
          totalPrice: Number.parseFloat(selectedServiceParse.price),
          time: selectedServiceParse.time,
          basePrice: Number.parseFloat(selectedServiceParse.price),
          userAddress: addressNoLongLat,
          userId: userIDHauzy,
          date: selectedOnlyDate,
          notificationId: "",
          type: 0,
          currentTime: currentLocalDate(),
          userReminder: Number.parseInt(selectedReminder ?? 30),
          bussinessUserReminder: bussinessUserReminder,
          reminderMessage: `We are reminding you for the booking which will start at ${startViewDate} and end at ${endViewDate}.`,
          bussinessUserAutoConfirmationHour: bussinessUserAutoConfirmationHour,
          bussinessUserisAutoConfirmation: bussinessUserisAutoConfirmation,
          startDateUtc: startDateUtc,
          endDateUtc: endDateUtc,
          bussinessAutoConfirmTime: bussinessAutConfirmTime,
        },
        notification: {
          id: "",
          content: `${firstName} ${lastName} post the booking which will start at ${startViewDate} and end at ${endViewDate}.`,
          time: "",
          idTo: userBusiness.uid,
          idFrom: getCurrentLoginUserId(),
          receiverToken: userBusiness.pushToken,
          senderName: firstName + " " + lastName,
          status: "Pending",
          senderPic: null,
          bookingId: "",
          userReminder: Number.parseInt(selectedReminder ?? 30),
          bussinessUserReminder: bussinessUserReminder,
          reminderMessage: `We are reminding you for the booking which will start at ${startViewDate} and end at ${endViewDate}.`,
          bussinessUserAutoConfirmationHour: bussinessUserAutoConfirmationHour,
          bussinessUserisAutoConfirmation: bussinessUserisAutoConfirmation,
          bussinessAutoConfirmTime: bussinessAutConfirmTime,
        },
      };

      try {
        setisPosting(true);
        const bookingResult = await submitBooking(data);
       // console.log("bookingResult");
        //console.log(bookingResult);
        //console.log(bookingResult.data.booking_id);


        if (bussinessUserisAutoConfirmation)
        {
            const clientName = firstName + lastName;
            fetch(`https://us-central1-bluegallery-c2877.cloudfunctions.net/sendHauzyEmail?code=hzb#2023&type=hzb_new_booking&email=${userBusiness.email}&username=${userBusiness.firstName}&client=${clientName}&date=${startViewDate}&service=${selectedService}&address=${addressNoLongLat}`)
            .then(response => response.json())
            .then(data => console.log("Triggered"))
            .catch(error => console.error(error));

            const service_provider = userBusiness.firstName + userBusiness.lastName;
            const bookingLink = "https://hauzy.app/" + locale + "/booking-details/" + bookingResult.data.booking_id;
            fetch(`https://us-central1-bluegallery-c2877.cloudfunctions.net/sendHauzyEmail?code=hzb#2023&type=hz_booking_confirmed&email=${email}&username=${firstName}&service_provider=${service_provider}&date=${startViewDate}&service=${selectedService}&address=${addressNoLongLat}&booking_link=${bookingLink}`)
            .then(response => response.json())
            .then(data => console.log("Triggered"))
            .catch(error => console.error(error));            
        }
        else
        {
            const clientName = firstName + lastName;
            fetch(`https://us-central1-bluegallery-c2877.cloudfunctions.net/sendHauzyEmail?code=hzb#2023&type=hzb_new_booking&email=${userBusiness.email}&username=${userBusiness.firstName}&client=${clientName}&date=${startViewDate}&service=${selectedService}&address=${addressNoLongLat}`)
            .then(response => response.json())
            .then(data => console.log("Triggered"))
            .catch(error => console.error(error));

            const service_provider = userBusiness.firstName + userBusiness.lastName;
            const bookingLink = "https://hauzy.app/" + locale + "/booking-details/" + bookingResult.data.booking_id;
            fetch(`https://us-central1-bluegallery-c2877.cloudfunctions.net/sendHauzyEmail?code=hzb#2023&type=hz_booking_tobe_confirmed&email=${email}&username=${firstName}&service_provider=${service_provider}&date=${startViewDate}&service=${selectedService}&address=${addressNoLongLat}&booking_link=${bookingLink}`)
            .then(response => response.json())
            .then(data => console.log("Triggered"))
            .catch(error => console.error(error));   
        }

        const booking = await getBooking(bookingResult.data.booking_id);

        setOpenBooking(booking);
        setShowModal(false);
        successAlert(translate(labels.bookingPostedFor) + " " + startViewDate);



        
      } catch (e) {
        errorAlert(e.message);
      }
      setisPosting(false);      
    }
    else {

      errorAlert("Jesteś zbyt daleko od Wykonawcy.");
    }

/*
    const currentUser = getUserData();
    if (selectedSlot === null) {
      errorAlert("Please select slot");
      return;
    }
    console.log("currentUser", currentUser);
    if (currentUser === null) {
      await checkProfileisCompleted(getCurrentLoginUserId());
      onSubmit(e);
      return;
    }

    if (
      currentUser.address === undefined ||
      currentUser.address === null ||
      currentUser.address.length === 0
    ) {
      errorAlert("address required");
      return;
    }
    const { location, booking_settings } = userBusiness;
    const kmRange =
      booking_settings.areaWhereServiceProvided === 0
        ? 50
        : booking_settings.areaWhereServiceProvided;
    var bussinesslocation = JSON.parse(location);

    const businesslat = Number.parseFloat(bussinesslocation["latitude"]);
    const businesslng = Number.parseFloat(bussinesslocation["longitude"]);
    const currentUserLongitude = JSON.parse(currentUser.address[0]).longitude;
    const currentUserLatitude = JSON.parse(currentUser.address[0]).latitude;
    const distance = getDistanceFromLatLonInKm(
      currentUserLatitude,
      currentUserLongitude,
      businesslat,
      businesslng
    );
    console.log(booking_settings, kmRange, distance);
    // successAlert(distance);

    if (distance <= kmRange) {
      const address = JSON.parse(currentUser.address[0]).address;
      const selectedOnlyDate = formatDate(selectedDate, "YYYY-MM-DD");
      const startdate = selectedOnlyDate + "T" + selectedSlot + ":00";

      const selectedServiceParse = JSON.parse(selectedService);

      const endDate = addMinutuesinDate(startdate, selectedServiceParse.time);

      const startViewDate = formatDate(startdate, "DD/MM/YYYY HH:mm");
      const endViewDate = formatDate(endDate, "DD/MM/YYYY HH:mm");

      const startDateUtc = formatDate(startdate, "YYYY-MM-DDTHH:mm:ss", true);
      const endDateUtc = formatDate(endDate, "YYYY-MM-DDTHH:mm:ss", true);

      const bussinessUserReminder = booking_settings?.reminder ?? 30;

      const bussinessUserAutoConfirmationHour =
        booking_settings?.bookingConfirmMode ?? 0;
      const bussinessUserisAutoConfirmation =
        booking_settings?.autoConfirmed ?? false;

      let bussinessAutConfirmTime = null;

      if (bussinessUserisAutoConfirmation === true) {
        if (bussinessUserAutoConfirmationHour === 0) {
          bussinessAutConfirmTime = currentUtcDate();
        } else {
          bussinessAutConfirmTime = subtractDurationinDateInUtc(
            "hours",
            startdate,
            bussinessUserAutoConfirmationHour
          );
        }
      }

      const data = {
        booking: {
          bookingId: "",
          startDate: startdate,
          endDate: endDate,
          service: selectedService,
          status: "Pending",
          bussinessUserId: userBusiness.uid,
          totalPrice: Number.parseFloat(selectedServiceParse.price),
          time: selectedServiceParse.time,
          basePrice: Number.parseFloat(selectedServiceParse.price),
          userAddress: address,
          userId: getCurrentLoginUserId(),
          date: selectedOnlyDate,
          notificationId: "",
          type: 0,
          currentTime: currentLocalDate(),
          userReminder: Number.parseInt(selectedReminder ?? 30),
          bussinessUserReminder: bussinessUserReminder,
          reminderMessage: `We are reminding you for the booking which will start at ${startViewDate} and end at ${endViewDate}.`,
          bussinessUserAutoConfirmationHour: bussinessUserAutoConfirmationHour,
          bussinessUserisAutoConfirmation: bussinessUserisAutoConfirmation,
          startDateUtc: startDateUtc,
          endDateUtc: endDateUtc,
          bussinessAutoConfirmTime: bussinessAutConfirmTime,
        },
        notification: {
          id: "",
          content: `${currentUser.firstName} ${currentUser.lastName} post the booking which will start at ${startViewDate} and end at ${endViewDate}.`,
          time: "",
          idTo: userBusiness.uid,
          idFrom: getCurrentLoginUserId(),
          receiverToken: userBusiness.pushToken,
          senderName: currentUser.firstName + " " + currentUser.lastName,
          status: "Pending",
          senderPic: currentUser.profilePic,
          bookingId: "",
          userReminder: Number.parseInt(selectedReminder ?? 30),
          bussinessUserReminder: bussinessUserReminder,
          reminderMessage: `We are reminding you for the booking which will start at ${startViewDate} and end at ${endViewDate}.`,
          bussinessUserAutoConfirmationHour: bussinessUserAutoConfirmationHour,
          bussinessUserisAutoConfirmation: bussinessUserisAutoConfirmation,
          bussinessAutoConfirmTime: bussinessAutConfirmTime,
        },
      };

      try {
        setisPosting(true);
        await submitBooking(data);
        setShowModal(false);
        successAlert(translate(labels.bookingPostedFor) + " " + startViewDate);
      } catch (e) {
        errorAlert(e.message);
      }
      setisPosting(false);
    } else {
      errorAlert("You cannot make booking.");
    }
    */
  };

  return (
    <div className="calendar_modal">
      <div className="calendar_modal-content">
        <span
          className="calendar_modal-close-icon"
          onClick={() => setShowModal(false)}
        >
          <Icon name="cancel" />
        </span>
        <h2 className="book-visit">{translate(labels.bookYourVisit)}</h2>
<form onSubmit={onSubmit} className="section form-items">
        <div className="calendar_modal__wrapper">
          <CustomCalender
            isLoading={slotsLoading}
            calenderStartDate={new Date()}
            eventList={eventsList}
            onMonthChange={async (e) => {
              try {
                setslotsLoading(true);
                console.log("start1", e.firstDate);

                const data = await getBussinessSlotinRange(
                  userBusiness?.uid,
                  true,
                  30,
                  e.firstDate,
                  e.lastDate
                );

                setMonthlySlots(data);
                const eList = [];
                data.forEach((e) => {
                  if (e.slots.length > 0) {
                    eList.push(shuffleMonthDayDateDash(e.date));
                  }
                });
                seteventsList(eList);
              } catch (error) {
                errorAlert("Error in fetching slots");
              } finally {
                setslotsLoading(false);
              }
            }}
            onDateSelect={(v) => {
              setselectedSlot(null);
              console.log(v, monthlySlots);
              onDateChange(v);
              const item = filterSelectedSlots(monthlySlots, v);
              console.log(item);
              if (item?.slots !== undefined && item?.slots !== null) {
                setuserslots(item.slots);
              }
            }}
            defaultDate={selectedDate}
          />
          <div>
          {/* <BookingCalender value={selectedDate} onChange={onDateChange} /> */}
          
            <SelectField
              label={translate(labels.service)}
              required={true}
              value={selectedService}
              onChange={(v) => {
                setSelectedService(v);
              }}
              options={userBusiness.services.map((e) => {
                return {
                  value: JSON.stringify(e),
                  name:
                    e?.title[locale] +
                    " - " +
                    minutuestoHoursString(e?.time, translate) +
                    " - " +
                    e?.price +
                    "zł",
                };
              })}
            />
            <div style={{ margin: "10px 0" }}></div>
            <SlotsList
              slotsLoading={slotsLoading}
              userslots={userslots}
              selectedSlot={selectedSlot}
              setselectedSlot={setselectedSlot}
            />

            <SelectField
              label={translate(labels.reminder)}
              required={true}
              value={selectedReminder}
              onChange={(v) => {
                setSelectedReminder(v);
              }}
              options={remindList}
            />
            <div style={{ margin: "10px 0" }}></div>  
            {/* {isPosting ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                className="modal__button cancel__button"
              >
                {translate(labels.cancel)}
              </button>
            )} */}




        
        </div>
        </div>
        <div className="calendar_modal__wrapper">
        <div className="form__group" style={{  width: "100%"  }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.firstName)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <input
              name="firstName"
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterFirstName)}
            />
          </div>
        </div>      
        <div className="form__group" style={{ width: "100%"  }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.lastName)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem"  }}
          >
            <input
              name="lastName"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterLastName)}
            />
          </div>
        </div>  
        </div>   
        <div className="calendar_modal__wrapper">         
        <div className="form__group" style={{ width: "100%"  }} >
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.email)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <input
              name="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="primary__input-content"
              type="email"
              placeholder={translate(labels.enterEmail)}
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </div>
        </div>          
          <div className="form__group" style={{ width: "100%"  }}>
            <label htmlFor="mobile-input" className="primary-label">
              {translate(labels.phone)}
            </label>
            <div className="primary__input" style={{ paddingLeft: "0", paddingRight: "0",  width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem"  }}>
              <select
                name="phone-identity-select"
                id="phone-identity-select"
                className="phone-identity-select"
                required
                onChange={(e) => setCode(e.target.value)}
                style={{ width: "auto", backgroundPosition: "calc(100% - 30px) 5px, calc(100% - 25px) 5px, calc(100% - 2em)", paddingRight: "3em"}}
              >
                <option value="+48">+48</option>
              </select>
              <input
                name="phone"
                value={phone}
                style={{marginLeft: "-1.5em"}}
                onChange={(e) => setPhone(e.target.value)}
                className="primary__input-content"
                type="number"

              />
            </div>
          </div>
          </div>
         <div className="calendar_modal__wrapper">
        <div className="form__group" style={{ width: "100%"  }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.street)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0",  width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem"  }}
          >
            <input
              name="street"
              value={street}
              required
              onChange={(e) => setStreet(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterStreet)}
            />
          </div>
        </div>
        <div className="form__group" style={{ width: "100%"  }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.houseNo)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem"  }}
          >
            <input
              name="houseNo"
              value={houseNo}
              required
              onChange={(e) => setHouseNo(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterHouseNumber)}
            />
          </div>
        </div>   
        <div className="form__group" style={{  width: "100%" }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.apartmentNo)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <input
              name="apartmentNo"
              value={apartmentNo}
              onChange={(e) => setApartmentNo(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterApartmentNumber)}
              style={{ width: "100%" }}
            />
          </div>
        </div>   
        </div>           
          <div className="calendar_modal__wrapper">
         <div className="form__group" style={{  width: "100%" }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.postalCode)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <input
              name="postalCode"
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterPostalCode)}
            />
          </div>
        </div>
        <div className="form__group" style={{ width: "100%" }}>
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.city)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", width: "auto", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <input
              name="city"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterCity)}
            />
          </div>
        </div>      
        </div>
         <div className="calendar_modal__wrapper" style={{ gap: "10px" }}> 
         <input
     type="checkbox"
     required="required" 
      style={{ width: "auto", verticalAlign: "middle" }}                      
   />
   <span style={{marginTop: "4px", marginLeft: "5px", verticalAlign: "middle", display: "inline-block"}}>{translate(labels.termAndConditionText)} <a href={"https://hauzy.app/" + locale + "/terms"}>{translate(labels.termAndConditionLink)}</a></span>
   </div>
                    {isPosting ? (
              <Loader />
            ) : (
                      <button className="primary__btn modal__button next__button">
                {translate(labels.confirmAndBook)}
              </button>
            )}

          </form>
      </div>
            {openBooking !== null ? (
        <SimpleBookingPreviewModal
          setbookingPreviewDialog={setOpenBooking}
          booking={openBooking}
        />
      ) : null}
    </div>
    // <Modal>
    //   <div className="modal-content calender__modal">
    //     <span className="modal-close-icon" onClick={() => setShowModal(false)}>
    //       <Icon name="cancel" />
    //     </span>

    //     <BookingCalender value={selectedDate} onChange={onDateChange} />
    //     <form onSubmit={onSubmit} className="section form-items">
    //       <SelectField
    //         label={translate(labels.service)}
    //         required={true}
    //         value={selectedService}
    //         onChange={(v) => {
    //           setSelectedService(v);
    //         }}
    //         options={userBusiness.services.map((e) => {
    //           return {
    //             value: JSON.stringify(e),
    //             name:
    //               e?.title[locale] +
    //               " - " +
    //               minutuestoHoursString(e?.time, translate) +
    //               " - " +
    //               e?.price +
    //               "zł",
    //           };
    //         })}
    //       />
    //       <div style={{ margin: "20px 0" }}></div>
    //       <label>{translate(labels.avalibletime)}</label>

    //       <div className="time">
    //         {slotsLoading ? (
    //           <div>{translate(labels.loading)}</div>
    //         ) : userslots.length === 0 ? (
    //           <span>{translate(labels.noSlotFound)}</span>
    //         ) : (
    //           userslots.map((option) => (
    //             <div
    //               key={option}
    //               className={`chip ${
    //                 selectedSlot === option ? "selected" : ""
    //               }`}
    //               onClick={() => setselectedSlot(option)}
    //             >
    //               {option}
    //             </div>
    //           ))
    //         )}
    //       </div>
    //       <SelectField
    //         label={translate(labels.reminder)}
    //         required={true}
    //         value={selectedReminder}
    //         onChange={(v) => {
    //           setSelectedReminder(v);
    //         }}
    //         options={remindList}
    //       />

    //       {isPosting ? (
    //         <Loader />
    //       ) : (
    //         <button className="primary__btn modal__button next__button">
    //           {translate(labels.next)}
    //         </button>
    //       )}
    //       {isPosting ? (
    //         <></>
    //       ) : (
    //         <button
    //           onClick={() => {
    //             setShowModal(false);
    //           }}
    //           className="modal__button cancel__button"
    //         >
    //           {translate(labels.cancel)}
    //         </button>
    //       )}
    //     </form>
    //   </div>
    // </Modal>
  );
};

export default BookingModel;
