// TODO : Will remove the optional check when Recommended Info contains data from other components.
import React, { Fragment, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Ratings from "./Ratings";
import Icon from "./Icon";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/auth";
import AuthModal from "./Registration/AuthModal";
import BookingModel from "../pages/Individual/Home/components/BookingModel";
import ShareModel from "../pages/Individual/Home/components/ShareModel";
import ShareModalB from "./share/ShareModal";
import { getCurrentLoginUserId } from "../services/auth_service";
import { ReactComponent as ShareIcon } from "../assets/svg icons/share-green.svg";

import { FavouritesContext } from "../context/favourites";
import ServiceList from "./ServiceProvider/ServiceList";

const RecommendedInfo = ({ userBusiness, hideService }) => {
  // const translate = useLocalization();
  const { locale = "en" } = useParams();
  const [showModal, setShowModal] = useState(null);
  const [markFav, setmarkFav] = useState(false);
  const userId = getCurrentLoginUserId();
  const [shareModal, openShareModal] = useState(null);
  console.log("userId", userId);

  const { loggedIn } = useContext(AuthContext);

  const { isthatBussinessFavourite, addToFavourites, removeFromFavourites } =
    useContext(FavouritesContext);

  // const services = userBusiness?.services ?? [];
  // const [servicesList, setservicesList] = useState(services.slice(0, 2));

  // const showMoreServices = () => {
  //   if (services.length === 0) {
  //     return;
  //   }
  //   if (services.length !== servicesList.length) {
  //     setservicesList(services.slice(0, servicesList.length + 1));
  //   }
  // };
  const addOrRemoveFavourite = () => {
    if (isthatBussinessFavourite(userBusiness.uid)) {
      removeFromFavourites(userBusiness.uid);
    } else {
      addToFavourites(userBusiness.uid, userBusiness);
    }
  };
  // const renderServeList = () => {
  //   if (!hideService) {
  //     return (
  //       <>
  //         {servicesList.map((service, index) => {
  //           return (
  //             <div key={index} className="services">
  //               <h3 className="services-name-price">
  //                 {service.title.en.charAt(0).toUpperCase() +
  //                   service.title.en.slice(1)}{" "}
  //                 <span className="full-stop">&#8226;</span>
  //                 <span>{`${service.time} hours  `}</span>
  //                 <span className="full-stop">&#8226;</span>
  //                 <span>{`  ${service.price.toFixed(1)} zł`}</span>
  //               </h3>

  //               <button
  //                 onClick={() => {
  //                   console.log("booking", showModal, loggedIn);
  //                   if (loggedIn) {
  //                     setShowModal("booking");
  //                   } else {
  //                     setShowModal("signin");
  //                   }
  //                 }}
  //                 className="book-button"
  //               >
  //                 {translate(labels.book)}
  //               </button>
  //             </div>
  //           );
  //         })}
  //         {services.length === 0 ? (
  //           <p className="nav__bottom-link show_more">
  //             {translate(labels.no_service_found)}
  //           </p>
  //         ) : (
  //           // : services.length !== servicesList.length ? (
  //           <p
  //             onClick={showMoreServices}
  //             className="nav__bottom-link show_more"
  //           >
  //             {services.length === 0
  //               ? translate(labels.no_service_found)
  //               : translate(labels.showMoreDetails)}
  //           </p>
  //         )}
  //       </>
  //     );
  //   }
  // };
  return (
    <Fragment>
      <div className="recommended__content">
        <div className="recommended__meta-info">
          {/*isthatBussinessFavourite(userBusiness.uid) ? (
            <img
              onClick={async () => {
                if (loggedIn) {
                  addOrRemoveFavourite();
                } else {
                  setShowModal("signin");
                  setmarkFav(true);
                }
              }}
              src={require("../assets/heart-fill.png").default}
              alt="search icon"
              className="recommended__meta-love-icon heart-fill"
            />
          ) : (
            <Icon
              onClick={async () => {
                if (loggedIn) {
                  addOrRemoveFavourite();
                } else {
                  setShowModal("signin");
                  setmarkFav(true);
                }
              }}
              name="heart"
              className={"recommended__meta-love-icon"}
            />
          )*/}
          {/* <Icon name="heart" className="recommended__meta-share-icon" /> */}
          <ShareIcon className="recommended__meta-share-icon" 
                          onClick={
            () => {
                  if (
                    userBusiness === undefined ||
                    userBusiness === null ||
                    userBusiness.uid === undefined ||
                    userBusiness.uid === null
                  ) {
                    return;
                  }
                  var host = window.location.host;

                  openShareModal(
                    host + `/${locale}/serviceprovider/${userBusiness?.uid}`
                  );
                  // incrementProfileShare(userBusiness?.uid);
                }
          }

          />

                  {userBusiness?.profilePic ?
                    <img
                    src={userBusiness?.profilePic}
                    className="recommended_profile"
                    width={65}
                    height={65}
                    alt=""
                  />
                  :
                   <img
                    src={require("../assets/app-icon.png")}
                    alt="Logo Hauzy Bussiness"
                    className="recommended_profile"
                    width={65}
                    height={65}
                  />
                  }
          <div className="text__content">          
            <h3 className="recommended__title">
              {`${
                userBusiness?.firstName || ""
              } ${userBusiness?.lastName || ""}`}
            </h3>

            <h3 className="recommended__spec">
              {locale == "en" ? userBusiness?.category?.name?.en : userBusiness?.category?.name?.pl}
              <span> &#8226; </span>
              {userBusiness?.location?.address || ""}
            </h3>
            {/* <h3>{userBusiness?.location?.address || ""}</h3> */}
            <div className="recommended__ratings">
              <Ratings
                className="recommended-icon"
                filled={!isNaN(userBusiness?.rating) ? userBusiness?.rating : 5}
              />
              <span className="opinions__number">
                {!isNaN(userBusiness?.rating) ? userBusiness?.rating : "5.0"}
              </span>
            </div>
            {/* <h2 className="recommended__description">
              {userBusiness?.description
                ? userBusiness?.description?.en?.split(
                    /(^.*?[a-z]{2,}[.!?])\s+\W*[A-Z]/
                  )[1]
                  ? `'${
                      userBusiness?.description.en.split
                        /(^.*?[a-z]{2,}[.!?])\s+\W*[A-Z]/
                      )[1]
                    }'`
                  : `'${userBusiness?.description.en.split(". ", 1)[0]}'`
                : "This is a description"}
            </h2> */}
            {/* {renderServeList()} */}
            {hideService ? null : (
              <ServiceList
                userBusiness={userBusiness}
                addOrRemoveFavourite={addOrRemoveFavourite}
                markFav={markFav}
              />
            )}
            {/* // ) : null} */}
          </div>
        </div>
      </div>
      {showModal === "signin" ? (
        <AuthModal
          onDone={(v) => {
            if (v && markFav) {
              addOrRemoveFavourite();
              setShowModal(null);
              return;
            } else if (v) {
              window.FB.CustomerChat.hide();
              setShowModal("booking");
              return;
            }
          }}
          setShowModal={setShowModal}
        />
      ) : null}
      {showModal === "booking" ? (
        <BookingModel setShowModal={setShowModal} userBusiness={userBusiness} />
      ) : null}
      {showModal === "share" ? (
        <ShareModel setShowModal={setShowModal} userBusiness={userBusiness} />
      ) : null}   
      {shareModal && (
        <ShareModalB openShareModal={openShareModal} link={shareModal} />
      )}   
    </Fragment>
  );
};

export default RecommendedInfo;
