import React, { useState, useEffect, Fragment, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";

import * as Functions from "../utils/function";

import AuthModal from "./Registration/AuthModal";
import BookingModel from "../pages/Individual/Home/components/BookingModel";
import { AuthContext } from "../context/auth";
import { getBussinessSlotinRange } from "../services/bussiness_service";
import useWindowDimensions from "../hooks/use-windows-dimensions";
const RecommendedCalender = ({ userBusiness }) => {
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const endDateUtc = new Date();
  endDateUtc.setDate(endDateUtc.getDate() + 7);

  const [showTime, setShowTime] = useState(4);
  const translate = useLocalization();
  const [showModal, setShowModal] = useState(null);
  const { height, width } = useWindowDimensions();
  console.log(height, width);

  const fetchSlots = async () => {
    try {
      setLoading(true);

      // setError("");
      // const response = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_FREE_EMPTSLOTSMULTIPLEDAYS}`,
      //   {
      //     data: {
      //       bussinessUserId: userBusiness?.uid,
      //       startDateUtc: new Date().toISOString(),
      //       endDateUtc,
      //       currentTime: new Date().toISOString(),
      //     },
      //   }
      // );
      // setSlots(response.data.result);
      const data = await getBussinessSlotinRange(userBusiness?.uid);
      setSlots(data);
    } catch (error) {
      console.error(error);
      // setError(translate(labels.errorOccurs));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userBusiness?.uid) {
      fetchSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getDays = slots.map((name, i) => {

    // const date = new Date(name.date);
    const currentDate = new Date();
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);

    const formattedToday = formatDate(currentDate);
    const formattedTomorrow = formatDate(tomorrow);

    // const month = date.getMonth() + 1;
    // const day = date.getDate();
    console.log(name);
    return {
      day:
        name?.date === formattedToday
          ? translate(labels.today)
          : name?.date === formattedTomorrow
          ? translate(labels.tomorrow)
          : translate(labels.daysOfWeek[new Date(name?.date).getDay()]) ,
      date: name.date,

      slots: name?.slots,
    };
  });

  let perChunk = 7;

  if (width <= 1100) {
    perChunk = 6;
  }
  if (width <= 930) {
    perChunk = 5;
  }
  if (width <= 820) {
    perChunk = 4;
  }
  if (width <= 700) {
    perChunk = 7;
  }

  if (width <= 600) {
    perChunk = 4;
  }
  const getWeekDays = getDays?.reduce((dayArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!dayArray[chunkIndex]) {
      dayArray[chunkIndex] = [];
    }

    dayArray[chunkIndex].push(item);

    return dayArray;
  }, []);

  const maxSlotsLength = getDays?.reduce((max, day) => {
    const { slots } = day;
    return slots?.length > max ? slots?.length : max;
  }, 0);

  const { loggedIn } = useContext(AuthContext);
  const [tapDate, settapDate] = useState(null);
  return (
    <Fragment>
      <div className="recommended__calendar">
        <Carousel showArrows={true} showThumbs={false}>
          {loading ? (
            <div className="loader"></div>
          ) : (
            getWeekDays?.map((days, index) => {
              return (
                <div key={index} className="recommended__calendar-cols">
                  {days.map((weekDay, dindex) => {
                    return (
                      <Fragment key={dindex}>
                        <div className="recommended__calendar-col">
                          <div className="calendar__date mg-bottom-small">
                            <span className="calendar__day">{weekDay.day}</span>
                            <span className="calendar__date">
                              {Functions.formatDate(weekDay.date, "DD.MM")}
                            </span>
                          </div>
                          {weekDay?.slots?.slice(0, showTime).map((time, i) => {
                            const timeSlot = new Date(
                              weekDay.date + "T" + time
                            );
                            if (timeSlot < new Date()) {
                              return null;
                            }
                            return (
                            <button
                              key={i}
                              className="free__slot recommended__time"
                              onClick={() => {
                                settapDate(weekDay.date + "T" + time);
                                console.log("Data slotu");
                                console.log(weekDay.date + "T" + time);
                                window.FB.CustomerChat.hide();
                                 setShowModal("booking"); 
                               /* if (loggedIn) {
                                  setShowModal("booking");
                                } else {
                                  setShowModal("signin");
                                } */
                              }}
                            >
                              {time}
                            </button>
                            );
                          })}
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              );
            })
          )}
        </Carousel>
        {!loading && (
          <span>
            {showTime !== maxSlotsLength ? (
              <p
                onClick={() => {
                  // if (showTime <= 7) {
                  //   setShowTime(showTime + 2);
                  // } else {
                  setShowModal("booking");
                  // }
                }}
                className="nav__bottom-link show_more"
              >
                {translate(
                  maxSlotsLength ? labels.showMoreDetails : labels.noSlotFound
                )}
              </p>
            ) : (
              <p
                onClick={() => setShowTime(showTime - 4)}
                className="nav__bottom-link show_more"
              >
                {translate(labels.showLess)}
              </p>
            )}
          </span>
        )}
      </div>
      {showModal === "signin" ? (
        <AuthModal setShowModal={setShowModal} />
      ) : null}
      {showModal === "booking" ? (
        <BookingModel
          setShowModal={setShowModal}
          userBusiness={userBusiness}
          initialDate={tapDate}
        />
      ) : null}
      {/* {showModal && !userId ? (
        <Modal>
          <div className="modal-content">
            <span
              className="modal-close-icon"
              onClick={() => setShowModal(false)}
            >
              <Icon name="cancel" />
            </span>
            <h3 className="secondary-heading mg-top-medium">{showModal}</h3>
            <SignIn page={false} />
          </div>
        </Modal>
      ) : null} */}
    </Fragment>
  );
};

export default RecommendedCalender;
