import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useContext,
} from "react";

import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Index = () => {
  const { locale = "en" } = useParams();
  const translate = useLocalization();

  const termData = [
    {
      title: "Definicje",
      descriptions: [
        {
          head: "Application / System",
          content:
            'Hauzy software and service dedicated to Service Providers, operated by Hauzy B and distributed under the business name "Hauzy B", available on the Internet in the domain https://hauzy.app/biznes, via mobile applications for iOS and Android software. The application enables establishing contact between the Service Provider and the Customer in order to advertise, promote, offer and book Mobile Services.',
        },
        {
          head: "Application for the Service Provider",
          content:
            'Hauzy software and service dedicated to the Service Provider, operated by Hauzy B and distributed under the business name "Hauzy B", available on the Internet in the domain https://hauzy.app/biznes, via mobile applications for iOS and Android software and via widgets that can be embedded on websites or social media profiles of Service Providers. The application for the Service Provider enables the Service Providers to manage reservations of the Service Provider"s Services',
        },
        {
          head: "Hauzy",
          content:
            "Lobocode GmbH (CHE-276.111.199) with its registered office in Zurich, at the address: Wildbachstrasse 58, 8008 Zurich, Switzerland.",
        },
      ],
    },
    {
      title: "Preliminary Provisions. Contact details",
      descriptions: [
        {
          content:
            "The Regulations specify the terms and conditions for the provision of electronic services by Hauzy B via the Application, in particular the terms and conditions for the use of the Application by Service Providers.",
        },
      ],
    },
  ];
  const MessengerRef = useRef();
  useEffect(() => {

    MessengerRef.current.setAttribute("page_id", "108613761055132");
    MessengerRef.current.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
        window.FB.init({
            xfbml: true,
            version: 'v16.0',
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}, []);
  return (
    <>
      <Navbar />
      <div className="main_content">
        <div className="terms_container">
          <h4 className="terms_heading">
            {translate(labels.termAndCondition)}
          </h4>
          <div className="main__term">
            {locale === "en" ? (
              <div
                class="c-tabsVertical_content c-legal_document"
                data-type="terms-of-service"
                data-region="pl"
                data-source=""
              >
                <h2>
                  <strong>I. Definitions</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    <strong>Application / System</strong> - Hauzy B software and
                    service dedicated to Service Providers, operated by Hauzy B
                    and distributed under the business name "
                    <strong>Hauzy B</strong>", available on the Internet in the
                    domain{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , via mobile applications for iOS and Android software. The
                    application enables establishing contact between the Service
                    Provider and the Customer in order to advertise, promote,
                    offer and book Mobile Services.
                  </li>
                  <li>
                    <strong>Application for the Service Provider</strong> -
                    Hauzy B software and service dedicated to the Service
                    Provider, operated by Hauzy B and distributed under the
                    business name "<strong>Hauzy B</strong>", available on the
                    Internet in the domain{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , via mobile applications for iOS and Android software and
                    via widgets that can be embedded on websites or social media
                    profiles of Service Providers. The application for the
                    Service Provider enables the Service Providers to manage
                    reservations of the Service Provider's Services.
                  </li>
                  <li>
                    <strong>Hauzy B</strong>– Lobocode GmbH (CHE-276.111.199)
                    with its registered office in Zurich, at the address:
                    Wildbachstrasse 58, 8008 Zurich, Switzerland.
                  </li>
                  <li>
                    <strong>Customer</strong> - a natural person over 16 years
                    of age, a legal person or an organizational unit without
                    legal personality who has created an account in the
                    Application for Customers under the name Hauzy, in order to
                    use the Services offered by Service Providers.
                  </li>
                  <li>
                    <strong>Service Provider's Account</strong> - part of the
                    Application together with a set of IT solutions that allow
                    logged-in users to use the Application.
                  </li>
                  <li>
                    <strong>Free Period</strong> - means 7 (seven) calendar days
                    during which the newly registered Service Provider, only on
                    the condition of accepting the Regulations and other
                    documents required by Hauzy B at a given time, may use the
                    Application without having to pay a Fee .
                  </li>
                  <li>
                    <strong>Fee or Fees</strong> - means the total amount of
                    charges due from the Service Provider to Hauzy B for any
                    reason due to Hauzy B from the Service Providers in
                    connection with the use of the Application, provided for in
                    the applicable price lists or regulations.
                  </li>
                  <li>
                    <strong>Service Provider</strong> - a natural person, legal
                    person or organizational unit without legal personality that
                    uses the Application in connection with the services it
                    offers with access to the Customer and has created an
                    Account in the Application for itself, in order to provide
                    Customer Services.
                  </li>
                  <li>
                    <strong>Business Card Profile</strong> - a profile created
                    by the Service Provider in the Application, visible to
                    Customers, containing, among others: (i) name and surname of
                    the Service Provider, (ii) age, (iii) photo, (iv)
                    information about the Service Provider's Services offered
                    (including the name of the Service Provider's Service, its
                    description, duration and actual price, (v) possibly -
                    information about the need to prepay or pay a deposit. The
                    Profile-Business Card also contains customer opinions
                    regarding the Services provided by the Service Provider.
                  </li>
                  <li>
                    <strong>Reservation</strong> - the Customer's booking of the
                    Service Provider's Service using the Application for
                    Customers, indicating the date and time of the Service
                    Provider's Service selected by the Customer.{" "}
                  </li>
                  <li>
                    <strong>Regulations</strong> - these regulations.
                  </li>
                  <li>
                    <strong>RODO</strong> - Regulation (EU) 2016/679 of the
                    European Parliament and of the Council of 27 April 2016 on
                    the protection of individuals with regard to the processing
                    of personal data and on the free movement of such data, and
                    repealing the Directive 95/46/EC (General Data Protection
                    Regulation) as amended.
                  </li>
                  <li>
                    <strong>Website</strong> - means https://hauzy.app/biznes.{" "}
                  </li>
                  <li>
                    <strong>Hauzy B Services</strong> - services provided by
                    Hauzy B to the Service Provider.{" "}
                  </li>
                  <li>
                    <strong>Service Provider Services</strong> - all mobile
                    services with access to the customer at the indicated
                    address offered by Service Providers using the Application
                    for the Customer.{" "}
                  </li>
                </ol>

                <p></p>
                <h2>
                  <strong>II. Preliminary Provisions. Contact details</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    The Regulations specify the terms and conditions for the
                    provision of electronic services by Hauzy B via the
                    Application, in particular the terms and conditions for the
                    use of the Application by Service Providers.
                  </li>
                  <li>
                    (<strong>Contact Details</strong>) Hauzy B can be contacted:
                    <ol>
                      <li>
                        by e-mail to:{" "}
                        <a href="mailto:info@hauzy.app">info@hauzy.app</a>;
                      </li>
                      <li>
                        by post to the address: ul. Ogrodowa 12, 61-821 Poznań.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>III. Types and scope of Hauzy B Services </strong>
                </h2>
                <ol>
                  <li>
                    Hauzy B grants Service Providers access to the Application,
                    enabling direct contact between the Customer (using the
                    Application for Customers) and the Service Provider. Through
                    the Application, Service Providers may advertise, promote
                    and offer the Service Provider's Services on the Internet
                    and directly to Customers. The application is available on
                    the Internet via the Website, it can also be installed on a
                    mobile device with Internet access.
                  </li>
                  <li>
                    The Hauzy B Services include, among others:{" "}
                    <ol>
                      <li>allowing access to the Application; </li>
                      <li>
                        enabling the creation of a Service Provider Account and
                        Business Card Profile;{" "}
                      </li>
                      <li>
                        enabling the use of the appointment booking calendar,
                        including managing Reservations and Contacting the
                        Customer;{" "}
                      </li>
                      <li>
                        allowing you to receive and send SMS and e-mail / push
                        notifications;{" "}
                      </li>
                      <li>
                        allowing Customers to access the Application for the
                        Customer and the widget on the Service Provider's
                        Facebook profile and the widget on the Service
                        Provider's website, enabling them to make Online
                        Reservations;
                      </li>
                    </ol>
                  </li>
                  <li>
                    As part of the Application, the Service Provider sets up a
                    Service Provider Account and a Profile-Business Card, in
                    which he provides his data, including personal data. The
                    data necessary for Hauzy B to provide Hauzy B Services under
                    the Agreement are the data necessary to set up an Account
                    and Profile-Business Card. Additional information, such as
                    photos of the Service Provider's portfolio of Services, may
                    be added by the Service Provider in order to expand the
                    Account and Business Card Profile.
                  </li>
                  <li>
                    The functionalities of the System available to the Service
                    Provider, including the methods / channels of booking the
                    Service Provider's Services, as well as the appearance of
                    the Application itself, depend on the current availability
                    of given solutions and functionalities and may change for
                    operational and technical reasons. The above also applies
                    accordingly to the functionality and appearance of the
                    Application for Customers.
                  </li>
                </ol>

                <h2>
                  <strong>
                    IV. Detailed conditions for the provision of services as
                    part of the Application
                  </strong>
                </h2>
                <ol>
                  <li>
                    Setting up a Service Provider's Account in the Application
                    by the Service Provider is tantamount to:{" "}
                    <ol>
                      <li>beginning of the Free Period;</li>
                      <li>
                        acknowledgement and acceptance of the provisions of the
                        Regulations and any other documents made available by
                        Hauzy B at a given time;
                      </li>
                      <li>
                        declaration that all data provided to Hauzy B by the
                        Service Provider are up-to-date and true;
                      </li>
                      <li>
                        authorizing Hauzy B to process the personal data of the
                        Service Provider saved on the Service Provider's Account
                        in order to provide services as part of the Application
                        and for diagnostic and statistical purposes.
                      </li>
                    </ol>
                  </li>
                  <li>
                    By agreeing to receive system messages related to the use of
                    the Application to the provided telephone number and e-mail
                    address, the Service Provider agrees:
                    <ol>
                      <li>
                        that Customers will contact him directly to the e-mail
                        address or telephone number provided by the Service
                        Provider and via chat in the Application;
                      </li>
                      <li>
                        that the data provided by him, including personal data
                        and information, including photos made available by him
                        in the Application and Profile-Business Card will be
                        visible to other Service Providers and Customers, as
                        well as to all Internet users;
                      </li>
                      <li>
                        for Hauzy B to send messages in the Application,
                        including notifications about changes in the
                        Application, in particular about technical breaks in the
                        operation of the Application, changes in the
                        Regulations, new content published in the Application,
                        new messages and events in the Application - via
                        messages directly in the Application and to the e-mail
                        address indicated in the registration form.
                      </li>
                    </ol>
                  </li>
                  <li>
                    All data and information are made available by the Service
                    Provider voluntarily.
                  </li>
                  <li>
                    Creating a common Business Card Profile for more than one
                    Service Provider is prohibited.{" "}
                  </li>
                  <li>
                    The Service Provider is obliged to inform Hauzy B about the
                    actual number of users using the Service Provider's Account.
                    If the Service Provider fails to provide Hauzy B with the
                    information referred to in the previous sentence, Hauzy B is
                    entitled to remove users from the Service Provider's Account
                    about which Hauzy B has not been informed.{" "}
                  </li>
                </ol>
                <h2>
                  <strong>
                    V. Activity of the Service Provider. Service Provider
                    Content{" "}
                  </strong>
                </h2>
                <ol>
                  <li>
                    The Service Provider undertakes that:
                    <ol>
                      <li>
                        by entering data into the Application, Profile-Business
                        Card Account and social media Facebook and Instagram, he
                        will be authorized to do so and will not infringe the
                        rights of third parties by his actions;
                      </li>
                      <li>
                        all information and data provided by him in the
                        Application, Account, Profile-Business Card and social
                        media Facebook and Instagram will be true.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Service Provider may post content belonging to it on its
                    Profile-Business Card, in particular such as photos, videos,
                    graphics, logos, descriptions and texts ("
                    <strong>Content of the Service Provider</strong>"). Upon
                    posting by the Service Provider any Content of the Service
                    Provider in the Application, the Service Provider grants
                    Hauzy B a non-exclusive, free of charge, unlimited territory
                    and unlimited in time license ("Licence for Hauzy B") to use
                    the Service Provider's Content posted in the Application in
                    accordance with the Regulations, on the following known at
                    the time of granting the License to Hauzy B, fields of use:
                    <ol>
                      <li>
                        in the scope of recording and multiplying the Service
                        Provider's Content - production of copies of this
                        content using a specific technique, including digital
                        technique, magnetic recording as well as printing and
                        reprographic technique;{" "}
                      </li>
                      <li>
                        in the scope of trading in the original or copies on
                        which the Service Provider's Content was recorded -
                        marketing the original or copies of the work;{" "}
                      </li>
                      <li>
                        in the scope of disseminating the Service Provider's
                        Content in a manner other than specified in letter b)
                        above - public performance, display, display,
                        reproduction, broadcasting and re-broadcasting, as well
                        as making this content publicly available in such a way
                        that everyone can have access to it place and time
                        chosen by you, including sharing this content on the
                        Internet, in particular in the Application;{" "}
                      </li>
                    </ol>
                    , however, the Service Provider undertakes that, in
                    accordance with the scope of the above-described License for
                    Hauzy B, the use of the Service Provider's Content covered
                    by this License for Hauzy B will not infringe anyone's
                    copyright, including moral rights, in particular in terms of
                    the right to mark the work with the name of the author.
                  </li>
                  <li>
                    The scope of the License for Hauzy B indicated in section 2
                    above includes Hauzy B's right to:
                    <ol>
                      <li>
                        use of the Service Provider's Content and content posted
                        in the Application by third parties, in order to provide
                        services, for information purposes and for promotion or
                        advertising of the Service Provider, Application or
                        Application for the Customer - also after the Service
                        Provider resigns from using Hauzy B Services, after
                        deleting the Account Service Provider and after removing
                        the Service Provider's Content from the Application;
                      </li>
                      <li>
                        use the Service Provider's Content made available in the
                        Application without territorial restrictions;
                      </li>
                      <li>
                        granting further licenses to use the Service Provider's
                        Content within the limits of the License held to Hauzy
                        B.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Resignation of the Service Provider from using the Hauzy B
                    Services or deletion of the Service Provider's Content or
                    the Service Provider's Account from the Application does not
                    affect the validity of the above. License to Hauzy B.{" "}
                  </li>
                  <li>
                    The Service Provider may independently post within the
                    Application, including on its Profile-Business Card, its
                    logotype / company (name), which is a trademark covered by
                    protection law ("Logo"). The Service Provider is aware that
                    at the moment of placing the Logo, Hauzy B gives its consent
                    to use the Logo to provide services, for information
                    purposes and to promote or advertise the Service Provider,
                    the Application or the Application for the Customer, in
                    particular on the Internet, without time and territory
                    restrictions. The Service Provider undertakes not to
                    withdraw such consent, and in the event of its withdrawal in
                    violation of the above obligation, Hauzy B is not obliged to
                    remove the Logo distributed before the date of consent
                    withdrawal.
                  </li>
                  <li>
                    If the Service Provider has posted in the Application or
                    social media Facebook and Instagram content containing the
                    image of a third party, the Service Provider represents and
                    warrants that it has the authorization of that person to use
                    that person's image, and posting the content in the
                    Application, granting the License to Hauzy B to the extent
                    indicated in section 2-3 and sec. 10 and the use of the
                    Content by Hauzy B in accordance with the terms of the
                    License for Hauzy B in no way infringe the rights of that
                    person.
                  </li>
                  <li>
                    The Service Provider bears full liability for damages
                    towards Hauzy B for any damage that Hauzy B incurs in
                    connection with the posting of the Service Provider's
                    Content in the Application and Hauzy B's use of this Content
                    under the terms of the License for Hauzy B and the use of
                    content posted in the media social networks Facebook and
                    Instagram under the conditions set out in para. 10 below. If
                    any claims are made against Hauzy B by any third parties,
                    the Service Provider shall indemnify Hauzy B from any
                    liability and cover all reasonable costs related thereto.
                  </li>
                  <li>
                    The Service Provider is responsible as for its own action or
                    omission for the actions and omissions of persons with whose
                    help it uses the Application..{" "}
                  </li>
                  <li>
                    In addition, the Service Provider undertakes to:
                    <ol>
                      <li>
                        allow Hauzy B to verify the content placed in the
                        Application and the Service Provider's Accounts and
                        Business Card Profiles in terms of their authenticity
                        and compliance with the Regulations, in particular by
                        providing the current telephone number and providing
                        additional explanations;
                      </li>
                      <li>
                        not to use any content owned by Hauzy B for purposes
                        other than the proper use of the Application;
                      </li>
                      <li>
                        not to use any content of other Service Providers or
                        Customers without their express consent;
                      </li>
                      <li>
                        do not conduct, including via the Application, any
                        activity or post any content in the Application, in your
                        social media profiles - Facebook and Instagram, Service
                        Provider's Account or Business Card Profile (including
                        information, photos, texts and other) : (i) violating
                        the law, contrary to decency or the principles of social
                        coexistence (including: pornographic, suggesting the
                        sexual nature of services, aimed at promoting a given
                        service through content of a sexual, ambiguous or
                        unethical nature), (ii) related to drugs, legal highs
                        alcohol or other stimulants, as well as the organization
                        of gambling games, (iii) violating the rights and
                        personal rights of third parties, (iv) violating the
                        principles of fair competition, (v) violating the
                        provisions of the Regulations, (vi) related to or
                        referring to tantra and practices for similar or (vii)
                        detrimental to the good name, reputation or reputation
                        of Hauzy B ("<strong>Prohibited Activities</strong>" and
                        "<strong>Prohibited Content</strong>", respectively);
                      </li>
                      <li>
                        use the Application only in a manner consistent with its
                        intended use;
                      </li>
                      <li>
                        not to copy, modify, distribute or reproduce all or part
                        of the Application;
                      </li>
                      <li>
                        not to offer or advertise services or products whose
                        sale or advertising is prohibited or subject to
                        restrictions in accordance with applicable law, and the
                        offering or trading of which is prohibited under
                        applicable regulations - in particular, advertising
                        alcohol, tobacco, medicinal products, narcotics and
                        similar products;
                      </li>
                      <li>
                        not to grant third parties access to the Service
                        Provider's Account in the Application - only the Service
                        Provider is responsible for the confidentiality and
                        security of their Account, including keeping passwords
                        and login secret;
                      </li>
                      <li>
                        immediately inform Hauzy B of any unlawful use of the
                        Service Provider's Account by third parties;
                      </li>
                      <li>
                        immediately inform Hauzy B if third parties pursue their
                        claims in connection with the violation of the law by
                        the Service Provider via the Application:{" "}
                      </li>
                      <li>
                        not to post on your Business Card Profile or business
                        card profiles of other Service Providers opinions about
                        yourself or the services they provide.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h2>
                  <strong>
                    VI. Reservation, confirmation and cancellation
                  </strong>
                </h2>

                <ol>
                  <li>
                    Reservation is made via the Application for the Customer.
                    The Service Provider confirms the Reservation request made
                    by the Customer, automatically or manually, using the
                    Application. The Reservation between the Service Provider
                    and the Customer takes place at the moment of its
                    confirmation by the Service Provider.
                  </li>
                  <li>
                    By default, the Application is set to the mode of automatic
                    confirmation of the Reservation request. In the settings of
                    the Profile-Business Card, the Service Provider may change
                    the setting and switch to the model of manual confirmation
                    of the Reservation. By changing the confirmation mode from
                    automatic to manual, the Service Provider undertakes to
                    confirm the Reservation request within a maximum of 12 hours
                    from receiving the Reservation request. Failure to meet this
                    deadline by the Service Provider results in marking the
                    Reservation as unconfirmed. In this case, the Service
                    Provider's Service does not have to be performed, and the
                    Customer may cancel it without consequences. The Service
                    Provider is solely responsible for the provision of the
                    Service Provider's Services to the Customers after making
                    the Reservation, with the generally applicable provisions of
                    law applicable.{" "}
                  </li>
                  <li>
                    The conditions under which the Reservation is made may be
                    specified by the Service Provider in the information
                    (regulations, etc.) provided to the Customer in the Customer
                    Application before making the Reservation. The content of
                    this information and the generally applicable provisions, in
                    particular the provisions on consumer rights, determine
                    whether, what type and content of the contract is concluded
                    between the Service Provider and the Customer at the time of
                    confirming the Reservation request.
                  </li>
                  <li>
                    The Application for the Customer allows the Customer to
                    cancel the Reservation, even after its prior confirmation by
                    the Service Provider. The effects of canceling the
                    Reservation are set out in the Reservation Terms and
                    Conditions and generally applicable regulations, in
                    particular the provisions on consumer rights.
                  </li>
                  <li>
                    Hauzy B reserves the right to verify the status of the
                    Reservation in terms of compliance with the facts. In
                    particular, Reservations with a changed or non-market price
                    of the Service Provider's Service and with a changed
                    Reservation status are subject to verification.
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VII. Fees and Billing</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    For the use of Hauzy B Services, the Service Provider is
                    obliged to pay Hauzy B all Fees in the amount specified in
                    the Price List or other applicable documents (e.g.
                    regulations or price lists for promotions or packages). Fees
                    shall be paid to Hauzy B's bank account indicated on the VAT
                    invoice issued by Hauzy B or by automatic charging of Fees
                    from the Service Provider's payment card.
                  </li>
                  <li>
                    The Service Provider declares that by accepting these
                    Regulations, he also consents to Hauzy B issuing electronic
                    invoices in accordance with applicable law and sending them
                    in electronic form to the e-mail address indicated by the
                    Hauzy B Service Provider.{" "}
                  </li>
                  <li>
                    The Service Provider's obligations include providing a valid
                    e-mail address for sending invoices, adding Hauzy B to
                    trusted contacts so that the e-mail with the invoice is not
                    filtered as spam, and immediately notifying Hauzy B of a
                    change of e-mail address for sending invoices , without
                    being summoned by Hauzy B.{" "}
                  </li>
                  <li>
                    Hauzy B undertakes to send invoices electronically in PDF
                    format, in a manner that ensures the authenticity of origin,
                    integrity of content and legibility of issued invoices from
                    the moment of issue until the expiry of the tax liability
                    limitation period, in accordance with the requirements set
                    out in art. 106m of the Act on tax on goods and services of
                    March 11, 2004, during the period of validity of the consent
                    referred to above. Duplicates and corrections of invoices to
                    electronic invoices will be issued and sent electronically
                    (e-mail) in PDF format. Electronic invoices are deemed
                    delivered when they are introduced into the means of
                    electronic communication in such a way that the Service
                    Provider can read their content.
                  </li>
                  <li>
                    If the Service Provider withdraws the consent referred to
                    above, Hauzy B loses the right to issue electronic invoices
                    on the next business day after the day on which Hauzy B
                    received the Service Provider's notification of the
                    withdrawal of consent. In the event of withdrawal of consent
                    to send invoices electronically, Hauzy B will send invoices
                    by traditional mail to the address of the Service Provider,
                    for an additional fee of PLN 50 net per month. With the
                    withdrawal of consent to send invoices electronically, the
                    Service Provider accepts the charging of an additional fee.{" "}
                  </li>
                  <li>
                    The rules and deadlines for issuing VAT invoices and the
                    payment deadlines for VAT invoices are specified in the
                    Price List.
                  </li>
                  <li>
                    Failure to pay the Fee or any part thereof within the
                    specified period will result in suspension of all Hauzy B
                    Services and blocking of the Service Provider's Account.
                    Resumption of Hauzy B Services and unblocking the Service
                    Provider's Account will take place within 12 hours on the
                    next working day (Monday - Friday, 8.00 - 16.00, except for
                    Saturdays and public holidays), after Hauzy B is delivered
                    by the Service Provider by e-mail with proof of payment the
                    entire amount due or after the amount is credited to Hauzy
                    B's account.
                  </li>
                  <li>
                    If it is not possible to collect the Fee from the payment
                    card, the Service Provider's Account is unblocked when the
                    Service Provider replenishes the funds on the card and
                    repeats the payment via the Application. Alternatively, the
                    Service Provider may report to Hauzy B and ask for a
                    re-collection of the Fee. The Account will be unblocked
                    immediately after the payment of the entire Fee due.{" "}
                  </li>
                  <li>
                    If the Service Provider activates the card payment option,
                    the Customer's payment made using a payment card will be
                    reduced by all currently applicable fees of payment service
                    providers and payment institutions, as well as by the
                    commission referred to below. An invoice for the collected
                    fees and commissions will be issued and sent after the end
                    of the settlement period.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VIII. Powers and responsibility of Hauzy B</strong>
                </h2>
                <ol>
                  <li>
                    Hauzy B is never a party to contracts concluded between the
                    Service Provider and the Customer, in particular in
                    connection with the Reservation made by the Customer. Thus,
                    Hauzy B is not responsible for the Customer's actions or
                    omissions, including the Customer's payment for the Service
                    Provider's Service or any other payment due to the Service
                    Provider from the Customer. The Service Provider is obliged
                    to provide the Service Provider's Services in accordance
                    with applicable law and is solely responsible to the
                    Customer for any violations thereof. In particular, the
                    Service Provider undertakes to provide the Customer with
                    information required by law and in the manner required by
                    law.{" "}
                  </li>
                  <li>
                    Hauzy B is entitled to carry out verification activities
                    aimed at controlling the content placed in the Application
                    and Accounts and Business Card Profile in terms of their
                    authenticity and compliance with the Regulations.{" "}
                  </li>
                  <li>
                    For:
                    <ol>
                      <li>
                        Hauzy B receives an official notification, other type of
                        information or makes a different assumption about the
                        Service Provider's Prohibited Activity or publication of
                        Prohibited Content;{" "}
                      </li>
                      <li>
                        the Service Provider's violation of the terms of the
                        Agreement, including the provisions of the Regulations,
                        or acting in a manner aimed at circumventing them,
                        including manipulation of the price of the Service
                        Provider's Service or the status of the Reservation;{" "}
                      </li>
                      <li>
                        offensive behavior towards Customers, other Service
                        Providers, employees and associates of Hauzy B
                        (including through threats or using profanity and the
                        like);{" "}
                      </li>
                    </ol>
                    Hauzy B has the right to take one or more of the following
                    Remedies (at Hauzy B's sole discretion) (“
                    <strong>Remedies</strong>”).{" "}
                  </li>
                  <li>
                    Remedial actions include the following:{" "}
                    <ol>
                      <li>
                        requesting the Service Provider to immediately remove
                        the Prohibited Content from the Application /
                        Profile-Business Card or to update the content, under
                        pain of refusing to post content in the Application /
                        Profile-Business Card, blocking access to this content
                        or removing it; or
                      </li>
                      <li>
                        requesting the Service Provider to immediately stop
                        violating or circumventing the Regulations; or
                      </li>
                      <li>
                        refuse to post content in the Application /
                        Profile-Business Card, immediately block access to the
                        content posted in the Application / Profile-Business
                        Card, remove content from the Application /
                        Profile-Business Card; or
                      </li>
                      <li>
                        temporary or permanent blocking of functionalities or
                        packages used by the Service Provider;{" "}
                      </li>
                      <li>
                        blocking or deleting the Service Provider's Account /
                        Business Card Profile.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Taking any remedial action by Hauzy B does not release the
                    Service Provider from the obligation to pay the Fees charged
                    for Hauzy B's Services until the Remedial Action is taken.{" "}
                  </li>
                  <li>
                    In the situations referred to in section 3 above, Hauzy B
                    will notify the Service Provider via e-mail or via the
                    Application of its intention to block access to the Service
                    Provider's Content, remove content from the Application /
                    Profile-Business Card, block the Service Provider's Account.
                    In cases of urgency, Hauzy B will block access to the
                    Content, remove content from the Application /
                    Profile-Business Card or block the Service Provider's
                    Account without notification of such intention.
                  </li>
                  <li>
                    In the event of any claims being made to Hauzy B or a
                    legally justified request to provide the Service Provider's
                    data by authorized bodies or by an entity intending to
                    pursue claims, Hauzy B is entitled to familiarize itself
                    with the facts and to possibly secure and transfer the
                    Service Provider's data and other information contained in
                    the Application, necessary to implement and secure any
                    claims or requests.
                  </li>
                  <li>
                    The total liability of Hauzy B for the services provided via
                    the Application does not include lost profits. Hauzy B is
                    liable to the Service Providers only for damages incurred by
                    the Service Providers as a result of using the Application,
                    and only those that were caused solely by Hauzy B's willful
                    misconduct.
                  </li>
                  <li>
                    Hauzy B is not liable to the Service Provider for:
                    <ol>
                      <li>
                        take any of the actions referred to in paragraphs 3 and
                        4 above;{" "}
                      </li>
                      <li>
                        interruptions in the functioning of the Application
                        resulting from reasons beyond the control of Hauzy B, in
                        particular constituting force majeure or indicated in
                        section 9 below;
                      </li>
                      <li>
                        functioning of ICT systems and telecommunications
                        networks when making payments by Customers via the
                        Application and Application for the Customer;
                      </li>
                      <li>
                        problems or technical difficulties on the part of the
                        Service Provider related to the operation of computer
                        equipment, telecommunications or access to the Internet,
                        which make it difficult or impossible to use the
                        Application or the services offered through it;
                      </li>
                      <li>
                        authenticity, reliability, correctness, veracity,
                        completeness of data and information included in the
                        Application by the Service Provider and provided by the
                        Service Provider via the Application;
                      </li>
                      <li>
                        damages caused by the Service Provider in connection
                        with the functioning of the Application or its activity
                        in it, including those related to violation of the
                        Regulations, unauthorized use of data made available
                        through the Application, providing untrue, incomplete,
                        outdated data and information, or failure to update
                        them;
                      </li>
                      <li>
                        damage caused by the Service Provider by violating the
                        rights of third parties, as well as damage caused by the
                        actions of third parties;
                      </li>
                      <li>
                        damage caused by the Service Provider in connection with
                        improper performance or non-performance by them of
                        contracts concluded via the Application or Services;
                      </li>
                      <li>
                        damages suffered by the Service Provider due to the
                        actions or omissions of the Customer;{" "}
                      </li>
                      <li>
                        content of opinions published by Customers in the
                        Customer Application;
                      </li>
                      <li>
                        effectiveness of marketing activities carried out by
                        Service Providers for Customers via the Application.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B has the right to temporarily discontinue the
                    provision of services in the event of failure, inspection or
                    modernization of the ICT system belonging to Hauzy B.
                  </li>
                  <li>
                    Hauzy B, in accordance with art. 14 of the Act of July 18,
                    2002 on the provision of electronic services:{" "}
                    <ol>
                      <li>
                        is not responsible for the stored data if it does not
                        know about the unlawful nature of the data or activities
                        related to them, and in the event of receiving an
                        official notification or obtaining reliable information
                        about the unlawful nature of data or activities related
                        to them, it will immediately prevent access to these
                        data;
                      </li>
                      <li>
                        if he receives an official notification of the unlawful
                        nature of the stored data provided by the Service
                        Provider and prevents access to this data, he shall not
                        be liable to the Service Provider for damage resulting
                        from preventing access to this data;
                      </li>
                      <li>
                        if he obtains reliable information about the unlawful
                        nature of the stored data provided by the Service
                        Provider and prevents access to this data, he is not
                        liable to the Service Provider for damage resulting from
                        preventing access to this data, if he immediately
                        notifies the Service Provider of his intention to
                        prevent access to it.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>IX. Complaints</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Any complaints related to the incorrect operation of the
                    Application may be submitted by the Service Provider via
                    e-mail to the following e-mail address:{" "}
                    <a href="mailto:info@hauzy.app">info@hauzy.app</a>.
                  </li>
                  <li>
                    Complaints will be considered within 30 days from the date
                    of their receipt by Hauzy B. In complex cases or when the
                    complaint will not be considered within the above period for
                    reasons beyond the control of Hauzy B, Hauzy B reserves the
                    right to extend the deadline for examining the complaint.{" "}
                  </li>
                  <li>
                    Hauzy B reserves the right to request information or
                    explanations from the advertiser in a situation where it is
                    required to recognize the complaint. Explanations or
                    information should be provided by the Service Provider
                    immediately, not later than within 30 days. In the event of
                    failure to meet this deadline, Hauzy B is entitled to leave
                    the complaint unprocessed and shall not be liable in this
                    respect.{" "}
                  </li>
                  <li>
                    Hauzy B's decision on the complaint will be sent to the
                    e-mail address from which the complaint was submitted.
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>X. Technical Requirements</strong>
                </h2>
                <ol>
                  <li>
                    In order to use the Application, it is necessary to have
                    devices that allow access to the Internet, e-mail and a web
                    browser. Using the Hauzy B Services via the Application
                    requires an active Internet connection. Using the
                    Application on a mobile device requires a functional mobile
                    device. The service provider, on its own and on its own
                    responsibility, should ensure that the technical
                    requirements of the mobile device are met, its
                    configuration, software update and Internet access.
                  </li>
                  <li>
                    Technical requirements that devices must meet:{" "}
                    <ol>
                      <li>
                        phone: Android operating system not older than 5.00 or
                        iOS (Apple) operating system not older than 14;{" "}
                      </li>
                      <li>
                        tablet: (for the Hauzy B Pro application for tablets)
                        Android operating system version not older than 5.00,
                        minimum 3GB RAM, minimum 7-inch screen diagonal. iPadOS
                        (Apple) operating system not older than version 14. iPad
                        (5th generation or later), iPad Air (2nd generation or
                        later), iPad Pro, iPad Mini (4th generation or later);
                      </li>
                      <li>
                        PC / Mac: minimum 1.4 GHz processor, 4.0 GB RAM,
                        recommended Google Chrome browser, Internet access.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B uses cookies that enable the collection of
                    information related to the use of the Application. Cookies
                    are text files that are saved on the hard drive of the
                    people visiting the Application in order to store
                    information and data regarding the use of the Application.
                    Lack of enabled cookies may cause irregularities or
                    difficulties in the functioning of the Application.
                  </li>
                  <li>
                    Most of the web browsers used (e.g. Firefox, Google Chrome
                    or Internet Explorer) include the option to reject the
                    command to store cookies. The service provider may
                    independently set the browser so that it can reject requests
                    to store all or selected cookies.
                  </li>
                  <li>
                    Special risks related to the Service Provider's use of the
                    Application include providing login data to unauthorized
                    persons who may deliberately change the configuration of the
                    Profile or Service Provider's Account. This applies in
                    particular to important elements of the Profile, such as
                    types of Services offered, price lists, photos and other
                    information about the Service Provider. Therefore, it is
                    necessary to keep passwords to the system secret, as well as
                    to configure access to the Application for employees of the
                    Service Provider separately.
                  </li>
                </ol>
                <h2>
                  <strong>XI. Personal data. Privacy</strong>
                </h2>
                <ol>
                  <li>
                    Hauzy B and the Service Provider process Customers' personal
                    data for their own purposes, therefore they are separate
                    administrators of their personal data.
                  </li>
                  <li>
                    At the time of confirmation of the Reservation, an agreement
                    is concluded between the Customer and the Service Provider
                    for the provision of Services by the Service Provider to the
                    Customer. Also:{" "}
                    <ol>
                      <li>
                        The Customer may, in the Application for the Customer,
                        give the Service Provider consent to the processing of
                        personal data for purposes other than for the
                        performance of the contract with the Customer and
                        consent to marketing communication;{" "}
                      </li>
                      <li>
                        Hauzy B provides the Service Provider with the use of
                        the Application of the Customer's personal data needed
                        to provide Services to that Customer;
                      </li>
                      <li>
                        The Service Provider becomes the administrator of such
                        Customer's personal data, and therefore is obliged to
                        comply with the rules set out in the GDPR and bears
                        responsibility for it resulting from the regulations.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Independently, in order to enable Hauzy B to provide
                    services to the Service Provider as part of the Application,
                    for which processing is necessary:
                    <ol>
                      <li>
                        personal data of Customers who have made a Reservation;{" "}
                      </li>
                      <li>
                        personal data of persons used by the Service Provider
                        using the Application;{" "}
                      </li>
                      <li>
                        Customers who have consented to the sending of marketing
                        communications by the Service Provider;{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Service Provider is also the administrator of the
                    Customer's personal data with whom he will conclude a
                    contract outside the Application. If he enters these data
                    into the Application, he is still their administrator, and
                    Hauzy B processes them on behalf of the Service Provider
                    under the Personal Data Processing Agreement.
                  </li>
                  <li>
                    Hauzy B processes data, including personal data of the
                    Service Provider, only to the extent that enables the proper
                    provision of Hauzy B Services and the proper performance of
                    the Agreement.
                  </li>
                  <li>
                    Hauzy B is not responsible for the scope of Customers'
                    personal data collected by Service Providers and for the
                    legality of collecting such data by Service Providers.
                    Detailed obligations of the parties related to this are
                    included in the Personal Data Processing Agreement.
                  </li>
                  <li>
                    Hauzy B's privacy policy, including the cookie policy
                    available on the Website.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>XII. Final Provisions</strong>
                </h2>
                <ol>
                  <li>
                    Hauzy B reserves the right to change the Regulations,
                    including its integral parts, i.e. other documents used by
                    Hauzy B. The new wording is effective from the date of its
                    publication on the Website or on the Service Provider's
                    Account in the Application. In the case of the above
                    changes, Hauzy B will notify the Service Provider of this
                    change to the telephone number or e-mail address of the
                    Service Provider indicated in the Registration process. The
                    service provider may not accept the changes and in this case
                    has the right to delete the account.
                  </li>
                  <li>
                    Hauzy B has the right to send to the e-mail address of the
                    Service Provider provided to Hauzy B any reminders, requests
                    for payment or notifications regarding the Service
                    Provider's arrears with Fees for Hauzy B.{" "}
                  </li>
                  <li>
                    If any provision of the Regulations, as well as other
                    documents accepted by the Service Provider, turns out to be
                    invalid or ineffective, it will in no case result in the
                    invalidity of the Regulations. In such a situation, the
                    parties will make every effort to replace the provision
                    considered invalid or ineffective with a valid and effective
                    provision, as close as possible to the original intention of
                    the parties.
                  </li>
                  <li>
                    The relationship between Hauzy B and the Service Provider is
                    governed by these Regulations.
                  </li>
                </ol>

                <p>
                  <span>Last update: March 15, 2023</span>
                </p>
              </div>
            ) : locale === "pl" ? (
              <div
                class="c-tabsVertical_content c-legal_document"
                data-type="terms-of-service"
                data-region="pl"
                data-source=""
              >

                <h2>
                  <strong>I. Definicje</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    <strong>Aplikacja / System </strong>– dedykowane dla
                    Usługodawców oprogramowanie i serwis Hauzy B, obsługiwane
                    przez Hauzy B i rozpowszechniane pod nazwą biznesową „
                    <strong>Hauzy B</strong>”, dostępne w sieci Internet w
                    domenie{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , za pośrednictwem aplikacji mobilnych dla oprogramowania
                    iOS i Android. Aplikacja umożliwia nawiązywanie kontaktu
                    pomiędzy Usługodawcą a Klientem w celu reklamy, promocji,
                    oferowania i rezerwacji Usług moblinych.
                  </li>
                  <li>
                    <strong>Aplikacja dla Usługodawcy</strong> – dedykowane dla
                    Usługodawcy oprogramowanie i serwis Hauzy B, obsługiwane
                    przez Hauzy B i rozpowszechniane pod nazwą biznesową „
                    <strong>Hauzy B</strong>”, dostępne w sieci Internet w
                    domenie{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , za pośrednictwem aplikacji mobilnych dla oprogramowania
                    iOS i Android oraz za pośrednictwem widgetów możliwych do
                    osadzenia na stronach internetowych lub profilach w mediach
                    społecznościowych Usługodawców. Aplikacja dla Usługodawcy
                    umożliwia zarządzaniem rezerwacjami Usług Usługodawcy przez
                    Usługodawców.
                  </li>
                  <li>
                    <strong>Hauzy B </strong>– Lobocode GmbH (CHE-276.111.199) z
                    siedzibą w Zurychu, pod adresem: Wildbachstrasse 58, 8008
                    Zurych, Szwajcaria.
                  </li>
                  <li>
                    <strong>Klient</strong> – osoba fizyczna, która ukończyła 16
                    lat, osoba prawna lub jednostka organizacyjna nieposiadająca
                    osobowości prawnej, która utworzyła konto w&nbspAplikacji
                    dla Klientów pod nazwą Hauzy, w celu korzystania z Usług
                    oferowanych przez Usługodawców.
                  </li>
                  <li>
                    <strong>Konto Usługodawcy </strong>– część Aplikacji wraz z
                    zespołem rozwiązań informatycznych, pozwalających na
                    korzystanie z Aplikacji zalogowanym użytkownikom.
                  </li>
                  <li>
                    <strong>Okres Bezpłatny</strong> – oznacza 7 (siedem) dni
                    kalendarzowych, podczas których nowozarejestrowany
                    Usługodawca, jedynie pod warunkiem akceptacji Regulaminu
                    oraz innych dokumentów wymaganych przez Hauzy B w danym
                    czasie, może korzystać z Aplikacji bez konieczności
                    wnoszenia Opłaty.
                  </li>
                  <li>
                    <strong>Opłata lub Opłaty</strong> – oznacza łącznie sumę
                    obciążeń należnych od Usługodawcy na rzecz Hauzy B z
                    jakiegokolwiek tytułu należne Hauzy B od Usługodawców w
                    związku z korzystaniem z Aplikacji, przewidziane
                    obowiązującymi cennikami lub regulaminami.
                  </li>
                  <li>
                    <strong>Usługodawca</strong> – osoba fizyczna, osoba prawna
                    lub jednostka organizacyjna nieposiadająca osobowości
                    prawnej, która korzysta z Aplikacji w związku z oferowanymi
                    przez siebie usługami z dojazdem do Klienta oraz utworzyła
                    Konto w Aplikacji dla siebie, w celu świadczenia Usług dla
                    Klientów.
                  </li>
                  <li>
                    <strong>Profil-Wizytówka</strong> – profil stworzony przez
                    Usługodawcę w Aplikacji, widoczny dla Klientów, zawierający
                    między innymi: (i) imię i nazwisko Usługodawcy, (ii) wiek,
                    (iii) zdjęcie, (iv) informacje o oferowanych Usługach
                    Usługodawcy (w tym nazwę Usługi Usługodawcy, jej opis, czas
                    trwania oraz rzeczywistą cenę, (v) ewentualnie - informację
                    o konieczności przedpłaty lub wniesienia kaucji.
                    Profil-Wizytówka zawiera również opinie Klientów dotyczące
                    zrealizowanych Usług Usługodawcy.
                  </li>
                  <li>
                    <strong>Rezerwacja</strong> – dokonanie przez Klienta
                    rezerwacji Usługi Usługodawcy za pomocą Aplikacji dla
                    Klientów, ze wskazaniem wybranej przez Klienta daty oraz
                    godziny Usługi Usługodawcy.{" "}
                  </li>
                  <li>
                    <strong>Regulamin</strong> – niniejszy regulamin.
                  </li>
                  <li>
                    <strong>RODO</strong> – Rozporządzenie Parlamentu
                    Europejskiego i Rady (EU) 2016/679 z dnia 27 kwietnia 2016
                    r. w sprawie ochrony osób fizycznych w związku z
                    przetwarzaniem danych osobowych i w sprawie swobodnego
                    przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                    (ogólne rozporządzenie o ochronie danych) wraz z
                    późniejszymi zmianami.
                  </li>
                  <li>
                    <strong>Strona WWW</strong> – oznacza
                    https://hauzy.app/biznes.{" "}
                  </li>
                  <li>
                    <strong>Usługi Hauzy B </strong>- usługi świadczone przez
                    Hauzy B na rzecz Usługodawcy.{" "}
                  </li>
                  <li>
                    <strong>Usługi Usługodawcy</strong> – wszelkie usługi
                    mobilne z dojazdem do klienta pod wskazany adres oferowane
                    przez Usługodawców za pomocą Aplikacji dla Klienta.{" "}
                  </li>
                </ol>

                <p></p>
                <h2>
                  <strong>II. Postanowienia wstępne. Dane kontaktowe</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Regulamin określa zasady i warunki świadczenia usług drogą
                    elektroniczną przez Hauzy B za pośrednictwem Aplikacji, w
                    szczególności zasady i warunki korzystania przez
                    Usługodawców z Aplikacji.
                  </li>
                  <li>
                    (<strong>Dane Kontaktowe</strong>) Z Hauzy B można
                    kontaktować się:
                    <ol>
                      <li>
                        pocztą elektroniczną na adres:{" "}
                        <a href="mailto:info@hauzy.app">info@hauzy.app</a>;
                      </li>
                      <li>
                        listownie na adres: ul. Ogrodowa 12, 61-821 Poznań.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>III. Rodzaje i zakres Usług Hauzy B </strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Hauzy B udziela Usługodawcom dostępu do Aplikacji,
                    umożliwiając nawiązanie bezpośredniego kontaktu pomiędzy
                    Klientem (korzystającym z Aplikacji dla Klientów) a
                    Usługodawcą. Za pośrednictwem Aplikacji, Usługodawcy mogą
                    reklamować, promować oraz oferować Usługi Usługodawcy w
                    Internecie oraz bezpośrednio Klientom. Aplikacja dostępna
                    jest w sieci Internet poprzez Stronę WWW, można ją również
                    zainstalować na urządzeniu mobilnym posiadającym dostęp do
                    Internetu.
                  </li>
                  <li>
                    W skład Usług Hauzy B wchodzą m.in.:
                    <ol>
                      <li>umożliwienie dostępu do Aplikacji; </li>
                      <li>
                        umożliwienie utworzenia Konta Usługodawcy i
                        Profilu-Wizytówki;{" "}
                      </li>
                      <li>
                        umożliwienie korzystania z kalendarza rezerwacji wizyt,
                        w tym zarządzanie Rezerwacjami oraz Kontakt z Klientem;{" "}
                      </li>
                      <li>
                        umożliwienie otrzymywania i wysyłania powiadomień SMS
                        oraz e-mail / push;{" "}
                      </li>
                      <li>
                        umożliwienie Klientom dostępu do Aplikacji dla Klienta
                        oraz widgetu na profilu Usługodawcy zamieszczonego na
                        Facebooku i widgetu na stronie internetowej Usługodawcy,
                        umożliwiających dokonanie Rezerwacji on-line;
                      </li>
                    </ol>
                  </li>
                  <li>
                    Uskugodawca w ramach Aplikacji zakłada Konto Usługodawcy
                    oraz Profil-Wizytówkę, w których podaje swoje dane, w tym
                    dane osobowe. Dane niezbędne do świadczenia przez Hauzy B
                    Usług Hauzy B w ramach Umowy to dane niezbędne do założenia
                    Konta i Profilu-Wizytówki. Dodatkowe informacje, takie jak
                    zdjęcia portfolio wykonanych Usług Usługodawcy, mogą być
                    dodane przez Usługodawcy w celu rozbudowania Konta i
                    Profilu-Wizytówki.
                  </li>
                  <li>
                    Dostępne dla Usługodawcy funkcjonalności Systemu, w tym
                    sposoby / kanały rezerwacji Usług Usługodawcy, jak również
                    sam wygląd Aplikacji, zależą od aktualnej dostępności danych
                    rozwiązań i funkcjonalności oraz mogą ulegać zmianie ze
                    względów operacyjno-technicznych. Powyższe stosuje się
                    odpowiednio również do funkcjonalności oraz wyglądu
                    Aplikacji dla Klientów.
                  </li>
                </ol>

                <h2>
                  <strong>
                    IV. Szczegółowe warunki świadczenia usług w ramach Aplikacji
                  </strong>
                </h2>

                <ol>
                  <li>
                    Założenie Konta Usługodawcy w Aplikacji przez Usługodawcę
                    jest równoznaczne z:{" "}
                    <ol>
                      <li>rozpoczęciem Okresu Bezpłatnego;</li>
                      <li>
                        przyjęciem do wiadomości i akceptacją postanowień
                        Regulaminu oraz wszelkich innych dokumentów
                        udostępnionych w danym czasie przez Hauzy B;
                      </li>
                      <li>
                        złożeniem oświadczenia, że wszelkie dane udostępnione
                        Hauzy B przez Usługodawcę są aktualne i zgodne z prawdą;
                      </li>
                      <li>
                        upoważnieniem Hauzy B do przetwarzania danych osobowych
                        Usługodawcy zapisanych na Koncie Usługodawcy w celu
                        świadczenia usług w ramach Aplikacji oraz w celach
                        diagnostycznych i statystycznych.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Wyrażając zgodę na otrzymywanie na podany numer telefonu
                    oraz adres e-mail komunikatów systemowych związanych z
                    korzystaniem z Aplikacji, Usługodawca wyraża zgodę:
                    <ol>
                      <li>
                        na to, że Klienci będą kontaktować się z nim
                        bezpośrednio na podany przez Usługodawcę adres e-mail
                        lub numer telefonu oraz przez czat w Aplikacji;
                      </li>
                      <li>
                        na to, że podane przez niego dane, w tym dane osobowe
                        oraz informacje, w tym zdjęcia udostępnione przez niego
                        w Aplikacji oraz Profilu-Wizytówce będą widoczne dla
                        innych Usługodawców oraz Klientów, jak również dla
                        wszystkich użytkowników sieci Internet;
                      </li>
                      <li>
                        na wysyłanie przez Hauzy B wiadomości w Aplikacji, w tym
                        z powiadomieniami o zmianach w Aplikacji, w
                        szczególności o przerwach technicznych w działaniu
                        Aplikacji, zmianach w Regulaminie, nowych treściach
                        publikowanych w Aplikacji, nowych komunikatach i
                        zdarzeniach w Aplikacji – za pośrednictwem komunikatów
                        bezpośrednio w Aplikacji oraz na adres e-mail wskazany w
                        formularzu rejestracyjnym.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Wszelkie dane i informacje udostępniane są przez Usługodawcę
                    dobrowolnie.
                  </li>
                  <li>
                    Tworzenie wspólnego Profilu-Wizytówki dla więcej niż jednego
                    Usługodawcy jest zabronione.{" "}
                  </li>
                  <li>
                    Usługodawca zobowiązany jest informować Hauzy B o
                    rzeczywistej ilości użytkowników korzystających z Konta
                    Usługodawcy. W przypadku braku przekazania Hauzy B przez
                    Usługodawcę informacji, o których mowa w zdaniu poprzednim,
                    Hauzy B uprawniona jest do usunięcia użytkowników z Konta
                    Usługodawcy, o których Hauzy B nie została poinformowana.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>
                    V. Działalność Usługodawcy. Treści Usługodawcy{" "}
                  </strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Usługodawca zobowiązuje się, że:
                    <ol>
                      <li>
                        wprowadzając dane do Aplikacji, Konta Profilu-Wizytówki
                        oraz mediów społecznościowych Facebook i Instagram,
                        będzie do tego uprawniony i swoim działaniem nie będzie
                        naruszać praw osób trzecich;
                      </li>
                      <li>
                        wszelkie informacje i dane udostępnione przez niego w
                        Aplikacji, Koncie, Profilu-Wizytówce oraz mediach
                        społecznościowych Facebook i Instagram będą prawdziwe.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Usługodawca może zamieścić na swoim Profilu-Wizytówce
                    należące do niego treści, w szczególności takie jak zdjęcia,
                    filmy, grafiki, logotypy, opisy i teksty („
                    <strong>Treści Usługodawcy</strong>”). Z chwilą
                    zamieszczenia przez Usługodawcę jakichkolwiek Treści
                    Usługodawcy w Aplikacji, Usługodawca udziela Hauzy B
                    niewyłącznej, nieodpłatnej, nieograniczonej terytorialnie
                    oraz nieograniczonej w czasie licencji („Licencja na rzecz
                    Hauzy B”), na korzystanie z Treści Usługodawcy
                    zamieszczonych w Aplikacji zgodnie z Regulaminem, na
                    następujących, znanych w chwili udzielenia Licencji na rzecz
                    Hauzy B, polach eksploatacji:
                    <ol>
                      <li>
                        w zakresie utrwalania i zwielokrotniania Treści
                        Usługodawcy – wytwarzanie określoną techniką egzemplarzy
                        tych treści, w tym techniką cyfrową, zapisu
                        magnetycznego oraz techniką drukarską i reprograficzną;{" "}
                      </li>
                      <li>
                        w zakresie obrotu oryginałem albo egzemplarzami, na
                        których utrwalono Treści Usługodawcy – wprowadzanie do
                        obrotu oryginału albo egzemplarzy utworu;{" "}
                      </li>
                      <li>
                        w zakresie rozpowszechniania Treści Usługodawcy w sposób
                        inny niż określony pod literą b) powyżej – publiczne
                        wykonanie, wystawienie, wyświetlenie, odtworzenie oraz
                        nadawanie i reemitowanie, a także publiczne
                        udostępnianie tych treści w taki sposób, aby każdy mógł
                        mieć do niego dostęp w miejscu i w czasie przez siebie
                        wybranym, w tym udostępnianie tych treści w sieci
                        Internet, w szczególności w Aplikacji;{" "}
                      </li>
                    </ol>
                    przy czym Usługodawca zobowiązuje się do tego, że zgodne z
                    zakresem wyżej opisanej Licencji na rzecz Hauzy B
                    korzystanie z Treści Usługodawcy tą Licencją na rzecz Hauzy
                    B objętych, nie będzie naruszać niczyich praw autorskich, w
                    tym osobistych praw autorskich, w szczególności w zakresie
                    prawa do oznaczenia utworu imieniem i nazwiskiem autora.
                  </li>
                  <li>
                    Zakres Licencji na rzecz Hauzy B wskazany w ustępie 2
                    powyżej, obejmuje prawo Hauzy B do:
                    <ol>
                      <li>
                        korzystania z Treści Usługodawcy oraz treści
                        zamieszczonych w Aplikacji przez osoby trzecie, w celu
                        świadczenia usług, w celach informacyjnych oraz promocji
                        lub reklamy Usługodawcy, Aplikacji lub Aplikacji dla
                        Klienta – także po rezygnacji Usługodawcy z korzystania
                        z Usług Hauzy B, po usunięciu Konta Usługodawcy oraz po
                        usunięciu z Aplikacji Treści Usługodawcy;
                      </li>
                      <li>
                        korzystania z Treści Usługodawcy udostępnionych w
                        Aplikacji bez ograniczeń terytorialnych;
                      </li>
                      <li>
                        udzielania dalszych licencji na korzystanie z Treści
                        Usługodawcy w granicach posiadanej Licencji na rzecz
                        Hauzy B.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Rezygnacja Usługodawcy z korzystania z Usług Hauzy B lub
                    usunięcie z Aplikacji Treści Usługodawcy lub Konta
                    Usługodawcy nie ma wpływu na obowiązywanie ww. Licencji na
                    rzecz Hauzy B.{" "}
                  </li>
                  <li>
                    Usługodawca może samodzielnie zamieścić w ramach Aplikacji,
                    w tym na swoim Profilu-Wizytówce, należący do niego logotyp
                    / firmę (nazwę), które stanowią znak towarowy objęty prawem
                    ochronnym („Logo”). Usługodawca jest świadomy tego, że z
                    chwilą umieszczenia Logo udziela Hauzy B zgody na używanie
                    Logo w celu świadczenia usług, w celach informacyjnych oraz
                    promocji lub reklamy Usługodawcy, Aplikacji lub Aplikacji
                    dla Klienta, w szczególności w sieci Internet, bez
                    ograniczeń czasowych i terytorialnych. Usługodawca
                    zobowiązuje się nie wycofywać takiej zgody, a w przypadku
                    jej wycofania z naruszeniem powyższego zobowiązania, Hauzy B
                    nie jest zobowiązane do usunięcia Logo rozpowszechnionego
                    przed dniem wycofania zgody.
                  </li>
                  <li>
                    Jeżeli Usługodawca zamieścił w Aplikacji lub mediach
                    społecznościowych Facebook i Instagram treść zawierającą
                    wizerunek osoby trzeciej, Usługodawca oświadcza i
                    gwarantuje, że posiada upoważnienie tej osoby do
                    wykorzystania jej wizerunku, a zamieszczenie treści w
                    Aplikacji, udzielenie Licencji na rzecz Hauzy B w zakresie
                    wskazanym w ustępie 2-3 i ust. 10 oraz korzystanie przez
                    Hauzy B z Treści zgodnie z warunkami Licencji na rzecz Hauzy
                    B, w żaden sposób nie naruszy praw tej osoby.
                  </li>
                  <li>
                    Usługodawca ponosi pełną odpowiedzialność odszkodowawczą
                    wobec Hauzy B za wszelkie szkody, jakie Hauzy B poniesie, w
                    związku z zamieszczeniem w Aplikacji Treści Usługodawcy oraz
                    korzystaniem przez Hauzy B z tych Treści na warunkach
                    Licencji na rzecz Hauzy B oraz korzystaniem z treści
                    zamieszczanych w mediach społecznościowych Facebook i
                    Instagram na warunkach określonych w ust. 10 poniżej. W
                    przypadku skierowania jakichkolwiek roszczeń przeciwko Hauzy
                    B przez jakiekolwiek osoby trzecie, Usługodawca zwolni Hauzy
                    B z wszelkiej odpowiedzialności i pokryje wszelkie
                    uzasadnione koszty z tym związane.
                  </li>
                  <li>
                    Usługodawca odpowiada jak za własne działanie lub
                    zaniechanie za działania i zaniechania osób, z których
                    pomocą korzysta z Aplikacji..{" "}
                  </li>
                  <li>
                    Ponadto, Usługodawca zobowiązuje się:
                    <ol>
                      <li>
                        umożliwiać Hauzy B przeprowadzanie weryfikacji treści
                        umieszczonych w Aplikacji oraz Kontach Usługodawcy i
                        Profilach-Wizytówkach pod względem ich autentyczności
                        oraz zgodności z Regulaminem, w szczególności poprzez
                        podanie aktualnego numeru telefonu i udzielenie
                        dodatkowych wyjaśnień;
                      </li>
                      <li>
                        nie wykorzystywać jakichkolwiek treści stanowiących
                        własność Hauzy B w celach innych niż prawidłowe
                        korzystanie z Aplikacji;
                      </li>
                      <li>
                        nie wykorzystywać jakichkolwiek treści innych
                        Usługodawców lub Klientów bez ich wyraźnej zgody;
                      </li>
                      <li>
                        nie prowadzić, w tym za pośrednictwem Aplikacji, żadnej
                        działalności ani nie umieszczać w Aplikacji, w swoich
                        profilach w mediach społecznościowych - Facebook i
                        Instagram, Koncie Usługodawcy lub Profilu-Wizytówce
                        żadnych treści (w tym informacji, zdjęć, tekstów oraz
                        innych): (i) naruszających prawo, sprzecznych z dobrymi
                        obyczajami lub zasadami współżycia społecznego (w tym:
                        pornograficznych, sugerujących seksualny charakter
                        usług, zmierzających do promowania danej usługi przez
                        treści o seksualnym, dwuznacznym lub nieetycznym
                        charakterze), (ii) związanych z narkotykami,
                        dopalaczami, alkoholem lub innymi używkami, a także
                        organizacją gier hazardowych, (iii) naruszających prawa
                        oraz dobra osobiste osób trzecich, (iv) naruszających
                        zasady uczciwej konkurencji, (v) naruszających
                        postanowienia Regulaminu, (vi) związanych z lub
                        nawiązujących do tantry oraz praktyk do niej podobnych
                        lub (vii) godzących w dobre imię, renomę lub reputację
                        Hauzy B (odpowiednio „
                        <strong>Działalność Niedozwolona</strong>” oraz „
                        <strong>Treści Niedozwolone</strong>”);
                      </li>
                      <li>
                        korzystać z Aplikacji tylko w sposób zgodny z jej
                        przeznaczeniem;
                      </li>
                      <li>
                        nie kopiować, nie modyfikować, nie rozpowszechniać oraz
                        nie reprodukować całości lub części Aplikacji;
                      </li>
                      <li>
                        nie oferować ani nie prowadzić reklamy usług lub
                        produktów, których sprzedaż lub reklama jest zakazana
                        lub podlega ograniczeniom zgodnie z obowiązującymi
                        przepisami prawa, oraz których oferowanie lub obrót jest
                        zabroniony zgodnie z obowiązującymi przepisami – w
                        szczególności w Aplikacji zabroniona jest reklama
                        alkoholu, wyrobów tytoniowych, produktów leczniczych,
                        środków odurzających oraz podobnych;
                      </li>
                      <li>
                        nie udzielać osobom trzecim dostępu do Konta Usługodawcy
                        w Aplikacj – za poufność i bezpieczeństwo swojego Konta,
                        w tym zachowanie w tajemnicy haseł i loginu,
                        odpowiedzialny jest wyłącznie Usługodawca;
                      </li>
                      <li>
                        niezwłocznie informować Hauzy B o każdym bezprawnym
                        wykorzystaniu Konta Usługodawcy przez osoby trzecie;
                      </li>
                      <li>
                        niezwłocznie informować Hauzy B, jeżeli osoby trzecie
                        będą dochodzić swoich roszczeń w związku z naruszeniem
                        prawa przez Usługodawcy za pośrednictwem Aplikacji:{" "}
                      </li>
                      <li>
                        nie umieszczać na swoim Profilu-Wizytówce lub
                        profilach-wizytówkach innych Usługodawców, opinii o
                        samym sobie lub świadczonych przez siebie usługach.
                      </li>
                    </ol>
                  </li>
                </ol>

                <h2>
                  <strong>
                    VI. Rezerwacja, jej potwierdzenie oraz anulowanie
                  </strong>
                </h2>

                <ol>
                  <li>
                    Rezerwacja następuje za pośrednictwem Aplikacji dla Klienta.
                    Usługodawca potwierdza żądanie Rezerwacji złożone przez
                    Klienta, automatycznie lub manualnie, za pomocą Aplikacji.
                    Do Rezerwacji pomiędzy Usługodawcą a Klientem dochodzi z
                    momentem jej potwierdzenia przez Usługodawcę.
                  </li>
                  <li>
                    Domyślnie w Aplikacji ustawiony jest tryb automatycznego
                    potwierdzania żądania Rezerwacji. W ustawieniach
                    Profilu-Wizytówki Usługodawca może dokonać zmiany ustawienia
                    i przejścia na model manualnego potwierdzanie Rezerwacji.
                    Zmieniając tryb potwierdzenia z automatycznego na manualny,
                    Usługodawca zobowiązuje się dokonywać potwierdzenia żądania
                    Rezerwacji w ciągu maksymalnie 12 godzin od otrzymania
                    żądania Rezerwacji. Niedotrzymanie tego terminu przez
                    Usługodawcy skutkuje oznaczeniem Rezerwacji jako
                    niepotwierdzonej. W takim przypadku Usługa Usługodawcy nie
                    musi zostać zrealizowana, a Klient może bez konsekwencji ją
                    anulować. Usługodawca ponosi wyłączną odpowiedzialność za
                    realizację Usług Usługodawcy na rzecz Klientów po dokonaniu
                    Rezerwacji, przy czym zastosowanie mają powszechnie
                    obowiązujące przepisy prawa.{" "}
                  </li>
                  <li>
                    Warunki, na jakich dokonywana jest Rezerwacja, mogą być
                    określane przez Usługodawcy w informacjach (regulaminach
                    itp.) udostępnianych Klientowi w Aplikacji dla Klienta przed
                    dokonaniem Rezerwacji. Treść tych informacji oraz przepisy
                    powszechnie obowiązujące, w szczególności przepisy o prawach
                    konsumentów, określają czy, jakiego typu i jakiej treści
                    umowa zawierana jest między Usługodawcą a Klientem w
                    momencie potwierdzenia żądania Rezerwacji.
                  </li>
                  <li>
                    Aplikacja dla Klienta umożliwia anulowanie przez Klienta
                    Rezerwacji, nawet po jej uprzednim potwierdzeniu przez
                    Usługodawcę. Skutki anulowania Rezerwacji określone są w
                    Warunkach Rezerwacji oraz przepisach powszechnie
                    obowiązujących, w szczególności przepisach o prawach
                    konsumenta.
                  </li>
                  <li>
                    Hauzy B zastrzega sobie prawo do weryfikacji statusu
                    Rezerwacji pod względem zgodności ze stanem faktycznym.
                    Weryfikacji podlegają w szczególności Rezerwacje ze
                    zmienioną lub nierynkową ceną Usługi Usługodawcy oraz ze
                    zmienionym statusem Rezerwacji.
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VII. Opłaty i fakturowanie</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Z tytułu korzystania z Usług Hauzy B, Usługodawca
                    zobowiązany jest do zapłaty na rzecz Hauzy B wszelkich Opłat
                    w wysokości określonej w Cenniku lub innych obowiązujących
                    dokumentach (np. regulaminach lub cennikach promocji lub
                    pakietów). Zapłata Opłat nastąpi na rachunek bankowy Hauzy B
                    wskazany na fakturze VAT, wystawionej przez Hauzy B lub
                    poprzez automatyczne pobieranie Opłat z karty płatniczej
                    Usługodawcy.
                  </li>
                  <li>
                    Usługodawca oświadcza, że akceptując niniejszy Regulamin,
                    wyraża również zgodę na wystawianie przez Hauzy B faktur
                    elektronicznych zgodnie z obowiązującymi przepisami prawa
                    oraz przesyłanie ich w formie elektronicznej, na adres
                    e-mail wskazany przez Usługodawcę Hauzy B.{" "}
                  </li>
                  <li>
                    Do obowiązków Usługodawcy należy podanie aktualnego adresu
                    e-mail do wysyłki faktur, dodanie Hauzy B do zaufanych
                    kontaktów, tak by e-mail z fakturą nie został odfiltrowany
                    jako spam oraz bezzwłoczne powiadamianie Hauzy B o zmianie
                    adresu e-mail do wysyłki faktur, bez wezwania ze strony
                    Hauzy B.{" "}
                  </li>
                  <li>
                    Hauzy B zobowiązuje się przesyłać faktury drogą
                    elektroniczną w formacie PDF, w sposób zapewniający
                    autentyczność pochodzenia, integralność treści i czytelność
                    wystawianych faktur od momentu wystawienia do czasu upływu
                    terminu przedawnienia zobowiązania podatkowego, zgodnie z
                    wymogami określonymi w art. 106m ustawy o podatku od towarów
                    i usług z dnia 11 marca 2004 r., w okresie obowiązywania
                    zgody, o której mowa wyżej. Duplikaty i korekty faktur do
                    faktur elektronicznych będą wystawiane i przesyłane drogą
                    elektroniczną (e-mail) w formacie PDF. Faktury elektroniczne
                    uznaje się za doręczone z chwilą wprowadzenia ich do środka
                    komunikacji elektronicznej w taki sposób, aby Usługodawca
                    mógł zapoznać się z ich treścią.
                  </li>
                  <li>
                    W razie cofnięcia przez Usługodawcy zgody, o której mowa
                    wyżej, Hauzy B traci prawo do wystawiania faktur
                    elektronicznych następnego dnia roboczego po dniu, w którym
                    Hauzy B otrzymała zawiadomienie Usługodawcy o cofnięciu
                    zgody. W przypadku cofnięcia zgody na wysyłanie faktur drogą
                    elektroniczną, Hauzy B będzie przesyłać faktury pocztą
                    tradycyjną na adres Usługodawcy, za dodatkową opłatą 50 PLN
                    netto miesięcznie. Wraz z cofnięciem zgody na wysyłanie
                    faktur drogą elektroniczną, Usługodawca akceptuje naliczenie
                    dodatkowej opłaty.{" "}
                  </li>
                  <li>
                    Zasady i terminy wystawiania faktur VAT oraz terminy
                    płatności faktur VAT zostały określone odpowiednio w
                    Cenniku.
                  </li>
                  <li>
                    Brak zapłaty Opłaty lub jej jakiejkolwiek części w
                    oznaczonym terminie, spowoduje zawieszenie wszelkich Usług
                    Hauzy B i zablokowanie Konta Usługodawcy. Wznowienie Usług
                    Hauzy B i odblokowanie Konta Usługodawcy nastąpi w terminie
                    12 godzin w najbliższym dniu roboczym (poniedziałek -
                    piątek, w godz. 8.00 - 16.00, z wyjątkiem sobót oraz dni
                    ustawowo wolnych od pracy), po doręczeniu Hauzy B przez
                    Usługodawcę drogą mailową dowodu uregulowania całej
                    należności lub po zaksięgowaniu należności na koncie Hauzy
                    B.
                  </li>
                  <li>
                    W sytuacji braku możliwości pobrania Opłaty z karty
                    płatniczej, odblokowanie Konta Usługodawcy następuje, gdy
                    Usługodawca uzupełni środki na karcie i ponowi płatność
                    przez Aplikację. Ewentualnie, Usługodawca może zgłosić się
                    do Hauzy B i poprosić o ponowienie pobrania Opłaty. Konto
                    zostanie odblokowane niezwłocznie po uregulowaniu całości
                    należnej Opłaty.{" "}
                  </li>
                  <li>
                    W razie aktywowania przez Usługodawcy opcji płatności kartą,
                    płatność Klienta dokonywana przy użyciu karty płatniczej
                    będzie pomniejszana o wszelkie aktualnie obowiązujące opłaty
                    dostawców usług płatniczych oraz instytucji płatniczych, a
                    także o prowizję, o której mowa poniżej. Faktura za pobrane
                    opłaty i prowizje zostanie wystawiona i wysłana po
                    zakończonym okresie rozliczeniowym.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VIII. Uprawnienia i odpowiedzialność Hauzy B</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Hauzy B nigdy nie jest stroną umów zawieranych pomiędzy
                    Usługodawcą a Klientem, w szczególności w związku z
                    Rezerwacją dokonaną przez Klienta. Tym samym, Hauzy B nie
                    ponosi odpowiedzialności za działania lub zaniechania
                    Klienta, w tym dokonanie przez Klienta płatności za Usługę
                    Usługodawcy lub jakiejkolwiek innej płatności należnej
                    Usługodawcy od Klienta. Usługodawca zobowiązany jest do
                    świadczenia Usług Usługodawcy zgodnie z obowiązującymi
                    przepisami prawa oraz jest wyłącznie odpowiedzialny względem
                    Klienta za ich ewentualne naruszenia. W szczególności,
                    Usługodawca zobowiązuje się do przekazania Klientowi
                    informacji wymaganych prawem i w sposób wymagany prawem.{" "}
                  </li>
                  <li>
                    Hauzy B jest uprawnione do przeprowadzenia działań
                    weryfikacyjnych, mających na celu kontrolę treści
                    umieszczonych w Aplikacji oraz Kontach i Profilu-Wizytówce
                    pod względem ich autentyczności oraz zgodności z
                    Regulaminem.{" "}
                  </li>
                  <li>
                    W przypadku:
                    <ol>
                      <li>
                        otrzymania przez Hauzy B urzędowego zawiadomienia,
                        innego rodzaju informacji lub powzięcia w innym trybie
                        przypuszczenia co do prowadzenia przez Usługodawcy
                        Działalności Niedozwolonej lub publikacji Treści
                        Niedozwolonych;{" "}
                      </li>
                      <li>
                        naruszenia przez Usługodawcy zasad Umowy, w tym
                        postanowień Regulaminu lub działania w sposób
                        zmierzający do ich obejścia, w tym manipulacji ceną
                        Usługi Usługodawcy lub statusem Rezerwacji;{" "}
                      </li>
                      <li>
                        obraźliwego zachowania względem Klientów, innych
                        Usługodawców, pracowników i współpracowników Hauzy B (w
                        tym poprzez groźby czy używanie wulgaryzmów i tym
                        podobne);{" "}
                      </li>
                    </ol>
                    Hauzy B ma prawo do podjęcia jednej lub więcej z
                    następujących czynności zaradczych (wedle wyłącznego uznania
                    Hauzy B) (“<strong>Czynności Zaradcze</strong>”).{" "}
                  </li>
                  <li>
                    Czynności zaradcze obejmują następujące możliwości:{" "}
                    <ol>
                      <li>
                        wezwania Usługodawcę do niezwłocznego usunięcia z
                        Aplikacji / Profilu-Wizytówki Treści Niedozwolonych bądź
                        dokonania aktualizacji treści, pod rygorem odmowy
                        zamieszczenia treści w Aplikacji / Profilu-Wizytówce,
                        zablokowania dostępu do tej treści lub jej usunięcia;
                        lub
                      </li>
                      <li>
                        wezwania Usługodawcy do natychmiastowego zaprzestania
                        naruszania lub obejścia Regulaminu; lub
                      </li>
                      <li>
                        odmowy zamieszczenia treści w Aplikacji /
                        Profilu-Wizytówce, natychmiastowego zablokowania dostępu
                        do zamieszczonej w Aplikacji / Profilu-Wizytówce treści,
                        usunięcia treści z Aplikacji / Profilu-Wizytówki; lub
                      </li>
                      <li>
                        czasowego lub stałego zablokowania danych
                        funkcjonalności lub pakietów, z których korzysta
                        Usługodawca;{" "}
                      </li>
                      <li>
                        zablokowania lub usunięcia Konta Usługodawcy /
                        Profilu-Wizytówki.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Podjęcie przez Hauzy B jakichkolwiek czynności zaradczych
                    nie zwalnia Usługodawcy z obowiązku zapłaty Opłat
                    naliczonych za Usługi Hauzy B do momentu podjęcia Czynności
                    Zaradczej.{" "}
                  </li>
                  <li>
                    W sytuacjach, o których mowa w ustępie 3 powyżej, Hauzy B
                    zawiadomi Usługodawcę za pośrednictwem poczty elektronicznej
                    lub za pośrednictwem Aplikacji o zamiarze zablokowania
                    dostępu do Treści Usługodawcy, usunięcia treści z Aplikacji
                    / Profilu-Wizytówki, zablokowania Konta Usługodawcy. W
                    przypadkach niecierpiących zwłoki, Hauzy B zablokuje dostęp
                    do Treści, usunie treści z Aplikacji / Profilu-Wizytówki lub
                    zablokuje Konto Usługodawcy bez zawiadomienia o takim
                    zamiarze.
                  </li>
                  <li>
                    W przypadku skierowania jakichkolwiek roszczeń do Hauzy B
                    lub też prawnie uzasadnionego żądania udostępnienia danych
                    Usługodawcy przez uprawnione organy lub przez podmiot
                    zamierzający dochodzić roszczeń, Hauzy B jest uprawniona do
                    zapoznania się ze stanem faktycznym oraz do ewentualnego
                    zabezpieczenia i przekazania danych Usługodawcy i innych
                    informacji zamieszczonych w Aplikacji, niezbędnych do
                    realizacji i zabezpieczenia ewentualnych roszczeń lub
                    spełnienia żądań.
                  </li>
                  <li>
                    Łączna odpowiedzialność Hauzy B z tytułu świadczonych za
                    pośrednictwem Aplikacji usług nie obejmuje utraconych
                    korzyści. Hauzy B ponosi odpowiedzialność wobec Usługodawców
                    wyłącznie za szkody poniesione przez Usługodawców na skutek
                    korzystania z Aplikacji i tylko takie, które powstały
                    wyłącznie z winy umyślnej Hauzy B.
                  </li>
                  <li>
                    Hauzy B nie ponosi wobec Usługodawcy odpowiedzialności za:
                    <ol>
                      <li>
                        podjęcia którychkolwiek działań, o których mowa w
                        ustępie 3 i 4 powyżej;{" "}
                      </li>
                      <li>
                        przerwy w funkcjonowaniu Aplikacji powstałe z przyczyn
                        niezależnych od Hauzy B, w szczególności stanowiących
                        siłę wyższą albo wskazanych w ustępie 9 poniżej;
                      </li>
                      <li>
                        funkcjonowanie systemów teleinformatycznych oraz sieci
                        telekomunikacyjnych przy dokonywaniu płatności przez
                        Klientów za pośrednictwem Aplikacji i Aplikacji dla
                        Klienta;
                      </li>
                      <li>
                        problemy lub utrudnienia techniczne po stronie
                        Usługodawcy związane z działaniem sprzętu komputerowego,
                        telekomunikacyjnego lub dostępem do sieci Internet,
                        które utrudniają lub uniemożliwiają korzystanie z
                        Aplikacji lub z usług oferowanych za jej pośrednictwem;
                      </li>
                      <li>
                        autentyczność, rzetelność, poprawność, prawdziwość,
                        kompletność danych i informacji zamieszczonych w
                        Aplikacji przez Usługodawcę oraz przekazywanych przez
                        Usługodawcę za pośrednictwem Aplikacji;
                      </li>
                      <li>
                        szkody spowodowane przez Usługodawcę w związku z
                        funkcjonowaniem Aplikacji lub jego działalnością w niej,
                        w tym związane z naruszeniem Regulaminu, nieuprawnionym
                        wykorzystaniem danych udostępnionych za pośrednictwem
                        Aplikacji, podaniem danych i informacji nieprawdziwych,
                        niekompletnych, nieaktualnych, lub zaniechaniem ich
                        aktualizacji;
                      </li>
                      <li>
                        szkody spowodowane przez Usługodawcę poprzez naruszenie
                        praw osób trzecich, a także szkody spowodowane
                        działaniem osób trzecich;
                      </li>
                      <li>
                        szkody spowodowane przez Usługodawcę w związku z
                        nienależytym wykonaniem bądź niewykonaniem przez nich
                        umów zawartych za pośrednictwem Aplikacji lub Usług;
                      </li>
                      <li>
                        szkody poniesione przez Usługodawcę z powodu działań lub
                        zaniechań Klienta;{" "}
                      </li>
                      <li>
                        treść opinii publikowanych przez Klientów w Aplikacji
                        dla Klientów;
                      </li>
                      <li>
                        skuteczność działań marketingowych realizowanych przez
                        Usługodawców na rzecz Klientów za pośrednictwem
                        Aplikacji.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B ma prawo do czasowego zaprzestania świadczenia usług
                    w przypadku awarii, przeglądów lub modernizacji systemu
                    teleinformatycznego należącego do Hauzy B.
                  </li>
                  <li>
                    Hauzy B, zgodnie z art. 14 Ustawy z dnia 18 lipca 2002 r. o
                    świadczeniu usług drogą elektroniczną:
                    <ol>
                      <li>
                        nie ponosi odpowiedzialności za przechowywane dane,
                        jeśli nie wie o bezprawnym charakterze danych lub
                        związanej z nimi działalności, a w razie otrzymania
                        urzędowego zawiadomienia lub uzyskania wiarygodnej
                        wiadomości o bezprawnym charakterze danych lub związanej
                        z nimi działalności niezwłocznie uniemożliwi dostęp do
                        tych danych;
                      </li>
                      <li>
                        jeśli otrzyma urzędowe zawiadomienie o bezprawnym
                        charakterze przechowywanych danych dostarczonych przez
                        Usługodawcy i uniemożliwi dostęp do tych danych, nie
                        ponosi odpowiedzialności względem Usługodawcy za szkodę
                        powstałą w wyniku uniemożliwienia dostępu do tych
                        danych;
                      </li>
                      <li>
                        jeśli uzyska wiarygodną wiadomość o bezprawnym
                        charakterze przechowywanych danych dostarczonych przez
                        Usługodawcy i uniemożliwi dostęp do tych danych, nie
                        odpowiada względem tego Usługodawcy za szkodę powstałą w
                        wyniku uniemożliwienia dostępu do tych danych, jeżeli
                        niezwłocznie zawiadomi Usługodawcę o zamiarze
                        uniemożliwienia do nich dostępu.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>IX. Reklamacje</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Wszelkie reklamacje związane z nieprawidłowym działaniem
                    Aplikacji, Usługodawca może zgłaszać za pomocą poczty
                    elektronicznej na adres e-mail:{" "}
                    <a href="mailto:info@hauzy.app">info@hauzy.app</a>.
                  </li>
                  <li>
                    Reklamacje będą rozpatrywane w terminie 30 dni od dnia ich
                    otrzymania przez Hauzy B. W przypadkach zawiłych lub gdy
                    rozpoznanie reklamacji nie będzie możliwe w powyższym
                    terminie z przyczyn niezależnych od Hauzy B, Hauzy B
                    zastrzega sobie prawo do wydłużenia terminu na rozpoznanie
                    reklamacji.
                  </li>
                  <li>
                    Hauzy B zastrzega sobie prawo żądania od reklamującego
                    udzielenia informacji lub wyjaśnień w sytuacji, gdy wymaga
                    tego rozpoznanie reklamacji. Wyjaśnienia lub informacje
                    powinny być udzielone przez Usługodawcy niezwłocznie, nie
                    później niż w terminie 30 dni. W przypadku uchybienia temu
                    terminowi, Hauzy B jest uprawniona do pozostawienia
                    reklamacji bez rozpoznania oraz nie ponosi odpowiedzialności
                    z tego tytułu.{" "}
                  </li>
                  <li>
                    Decyzja Hauzy B w przedmiocie reklamacji będzie wysłana na
                    adres e-mail, z którego została zgłoszona Reklamacja.
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>X. Wymagania Techniczne</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    W celu korzystania z Aplikacji konieczne jest posiadanie
                    urządzeń pozwalających na dostęp do Internetu, poczty
                    elektronicznej oraz przeglądarki internetowej. Korzystanie z
                    Usług Hauzy B za pośrednictwem Aplikacji wymaga aktywnego
                    połączenia z Internetem. Korzystanie z Aplikacji na
                    urządzeniu mobilnym wymaga dysponowania sprawnym urządzeniem
                    mobilnym. Usługodawca we własnym zakresie i na własną
                    odpowiedzialność powinien zadbać o spełnienie wymogów
                    technicznych urządzenia mobilnego, jego konfigurację,
                    aktualność oprogramowania oraz dostęp do Internetu.
                  </li>
                  <li>
                    Wymogi techniczne, jakie muszą spełniać urządzenia:{" "}
                    <ol>
                      <li>
                        telefon: system operacyjny Android w wersji nie starszej
                        niż 5.00 lub system operacyjny iOS (Apple) w wersji nie
                        starszej niż 14;{" "}
                      </li>
                      <li>
                        tablet: (dla aplikacji Hauzy B Pro na tablety) system
                        operacyjny Android w wersji nie starszej niż 5.00,
                        minimum 3GB RAM, minimum 7 cali przekątna ekranu. System
                        operacyjny iPadOS (Apple) w wersji nie starszej niż 14.
                        iPad (5 generacja lub nowsza), iPad Air (2 generacja lub
                        nowsza), iPad Pro, iPad Mini (4 generacja lub nowsza);
                      </li>
                      <li>
                        PC / Mac: procesor minimum 1.4 GHz, 4.0GB RAM,
                        rekomendowana przeglądarka Google Chrome, dostęp do
                        sieci Internet.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B posługuje się plikami typu cookies, które
                    umożliwiają gromadzenie informacji związanych z korzystaniem
                    z Aplikacji. Pliki cookies to pliki tekstowe, które
                    zapisywane są na dysku twardym w komputerach osób
                    odwiedzających Aplikację, w celu zapisywania w nich
                    informacji i danych dotyczących korzystania z Aplikacji.
                    Brak włączonej obsługi plików cookies może powodować
                    nieprawidłowości lub utrudnienia w funkcjonowaniu Aplikacji.
                  </li>
                  <li>
                    Większość stosowanych przeglądarek internetowych (np.
                    Firefox, Google Chrome lub Internet Explorer) zawiera opcję
                    odrzucenia polecenia przechowywania plików cookies.
                    Usługodawca może samodzielnie tak ustawić przeglądarkę by
                    móc odrzucać prośby o przechowywanie wszystkich lub
                    wybranych cookies.
                  </li>
                  <li>
                    Do szczególnych zagrożeń związanych z korzystaniem przez
                    Usługodawcy z Aplikacji należy udostępnianie danych do
                    logowania osobom do tego niepowołanym, które mogą w sposób
                    celowy zmienić konfigurację Profilu lub Konta Usługodawcy.
                    Dotyczy to w szczególności istotnych elementów Profilu
                    takich jak rodzaje oferowanych Usług, cenniki, zdjęcia oraz
                    inne informacje o Usługodawcy. W związku z tym, konieczne
                    jest zachowywanie w tajemnicy haseł do systemu, a także
                    odrębne skonfigurowanie dostępu do Aplikacji dla pracowników
                    Usługodawcy.
                  </li>
                </ol>
                <h2>
                  <strong>XI. Dane osobowe. Prywatność</strong>
                </h2>
                <ol>
                  <li>
                    Hauzy B i Usługodawca przetwarzają dane osobowe Klientów we
                    własnych celach, zatem są odrębnymi administratorami ich
                    danych osobowych.
                  </li>
                  <li>
                    W momencie potwierdzenia Rezerwacji, dochodzi do zawarcia
                    umowy między Klientem a Usługodawcą o świadczenie przez
                    Usługodawcę Usług na rzecz Klienta. Ponadto:{" "}
                    <ol>
                      <li>
                        Klient może w Aplikacji dla Klienta udzielić Usługodawcy
                        zgód na przetwarzanie przez niego danych osobowych w
                        innych celach niż w celu wykonania umowy z Klientem oraz
                        zgód na komunikację marketingową;{" "}
                      </li>
                      <li>
                        Hauzy B udostępnia Usługodawcy za pomocą Aplikacji dane
                        osobowe Klienta potrzebne do świadczenia przez niego
                        Usług na rzecz tego Klienta;
                      </li>
                      <li>
                        Usługodawca staje się administratorem danych osobowych
                        takiego Klienta, w związku z czym zobowiązany jest do
                        przestrzegania zasad określonych w RODO i ponosi za to
                        odpowiedzialność wynikającą z przepisów.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Niezależnie, w celu umożliwienia Hauzy B świadczenia na
                    rzecz Usługodawcy usług w ramach Aplikacji, do których
                    niezbędne jest przetwarzanie:
                    <ol>
                      <li>
                        danych osobowych Klientów, którzy dokonali Rezerwacji;{" "}
                      </li>
                      <li>
                        danych osobowych osób, którymi Usługodawca posługuje się
                        korzystając z Aplikacji;{" "}
                      </li>
                      <li>
                        Klientów, którzy wyrazili zgodę na wysyłkę komunikacji
                        marketingowej przez Usługodawcę;{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    Usługodawca jest także administratorem danych osobowych
                    Klienta, z którym zawrze umowę poza Aplikacją. Jeśli
                    wprowadzi te dane do Aplikacji nadal jest ich
                    administratorem, a Hauzy B przetwarza je w imieniu
                    Usługodawcy na podstawie Umowy Powierzenia Przetwarzania
                    Danych Osobowych.
                  </li>
                  <li>
                    Hauzy B przetwarza dane, w tym dane osobowe Usługodawcy
                    jedynie w zakresie umożliwiającym prawidłowe świadczenie
                    Usług Hauzy B i prawidłową realizację Umowy.
                  </li>
                  <li>
                    Hauzy B nie odpowiada za zakres danych osobowych Klientów
                    zbieranych przez Usługodawców i za legalność zbierania tych
                    danych przez Usługodawców. Szczegółowe zobowiązania stron z
                    tym związane zawiera Umowa Powierzenia Przetwarzania Danych
                    Osobowych.
                  </li>
                  <li>
                    Polityka prywatności Hauzy B, w tym polityka plików cookies
                    dostępna na Stronie WWW.{" "}
                  </li>
                </ol>
                <h2>
                  <strong>XII. Postanowienia końcowe</strong>
                </h2>

                <ol>
                  <li>
                    Hauzy B zastrzega sobie prawo do zmiany Regulaminu, w tym
                    jego integralnych części, tj. innych dokumentów
                    wykorzystywanych przez Hauzy B. Nowe brzmienie obowiązuje od
                    dnia jego opublikowania na Stronie WWW lub na Koncie
                    Usługodawcy w Aplikacji. W przypadku ww. zmiany, Hauzy B
                    zawiadomi Usługodawcę o tej zmianie na wskazany w procesie
                    Rejestracji numer telefonu lub adres e-mail Usługodawcę.
                    Usługodawca może nie zaakceptować zmian i w takim wypadku ma
                    prawo usunąć konto.
                  </li>
                  <li>
                    Hauzy B ma prawo do wysyłania na adres e-mail Usługodawcy
                    przekazany Hauzy B wszelkich monitów, wezwań do zapłaty lub
                    zawiadomień dotyczących zalegania przez Usługodawcy z
                    Opłatami na rzecz Hauzy B.{" "}
                  </li>
                  <li>
                    W przypadku gdy którekolwiek postanowienie Regulaminu, a
                    także innych dokumentów zaakceptowanych przez Usługodawcę
                    okaże się być nieważne lub nieskuteczne, nie będzie to w
                    żadnym przypadku skutkować nieważnością Regulaminu. W takiej
                    sytuacji strony dołożą wszelkich starań w celu zastąpienia
                    postanowienia uważanego za nieważne lub nieskuteczne
                    postanowieniem ważnym i skutecznym, jak najbliższym
                    pierwotnej intencji stron.
                  </li>
                  <li>
                    Stosunek pomiędzy Hauzy B a Usługodawcą reguluje niniejszy
                    Regulamin.
                  </li>
                </ol>

                <p>
                  <span>Ostatnia aktualizacja: 15 marca 2023</span>
                </p>
              </div>
            ) : locale === "ru" ? (
              <div
                class="c-tabsVertical_content c-legal_document"
                data-type="terms-of-service"
                data-region="pl"
                data-source=""
              >
                <h2>
                  <strong>И. Определения</strong>
                </h2>
                <ol>
                  <li>
                    <strong>Приложение/система</strong> – программное
                    обеспечение и служба Hauzy B, предназначенные для
                    поставщиков услуг, управляемые Hauzy B и распространяемые
                    под торговым названием <strong>Hauzy B</strong>, доступные в
                    Интернете. в домене{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , через мобильные приложения для iOS и Android. Приложение
                    позволяет установить контакт между Поставщиком услуг и
                    Клиентом для рекламы, продвижения, предложения и
                    бронирования Мобильных услуг.
                  </li>
                  <li>
                    <strong>Приложение для поставщика услуг</strong> —
                    программное обеспечение и служба Hauzy B, предназначенные
                    для поставщика услуг, управляемые Hauzy B и распространяемые
                    под торговым названием «<strong>Hauzy B</strong>», доступны
                    в Интернете в домене{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , через мобильные приложения для iOS и Android и через
                    виджеты, которые можно встроенные в веб-сайты или профили
                    социальных сетей поставщиков услуг. Приложение для
                    Поставщика услуг позволяет Поставщикам услуг управлять
                    бронированием Услуг Поставщика услуг.
                  </li>
                  <li>
                    <strong>Hauzy B</strong>– Lobocode GmbH (CHE-276.111.199) с
                    зарегистрированным офисом в Цюрихе по адресу:
                    Wildbachstrasse 58, 8008 Цюрих, Швейцария.
                  </li>
                  <li>
                    <strong>Клиент</strong> — физическое лицо старше 16 лет,
                    юридическое лицо или организационная единица без
                    юридического лица, создавшее учетную запись в Приложении для
                    клиентов под именем Hauzy, с целью использования Услуги,
                    предлагаемые Поставщиками услуг.
                  </li>
                  <li>
                    <strong>Учетная запись поставщика услуг</strong> — часть
                    Приложения вместе с набором ИТ-решений, которые позволяют
                    вошедшим в систему пользователям использовать Приложение.
                  </li>
                  <li>
                    <strong>Бесплатный период</strong> - означает 7 (семь)
                    календарных дней, в течение которых вновь зарегистрированный
                    Поставщик услуг, только при условии принятия Регламента и
                    других документов, требуемых Hauzy B в данный момент
                    времени, может использовать Приложение без уплаты сбора .
                  </li>
                  <li>
                    <strong>Вознаграждение или Сборы</strong> — общая сумма
                    сборов, причитающихся с Поставщика услуг Hauzy B по любой
                    причине, причитающихся Hauzy B с Поставщиков услуг в связи с
                    использованием Приложения, предусмотренных для в применимых
                    прайс-листах или правилах.
                  </li>
                  <li>
                    <strong>Поставщик услуг</strong> — физическое лицо,
                    юридическое лицо или организационная единица без статуса
                    юридического лица, которое использует Приложение в связи с
                    предлагаемыми им услугами с доступом к Клиенту и создало
                    Учетную запись в Приложении для себя, чтобы обеспечить
                    обслуживание клиентов.
                  </li>
                  <li>
                    <strong>Профиль визитной карточки</strong> — профиль,
                    созданный Поставщиком услуг в Приложении, видимый Клиентам,
                    содержащий, среди прочего: (i) имя и фамилию Поставщика
                    услуг, (ii) возраст, (iii) фото, (iv) информация о
                    предлагаемых Услугах Исполнителя (в том числе наименование
                    Услуги Исполнителя, ее описание, продолжительность и
                    актуальная стоимость, (v) возможно - информация о
                    необходимости внесения предоплаты или внесения залога.
                    Профиль-Визитка также содержит мнения клиентов относительно
                    Услуг, предоставляемых Поставщиком услуг.
                  </li>
                  <li>
                    <strong>Бронирование</strong> - бронирование Заказчиком
                    Услуги Исполнителя с использованием Приложения для
                    Заказчиков с указанием даты и времени оказания Услуги
                    Исполнителя, выбранного Заказчиком.{" "}
                  </li>
                  <li>
                    <strong>Правила</strong> — настоящие правила.
                  </li>
                  <li>
                    <strong>RODO</strong> — Регламент (ЕС) 2016/679 Европейского
                    парламента и Совета от 27 апреля 2016 г. о защите физических
                    лиц в отношении обработки персональных данных и о свободном
                    перемещении такие данные, а также отмену Директивы 95/46/EC
                    (Общее положение о защите данных) с поправками.
                  </li>
                  <li>
                    <strong>Веб-сайт</strong> означает https://hauzy.app/biznes.{" "}
                  </li>
                  <li>
                    <strong>Услуги Hauzy B</strong> — услуги, предоставляемые
                    Hauzy B Поставщику услуг.{" "}
                  </li>
                  <li>
                    <strong>Услуги поставщика услуг</strong> — все мобильные
                    услуги с доступом к клиенту по указанному адресу,
                    предлагаемые поставщиками услуг с использованием приложения
                    для клиента.{" "}
                  </li>
                </ol>

                <p></p>
                <h2>
                  <strong>
                    II. Предварительные положения. Контактная информация
                  </strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Регламент определяет условия предоставления электронных
                    услуг Hauzy B через Приложение, в частности условия
                    использования Приложения Поставщиками услуг.
                  </li>
                  <li>
                    (<strong>Контактная информация</strong>) С Хаузой Б можно
                    связаться:
                    <ol>
                      <li>
                        по электронной почте:{" "}
                        <a href="mailto:info@hauzy.app">info@hauzy.app</a>;
                      </li>
                      <li>
                        почтой по адресу: ул. Огродова 12, 61-821 Познань.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>III. Виды и объем услуг Hauzy B </strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Hauzy B предоставляет Поставщикам услуг доступ к Приложению,
                    обеспечивая прямой контакт между Клиентом (используя
                    Приложение для Клиентов) и Поставщиком услуг. Через
                    Приложение Поставщики услуг могут рекламировать, продвигать
                    и предлагать Услуги Поставщика услуг в Интернете и
                    непосредственно Клиентам. Приложение доступно в сети
                    Интернет через Сайт, его также можно установить на мобильное
                    устройство с доступом в Интернет.
                  </li>
                  <li>
                    Службы Hauzy B включают, среди прочего:{" "}
                    <ol>
                      <li>предоставление доступа к Приложению; </li>
                      <li>
                        возможность создания учетной записи поставщика услуг и
                        профиля визитной карточки;{" "}
                      </li>
                      <li>
                        включение использования календаря бронирования встреч,
                        включая управление бронированием и связь с клиентом;{" "}
                      </li>
                      <li>
                        позволяя вам получать и отправлять SMS и
                        e-mail/push-уведомления;{" "}
                      </li>
                      <li>
                        предоставление Клиентам доступа к Приложению для Клиента
                        и виджету в профиле Поставщика услуг на Facebook, а
                        также к виджету на веб-сайте Поставщика услуг, что
                        позволяет им осуществлять онлайн-бронирование;
                      </li>
                    </ol>
                  </li>
                  <li>
                    В рамках Приложения Услугодатель создает Учетную запись
                    Услугодателя и Профиль-визитку, в которых предоставляет свои
                    данные, в том числе персональные данные. Данные, необходимые
                    Hauzy B для предоставления Услуг Hauzy B в соответствии с
                    Соглашением, — это данные, необходимые для создания Учетной
                    записи и Профиля-визитной карточки. Дополнительная
                    информация, такая как фотографии портфолио Услуг Поставщика
                    услуг, может быть добавлена Поставщиком услуг для расширения
                    Профиля учетной записи и визитной карточки.
                  </li>
                  <li>
                    Функциональные возможности Системы, доступные Исполнителю, в
                    том числе способы/каналы бронирования Услуг Исполнителя, а
                    также внешний вид самого Приложения, зависят от текущей
                    доступности данных решений и функциональных возможностей и
                    могут меняться в зависимости от операционных и технических
                    причины. Вышеупомянутое также относится соответственно к
                    функциональности и внешнему виду Приложения для клиентов.
                  </li>
                </ol>
                <h2>
                  <strong>
                    IV. Подробные условия оказания услуг в рамках Заявки
                  </strong>
                </h2>

                <ol>
                  <li>
                    Настройка учетной записи поставщика услуг в приложении
                    поставщиком услуг равносильна:{" "}
                    <ol>
                      <li>начало бесплатного периода;</li>
                      <li>
                        подтверждение и принятие положений Правил и любых других
                        документов, предоставленных Hauzy B в данный момент
                        времени;
                      </li>
                      <li>
                        заявление о том, что все данные, предоставленные Hauzy B
                        Поставщиком услуг, являются актуальными и достоверными;
                      </li>
                      <li>
                        разрешение Hauzy B обрабатывать персональные данные
                        Поставщика услуг, сохраненные в Учетной записи
                        Поставщика услуг, для предоставления услуг в рамках
                        Приложения, а также для диагностики и статистических
                        целей.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Соглашаясь получать системные сообщения, связанные с
                    использованием Приложения, на предоставленный номер телефона
                    и адрес электронной почты, Поставщик услуг соглашается:
                    <ol>
                      <li>
                        что Клиенты свяжутся с ним напрямую по адресу
                        электронной почты или номеру телефона, предоставленному
                        Поставщиком услуг, и через чат в Приложении;
                      </li>
                      <li>
                        что предоставленные им данные, включая личные данные и
                        информацию, включая фотографии, предоставленные им в
                        Приложении и Профиле-визитке, будут видны другим
                        Поставщикам услуг и Клиентам, а также всем пользователям
                        Интернета;
                      </li>
                      <li>
                        для Hauzy B отправлять сообщения в Приложении, в том
                        числе уведомления об изменениях в Приложении, в
                        частности о технических перерывах в работе Приложения,
                        изменениях в Регламенте, новом контенте, опубликованном
                        в Приложении, новых сообщениях и событиях в Заявке -
                        посредством сообщений непосредственно в Заявке и на
                        адрес электронной почты, указанный в регистрационной
                        форме.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Все данные и информация предоставляются Поставщиком услуг на
                    добровольной основе.
                  </li>
                  <li>
                    Создание общего профиля визитной карточки для более чем
                    одного поставщика услуг запрещено.{" "}
                  </li>
                  <li>
                    Поставщик услуг обязан информировать Hauzy B о фактическом
                    количестве пользователей, использующих Учетную запись
                    Поставщика услуг. Если Поставщик услуг не предоставит Hauzy
                    B информацию, указанную в предыдущем предложении, Hauzy B
                    имеет право удалить пользователей из Учетной записи
                    Поставщика услуг, о которых Hauzy B не был проинформирован.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>
                    В. Деятельность Поставщика услуг. Контент поставщика услуг{" "}
                  </strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Поставщик услуг обязуется:
                    <ol>
                      <li>
                        внося данные в Приложение, Профиль-Визитку и социальные
                        сети Facebook и Instagram, он будет уполномочен на это и
                        не будет нарушать своими действиями права третьих лиц;
                      </li>
                      <li>
                        вся информация и данные, предоставленные им в
                        Приложении, Аккаунте, Профиле-визитке и социальных сетях
                        Facebook и Instagram, будут верны.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Поставщик услуг может размещать принадлежащий ему контент в
                    своем профиле-визитке, в частности, такие как фотографии,
                    видео, графика, логотипы, описания и тексты («
                    <strong>Контент поставщика услуг</strong>»). . При
                    размещении Поставщиком услуг любого Контента Поставщика
                    услуг в Приложении Поставщик услуг предоставляет Hauzy B
                    неисключительную, бесплатную, неограниченную территорию и
                    неограниченную во времени лицензию («Лицензия для Hauzy B»)
                    на использование Сервиса. Контент Провайдера, размещенный в
                    Приложении в соответствии с Регламентом, на следующих
                    известных на момент предоставления Лицензии Хаузе Б сферах
                    использования:
                    <ol>
                      <li>
                        в рамках записи и тиражирования Контента Исполнителя -
                        изготовление копий этого контента с использованием
                        определенной техники, включая цифровую технику,
                        магнитную запись, а также печатно-репрографическую
                        технику;{" "}
                      </li>
                      <li>
                        в рамках торговли оригиналом или копиями, на которых был
                        записан Контент Поставщика услуг, - маркетинг оригинала
                        или копий произведения;{" "}
                      </li>
                      <li>
                        в рамках распространения Контента Поставщика услуг
                        способом, отличным от указанного в подпункте b) выше, -
                        публичное исполнение, показ, показ, воспроизведение,
                        трансляция и ретрансляция, а также доведение этого
                        контента до всеобщего сведения в таком способ, которым
                        каждый может иметь доступ к нему в выбранном вами месте
                        и времени, в том числе делиться этим контентом в
                        Интернете, в частности, в Приложении;{" "}
                      </li>
                    </ol>
                    , однако, Поставщик услуг обязуется, что в соответствии с
                    объемом вышеописанной Лицензии для Hauzy B использование
                    Контента Поставщика услуг, подпадающего под действие
                    настоящей Лицензии для Hauzy B, не будет нарушать чьи-либо
                    авторские права, в том числе моральные прав, в частности в
                    части права маркировать произведение именем автора.
                  </li>
                  <li>
                    Объем Лицензии для Hauzy B, указанный в разделе 2 выше,
                    включает право Hauzy B на:
                    <ol>
                      <li>
                        использование Контента Поставщика услуг и контента,
                        размещенного в Приложении, третьими лицами для оказания
                        услуг, в информационных целях и для продвижения или
                        рекламы Поставщика услуг, Приложения или Приложения для
                        Заказчика - также после Поставщика услуг отказывается от
                        использования Услуг Hauzy B после удаления Поставщика
                        услуг учетной записи и после удаления Контента
                        Поставщика услуг из Приложения;
                      </li>
                      <li>
                        использовать Контент Поставщика услуг, доступный в
                        Приложении, без территориальных ограничений;
                      </li>
                      <li>
                        предоставление дальнейших лицензий на использование
                        Контента Поставщика услуг в рамках Лицензии,
                        принадлежащей Хаузе Б.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Поставщик услуг обязуется:
                    <ol>
                      <li>
                        внося данные в Приложение, Профиль-Визитку и социальные
                        сети Facebook и Instagram, он будет уполномочен на это и
                        не будет нарушать своими действиями права третьих лиц;
                      </li>
                      <li>
                        вся информация и данные, предоставленные им в
                        Приложении, Аккаунте, Профиле-визитке и социальных сетях
                        Facebook и Instagram, будут верны.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Поставщик услуг может размещать принадлежащий ему контент в
                    своем профиле-визитке, в частности, такие как фотографии,
                    видео, графика, логотипы, описания и тексты («
                    <strong>Контент поставщика услуг</strong>»). . При
                    размещении Поставщиком услуг любого Контента Поставщика
                    услуг в Приложении Поставщик услуг предоставляет Hauzy B
                    неисключительную, бесплатную, неограниченную территорию и
                    неограниченную во времени лицензию («Лицензия для Hauzy B»)
                    на использование Сервиса. Контент Провайдера, размещенный в
                    Приложении в соответствии с Регламентом, на следующих
                    известных на момент предоставления Лицензии Хаузе Б сферах
                    использования:
                    <ol>
                      <li>
                        в рамках записи и тиражирования Контента Исполнителя -
                        изготовление копий этого контента с использованием
                        определенной техники, включая цифровую технику,
                        магнитную запись, а также печатно-репрографическую
                        технику;{" "}
                      </li>
                      <li>
                        в рамках торговли оригиналом или копиями, на которых был
                        записан Контент Поставщика услуг, - маркетинг оригинала
                        или копий произведения;{" "}
                      </li>
                      <li>
                        в рамках распространения Контента Поставщика услуг
                        способом, отличным от указанного в подпункте b) выше, -
                        публичное исполнение, показ, показ, воспроизведение,
                        трансляция и ретрансляция, а также доведение этого
                        контента до всеобщего сведения в таком способ, которым
                        каждый может иметь доступ к нему в выбранном вами месте
                        и времени, в том числе делиться этим контентом в
                        Интернете, в частности, в Приложении;{" "}
                      </li>
                    </ol>
                    , однако, Поставщик услуг обязуется, что в соответствии с
                    объемом вышеописанной Лицензии для Hauzy B использование
                    Контента Поставщика услуг, подпадающего под действие
                    настоящей Лицензии для Hauzy B, не будет нарушать чьи-либо
                    авторские права, в том числе моральные прав, в частности в
                    части права маркировать произведение именем автора.
                  </li>
                  <li>
                    Объем Лицензии для Hauzy B, указанный в разделе 2 выше,
                    включает право Hauzy B на:
                    <ol>
                      <li>
                        использование Контента Поставщика услуг и контента,
                        размещенного в Приложении, третьими лицами для оказания
                        услуг, в информационных целях и для продвижения или
                        рекламы Поставщика услуг, Приложения или Приложения для
                        Заказчика - также после Поставщика услуг отказывается от
                        использования Услуг Hauzy B после удаления Поставщика
                        услуг учетной записи и после удаления Контента
                        Поставщика услуг из Приложения;
                      </li>
                      <li>
                        использовать Контент Поставщика услуг, доступный в
                        Приложении, без территориальных ограничений;
                      </li>
                      <li>
                        предоставление дальнейших лицензий на использование
                        Контента Поставщика услуг в рамках Лицензии,
                        принадлежащей Хаузе Б.
                      </li>
                    </ol>
                  </li>
                  <li>
                    разрешить Hauzy B проверять контент, размещенный в
                    Приложении, Учетных записях и Профилях визитных карточек
                    Поставщика услуг, с точки зрения их подлинности и
                    соответствия Регламенту, в частности, путем предоставления
                    действующего номера телефона и предоставления дополнительных
                    пояснений;
                    <ol>
                      <li>
                        не использовать любой контент, принадлежащий Hauzy B,
                        для целей, отличных от надлежащего использования
                        Приложения;
                      </li>
                      <li>
                        не использовать какой-либо контент других поставщиков
                        услуг или клиентов без их явного согласия;
                      </li>
                      <li>
                        не проводить, в том числе через Приложение, какую-либо
                        деятельность и не размещать какой-либо контент в
                        Приложении, в своих профилях в социальных сетях -
                        Facebook и Instagram, Аккаунте Поставщика услуг или
                        Профиле-визитке (включая информацию, фотографии, тексты
                        и прочее): (i) нарушающие закон, противоречащие
                        принципам приличия или принципам общежития (в том числе:
                        порнографические, намекающие на сексуальный характер
                        услуг, направленные на продвижение данной услуги
                        посредством содержания сексуального, двусмысленного или
                        неэтичного характера), (ii) связанные к наркотикам,
                        легальному алкоголю или другим стимуляторам, а также к
                        организации азартных игр, (iii) нарушению прав и личных
                        прав третьих лиц, (iv) нарушению принципов честной
                        конкуренции, (v) нарушению положений Правила, (vi)
                        относящиеся к тантре и практикам или относящиеся к ним,
                        или (vii) наносящие ущерб доброму имени, репутации или
                        репутации Hauzy B («
                        <strong>Запрещенная деятельность</strong>» и «
                        <strong>Запрещенный контент </strong>", соответственно);
                      </li>
                      <li>
                        использовать Приложение только в соответствии с его
                        назначением;
                      </li>
                      <li>
                        не копировать, не изменять, не распространять и не
                        воспроизводить Приложение полностью или частично;
                      </li>
                      <li>
                        не предлагать и не рекламировать услуги или продукты,
                        продажа или реклама которых запрещены или на которые
                        распространяются ограничения в соответствии с применимым
                        законодательством, а также предложение или торговля
                        которыми запрещены в соответствии с применимыми нормами,
                        в частности, реклама алкоголя, табака, лекарственных
                        средств , наркотики и аналогичные товары;
                      </li>
                      <li>
                        не предоставлять третьим лицам доступ к Учетной записи
                        Исполнителя в Приложении - только Поставщик услуг несет
                        ответственность за конфиденциальность и безопасность
                        своей Учетной записи, в том числе за сохранение в тайне
                        паролей и логина;
                      </li>
                      <li>
                        немедленно информировать Hauzy B о любом незаконном
                        использовании учетной записи Поставщика услуг третьими
                        лицами;
                      </li>
                      <li>
                        немедленно информировать Hauzy B, если третьи лица
                        предъявляют претензии в связи с нарушением закона
                        Поставщиком услуг через Приложение:{" "}
                      </li>
                      <li>
                        не публиковать в своем профиле визитной карточки или
                        профилях визитных карточек других поставщиков услуг
                        мнения о себе или предоставляемых ими услугах.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VI. Бронирование, подтверждение и отмена</strong>
                </h2>
                <ol>
                  <li>
                    Бронирование осуществляется через Приложение для Заказчика.
                    Поставщик услуг автоматически или вручную подтверждает
                    запрос на Бронирование, сделанный Заказчиком, с помощью
                    Приложения. Бронирование между Поставщиком услуг и
                    Заказчиком происходит в момент его подтверждения Поставщиком
                    услуг.
                  </li>
                  <li>
                    По умолчанию в Приложении установлен режим автоматического
                    подтверждения заявки на Бронирование. В настройках
                    Профиля-Визитки Исполнитель может изменить настройку и
                    перейти на модель ручного подтверждения Бронирования.
                    Изменяя режим подтверждения с автоматического на ручной,
                    Поставщик услуг обязуется подтвердить запрос на Бронирование
                    в течение максимум 12 часов с момента получения запроса на
                    Бронирование. Несоблюдение Поставщиком услуг этого срока
                    приводит к тому, что Бронирование помечается как
                    неподтвержденное. В этом случае Услугу Исполнителя выполнять
                    не нужно, и Заказчик может отказаться от нее без
                    последствий. Поставщик услуг несет единоличную
                    ответственность за предоставление Услуг Поставщика услуг
                    Клиентам после совершения Бронирования в соответствии с
                    применимыми общеприменимыми положениями законодательства.{" "}
                  </li>
                  <li>
                    Условия, на которых осуществляется Бронирование, могут быть
                    указаны Поставщиком услуг в информации (положениях и т. д.),
                    предоставленной Заказчику в Заявке Заказчика до
                    осуществления Бронирования. Содержание этой информации и
                    общеприменимые положения, в частности положения о правах
                    потребителей, определяют, заключается ли договор между
                    Поставщиком услуг и Клиентом в момент подтверждения запроса
                    на бронирование, а также какой тип и содержание.
                  </li>
                  <li>
                    Заявка для Заказчика позволяет Заказчику отменить
                    Бронирование даже после его предварительного подтверждения
                    Поставщиком услуг. Последствия отмены Бронирования изложены
                    в Условиях бронирования и общеприменимых правилах, в
                    частности в положениях о правах потребителей.
                  </li>
                  <li>
                    Hauzy B оставляет за собой право проверять статус
                    Бронирования с точки зрения соответствия фактам. В
                    частности, проверке подлежат Бронирования с измененной или
                    нерыночной стоимостью Услуги Исполнителя и с измененным
                    статусом Бронирования.
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VII. Сборы и выставление счетов</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    За использование Услуг Hauzy B Поставщик услуг обязан
                    уплатить Hauzy B все Сборы в размере, указанном в
                    Прейскуранте или других применимых документах (например,
                    правилах или прейскурантах для рекламных акций или пакетов).
                    Комиссионные оплачиваются на банковский счет Hauzy B,
                    указанный в счете-фактуре, выставленном Hauzy B, или путем
                    автоматического списания Комиссий с платежной карты
                    Поставщика услуг.
                  </li>
                  <li>
                    Поставщик услуг заявляет, что, принимая настоящие Правила,
                    он также дает согласие на выставление Hauzy B электронных
                    счетов в соответствии с применимым законодательством и
                    отправку их в электронной форме на адрес электронной почты,
                    указанный Поставщиком услуг Hauzy B.{" "}
                  </li>
                  <li>
                    Обязательства Поставщика услуг включают предоставление
                    действующего адреса электронной почты для отправки счетов,
                    добавление Hauzy B в доверенные контакты, чтобы электронная
                    почта со счетом не отфильтровывалась как спам, и немедленное
                    уведомление Hauzy B об изменении электронной почты.
                    -почтовый адрес для отправки счетов без вызова Хаузы Б.{" "}
                  </li>
                  <li>
                    Hauzy B обязуется отправлять счета-фактуры в электронном
                    виде в формате PDF таким образом, чтобы обеспечить
                    подлинность происхождения, целостность содержания и
                    удобочитаемость выставленных счетов-фактур с момента
                    выставления до истечения срока давности налоговых
                    обязательств в соответствии с требования, изложенные в ст.
                    106м Закона о налоге на товары и услуги от 11 марта 2004 г.,
                    в течение срока действия согласия, указанного выше.
                    Дубликаты и исправления счетов-фактур к электронным
                    счетам-фактурам будут выдаваться и отправляться в
                    электронном виде (по электронной почте) в формате PDF.
                    Электронные счета считаются доставленными, когда они
                    вводятся в средства электронной связи таким образом, что
                    Поставщик услуг может прочитать их содержание.
                  </li>
                  <li>
                    Если Поставщик услуг отзывает упомянутое выше согласие,
                    Hauzy B теряет право выставлять электронные счета на
                    следующий рабочий день после дня, когда Hauzy B получил
                    уведомление от Поставщика услуг об отзыве согласия. В случае
                    отзыва согласия на отправку счетов-фактур в электронном виде
                    Hauzy B будет отправлять счета-фактуры традиционной почтой
                    на адрес Поставщика услуг за дополнительную плату в размере
                    50 злотых нетто в месяц. При отзыве согласия на отправку
                    счетов-фактур в электронном виде Поставщик услуг принимает
                    взимание дополнительной платы.{" "}
                  </li>
                  <li>
                    Правила и сроки выставления счетов-фактур, а также сроки
                    оплаты счетов-фактур указаны в Прейскуранте.
                  </li>
                  <li>
                    Неуплата Вознаграждения или любой его части в течение
                    указанного периода приведет к приостановке всех Услуг Hauzy
                    B и блокировке Учетной записи Поставщика услуг.
                    Возобновление оказания Услуг Hauzy B и разблокировка Учетной
                    записи Поставщика услуг произойдет в течение 12 часов
                    следующего рабочего дня (понедельник - пятница, 8.00 -
                    16.00, кроме субботы и праздничных дней), после доставки
                    Hauzy B Поставщиком услуг по электронной почте. - по почте с
                    доказательством оплаты всей причитающейся суммы или после
                    того, как сумма будет зачислена на счет Hauzy B.
                  </li>
                  <li>
                    В случае невозможности взыскания Комиссии с платежной карты
                    Учетная запись Поставщика услуг разблокируется, когда
                    Поставщик услуг пополняет средства на карте и повторяет
                    платеж через Приложение. В качестве альтернативы Поставщик
                    услуг может обратиться в Hauzy B и потребовать повторного
                    взыскания Платы. Учетная запись будет разблокирована сразу
                    же после оплаты всей причитающейся Комиссии.{" "}
                  </li>
                  <li>
                    Если Поставщик услуг активирует возможность оплаты картой,
                    платеж Клиента, осуществленный с использованием платежной
                    карты, будет уменьшен на все действующие в настоящее время
                    сборы поставщиков платежных услуг и платежных учреждений, а
                    также комиссию, указанную ниже. Счет на собранные сборы и
                    комиссии будет выставлен и отправлен после окончания
                    расчетного периода.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>VIII. Полномочия и ответственность Hauzy B</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Hauzy B никогда не является стороной договоров, заключенных
                    между Поставщиком услуг и Клиентом, в частности, в связи с
                    Бронированием, сделанным Клиентом. Таким образом, Hauzy B не
                    несет ответственности за действия или бездействие Заказчика,
                    включая оплату Заказчиком Услуг Поставщика услуг или любой
                    другой платеж, причитающийся Поставщику услуг от Заказчика.
                    Исполнитель обязан предоставлять Услуги Исполнителя в
                    соответствии с действующим законодательством и несет
                    единоличную ответственность перед Заказчиком за любые его
                    нарушения. В частности, Поставщик услуг обязуется
                    предоставлять Заказчику информацию, требуемую
                    законодательством, и в порядке, предусмотренном
                    законодательством.{" "}
                  </li>
                  <li>
                    Hauzy B имеет право проводить проверки, направленные на
                    проверку контента, размещенного в Приложении, Учетных
                    записях и Профиле визитной карточки, на предмет их
                    подлинности и соответствия Регламенту.{" "}
                  </li>
                  <li>
                    Для:
                    <ol>
                      <li>
                        Hauzy B получает официальное уведомление, другую
                        информацию или делает другое предположение о Запрещенной
                        деятельности Поставщика услуг или публикации
                        Запрещенного контента;{" "}
                      </li>
                      <li>
                        нарушение Исполнителем условий Соглашения, в том числе
                        положений Регламента, или действия, направленные на их
                        обход, включая манипулирование ценой на Услугу
                        Исполнителя или статусом Бронирования;{" "}
                      </li>
                      <li>
                        оскорбительное поведение по отношению к Клиентам, другим
                        Поставщикам услуг, сотрудникам и партнерам Hauzy B (в
                        том числе посредством угроз или использования
                        ненормативной лексики и т.п.);{" "}
                      </li>
                    </ol>
                    Hauzy B имеет право использовать одно или несколько из
                    следующих средств правовой защиты (по собственному
                    усмотрению Hauzy B) («
                    <strong>средства правовой защиты</strong>»).{" "}
                  </li>
                  <li>
                    К исправительным действиям относятся следующие:{" "}
                    <ol>
                      <li>
                        просить Поставщика услуг немедленно удалить Запрещенный
                        контент из Приложения/Профиля-визитки или обновить
                        контент, под страхом отказа от размещения контента в
                        Приложении/Профиле-визитке, блокировки доступа к этому
                        контенту или удаления это; или
                      </li>
                      <li>
                        просить Поставщика услуг немедленно прекратить нарушение
                        или обход Правил; или
                      </li>
                      <li>
                        отказаться от размещения контента в
                        Приложении/Профиле-Визитке, немедленно заблокировать
                        доступ к контенту, размещенному в
                        Приложении/Профиле-Визитке, удалить контент из
                        Приложения/Профиля-Визитки; или
                      </li>
                      <li>
                        временная или постоянная блокировка функций или пакетов,
                        используемых Поставщиком услуг;{" "}
                      </li>
                      <li>
                        блокировка или удаление учетной записи/профиля визитной
                        карточки поставщика услуг.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Выполнение каких-либо мер по исправлению положения со
                    стороны Hauzy B не освобождает Поставщика услуг от
                    обязательства по уплате Сборов, взимаемых за Услуги Hauzy B,
                    до тех пор, пока не будут приняты меры по исправлению
                    положения.{" "}
                  </li>
                  <li>
                    В ситуациях, указанных в разделе 3 выше, Hauzy B уведомит
                    Поставщика услуг по электронной почте или через Приложение о
                    своем намерении заблокировать доступ к Контенту Поставщика
                    услуг, удалить контент из Приложения / Профиля-визитки. ,
                    заблокировать Учетную запись Поставщика услуг. В экстренных
                    случаях Hauzy B заблокирует доступ к Контенту, удалит
                    контент из Приложения/Профиля-Визитки или заблокирует
                    Учетную запись Поставщика услуг без уведомления о таком
                    намерении.
                  </li>
                  <li>
                    В случае предъявления каких-либо претензий к Hauzy B или
                    юридически обоснованного запроса на предоставление данных
                    Исполнителя со стороны уполномоченных органов или лица,
                    намеревающегося предъявлять претензии, Hauzy B вправе
                    ознакомиться с фактами и, возможно, защищать и передавать
                    данные Поставщика услуг и другую информацию, содержащуюся в
                    Приложении, необходимую для реализации и защиты любых
                    претензий или запросов.
                  </li>
                  <li>
                    Общая ответственность Hauzy B за услуги, предоставляемые
                    через Приложение, не включает упущенную выгоду. Hauzy B
                    несет ответственность перед Поставщиками услуг только за
                    ущерб, понесенный Поставщиками услуг в результате
                    использования Приложения, и только за ущерб, который был
                    вызван исключительно умышленными неправомерными действиями
                    Hauzy B.
                  </li>
                  <li>
                    Hauzy B не несет ответственности перед Поставщиком услуг за:
                    <ol>
                      <li>
                        предпринять любые действия, указанные в пунктах 3 и 4
                        выше;{" "}
                      </li>
                      <li>
                        перебои в работе Приложения, возникающие по причинам, не
                        зависящим от Hauzy B, в частности, представляющим собой
                        форс-мажорные обстоятельства или указанные в разделе 9
                        ниже;
                      </li>
                      <li>
                        функционирование систем ИКТ и телекоммуникационных сетей
                        при осуществлении платежей Клиентами через Приложение и
                        Приложение для Клиента;
                      </li>
                      <li>
                        проблемы или технические трудности со стороны Поставщика
                        услуг, связанные с работой компьютерного оборудования,
                        телекоммуникаций или доступом в Интернет, которые
                        затрудняют или делают невозможным использование
                        Приложения или услуг, предлагаемых через него;
                      </li>
                      <li>
                        подлинность, надежность, правильность, достоверность,
                        полнота данных и информации, включенных в Приложение
                        Поставщиком услуг и предоставленных Поставщиком услуг
                        через Приложение;
                      </li>
                      <li>
                        ущерб, причиненный Исполнителем в связи с
                        функционированием Приложения или его деятельностью в
                        нем, в том числе связанный с нарушением Регламента,
                        несанкционированным использованием данных, доступных
                        через Приложение, предоставлением недостоверных,
                        неполных, устаревших данных и информацию или
                        невозможность их обновления;
                      </li>
                      <li>
                        ущерб, причиненный Исполнителем путем нарушения прав
                        третьих лиц, а также ущерб, причиненный действиями
                        третьих лиц;
                      </li>
                      <li>
                        ущерб, причиненный Исполнителем в связи с ненадлежащим
                        исполнением или неисполнением ими договоров, заключенных
                        посредством Приложения или Сервисов;
                      </li>
                      <li>
                        ущерб, понесенный Поставщиком услуг в результате
                        действий или бездействия Клиента;{" "}
                      </li>
                      <li>
                        содержание мнений, опубликованных Клиентами в Клиентском
                        приложении;
                      </li>
                      <li>
                        эффективность маркетинговой деятельности, проводимой
                        Поставщиками услуг для Клиентов через Приложение.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B имеет право временно прекратить предоставление услуг
                    в случае отказа, проверки или модернизации ИКТ-системы,
                    принадлежащей Hauzy B.
                  </li>
                  <li>
                    Хаузи Б, в соответствии со ст. 14 Закона от 18 июля 2002 г.
                    об оказании электронных услуг:{" "}
                    <ol>
                      <li>
                        не несет ответственности за хранимые данные, если ему не
                        известно о противоправном характере данных или действий,
                        связанных с ними, а также в случае получения
                        официального уведомления или получения достоверной
                        информации о незаконном характере данных или действий,
                        связанных с ними. им это немедленно предотвратит доступ
                        к этим данным;
                      </li>
                      <li>
                        если он получит официальное уведомление о незаконном
                        характере хранимых данных, предоставленных Поставщиком
                        услуг, и предотвратит доступ к этим данным, он не будет
                        нести ответственности перед Поставщиком услуг за ущерб,
                        возникший в результате предотвращения доступа к этим
                        данным;
                      </li>
                      <li>
                        если он получит достоверную информацию о незаконном
                        характере хранимых данных, предоставленных Поставщиком
                        услуг, и предотвратит доступ к этим данным, он не несет
                        ответственности перед Поставщиком услуг за ущерб,
                        возникший в результате предотвращения доступа к этим
                        данным, если он немедленно уведомит Поставщику услуг о
                        его намерении воспрепятствовать доступу к нему.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h2>
                  <strong>IX. Жалобы</strong>
                </h2>

                <ol>
                  <li>
                    Любые жалобы, связанные с некорректной работой Приложения,
                    могут быть отправлены Поставщиком услуг по электронной почте
                    на следующий адрес электронной почты:{" "}
                    <a href="mailto:info@hauzy.app">info@hauzy. приложение</a>.
                  </li>
                  <li>
                    Жалобы будут рассмотрены в течение 30 дней с даты их
                    получения Hauzy B. В сложных случаях или когда жалоба не
                    будет рассмотрена в указанный выше срок по причинам, не
                    зависящим от Hauzy B, Hauzy B оставляет за собой право
                    продлить срок рассмотрения жалобы.{" "}
                  </li>
                  <li>
                    Hauzy B оставляет за собой право запросить информацию или
                    объяснения у рекламодателя в ситуации, когда это необходимо
                    для признания жалобы. Объяснения или информация должны быть
                    предоставлены Поставщиком услуг немедленно, не позднее, чем
                    в течение 30 дней. В случае несоблюдения этого срока Hauzy B
                    имеет право оставить жалобу без рассмотрения и не несет
                    ответственности в связи с этим.{" "}
                  </li>
                  <li>
                    Решение Hauzy B по жалобе будет отправлено на адрес
                    электронной почты, с которого была подана жалоба.
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>Х. Технические требования</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Чтобы использовать Приложение, необходимо иметь устройства,
                    обеспечивающие доступ к Интернету, электронной почте и
                    веб-браузеру. Для использования Сервисов Hauzy B через
                    Приложение требуется активное подключение к Интернету. Для
                    использования Приложения на мобильном устройстве требуется
                    работающее мобильное устройство. Поставщик услуг
                    самостоятельно и под свою ответственность должен обеспечить
                    выполнение технических требований к мобильному устройству,
                    его конфигурации, обновлению программного обеспечения и
                    доступу в Интернет.
                  </li>
                  <li>
                    Технические требования, которым должны соответствовать
                    устройства:{" "}
                    <ol>
                      <li>
                        телефон: операционная система Android не старше 5.00 или
                        операционная система iOS (Apple) не старше 14;{" "}
                      </li>
                      <li>
                        планшет: (для приложения Hauzy B Pro для планшетов)
                        версия операционной системы Android не старше 5.00,
                        оперативная память не менее 3 ГБ, диагональ экрана не
                        менее 7 дюймов. Операционная система iPadOS (Apple) не
                        старше версии 14. iPad (5-го поколения или новее), iPad
                        Air (2-го поколения или новее), iPad Pro, iPad Mini
                        (4-го поколения или новее);
                      </li>
                      <li>
                        ПК/Mac: процессор с тактовой частотой не менее 1,4 ГГц,
                        4 ГБ ОЗУ, рекомендуемый браузер Google Chrome, доступ в
                        Интернет.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B использует файлы cookie, позволяющие собирать
                    информацию, связанную с использованием Приложения. Файлы
                    cookie — это текстовые файлы, которые сохраняются на жестком
                    диске людей, посещающих Приложение, для хранения информации
                    и данных об использовании Приложения. Отсутствие включенных
                    файлов cookie может вызвать сбои или трудности в работе
                    Приложения.
                  </li>
                  <li>
                    Большинство используемых веб-браузеров (например, Firefox,
                    Google Chrome или Internet Explorer) позволяют отклонить
                    команду на сохранение файлов cookie. Поставщик услуг может
                    самостоятельно настроить браузер таким образом, чтобы он мог
                    отклонять запросы на сохранение всех или выбранных файлов
                    cookie.
                  </li>
                  <li>
                    Особые риски, связанные с использованием Приложения
                    Поставщиком услуг, включают предоставление данных для входа
                    неуполномоченным лицам, которые могут преднамеренно изменить
                    конфигурацию Профиля или Учетной записи Поставщика услуг.
                    Это относится, в частности, к важным элементам Профиля,
                    таким как типы предлагаемых Услуг, прайс-листы, фотографии и
                    другая информация о Поставщике услуг. Поэтому необходимо
                    держать в секрете пароли к системе, а также отдельно
                    настроить доступ к Приложению для сотрудников Исполнителя.
                  </li>
                </ol>
                <h2>
                  <strong>XI. Личные данные. Конфиденциальность</strong>
                </h2>

                <ol>
                  <li>
                    Hauzy B и Поставщик услуг обрабатывают личные данные
                    Клиентов в своих целях, поэтому они являются отдельными
                    администраторами своих личных данных.
                  </li>
                  <li>
                    Во время подтверждения Бронирования между Заказчиком и
                    Поставщиком услуг заключается договор об оказании Услуг
                    Заказчику Поставщиком услуг. Также:{" "}
                    <ol>
                      <li>
                        Клиент может в Заявке для Заказчика дать Поставщику
                        услуг согласие на обработку персональных данных в целях,
                        отличных от исполнения договора с Заказчиком, и согласие
                        на маркетинговую коммуникацию;{" "}
                      </li>
                      <li>
                        Hauzy B предоставляет Поставщику услуг использование
                        Приложения персональных данных Клиента, необходимых для
                        предоставления Услуг этому Клиенту;
                      </li>
                      <li>
                        Поставщик услуг становится администратором таких
                        персональных данных Клиента и, следовательно, обязан
                        соблюдать правила, изложенные в GDPR, и несет за это
                        ответственность, вытекающую из правил.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Самостоятельно, чтобы Hauzy Б могла предоставлять услуги
                    Поставщику услуг в рамках Приложения, для которого
                    необходима обработка:
                    <ol>
                      <li>
                        личные данные Клиентов, осуществивших Бронирование;{" "}
                      </li>
                      <li>
                        персональные данные лиц, используемые Исполнителем с
                        помощью Приложения;{" "}
                      </li>
                      <li>
                        Клиенты, давшие согласие на отправку маркетинговых
                        сообщений Поставщиком услуг;{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    Поставщик услуг также является администратором персональных
                    данных Клиента, с которым он заключает договор вне
                    Приложения. Если он вводит эти данные в Приложение, он
                    по-прежнему является их администратором, а Hauzy B
                    обрабатывает их от имени Поставщика услуг в соответствии с
                    Соглашением об обработке персональных данных.
                  </li>
                  <li>
                    Hauzy B обрабатывает данные, включая персональные данные
                    Поставщика услуг, только в той мере, в какой это
                    обеспечивает надлежащее предоставление Услуг Hauzy B и
                    надлежащее выполнение Соглашения.
                  </li>
                  <li>
                    Hauzy B не несет ответственности за объем персональных
                    данных Клиентов, собираемых Поставщиками услуг, и за
                    законность сбора таких данных Поставщиками услуг. Подробные
                    обязательства сторон, связанные с этим, включены в
                    Соглашение об обработке персональных данных.
                  </li>
                  <li>
                    Политика конфиденциальности Hauzy B, включая политику
                    использования файлов cookie, доступную на Веб-сайте.{" "}
                  </li>
                </ol>
                <p></p>
                <h2>
                  <strong>XII. Заключительные положения</strong>
                </h2>
                <p></p>
                <ol>
                  <li>
                    Хаузи Б оставляет за собой право изменять Регламент, в том
                    числе его неотъемлемые части, т.е. другие документы,
                    используемые Хаузи Б. Новая редакция вступает в силу с даты
                    ее публикации на Сайте или в Аккаунте Поставщика услуг в
                    Приложении. В случае вышеизложенного изменений, Hauzy B
                    уведомит Поставщика услуг об этом изменении по номеру
                    телефона или адресу электронной почты Поставщика услуг,
                    указанному в процессе Регистрации. Поставщик услуг может не
                    принять изменения и в этом случае имеет право удалить
                    учетную запись.
                  </li>
                  <li>
                    Hauzy B имеет право отправлять на адрес электронной почты
                    Поставщика услуг, предоставленный Hauzy B, любые
                    напоминания, требования об оплате или уведомления о
                    задолженности Поставщика услуг по оплате сборов для Hauzy B.{" "}
                  </li>
                  <li>
                    Если какое-либо положение Регламента, а также иных
                    документов, принятых Исполнителем, окажется недействительным
                    или недействительным, это ни в коем случае не влечет
                    недействительности Регламента. В такой ситуации стороны
                    приложат все усилия, чтобы заменить положение, признанное
                    недействительным или недействительным, действительным и
                    эффективным положением, максимально близким к
                    первоначальному намерению сторон.
                  </li>
                  <li>
                    Отношения между Hauzy B и Поставщиком услуг регулируются
                    настоящими Правилами.
                  </li>
                </ol>

                <p>
                  <span>Последнее обновление: 15 марта 2023 г.</span>
                </p>
              </div>
            ) : locale === "uk" ? (
              <div
                class="c-tabsVertical_content c-legal_document"
                data-type="terms-of-service"
                data-region="pl"
                data-source=""
              >

                <h2>
                  <strong>I. Definicje</strong>
                </h2>

                <ol>
                  <li>
                    <strong>Програма/система</strong> – програмне забезпечення
                    та сервіс Hauzy B, призначені для постачальників послуг,
                    якими керує Hauzy B і розповсюджуються під комерційною
                    назвою «<strong>Hauzy B</strong>», доступні в Інтернеті в
                    домені{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , через мобільні програми для програмного забезпечення iOS
                    та Android. Додаток дозволяє встановити контакт між
                    Постачальником послуг і Клієнтом з метою реклами,
                    просування, пропозиції та бронювання Мобільних послуг.
                  </li>
                  <li>
                    <strong>Заявка для Постачальника послуг</strong> – доступне
                    програмне забезпечення та служба Hauzy B, призначена для
                    Постачальника послуг, якою керує Hauzy B і розповсюджується
                    під комерційною назвою «<strong>Hauzy B</strong>». в
                    Інтернеті в домені{" "}
                    <a href="https://hauzy.app/biznes">
                      https://hauzy.app/biznes
                    </a>
                    , через мобільні додатки для програмного забезпечення iOS і
                    Android і через віджети, які можна вбудовані на веб-сайтах
                    або в профілях постачальників послуг у соціальних мережах.
                    Програма для Постачальника послуг дозволяє Постачальникам
                    послуг керувати резервуванням Послуг Постачальника послуг.
                  </li>
                  <li>
                    <strong>Hauzy B</strong>– Lobocode GmbH (CHE-276.111.199) із
                    зареєстрованим офісом у Цюріху, за адресою: Wildbachstrasse
                    58, 8008 Zurich, Швейцарія.
                  </li>
                  <li>
                    <strong>Клієнт</strong> - фізична особа віком від 16 років,
                    юридична особа або організаційна одиниця без статусу
                    юридичної особи, яка створила обліковий запис у Додатку для
                    клієнтів під іменем Hauzy, щоб використовувати Послуги, які
                    пропонують Постачальники послуг.
                  </li>
                  <li>
                    <strong>Обліковий запис постачальника послуг</strong> –
                    частина Програми разом із набором ІТ-рішень, які дозволяють
                    зареєстрованим користувачам використовувати Програму.
                  </li>
                  <li>
                    <strong>Безкоштовний період</strong> - означає 7 (сім)
                    календарних днів, протягом яких новозареєстрований
                    Постачальник послуг, лише за умови прийняття Правил та інших
                    документів, необхідних Hauzy B у певний час, може
                    використовувати Додаток без сплати Комісії .
                  </li>
                  <li>
                    <strong>Комісія або комісії</strong> – означає загальну суму
                    платежів, які Постачальник послуг зобов’язаний сплатити
                    Hauzy B з будь-якої причини Hauzy B з боку Постачальників
                    послуг у зв’язку з використанням Програми, передбачену для у
                    відповідних прайс-листах або положеннях.
                  </li>
                  <li>
                    <strong>Постачальник послуг</strong> - фізична особа,
                    юридична особа або організаційний підрозділ без статусу
                    юридичної особи, яка використовує Програму у зв’язку з
                    послугами, які вона пропонує, з доступом до Клієнта та
                    створила Обліковий запис у Додатку для для надання послуг
                    клієнтам.
                  </li>
                  <li>
                    <strong>Профіль візитної картки</strong> – профіль,
                    створений Постачальником послуг у Додатку, видимий для
                    Клієнтів, який містить, серед іншого: (i) ім’я та прізвище
                    Постачальника послуг, (ii) вік, (iii) фотографію, (iv)
                    інформацію про запропоновані послуги Постачальника послуг
                    (включаючи назву Послуги Постачальника послуг, її опис,
                    тривалість і фактичну ціну, (v) можливо - інформацію про
                    необхідність передоплати або внесення застави.
                    Профіль-візитка також містить відгуки клієнтів щодо Послуг,
                    що надаються Постачальником послуг.
                  </li>
                  <li>
                    <strong>Бронювання</strong> - бронювання Клієнтом Послуги
                    Постачальника за допомогою Додатку для Замовників із
                    зазначенням дати та часу обраної Замовником Послуги
                    Постачальника.{" "}
                  </li>
                  <li>
                    <strong>Положення</strong> – ці положення.
                  </li>
                  <li>
                    <strong>RODO</strong> – Регламент (ЄС) 2016/679
                    Європейського Парламенту та Ради від 27 квітня 2016 року про
                    захист осіб у зв’язку з обробкою персональних даних і про
                    вільне переміщення такі дані та скасування Директиви
                    95/46/EC (Загальний регламент захисту даних) із поправками.
                  </li>
                  <li>
                    <strong>Веб-сайт</strong> – означає
                    https://hauzy.app/biznes.{" "}
                  </li>
                  <li>
                    <strong>Послуги Hauzy B</strong> – послуги, які Hauzy B
                    надає Постачальнику послуг.{" "}
                  </li>
                  <li>
                    <strong>Послуги Постачальника послуг</strong> - усі мобільні
                    послуги з доступом до клієнта за вказаною адресою, які
                    пропонують Постачальники послуг за допомогою Додатку для
                    Клієнта.{" "}
                  </li>
                </ol>

                <h2>
                  <strong>II. Попередні положення. Контактні дані</strong>
                </h2>

                <ol>
                  <li>
                    Положення визначають умови надання електронних послуг Hauzy
                    B через Додаток, зокрема умови використання Додатка
                    постачальниками послуг.
                  </li>
                  <li>
                    (<strong>Контактна інформація</strong>) З Hauzy B можна
                    зв’язатися:
                    <ol>
                      <li>
                        електронною поштою на адресу:{" "}
                        <a href="mailto:info@hauzy.app">info@hauzy.app</a>;
                      </li>
                      <li>
                        поштою за адресою: вул. Ogrodowa 12, 61-821 Poznań.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h2>
                  <strong>III. Типи та обсяг послуг Hauzy B </strong>
                </h2>

                <ol>
                  <li>
                    Hauzy B надає Постачальникам послуг доступ до Програми,
                    уможливлюючи прямий контакт між Клієнтом (за допомогою
                    Програми для клієнтів) і Постачальником послуг. Через
                    Додаток Постачальники послуг можуть рекламувати, рекламувати
                    та пропонувати Послуги Постачальника послуг в Інтернеті та
                    безпосередньо Клієнтам. Додаток доступний в Інтернеті через
                    Веб-сайт, його також можна встановити на мобільний пристрій
                    із доступом до Інтернету.
                  </li>
                  <li>
                    Сервіси Hauzy B включають, зокрема:
                    <ol>
                      <li>надання доступу до Програми; </li>
                      <li>
                        дозволити створення облікового запису постачальника
                        послуг і профілю візитної картки;{" "}
                      </li>
                      <li>
                        увімкнення використання календаря бронювання зустрічей,
                        включаючи керування Бронюваннями та зв’язок із Клієнтом;{" "}
                      </li>
                      <li>
                        дозволяє отримувати та надсилати SMS та електронну
                        пошту/push-повідомлення;{" "}
                      </li>
                      <li>
                        надання Клієнтам доступу до Програми для Клієнта та
                        віджету в профілі Постачальника послуг у Facebook, а
                        також до віджету на веб-сайті Постачальника послуг, що
                        дозволяє їм здійснювати онлайн-бронювання;
                      </li>
                    </ol>
                  </li>
                  <li>
                    У рамках Заявки Постачальник послуг створює Обліковий запис
                    Постачальника послуг і Профіль-візитну картку, в яких надає
                    свої дані, в тому числі особисті. Дані, необхідні Hauzy B
                    для надання Послуг Hauzy B згідно з Угодою, є даними,
                    необхідними для налаштування облікового запису та
                    профілю-візитної картки. Постачальник послуг може додати
                    додаткову інформацію, як-от фотографії портфоліо послуг
                    Постачальника послуг, щоб розширити обліковий запис і
                    профіль візитної картки.
                  </li>
                  <li>
                    Функціональні можливості Системи, доступні Постачальнику
                    послуг, включаючи способи / канали бронювання Послуг
                    Постачальника послуг, а також зовнішній вигляд самого
                    Додатку, залежать від поточної доступності даних рішень і
                    функцій і можуть змінюватися в операційних і технічних
                    умовах. причини. Вищезазначене також стосується
                    функціональності та зовнішнього вигляду Програми для
                    клієнтів.
                  </li>
                </ol>

                <h2>
                  <strong>
                    IV. Детальні умови надання послуг у складі Заявки
                  </strong>
                </h2>

                <ol>
                  <li>
                    Налаштування Постачальником послуг облікового запису
                    Постачальника послуг у Додатку означає:{" "}
                    <ol>
                      <li>початок Безкоштовного періоду;</li>
                      <li>
                        підтвердження та прийняття положень Регламенту та
                        будь-яких інших документів, наданих Hauzy B у певний
                        час;
                      </li>
                      <li>
                        заява про те, що всі дані, надані Hauzy B Постачальником
                        послуг, є актуальними та правдивими;
                      </li>
                      <li>
                        надання дозволу Hauzy B обробляти персональні дані
                        Постачальника послуг, збережені в обліковому записі
                        Постачальника послуг, щоб надавати послуги в рамках
                        Програми та для діагностичних і статистичних цілей.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Погоджуючись отримувати системні повідомлення, пов’язані з
                    використанням Програми, на наданий номер телефону та адресу
                    електронної пошти, Постачальник послуг погоджується:
                    <ol>
                      <li>
                        що Клієнти зв’яжуться з ним безпосередньо за адресою
                        електронної пошти чи номером телефону, наданими
                        Постачальником послуг, і через чат у Додатку;
                      </li>
                      <li>
                        що надані ним дані, включаючи персональні дані та
                        інформацію, включаючи фотографії, надані ним у Заявці та
                        Профілі-візитній картці, будуть видимі іншим
                        Постачальникам послуг і Клієнтам, а також усім
                        користувачам Інтернету;
                      </li>
                      <li>
                        для надсилання Hauzy B повідомлень у Додатку, зокрема
                        сповіщень про зміни в Додатку, зокрема про технічні
                        перерви в роботі Додатку, зміни в Регламенті, новий
                        вміст, опублікований у Додатку, нові повідомлення та
                        події в Заявка - через повідомлення безпосередньо в
                        Заявці та на електронну адресу, вказану в реєстраційній
                        формі.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Усі дані та інформація надаються Постачальником послуг
                    добровільно.
                  </li>
                  <li>
                    Створення спільного профілю візитної картки для кількох
                    постачальників послуг заборонено.{" "}
                  </li>
                  <li>
                    Постачальник послуг зобов'язаний повідомити Hauzy B про
                    фактичну кількість користувачів, які використовують
                    обліковий запис Постачальника послуг. Якщо Постачальник
                    послуг не надає Hauzy B інформацію, зазначену в попередньому
                    реченні, Hauzy B має право видаляти користувачів з
                    Облікового запису Постачальника послуг, про які Hauzy B не
                    було повідомлено.{" "}
                  </li>
                </ol>
                <h2>
                  <strong>
                    В. Діяльність Постачальника послуг. Вміст постачальника
                    послуг{" "}
                  </strong>
                </h2>

                <ol>
                  <li>
                    Постачальник послуг зобов'язується:
                    <ol>
                      <li>
                        вводячи дані в Додаток, обліковий запис Профілю-візитки
                        та соціальні мережі Facebook та Instagram, він буде
                        уповноважений робити це та не порушуватиме своїми діями
                        права третіх осіб;
                      </li>
                      <li>
                        уся інформація та дані, надані ним у Заявці, Обліковому
                        записі, Профілі-візитній картці та соціальних мережах
                        Facebook та Instagram, будуть правдивими.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Постачальник послуг може публікувати належний йому вміст у
                    своєму профілі-візитній картці, зокрема фотографії, відео,
                    графіку, логотипи, описи та тексти ("
                    <strong>Вміст Постачальника послуг</strong>") . Після
                    розміщення Постачальником послуг будь-якого Вмісту
                    Постачальника послуг у Додатку Постачальник послуг надає
                    Hauzy B невиключну, безкоштовну, необмежену територію та
                    необмежену в часі ліцензію («Ліцензія для Hauzy B») на
                    використання Послуги. Вміст Постачальника, опублікований у
                    Додатку відповідно до Регламенту, у таких сферах
                    використання, відомих на момент надання Ліцензії Hauzy B:
                    <ol>
                      <li>
                        в рамках запису та множення Контенту Постачальника
                        послуг - виготовлення копій цього контенту за допомогою
                        спеціальної техніки, включаючи цифрову техніку,
                        магнітний запис, а також техніку друку та репрографії;{" "}
                      </li>
                      <li>
                        в рамках торгівлі оригіналом або копіями, на яких було
                        записано Вміст Постачальника послуг - маркетинг
                        оригіналу або копій твору;{" "}
                      </li>
                      <li>
                        в рамках поширення Контенту Постачальника послуг
                        способом, відмінним від зазначеного в пункті b) вище, -
                        публічне виконання, відображення, відображення,
                        відтворення, трансляція та повторна трансляція, а також
                        надання цього контенту загальнодоступним у такий спосіб
                        спосіб, за допомогою якого кожен може мати доступ до
                        нього у вибране вами місце та час, включаючи обмін цим
                        вмістом в Інтернеті, зокрема в Додатку;{" "}
                      </li>
                    </ol>
                    , однак Постачальник послуг зобов’язується, що відповідно до
                    сфери дії вищеописаної Ліцензії для Hauzy B використання
                    Вмісту Постачальника послуг, на який поширюється ця Ліцензія
                    для Hauzy B, не порушуватиме чиїсь авторські права, зокрема
                    моральні права, зокрема в частині права позначати твір
                    іменем автора.
                  </li>
                  <li>
                    Сфера дії Ліцензії для Hauzy B, зазначеної в розділі 2 вище,
                    включає право Hauzy B на:
                    <ol>
                      <li>
                        використання Контенту Постачальника послуг і вмісту,
                        опублікованого в Додатку, третіми особами, щоб надавати
                        послуги, в інформаційних цілях, а також для просування
                        чи реклами Постачальника послуг, Додатку або Додатку для
                        Клієнта - також після Постачальника послуг відмовляється
                        від використання Сервісів Hauzy B після видалення
                        Постачальника послуг облікового запису та після
                        видалення Вмісту Постачальника послуг із Програми;
                      </li>
                      <li>
                        використовувати Вміст Постачальника послуг, доступний у
                        Додатку, без територіальних обмежень;
                      </li>
                      <li>
                        надання подальших ліцензій на використання Вмісту
                        Постачальника послуг у межах Ліцензії, наданої Hauzy B.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Відмова Постачальника послуг від використання Послуг Hauzy B
                    або видалення Вмісту Постачальника послуг чи облікового
                    запису Постачальника послуг із Програми не впливає на
                    дійсність вищезазначеного. Ліцензія на Hauzy B.{" "}
                  </li>
                  <li>
                    Постачальник послуг може самостійно розміщувати в Додатку,
                    зокрема у своєму профілі-візитній картці, свій
                    логотип/компанію (назва), яка є торговою маркою, на яку
                    поширюється дія закону про захист («Логотип»). Постачальник
                    послуг усвідомлює, що на момент розміщення Логотипу Hauzy B
                    дає свою згоду на використання Логотипу для надання послуг,
                    в інформаційних цілях та для просування або реклами
                    Постачальника послуг, Додатку або Додатку для Клієнта,
                    зокрема в Інтернеті, без обмежень за часом і територією.
                    Постачальник послуг зобов’язується не відкликати таку згоду,
                    і в разі її відкликання з порушенням вищевказаного
                    зобов’язання Hauzy B не зобов’язаний видаляти Логотип,
                    розповсюджений до дати відкликання згоди.
                  </li>
                  <li>
                    Якщо Постачальник послуг опублікував у Додатку чи соціальних
                    мережах Facebook та Instagram вміст, що містить зображення
                    третьої сторони, Постачальник послуг заявляє та гарантує, що
                    він має дозвіл цієї особи на використання зображення цієї
                    особи та розміщення вміст у Додатку, надаючи Ліцензію Hauzy
                    B в обсязі, зазначеному в розділі 2-3 і розд. 10 та
                    використання Вмісту Hauzy B згідно з умовами Ліцензії для
                    Hauzy B жодним чином не порушують права цієї особи.
                  </li>
                  <li>
                    Постачальник послуг несе повну відповідальність за збитки
                    Hauzy B за будь-які збитки, завдані Hauzy B у зв’язку з
                    публікацією Вмісту Постачальника послуг у Програмі та
                    використанням Hauzy B цього Вмісту згідно з умовами Ліцензії
                    для Hauzy B та використання контенту, розміщеного в медіа
                    соціальних мережах Facebook та Instagram на умовах,
                    визначених абз. 10 нижче. Якщо будь-які претензії
                    висуваються до Hauzy B будь-якими третіми сторонами,
                    Постачальник послуг звільняє Hauzy B від будь-якої
                    відповідальності та покриває всі розумні витрати, пов’язані
                    з цим.
                  </li>
                  <li>
                    Постачальник послуг несе відповідальність за власні дії чи
                    бездіяльність за дії та бездіяльність осіб, за допомогою
                    яких він використовує Програму.{" "}
                  </li>
                  <li>
                    Крім того, Постачальник послуг зобов’язується:
                    <ol>
                      <li>
                        дозволити Hauzy B перевіряти вміст, розміщений у
                        Додатку, облікові записи та профілі візитних карток
                        Постачальника послуг на предмет їх автентичності та
                        відповідності Правилам, зокрема шляхом надання поточного
                        номера телефону та надання додаткових пояснень;
                      </li>
                      <li>
                        не використовувати будь-який вміст, що належить Hauzy B,
                        для інших цілей, окрім належного використання Програми;
                      </li>
                      <li>
                        не використовувати будь-який вміст інших Постачальників
                        послуг або Клієнтів без їхньої прямої згоди;
                      </li>
                      <li>
                        не здійснювати, зокрема через Додаток, будь-які дії та
                        не публікувати будь-який вміст у Додатку у своїх
                        профілях у соціальних мережах — Facebook та Instagram,
                        обліковому записі Постачальника послуг або профілі
                        візитної картки (включаючи інформацію, фотографії,
                        тексти та інше): (i) порушення закону, що суперечить
                        пристойності або принципам соціального співіснування
                        (включаючи: порнографічні, що передбачають сексуальний
                        характер послуг, спрямовані на просування певної послуги
                        через вміст сексуального, неоднозначного чи неетичного
                        характеру), (ii) пов’язані до наркотиків, законного
                        алкоголю чи інших стимуляторів, а також до організації
                        азартних ігор, (iii) порушення прав та особистих прав
                        третіх осіб, (iv) порушення принципів чесної
                        конкуренції, (v) порушення положень Правила, (vi)
                        пов’язані або посилаються на тантру та практики для
                        подібних або (vii) шкідливих для доброго імені,
                        репутації чи репутації Hauzy B («
                        <strong>Заборонена діяльність</strong>» і «
                        <strong>Заборонений вміст </strong>", відповідно);
                      </li>
                      <li>
                        використовувати Програму лише відповідно до її цільового
                        призначення;
                      </li>
                      <li>
                        не копіювати, не змінювати, не розповсюджувати та не
                        відтворювати повністю або частково Програму;
                      </li>
                      <li>
                        не пропонувати та не рекламувати послуги чи продукти,
                        продаж чи реклама яких заборонена або підлягає
                        обмеженням відповідно до чинного законодавства, а також
                        пропозиція чи торгівля якими заборонено чинним
                        законодавством, зокрема реклама алкоголю, тютюну,
                        лікарських засобів , наркотики та подібні продукти;
                      </li>
                      <li>
                        не надавати третім сторонам доступ до облікового запису
                        Постачальника послуг у Додатку – лише Постачальник
                        послуг несе відповідальність за конфіденційність і
                        безпеку свого Облікового запису, включаючи збереження
                        паролів і логіна в секреті;
                      </li>
                      <li>
                        негайно повідомляти Hauzy B про будь-яке незаконне
                        використання облікового запису Постачальника послуг
                        третіми сторонами;
                      </li>
                      <li>
                        негайно повідомте Hauzy B, якщо треті сторони подадуть
                        свої претензії у зв’язку з порушенням законодавства
                        Постачальником послуг через Додаток:{" "}
                      </li>
                      <li>
                        не публікувати у своєму профілі візитки чи профілях
                        візитних карток інших постачальників послуг думки про
                        себе чи послуги, які вони надають.
                      </li>
                    </ol>
                  </li>
                </ol>

                <h2>
                  <strong>VI. Бронювання, підтвердження та скасування</strong>
                </h2>

                <ol>
                  <li>
                    Бронювання здійснюється через Додаток для клієнта.
                    Постачальник послуг підтверджує запит на Бронювання,
                    зроблений Клієнтом, автоматично або вручну за допомогою
                    Додатку. Бронювання між Постачальником послуг і Клієнтом
                    відбувається в момент його підтвердження Постачальником
                    послуг.
                  </li>
                  <li>
                    За замовчуванням Програма налаштована на режим автоматичного
                    підтвердження запиту на бронювання. В налаштуваннях
                    Профілю-Візитки Постачальник послуг може змінити
                    налаштування та перейти до моделі ручного підтвердження
                    Бронювання. Змінюючи режим підтвердження з автоматичного на
                    ручний, Постачальник послуг зобов’язується підтвердити запит
                    на Бронювання протягом максимум 12 годин з моменту отримання
                    запиту на Бронювання. Недотримання цього терміну
                    Постачальником послуг призводить до позначення Бронювання як
                    непідтвердженого. У цьому випадку послуга Постачальника
                    послуг не повинна виконуватися, і Клієнт може скасувати її
                    без наслідків. Постачальник послуг несе одноосібну
                    відповідальність за надання Послуг Постачальника послуг
                    Клієнтам після здійснення Бронювання відповідно до
                    застосовних загальних положень законодавства.{" "}
                  </li>
                  <li>
                    Умови, за яких здійснюється Бронювання, можуть бути
                    визначені Постачальником послуг в інформації (нормативних
                    актах тощо), наданій Клієнту в Заяві Клієнта до здійснення
                    Бронювання. Зміст цієї інформації та загальноприйнятих
                    положень, зокрема положень про права споживачів, визначають,
                    чи укладено, який тип і зміст договору між Постачальником
                    послуг і Клієнтом під час підтвердження запиту на
                    Бронювання.
                  </li>
                  <li>
                    Заявка для Клієнта дозволяє Клієнту скасувати Бронювання
                    навіть після його попереднього підтвердження Постачальником
                    послуг. Наслідки скасування бронювання викладені в Умовах
                    бронювання та загальноприйнятих нормативних актах, зокрема
                    положеннях про права споживачів.
                  </li>
                  <li>
                    Hauzy B залишає за собою право перевіряти статус Бронювання
                    на відповідність фактам. Зокрема, перевірці підлягають
                    Бронювання зі зміненою або неринковою ціною Послуги
                    Постачальника послуг і зі зміненим статусом Бронювання.
                  </li>
                </ol>
                <h2>
                  <strong>VII. Комісії та виставлення рахунків</strong>
                </h2>

                <ol>
                  <li>
                    За користування Послугами Hauzy B Постачальник послуг
                    зобов’язаний сплатити Hauzy B усі Комісії в сумі, зазначеній
                    у Прейскуранті або інших відповідних документах (наприклад,
                    правилах або прейскурантах для рекламних акцій або пакетів).
                    Комісії сплачуються на банківський рахунок Hauzy B,
                    зазначений у рахунку-фактурі з ПДВ, виданому Hauzy B, або
                    шляхом автоматичного стягнення Комісії з платіжної картки
                    Постачальника послуг.
                  </li>
                  <li>
                    Постачальник послуг заявляє, що, приймаючи ці Правила, він
                    також дає згоду на те, щоб Hauzy B виставляла електронні
                    рахунки-фактури відповідно до чинного законодавства та
                    надсилала їх в електронній формі на адресу електронної
                    пошти, вказану Постачальником послуг Hauzy B.{" "}
                  </li>
                  <li>
                    Зобов’язання Постачальника послуг включають надання дійсної
                    адреси електронної пошти для надсилання рахунків-фактур,
                    додавання Hauzy B до довірених контактів, щоб електронний
                    лист із рахунком-фактурою не було відфільтровано як спам, а
                    також негайне сповіщення Hauzy B про зміну електронної
                    пошти. - адреса електронної пошти для надсилання рахунків
                    без виклику Hauzy B.{" "}
                  </li>
                  <li>
                    Hauzy B зобов’язується надсилати рахунки-фактури в
                    електронному вигляді у форматі PDF таким чином, щоб
                    гарантувати автентичність походження, цілісність вмісту та
                    розбірливість виписаних рахунків-фактур з моменту виписки до
                    закінчення терміну давності податкових зобов’язань
                    відповідно до вимоги, встановлені ст. 106м Закону про
                    податок на товари та послуги від 11.03.2004 р. протягом
                    строку дії вищезгаданої згоди. Дублікати та виправлення
                    рахунків-фактур до електронних рахунків-фактур будуть видані
                    та надіслані в електронному вигляді (електронною поштою) у
                    форматі PDF. Електронні рахунки-фактури вважаються
                    доставленими, коли вони введені в засоби електронного
                    зв’язку таким чином, що Постачальник послуг може прочитати
                    їхній вміст.
                  </li>
                  <li>
                    Якщо Постачальник послуг відкликає згоду, згадану вище,
                    Hauzy B втрачає право виставляти електронні рахунки-фактури
                    на наступний робочий день після дня, коли Hauzy B отримав
                    повідомлення Постачальника послуг про відкликання згоди. У
                    разі відкликання згоди на надсилання рахунків в електронному
                    вигляді Hauzy B надсилатиме рахунки традиційною поштою на
                    адресу Постачальника послуг за додаткову плату в розмірі 50
                    злотих нетто на місяць. Після відкликання згоди на
                    надсилання рахунків-фактур в електронному вигляді
                    Постачальник послуг погоджується на стягнення додаткової
                    комісії.{" "}
                  </li>
                  <li>
                    Правила та терміни виписування рахунків-фактур з ПДВ, а
                    також терміни сплати рахунків-фактур з ПДВ визначені в
                    Прейскуранті.
                  </li>
                  <li>
                    Несплата Комісії або будь-якої її частини протягом
                    зазначеного періоду призведе до призупинення всіх Послуг
                    Hauzy B і блокування Облікового запису Постачальника послуг.
                    Відновлення надання Послуг Hauzy B та розблокування
                    Облікового запису Постачальника послуг відбудеться протягом
                    12 годин наступного робочого дня (понеділок – п’ятниця, 8.00
                    – 16.00, крім суботи та святкових днів), після того, як
                    Hauzy B буде доставлено Постачальником послуг електронною
                    поштою. - поштою з підтвердженням оплати всієї належної суми
                    або після зарахування суми на рахунок Hauzy B.
                  </li>
                  <li>
                    Якщо неможливо стягнути Комісію з платіжної картки, Рахунок
                    Постачальника послуг розблоковується, коли Постачальник
                    послуг поповнює кошти на картці та повторює оплату через
                    Додаток. Крім того, Постачальник послуг може повідомити
                    Hauzy B і попросити повторне стягнення Комісії. Рахунок буде
                    розблоковано одразу після сплати повної належної Комісії.{" "}
                  </li>
                  <li>
                    Якщо Постачальник послуг активує опцію оплати карткою,
                    платіж Клієнта, здійснений за допомогою платіжної картки,
                    буде зменшено на суму всіх чинних комісій постачальників
                    платіжних послуг і платіжних установ, а також на комісію,
                    зазначену нижче. Рахунок-фактура на стягнені збори та
                    комісії буде виставлено та надіслано після закінчення
                    розрахункового періоду.{" "}
                  </li>
                </ol>
                <h2>
                  <strong>
                    VIII. Повноваження та відповідальність Hauzy B
                  </strong>
                </h2>

                <ol>
                  <li>
                    Hauzy B ніколи не є стороною договорів, укладених між
                    Постачальником послуг і Клієнтом, зокрема у зв’язку з
                    Бронюванням, здійсненим Клієнтом. Таким чином, Hauzy B не
                    несе відповідальності за дії або бездіяльність Клієнта,
                    включно з оплатою Клієнтом Послуги Постачальника послуг або
                    будь-якою іншою оплатою Постачальнику послуг від Клієнта.
                    Постачальник послуг зобов'язаний надавати Послуги
                    Постачальника послуг відповідно до чинного законодавства та
                    несе повну відповідальність перед Замовником за будь-які
                    його порушення. Зокрема, Виконавець зобов’язується надавати
                    Замовнику інформацію, передбачену законодавством, та в
                    порядку, передбаченому законодавством.{" "}
                  </li>
                  <li>
                    Hauzy B має право здійснювати заходи з перевірки, спрямовані
                    на контроль вмісту, розміщеного в Додатку, облікових записах
                    і профілі візитної картки, щодо їх автентичності та
                    відповідності Правилам.{" "}
                  </li>
                  <li>
                    Для:
                    <ol>
                      <li>
                        Hauzy B отримує офіційне повідомлення, іншу інформацію
                        або робить інше припущення щодо забороненої діяльності
                        Постачальника послуг або публікації забороненого вмісту;{" "}
                      </li>
                      <li>
                        порушення Постачальником послуг умов Угоди, в тому числі
                        положень Правил, або діяння в спосіб, спрямований на їх
                        обхід, включаючи маніпулювання ціною Послуги
                        Постачальника послуг або статусом Бронювання;{" "}
                      </li>
                      <li>
                        образлива поведінка по відношенню до Клієнтів, інших
                        Постачальників послуг, співробітників і партнерів Hauzy
                        B (зокрема через погрози або використання ненормативної
                        лексики тощо);{" "}
                      </li>
                    </ol>
                    Hauzy B має право скористатися одним або кількома з
                    наведених нижче Засобів правового захисту (на власний розсуд
                    Hauzy B) («<strong>Засоби правового захисту</strong>»).{" "}
                  </li>
                  <li>
                    Коригувальні дії включають наступне:{" "}
                    <ol>
                      <li>
                        вимагати від Постачальника послуг негайно видалити
                        Заборонений вміст із Додатку/Профілю-візитки або оновити
                        вміст під загрозою відмови розміщувати вміст у
                        Додатку/Профілі-візитній картці, заблокувати доступ до
                        цього вмісту або видалити це; або
                      </li>
                      <li>
                        вимагати від Постачальника послуг негайно припинити
                        порушення чи обхід Правил; або
                      </li>
                      <li>
                        відмовити в розміщенні контенту в Додатку /
                        Профілі-Візитці, негайно заблокувати доступ до
                        розміщеного в Додатку / Профілі-Візитці контенту,
                        видалити контент із Додатку / Профілю-Візитки; або
                      </li>
                      <li>
                        тимчасове або постійне блокування функцій або пакетів,
                        які використовуються Постачальником послуг;{" "}
                      </li>
                      <li>
                        блокування або видалення облікового запису/профілю
                        візитної картки постачальника послуг.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Вжиття Hauzy B будь-яких заходів для виправлення не звільняє
                    Постачальника послуг від зобов’язання сплачувати Комісії, що
                    стягуються за Послуги Hauzy B, доки не буде вжито заходів
                    для виправлення.{" "}
                  </li>
                  <li>
                    У ситуаціях, згаданих у розділі 3 вище, Hauzy B повідомить
                    Постачальника послуг електронною поштою або через Додаток
                    про свій намір заблокувати доступ до Вмісту Постачальника
                    послуг, видалити вміст із Додатка/Профілю-візитки ,
                    заблокувати обліковий запис Постачальника послуг. У
                    термінових випадках Hauzy B заблокує доступ до Контенту,
                    видалить вміст із Додатку/Профілю-візитки або заблокує
                    Обліковий запис Постачальника послуг без повідомлення про
                    такий намір.
                  </li>
                  <li>
                    У разі будь-яких претензій до Hauzy B або юридично
                    обґрунтованого запиту надати дані Постачальника послуг
                    уповноваженими органами чи організацією, яка має намір
                    подати претензії, Hauzy B має право ознайомитися з фактами
                    та, можливо, захищати та передавати дані Постачальника
                    послуг та іншу інформацію, що міститься в Додатку, необхідну
                    для реалізації та захисту будь-яких претензій або запитів.
                  </li>
                  <li>
                    Загальна відповідальність Hauzy B за послуги, надані через
                    Додаток, не включає втрачену вигоду. Hauzy B несе
                    відповідальність перед Постачальниками послуг лише за
                    збитки, завдані Постачальникам послуг у результаті
                    використання Програми, і лише ті, які були спричинені
                    виключно навмисною неправомірною поведінкою Hauzy B.
                  </li>
                  <li>
                    Hauzy B не несе відповідальності перед Постачальником послуг
                    за:
                    <ol>
                      <li>
                        здійснювати будь-які дії, згадані в пунктах 3 і 4 вище;{" "}
                      </li>
                      <li>
                        перерви у функціонуванні Програми внаслідок причин, які
                        не залежать від Hauzy B, зокрема, є форс-мажорними
                        обставинами або зазначеними в розділі 9 нижче;
                      </li>
                      <li>
                        функціонування систем ІКТ і телекомунікаційних мереж під
                        час здійснення Клієнтами платежів через Додаток і
                        Додаток для Клієнта;
                      </li>
                      <li>
                        проблеми або технічні труднощі з боку Постачальника
                        послуг, пов’язані з роботою комп’ютерного обладнання,
                        телекомунікацій або доступу до Інтернету, які
                        ускладнюють або роблять неможливим використання Програми
                        або послуг, які пропонуються через неї;
                      </li>
                      <li>
                        автентичність, надійність, правильність, достовірність,
                        повнота даних та інформації, включеної в Додаток
                        Постачальником послуг і наданої Постачальником послуг
                        через Додаток;
                      </li>
                      <li>
                        збитки, заподіяні Постачальником послуг у зв’язку з
                        функціонуванням Додатку або його діяльністю в ньому, в
                        тому числі пов’язані з порушенням Регламенту,
                        несанкціонованим використанням даних, доступних через
                        Додаток, наданням неправдивих, неповних, застарілих
                        даних та інформацію або неможливість їх оновлення;
                      </li>
                      <li>
                        збитки, завдані Постачальником послуг шляхом порушення
                        прав третіх осіб, а також збитки, заподіяні діями третіх
                        осіб;
                      </li>
                      <li>
                        збиток, заподіяний Постачальником послуг у зв’язку з
                        неналежним виконанням або невиконанням ним договорів,
                        укладених через Додаток або Послуги;
                      </li>
                      <li>
                        збитки, понесені Постачальником послуг через дії чи
                        бездіяльність Замовника;{" "}
                      </li>
                      <li>
                        вміст думок, опублікованих Клієнтами в Додатку Клієнта;
                      </li>
                      <li>
                        ефективність маркетингової діяльності, яку здійснюють
                        Постачальники послуг для Клієнтів через Додаток.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B має право тимчасово припинити надання послуг у разі
                    збою, перевірки або модернізації системи ІКТ, що належить
                    Hauzy B.
                  </li>
                  <li>
                    Hauzy B, відповідно до ст. 14 Закону від 18 липня 2002 р.
                    Про надання електронних послуг:{" "}
                    <ol>
                      <li>
                        не несе відповідальності за збережені дані, якщо йому не
                        відомо про протиправний характер даних або пов’язану з
                        ними діяльність, а також у разі отримання офіційного
                        повідомлення або отримання достовірної інформації про
                        протиправний характер даних або пов’язаної з для них це
                        негайно заблокує доступ до цих даних;
                      </li>
                      <li>
                        якщо він отримує офіційне повідомлення про незаконний
                        характер збережених даних, наданих Постачальником
                        послуг, і перешкоджає доступу до цих даних, він не несе
                        відповідальності перед Постачальником послуг за шкоду,
                        спричинену перешкоджанням доступу до цих даних;
                      </li>
                      <li>
                        якщо він отримує достовірну інформацію про незаконний
                        характер збережених даних, наданих Постачальником
                        послуг, і перешкоджає доступу до цих даних, він не несе
                        відповідальності перед Постачальником послуг за шкоду,
                        спричинену перешкоджанням доступу до цих даних, якщо він
                        негайно повідомляє Постачальника послуг про свій намір
                        заборонити доступ до нього.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h2>
                  <strong>IX. Скарги</strong>
                </h2>

                <ol>
                  <li>
                    Будь-які скарги, пов’язані з некоректною роботою Програми,
                    Постачальник послуг може надсилати електронною поштою на
                    таку електронну адресу:{" "}
                    <a href="mailto:info@hauzy.app">info@hauzy. додаток</a>.
                  </li>
                  <li>
                    Скарги розглядатимуться протягом 30 днів із дати їх
                    отримання Hauzy B. У складних випадках або якщо скаргу не
                    буде розглянуто протягом зазначеного вище періоду з причин,
                    які не залежать від Hauzy B, Hauzy B залишає за собою право
                    продовжити строк розгляду скарги.{" "}
                  </li>
                  <li>
                    Hauzy B залишає за собою право вимагати від рекламодавця
                    інформації або пояснень у ситуації, коли потрібно визнати
                    скаргу. Пояснення або інформація повинна бути надана
                    Постачальником послуг негайно, не пізніше ніж протягом 30
                    днів. У разі недотримання цього терміну Hauzy B має право
                    залишити скаргу без розгляду та не несе відповідальності за
                    це.{" "}
                  </li>
                  <li>
                    Рішення Hauzy B щодо скарги буде надіслано на адресу
                    електронної пошти, з якої було подано скаргу.
                  </li>
                </ol>
                <h2>
                  <strong>X. Технічні вимоги</strong>
                </h2>

                <ol>
                  <li>
                    Щоб користуватися Програмою, необхідно мати пристрої, що
                    забезпечують доступ до Інтернету, електронну пошту та
                    веб-браузер. Для використання Сервісів Hauzy B через Додаток
                    потрібне активне підключення до Інтернету. Для використання
                    програми на мобільному пристрої потрібен функціональний
                    мобільний пристрій. Постачальник послуг самостійно та під
                    свою власну відповідальність повинен забезпечити виконання
                    технічних вимог мобільного пристрою, його конфігурацію,
                    оновлення програмного забезпечення та доступ до Інтернету.
                  </li>
                  <li>
                    Технічні вимоги, яким мають відповідати пристрої:{" "}
                    <ol>
                      <li>
                        телефон: операційна система Android не старше 5.00 або
                        операційна система iOS (Apple) не старше 14 років;{" "}
                      </li>
                      <li>
                        планшет: (для програми Hauzy B Pro для планшетів) версія
                        операційної системи Android не старше 5.00, мінімум 3 ГБ
                        оперативної пам’яті, діагональ екрану мінімум 7 дюймів.
                        Операційна система iPadOS (Apple) не старше версії 14.
                        iPad (5-го покоління або новішої), iPad Air (2-го
                        покоління або новішої), iPad Pro, iPad Mini (4-го
                        покоління або новішої);
                      </li>
                      <li>
                        ПК/Mac: мінімум 1,4 ГГц процесор, 4,0 ГБ оперативної
                        пам’яті, рекомендований браузер Google Chrome, доступ до
                        Інтернету.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hauzy B використовує файли cookie, які дозволяють збирати
                    інформацію, пов’язану з використанням Програми. Файли cookie
                    — це текстові файли, які зберігаються на жорсткому диску
                    людей, які відвідують Програму, для зберігання інформації та
                    даних щодо використання Програми. Відсутність увімкнених
                    файлів cookie може спричинити збої або труднощі у
                    функціонуванні Програми.
                  </li>
                  <li>
                    Більшість використовуваних веб-браузерів (наприклад,
                    Firefox, Google Chrome або Internet Explorer) містять опцію
                    відхилення команди для збереження файлів cookie.
                    Постачальник послуг може самостійно налаштувати браузер так,
                    щоб він міг відхиляти запити на збереження всіх або вибраних
                    файлів cookie.
                  </li>
                  <li>
                    Особливі ризики, пов’язані з використанням Програми
                    Постачальником послуг, включають надання даних для входу
                    неавторизованим особам, які можуть навмисно змінити
                    конфігурацію Профілю або Облікового запису Постачальника
                    послуг. Це стосується, зокрема, важливих елементів Профілю,
                    таких як типи пропонованих Послуг, прайс-листи, фотографії
                    та інша інформація про Постачальника послуг. Тому паролі до
                    системи необхідно зберігати в таємниці, а також окремо
                    налаштовувати доступ до Додатку для співробітників
                    Постачальника послуг.
                  </li>
                </ol>

                <h2>
                  <strong>XI. Особисті дані. Конфіденційність</strong>
                </h2>

                <ol>
                  <li>
                    Hauzy B і Постачальник послуг обробляють персональні дані
                    Клієнтів у власних цілях, тому вони є окремими
                    адміністраторами їхніх персональних даних.
                  </li>
                  <li>
                    Під час підтвердження Бронювання між Замовником і
                    Постачальником послуг укладається договір про надання Послуг
                    Постачальником послуг Замовнику. Також:{" "}
                    <ol>
                      <li>
                        Клієнт може в Заяві для Клієнта надати Постачальнику
                        послуг згоду на обробку персональних даних для цілей,
                        відмінних від виконання договору з Клієнтом, і згоду на
                        маркетингову комунікацію;{" "}
                      </li>
                      <li>
                        Hauzy B надає Постачальнику послуг використання Програми
                        персональних даних Клієнта, необхідних для надання
                        Послуг цьому Клієнту;
                      </li>
                      <li>
                        Постачальник послуг стає адміністратором персональних
                        даних такого Клієнта, а тому зобов’язаний дотримуватися
                        правил, викладених у GDPR, і несе за це
                        відповідальність, що випливає з цих правил.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Незалежно, щоб Hauzy B могла надавати послуги Постачальнику
                    послуг у рамках Програми, для якої необхідна обробка:
                    <ol>
                      <li>
                        персональні дані Клієнтів, які здійснили Бронювання;{" "}
                      </li>
                      <li>
                        особисті дані осіб, які використовує Постачальник послуг
                        за допомогою Програми;{" "}
                      </li>
                      <li>
                        Клієнти, які дали згоду на надсилання маркетингових
                        повідомлень Постачальником послуг;{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    Постачальник послуг також є адміністратором персональних
                    даних Клієнта, з яким він укладає договір поза Додатком.
                    Якщо він вводить ці дані в Додаток, він залишається їхнім
                    адміністратором, і Hauzy B обробляє їх від імені
                    Постачальника послуг відповідно до Угоди про обробку
                    персональних даних.
                  </li>
                  <li>
                    Hauzy B обробляє дані, включно з особистими даними
                    Постачальника послуг, лише в обсязі, який забезпечує належне
                    надання послуг Hauzy B і належне виконання Угоди.
                  </li>
                  <li>
                    Hauzy B не несе відповідальності за обсяг персональних даних
                    клієнтів, зібраних Постачальниками послуг, і за законність
                    збору таких даних Постачальниками послуг. Детальні
                    зобов’язання сторін щодо цього включені в Угоду про обробку
                    персональних даних.
                  </li>
                  <li>
                    Політика конфіденційності Hauzy B, включаючи політику щодо
                    файлів cookie, доступну на веб-сайті.{" "}
                  </li>
                </ol>
                <h2>
                  <strong>XII. Прикінцеві положення</strong>
                </h2>

                <ol>
                  <li>
                    Hauzy B залишає за собою право змінювати Положення,
                    включаючи його невід’ємні частини, тобто інші документи, які
                    використовує Hauzy B. Нова редакція набуває чинності з дати
                    її публікації на Веб-сайті або в Обліковому записі
                    Постачальника послуг у Додатку. У випадку вищезазначеного
                    зміни, Hauzy B повідомить Постачальника послуг про цю зміну
                    на номер телефону або адресу електронної пошти Постачальника
                    послуг, вказаних у процесі реєстрації. Постачальник послуг
                    може не прийняти зміни та в цьому випадку має право видалити
                    обліковий запис.
                  </li>
                  <li>
                    Hauzy B має право надсилати на адресу електронної пошти
                    Постачальника послуг, надану Hauzy B, будь-які нагадування,
                    запити на оплату або сповіщення щодо заборгованості
                    Постачальника послуг із Комісії для Hauzy B.{" "}
                  </li>
                  <li>
                    Якщо будь-яке положення Регламенту, а також інших
                    документів, прийнятих Постачальником послуг, виявиться
                    недійсним або неефективним, це ні в якому разі не призведе
                    до недійсності Регламенту. У такій ситуації сторони
                    докладуть усіх зусиль, щоб замінити положення, яке
                    вважається недійсним або неефективним, на дійсне та
                    ефективне положення, якомога ближче до початкового наміру
                    сторін.
                  </li>
                  <li>
                    Відносини між Hauzy B і Постачальником послуг регулюються
                    цими Правилами.
                  </li>
                </ol>

                <p>
                  <span>Останнє оновлення: 15 березня 2023 р.</span>
                </p>
              </div>
            ) : null}
            {/*
          <ol>
            {termData.map((term) => {
              return (
                <>
                  <strong className="strong">
                    <li style={{ listStyle: "upper-roman" }}>{term.title}</li>
                  </strong>
                  <ol>
                    {term.descriptions.map((description) => {
                      return (
                        <li className="description__list">
                          <strong>{description.head}</strong>
                          <span> - </span>
                          <spna>{description.content}</spna>
                        </li>
                      );
                    })}
                  </ol>
                </>
              );
            })}
          </ol>
        */}
          </div>
        </div>
        <Footer />
              <div id="fb-root"></div>
        <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat">
        </div>
      </div>
    </>
    // <div className="terms_container">
    //   <h4 className="terms_heading">
    //     {translate(labels.termAndCondition)}
    //   </h4>
    //   <div className="main__term">
    //     <ol>
    //       {termData.map((term) => {
    //         return (
    //           <>
    //             <strong className="strong">
    //               <li style={{ listStyle: "upper-roman" }}>{term.title}</li>
    //             </strong>
    //             <ol>
    //               {term.descriptions.map((description) => {
    //                 return (
    //                   <li className="description__list">
    //                     <strong>{description.head}</strong>
    //                     <span> - </span>
    //                     <spna>{description.content}</spna>
    //                   </li>
    //                 );
    //               })}
    //             </ol>
    //           </>
    //         );
    //       })}
    //     </ol>
    //   </div>
    // </div>
  );
};

export default Index;
