import React from 'react';
import reactDOM from 'react-dom';

/** MAIN COMPONENT
 * - responsible for displaying content as modal
 */
const Modal = props => {
  return reactDOM.createPortal(
    <div className="modal">{props.children}</div>,
    document.getElementById('modal')
  );
};

export default Modal;
