import React, { useContext, useEffect, useState } from "react";
import Icon from "../Icon";
import {
  currentLocalDate,
  formatDate,
  getTwoDatesDifferenceinSeconds,
  minutuestoHoursString,
} from "../../utils/function";
import {
  getbookingStutusLocaleText,
  getreminderLocaleText,
} from "../../utils/locale_functions";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";

import { errorAlert, successAlert } from "../../utils/alert";
import Loader from "../Loader";
import {
  changeBookingStatusAndNotify,
  getGoogleEventsData,
} from "../../services/booking_service";
import { getUserData } from "../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import  BookingContent  from "./BookingContent";
import { getBussinessProfile } from "../../services/bussiness_service";
import { getUserProfile } from "../../services/user_service";
import { getGroupChatId } from "../../services/chat_service";
import { getUsersFromBooking } from "../../services/booking_service";
import { getBooking } from "../../services/booking_service";
import { ActionState } from "../../utils/enum";

const BookingOverview = ({ business, children  }) => {

  const { id } = useParams();
  const translate = useLocalization();


    const [booking, setBooking] = useState();
const [userBusiness, setUserBusiness] = useState([]);

  const [loadingState, setloadingState] = useState("initial");
  const [reviewsloadingState, setreviewsloadingState] = useState(
    ActionState.Initial
  );

  const getCollectionData = async () => {
    setloadingState("loading");

console.log("booking is loading");


    const currentBooking = await getBooking(id);


      setBooking(currentBooking);
     var idsFromBooking;
     idsFromBooking = await getUsersFromBooking(id);
   //  console.log("user 1: " + idsFromBooking.regular);
   //  console.log("user 2: " + idsFromBooking.business);


    const bussiness = await getBussinessProfile(idsFromBooking.business);
    setUserBusiness(bussiness);
    setloadingState("loaded");
  };


  useEffect(() => {
    getCollectionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  if (loadingState !== "loaded") {
    console.log("Loading....");
    return <Loader />;
  } 
  return (
    <section className="service-provider-section">
           <BookingContent booking={booking} bussinessUser={userBusiness}/>
    </section>
  );
};

export default BookingOverview;
