import { useState, useEffect } from "react";
import BasicChat from "./BasicChat";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { ActionState } from "../../utils/enum";
import Loader from "../Loader";
import { getBussinessProfile } from "../../services/bussiness_service";
import { getUserProfile } from "../../services/user_service";
import { getGroupChatId, getUsersFromConversation } from "../../services/chat_service";
import { getUsersFromBooking, getNextBookingFromBusiness, getBooking } from "../../services/booking_service";



const Index = ({ business, children }) => {
  const { id } = useParams();
  const translate = useLocalization();

  const [userBusiness, setUserBusiness] = useState([]);
  const [userRegular, setUserRegular] = useState([]);  
  const [groupChatId, setGroupChatId] = useState([]);  
    const [booking, setBooking] = useState();


  const [loadingState, setloadingState] = useState(ActionState.Initial);
  const [reviewsloadingState, setreviewsloadingState] = useState(
    ActionState.Initial
  );
  const [reviews, setreviews] = useState([]);
  const getCollectionData = async () => {
    setloadingState(ActionState.Loading);

   //  var idsFromBooking;
   //  idsFromBooking = await getUsersFromBooking(id);
   //  console.log("user 1: " + idsFromBooking.regular);
   //  console.log("user 2: " + idsFromBooking.business);

var idsFromConversation;
     idsFromConversation = await getUsersFromConversation(id)
     console.log("user regular: " + idsFromConversation.regular);
     console.log("user business: " + idsFromConversation.business);

    //const currentBooking = await getBooking(id);
    const bussiness = await getBussinessProfile(idsFromConversation.business);
    setUserBusiness(bussiness);

    const regular = await getUserProfile(idsFromConversation.regular);
    setUserRegular(regular);


   // const groupChatId = await getGroupChatId(bussiness.uid, regular.uid);
    //console.log("groupChatId " + groupChatId);
    // setGroupChatId("nNOZOY1Mx2crsslfZU7yMSf4RqN2-njVXQU3CAgO5JAkZGUx7A13Ju372_B");


     const nextBooking = await getNextBookingFromBusiness(regular.uid, bussiness.uid);
     console.log("nextBooking ");
     console.log(nextBooking);
      

      setBooking(nextBooking);
     // console.log("current booking");
      // console.log(booking);
    setloadingState(ActionState.Loaded);
  };


  useEffect(() => {
    getCollectionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  if (loadingState !== ActionState.Loaded) {
    return <Loader />;
  } else if (loadingState === ActionState.Error) {
    return <span>Error Occurs</span>;
  }
  return (
    <section className="service-provider-section basic_chat_box">
           <BasicChat bussinessProfile={userBusiness} regularProfile={userRegular} booking={booking} groupChatId={id}/>
    </section>
  );
};

export default Index;


