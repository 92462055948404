import React from "react";
import SelectInput from "../SelectInput";
import Ratings from "../Ratings";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { ActionState } from "../../utils/enum";
import Loader from "../Loader";
import { formatTimeAgo, getUserFullName } from "../../utils/function";

const Opinions = ({ userBusiness, reviewsloadingState, reviews }) => {
  const translate = useLocalization();

  // const opinions = [
  //   {
  //     rating: 5,
  //     feedback: "I recommend, very good service, a great photographer.",
  //     by: "Borys",
  //     date: "23.09.2020",
  //   },
  //   {
  //     rating: 4,
  //     feedback: "I recommend, very good service, a great photographer.",
  //     by: "Ali",
  //     date: "23.09.2020",
  //   },
  //   {
  //     rating: 3,
  //     feedback: "I recommend, very good service, a great photographer.",
  //     by: "Safwan",
  //     date: "23.09.2020",
  //   },
  //   {
  //     rating: 2,
  //     feedback: "I recommend, very good service, a great photographer.",
  //     by: "Borys",
  //     date: "23.09.2020",
  //   },
  //   {
  //     rating: 1,
  //     feedback: "I recommend, very good service, a great photographer.",
  //     by: "Borys",
  //     date: "23.09.2020",
  //   },
  // ];
  if (reviewsloadingState === ActionState.Loading) {
    return (
      <div className="provider__col">
        <Loader />
      </div>
    );
  } else if (reviewsloadingState === ActionState.Error) {
    return (
      <div className="provider__col">
        <span>Error Occurs</span>
      </div>
    );
  }
  return (
    <div className="provider__col">
      <div className="provider-header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <h3 className="provider-heading">{translate(labels.opinions)}</h3>
          <div className="opinion__stars">
            <Ratings
              className="recommended-icon"
              filled={!isNaN(userBusiness?.rating) ? userBusiness?.rating : 5}
            />
            <span className="opinions__number">
              {!isNaN(userBusiness?.rating) ? userBusiness?.rating : "5.0"}{" "}
              {translate(labels.opinions)}
            </span>
          </div>
        </div>

        <SelectInput id="search-sort-order">
          <option value="highest">
            {translate(labels.highestRated)}
          </option>
          <option value="lowest">
            {translate(labels.lowestRated)}
          </option>    
          <option value="newest">
            {translate(labels.newestFirst)}
          </option>
          <option value="oldest">
            {translate(labels.oldestFirst)}
          </option>                 
        </SelectInput>
      </div>

      {reviews.length === 0 ? (
        <div className="no_review">
          <img
            src={require("../../assets/no_review.png").default}
            alt="send icon"
          />
          <h5>{translate(labels.no_review_yet)}</h5>
          <p>{translate(labels.new_review_display_here)}</p>
          {/* <ChatIcon
            width={30}
            height={30}
            className="provider-header-icon svg-icons"
          /> */}
        </div>
      ) : (
        <div className="review_wrapper">
          {reviews.map((review, i) => {
            const {
              userProfile,
              bookingId,
              timestamp,
              reviewStars,
              reviewText,
            } = review;
            return (
              <div key={i} className="small-review-tile">
                <div className="row">
                  <div className="row">
                    <img
                      src={userProfile.profilePic}
                      alt="guest"
                      className="image"
                    />
                    <div className="review__meta">
                      <span className="guest__link guest__link-blue">
                        #{bookingId.substring(0, 8)}
                      </span>
                      <span className="review__name">
                        {getUserFullName(userProfile)}
                      </span>
                    </div>
                  </div>

                  <div>
                    <span className="review__date mg-top-small">
                      {formatTimeAgo(timestamp.toDate())}
                    </span>
                    <div className="recommended__ratings">
                      <Ratings
                        className="recommended-icon mg-bottom-small"
                        filled={reviewStars}
                      />
                    </div>
                  </div>
                </div>
                <p className="review-text">{reviewText}</p>
              </div>
            );
          })}
        </div>
      )}
      {/*
      <div className="provider__opinions">
        {opinions.slice(0, 3).map((opinion, i) => (
          <OpinionTile opinion={opinion} key={i} />
        ))}

        <button className="primary__btn">
          {translate(labels.addYourOpinion)}
        </button>
      </div> */}
    </div>
  );
};

export default Opinions;
