import React from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as PinIcon } from "../../../assets/svg icons/pin.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/svg icons/calendar.svg";
import { ReactComponent as PersonIcon } from "../../../assets/svg icons/person.svg";
import Footer from "../../../components/Footer";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";
import { useParams } from "react-router-dom";
const SearchSubSlotPage = () => {
  const { locale = "en" } = useParams();
  const translate = useLocalization();

  return (
    <div className="main">
      <div className="slot">
        <h3 className="secondary-heading mg-bottom-medium">
          {translate(labels.findAFreeSlot)}
        </h3>
        <div className="slot__btn-stack mg-bottom-medium">
          <button className="select__btn">
            <CalendarIcon
              className="nav__search-icon svg-icons"
              width={20}
              height={20}
            />
            {translate(labels.selectDateOfTheEvent)}
          </button>
          <button className="select__btn">
            <PersonIcon
              className="nav__search-icon svg-icons"
              width={20}
              height={20}
            />
            {translate(labels.selectServices)}
          </button>
          <button className="select__btn">
            <PinIcon
              className="nav__search-icon svg-icons"
              width={20}
              height={20}
            />
            {translate(labels.where)}
          </button>
        </div>
        <NavLink
          to={`/${locale}/searchslotsub/result`}
          className="primary__btn"
        >
          {translate(labels.search)}
        </NavLink>
      </div>
      <Footer />
    </div>
  );
};

export default SearchSubSlotPage;
