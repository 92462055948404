/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getFirestoreDocs } from "../../services/firestore_service";
import { categoriesCollection } from "../../utils/collections";
import { getMultipleRandom } from "../../helpers/getMultipleRandom";
import { getBussinessByCategories } from "../../services/bussiness_service";
import { ActionState } from "../../utils/enum";

export const CategoryContext = React.createContext();

export const CategoryProvider = ({ children }) => {
  const [categoryList, setcategoryList] = useState([]);
  const [randomcategoryList, setrandomcategoryList] = useState([]);

  const [categoryloading, setLoading] = useState(false);

  const [categoryBussinessList, setcategoryBussinessList] = useState([]);
  const [categoryBussinessListState, setcategoryBussinessListState] = useState(
    ActionState.Initial
  );
  const [lastCategoryFetched, setlastCategoryFetched] = useState(null);

  const getCategories = async (refresh) => {
    if (refresh === true || categoryList.length === 0) {
      console.log("getCategories");
      setLoading(true);
      if (
        window.location.href.includes("/category/") &&
        lastCategoryFetched === null
      ) {
        setcategoryBussinessListState(ActionState.Loading);
      }
      const categories = await getFirestoreDocs(categoriesCollection);
      setcategoryList(categories);
      setrandomcategoryList(getMultipleRandom(categories, 9));
      setLoading(false);

      if (
        window.location.href.includes("/category/") &&
        lastCategoryFetched === null
      ) {
        getInitialAllBussinessByCategories(categories);
      }
    }
  };

  const getInitialAllBussinessByCategories = async (categoryList) => {
    try {
      const href = window.location.href;
      const category = href.substring(href.lastIndexOf("/") + 1);
      console.log("--------", categoryList.length, category);
      setlastCategoryFetched(category);

      const name = getRightCategoryName(category, categoryList);

      const bussiness = await getBussinessByCategories(name);
      setcategoryBussinessList(bussiness);
      setcategoryBussinessListState(ActionState.Loaded);
    } catch (e) {
      setcategoryBussinessListState(ActionState.Error);
    }
  };

  const getAllBussinessByCategories = async (category) => {
    console.log("--------yaa", categoryList.length);
    try {
      if (lastCategoryFetched === category || categoryList.length === 0) {
        return;
      }
      console.log("--------yaa return", categoryList.length);
      setlastCategoryFetched(category);
      setcategoryBussinessListState(ActionState.Loading);
      const name = getRightCategoryName(category, categoryList);

      const bussiness = await getBussinessByCategories(name);
      setcategoryBussinessList(bussiness);
      setcategoryBussinessListState(ActionState.Loaded);
    } catch (e) {
      setcategoryBussinessListState(ActionState.Error);
    }
  };

  const getRightCategoryName = (name, categoryList) => {
    let id = "";

    for (var category of categoryList) {
      if (
        category.key.replaceAll(/[/" "]/g, "-").toLowerCase() ===
        name.toLowerCase()
      ) {
        id = category.key;
        break;
      }
    }
    return id;
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        categoryloading,
        randomcategoryList,
        categoryList,
        getCategories,
        getAllBussinessByCategories,
        categoryBussinessList,
        categoryBussinessListState,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
