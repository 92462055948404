import React from "react";
import CollapseContainer from "../../../components/collapse/CollapseContaier";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";

const Faq = () => {
  const translate = useLocalization();

  const list = [
    {
      title: translate(labels.faqList[0]["title"]),
      desc: translate(labels.faqList[0]["desc"]),
    },
    {
      title: translate(labels.faqList[1]["title"]),
      desc: translate(labels.faqList[1]["desc"]),
    },
    {
      title: translate(labels.faqList[2]["title"]),
      desc: translate(labels.faqList[2]["desc"]),
    },
    {
      title: translate(labels.faqList[3]["title"]),
      desc: translate(labels.faqList[3]["desc"]),
    },
    {
      title: translate(labels.faqList[4]["title"]),
      desc: translate(labels.faqList[4]["desc"]),
    },
  ];
  return <div  id="faq"><CollapseContainer list={list} /></div>;
};

export default Faq;
