import { doc, getDoc, getFirestore, collection, getDocs, where, query } from "firebase/firestore";
import { userCollection } from "../utils/collections";

let loadedUserProfile = [];

const checkUserProfileAlreadyAdded = (id) => {
  const check = loadedUserProfile.find((element) => element.uid === id);
  if (check === undefined || check === null) {
    return false;
  }
  return true;
};
const getLoadedUserProfile = (id) => {
  const getprofile = loadedUserProfile.find((element) => element.uid === id);

  return getprofile;
};
const addtoloaded = (profile) => {
  if (!checkUserProfileAlreadyAdded(profile.uid)) {
    loadedUserProfile.push(profile);
  } else {
    const index = loadedUserProfile.findIndex(
      (element) => element.uid === profile.uid
    );

    if (index >= 0) {
      loadedUserProfile[index] = profile;
    }
  }
  //   console.log(loadedUserProfile);
};

export const getUserProfile = async (id) => {
  if (checkUserProfileAlreadyAdded(id)) {
    return getLoadedUserProfile(id);
  } else {
    console.log("firestore");
    console.log("user profile ID: " + id);
    const db = getFirestore();

    const docRef = doc(db, userCollection, id);
    const docSnap = await getDoc(docRef);
    const profile = docSnap.data();
    console.log("profile", profile);
    addtoloaded(profile);
    return profile;
  }
};



export const findUserId = async (email) => {


  const db = getFirestore();
  const q = query(collection(db, userCollection), where("email", "==", email));

  const querySnapshot = await getDocs(q);

    if(!querySnapshot.empty) {
        console.log("querySnapshot");
        console.log(querySnapshot);
        return querySnapshot.docs[0].data().uid;
        // rest of your code 
    }
    return null;
};




