import { orderBy } from "firebase/firestore";
import { favouritesCollection, userCollection } from "../utils/collections";
import { checkIsLoggedIn, getCurrentLoginUserId } from "./auth_service";
import {
  deleteFirestoreDoctoChildPath,
  getFirestoreDocofChildCollection,
  getFirestoreDocsFromChildCollection,
  getServerTimestamp,
  setFirestoreDoctoChildPath,
} from "./firestore_service";

export const getMyAllFavouriteUsers = async () => {
  try {
    if (checkIsLoggedIn()) {
      const myId = getCurrentLoginUserId();
      let list = [];
      if (myId !== "") {
        var value = await getFirestoreDocsFromChildCollection(
          userCollection,
          myId,
          favouritesCollection,
          [orderBy("timestamp", "desc")]
        );
        for (var element of value) {
          list.push(element);
        }
      }
      console.log(list);
      return list;
    }
    return [];
  } catch (e) {
    throw e;
  }
};

export const addFavouriteBussinessUser = async (bussinessUserid) => {
  try {
    const myId = getCurrentLoginUserId();
    await setFirestoreDoctoChildPath(
      userCollection,
      myId,
      favouritesCollection,
      bussinessUserid,
      {
        id: bussinessUserid,
        timestamp: getServerTimestamp(),
      }
    );
  } catch (e) {
    throw e;
  }
};

export const removeFavouriteBussinessUser = async (bussinessUserid) => {
  try {
    const myId = getCurrentLoginUserId();
    await deleteFirestoreDoctoChildPath(
      userCollection,
      myId,
      favouritesCollection,
      bussinessUserid
    );
  } catch (e) {
    throw e;
  }
};

export const checkIsFavourite = async (bussinessUserid) => {
  try {
    const myId = getCurrentLoginUserId();

    const data = await getFirestoreDocofChildCollection(
      userCollection,
      myId,
      favouritesCollection,
      bussinessUserid
    );

    return data.exists() ? false : true;
  } catch (e) {
    throw e;
  }
};
