export const userBussinessCollection = "users_business";
export const categoriesCollection = "Categories";
export const userCollection = "users";
export const usersCollection = "users";
export const bookingsCollection = "Bookings";
export const notificationCollection = "Notifications";
export const reviewsCollection = "reviews";
export const favouritesCollection = "Favourites";
export const conversationCollection = "Conversations";

