import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { userBussinessCollection, userCollection } from "../utils/collections";
import {
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithPopup,
} from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth";

// export const authenticateUser=async( user) => {
//     QuerySnapshot result = await firestore
//         .collection("users_business")
//         .where("uid", isEqualTo: "${user.uid}_B")
//         .get();

//     final List<DocumentSnapshot> docs = result.docs;
//     return docs.isEmpty ? true : false;
//   }

import { auth } from "../firebase/firebase.config";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { userlocalDbKey } from "../utils/constant";
export async function IsLoggedIn() {
  try {
    await new Promise((resolve, reject) =>
      auth.onAuthStateChanged(
        (user) => {
          if (user) {
            // User is signed in.
            resolve(user);
          } else {
            // No user is signed in.
            reject("no user logged in");
          }
        },
        // Prevent console error
        (error) => reject(error)
      )
    );
    return true;
  } catch (error) {
    return false;
  }
}
export const getCurrentLoginUserId = () => {
  return auth.currentUser?.uid ?? "";
};

export const checkIsLoggedIn = () => {
  return auth.currentUser?.uid === undefined || auth.currentUser?.uid === null
    ? false
    : true;
};

export const googleSignin = async (locale) => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    auth.languageCode = locale;

    const result = await signInWithPopup(auth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    GoogleAuthProvider.credentialFromResult(result);

    // const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    const checkUserExsist = await checkEmailExists(user.email);

    if (!checkUserExsist) {
      await addInitialUserData(result.user.uid, null, user.email);
    }
    return user.uid;
  } catch (error) {
    const errorMessage = error.message;
    throw errorMessage;
  }
};
export const facebookSignin = async (locale) => {
  try {
    const provider = new FacebookAuthProvider();
    auth.languageCode = locale;

    const result = await signInWithPopup(auth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    FacebookAuthProvider.credentialFromResult(result);

    // const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    const checkUserExsist = await checkEmailExists(user.email);

    if (!checkUserExsist) {
      await addInitialUserData(user.uid, null, user.email);
    }
    return user.uid;
  } catch (error) {
    const errorMessage = error.message;
    throw errorMessage;
  }
};
export const signin = async (email, password) => {
  try {
    if (!email && !password) return;
    const checkUserExsist = await checkEmailExists(email);

    if (!checkUserExsist) {
      throw new Error("User doesn't exist");
    }
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    // await checkProfileisCompleted(userCredential.uid);
    return userCredential.user.uid;
  } catch (e) {
    throw e;
  }
};
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (e) {
    throw e;
  }
};

export const signup = async (email, password, phone) => {
  try {
    const checkUser = await checkEmailExistsInBoth(email);
    console.log(checkUser);

    if (checkUser) {
      if (checkUser.type === "user") {
        throw new Error("User already exist");
      }
      if (checkUser.type === "bussiness") {
        await addInitialUserData(
          checkUser.id,
          phone === undefined ? null : phone,
          email
        );
        await signin(email, password);
        return checkUser.id;
      }
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await addInitialUserData(
      userCredential.user.uid,
      phone === undefined ? null : phone,
      email
    );
    return userCredential.user.uid;
  } catch (e) {
    throw e;
  }
};
export const checkEmailExistsInBoth = async (email) => {
  const db = getFirestore();
  let q = query(collection(db, userCollection), where("email", "==", email));

  let querySnapshot = await getDocs(q);
  console.log(querySnapshot.docs);
  if (!querySnapshot.empty) {
    return {
      type: "user",
      id: querySnapshot.docs[0].id,
    };
  } else {
    q = query(
      collection(db, userBussinessCollection),
      where("email", "==", email)
    );

    querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return {
        type: "bussiness",
        id: querySnapshot.docs[0].id,
      };
    }
  }

  return null;
};
export const userStateListener = (callback) => {
  return onAuthStateChanged(auth, callback);
};

export const SignOutUser = async () => await signOut(auth);
export const verifyAndRedirectUser = async (
  id,
  phoneNumber,
  history,
  locale,
  onDone,
  email
) => {
  const checkIsNewUser = await isNewUser(id);

  if (checkIsNewUser) {
    await addInitialUserData(id, phoneNumber, email);

    history.push(`/${locale}/client`);
    window.location.reload();

    // history.push(`/biznes/${locale}/user/${generateBussinessUserId(id)}`);
  } else {
    if (onDone === null) {
      history.push(`/${locale}/client`);
    } else {
      await checkProfileisCompleted(id);
      onDone(true);
    }

    // history.push(`/biznes/${locale}/user/${generateBussinessUserId(id)}`);
  }
};
export const checkEmailExists = async (email) => {
  const db = getFirestore();
  const q = query(collection(db, userCollection), where("email", "==", email));

  const querySnapshot = await getDocs(q);

  return querySnapshot.empty ? false : true;
};
export const isNewUser = async (id) => {
  const db = getFirestore();

  const docRef = doc(db, userCollection, id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? false : true;
};

export const checkProfileisCompleted = async (id) => {
  const db = getFirestore();

  const docRef = doc(db, userCollection, id);

  const docSnap = await getDoc(docRef);
  console.log("User Profile Id", id, docSnap);
  const data = docSnap.data();
  console.log("User Profile", data, docSnap, docRef);

  if (data !== undefined) {
    localStorage.setItem(userlocalDbKey, JSON.stringify(data));
  }
  return data?.isProfileCompleted ?? false;
};

export const getUserData = () => {
  const userProfile = localStorage.getItem(userlocalDbKey);
  const user =
    userProfile === undefined || userProfile === null
      ? null
      : JSON.parse(userProfile);
  return user;
};

export const logout = () => {
  localStorage.removeItem(userlocalDbKey);
  signOut(auth)
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {});
};

export const addInitialUserData = async (id, phoneNumber, email) => {
  try {
    var initialData = {
      uid: id,
      accountCreatedDate: new Date().toISOString(),
      firstName: null,
      isProfileCompleted: false,
      lastName: null,
      address: [],
      phone: phoneNumber,
      profilePic: null,
      email: email,
      // booking_settings: {
      //   bookingConfirmMode: 0,
      //   allowBookingChange: 0,
      //   showYourCalender: 1,
      //   reminder: 30,
      //   minimalGap: 1,
      //   latestMoment: 48,
      //   areaWhereServiceProvided: 0,
      //   autoConfirmed: false,
      // },
      notification_settings: {
        cancelleddBooking: true,
        incomingCall: true,
        messages: true,
        newBooking: true,
        push: true,
      },
      option_settings: {
        newsLetter: true,
        phoneCalls: true,
        textMessage: true,
      },
    };
    const db = getFirestore();
    console.log("initialData ", initialData);
    const collectionRef = doc(db, userCollection, id);
    // console.log("data", data);
    await setDoc(collectionRef, initialData);
  } catch (error) {
    throw error;
  }
};




export const signUpWhileConversation= async (email, firstName) => {
  try {
    const password = Math.random().toString(36).substr(2, 8);
    const checkUser = await checkEmailExistsInBoth(email);
     var address = [];



    if (checkUser) {
        await addUserDataFromForm(
          checkUser.id,
          "",
          email,
          firstName, 
          "",
          address
        );
        return checkUser.id;
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await addUserDataFromForm(
      userCredential.user.uid,
          "",
          email,
          firstName, 
          "",
          address
    );
    return userCredential.user.uid;
  } catch (e) {
    throw e;
  }
};

export const signUpWhileBooking= async (email, phone, firstName, lastName, latitude, longitude, street, houseNo, apartmentNo, postalCode, city, country) => {
  try {
    const password = Math.random().toString(36).substr(2, 8);
    const checkUser = await checkEmailExistsInBoth(email);
     var address;
    if (apartmentNo == "")
    {
      address = [{"latitude":latitude,"longitude":longitude,"address":street + " " + houseNo + ", " + postalCode + " " + city + ", " + city}];
    }
    else
    {
      address = [{"latitude":latitude,"longitude":longitude,"address":street + " " + houseNo + "/" + apartmentNo + ", " + postalCode + " " + city + ", " + country}];

    }


    if (checkUser) {
        await addUserDataFromForm(
          checkUser.id,
          phone,
          email,
          firstName, 
          lastName,
          address
        );
        return checkUser.id;
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await addUserDataFromForm(
      userCredential.user.uid,
          phone,
          email,
          firstName, 
          lastName,
          address
    );
    return userCredential.user.uid;
  } catch (e) {
    throw e;
  }
};

export const addUserDataFromForm = async (id, phoneNumber, email, firstName, lastName, address) => {
  try {
    var initialData = {
      uid: id,
      accountCreatedDate: new Date().toISOString(),
      firstName: firstName,
      isProfileCompleted: true,
      lastName: lastName,
      address: address,
      phone: phoneNumber,
      profilePic: null,
      email: email,
      notification_settings: {
        cancelleddBooking: true,
        incomingCall: true,
        messages: true,
        newBooking: true,
        push: true,
      },
      option_settings: {
        newsLetter: true,
        phoneCalls: true,
        textMessage: true,
      },
    };
    const db = getFirestore();
    console.log("initialData ", initialData);
    const collectionRef = doc(db, userCollection, id);
    // console.log("data", data);
    await setDoc(collectionRef, initialData);
  } catch (error) {
    throw error;
  }
};
