import { doc, getFirestore, setDoc, updateDoc, where, orderBy, query, collection, getDocs, getDoc } from "firebase/firestore";
import { hashCode } from "../utils/function";
import { v4 as uuidv4 } from "uuid";
import { conversationCollection } from "../utils/collections";
export const updateTranslateMessage = async (
  translatedMessage,
  groupChatId,
  messageId,
  currentLanguage
) => {
  try {
    const db = getFirestore();
    const messageRef = doc(
      db,
      "Conversations",
      groupChatId,
      groupChatId,
      messageId
    );
    const convoRef = doc(db, "Conversations", groupChatId);

    var d1 = {};
    d1[`translated_message.${currentLanguage}`] = translatedMessage;

    var d2 = {};
    d2[`lastMessage.translated_message.${currentLanguage}`] = translatedMessage;

    console.log(d1, d2);
    await updateDoc(messageRef, d1);
    await updateDoc(convoRef, d2);
  } catch (e) {
    throw e;
  }
};

export const sendMessage = async (messageData, groupChatId, users) => {
  console.log("dodajemy jakas wiadomosc...");
  const db = getFirestore();
  const id = uuidv4();
  messageData["id"] = id;
  const messageRef = doc(db, "Conversations", groupChatId, groupChatId, id);
  const convoRef = doc(db, "Conversations", groupChatId);
  await setDoc(messageRef, messageData);
  delete messageData.receiverToken;
  var convoData = {
    lastMessage: messageData,
    users: users,
    groupChatid: groupChatId,
  };
  await setDoc(convoRef, convoData);
};
/*
export function getGroupChatId(myid, userId) {
  if (hashCode(myid) <= hashCode(userId)) {
    return `${myid}-${userId}`;
  } else {
    return `${userId}-${myid}`;
  }
} */

export const getConversation = async (id) => {
    const db = getFirestore();

    const docRef = doc(db, "Conversations", id);
    const docSnap = await getDoc(docRef);
    const conversation = docSnap.data();
    console.log("conversation w getConversation", conversation);
    return conversation;

};

export const generateChatId = async () => {
    var id;
    while(1)
    {
    id = uuidv4();

    const db = getFirestore();

    const colRef = collection(db, conversationCollection);


    let q = query(colRef, where(id, "==", "groupChatid"));
    const querySnapshot = await getDocs(q);

    if(querySnapshot.empty) {
        return id
        // rest of your code 
    }





}

};


export const getUsersFromConversation = async (id) => {
  const conversation = await getConversation(id);
  //const ids = {user: booking.userID, business: booking.businessUserID }
  //console.log("ids");
  //console.log(booking.bussinessUserId);
  //console.log(booking.userId);
  if (conversation.users[0].slice(-2) == "_B")
  {
  return {
        business: conversation.users[0],
        regular: conversation.users[1],
    }
  }
  else
  {
  return {
        business: conversation.users[1],
        regular: conversation.users[0],
    }
  }    
};


export const markAllAsRead = async (groupChatId, myId) => {
  const db = getFirestore();

  const docRef = doc(db, "Conversations", groupChatId);
  const colRef = collection(docRef, groupChatId);

  let q = query(colRef, where(myId, "==", "unread"));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((e) => {
    console.log("querySnapshot ", e.data().id, e.data()[myId]);
    const messageRef = doc(
      db,
      "Conversations",
      groupChatId,
      groupChatId,
      e.data().id
    );
    var d = {};
    d[myId] = "read";

    updateDoc(messageRef, d);
  });
};
