import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ChatIcon } from "../../assets/svg icons/chat-green.svg";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { languagesList } from "../../utils/function";
import MessageSentModal from "../MessageSentModal";
import {
  getCurrentLoginUserId,
  getUserData,
  signUpWhileConversation,
} from "../../services/auth_service";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  collection,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { getServerTimestamp } from "../../services/firestore_service";

import Loader from "../Loader";
import MessageTile from "../Chat/components/MessageTile";
import { getGroupChatId, sendMessage, generateChatId } from "../../services/chat_service";
import { findUserId } from "../../services/user_service";

import { AuthContext } from "../../context/auth";
import AuthModal from "../Registration/AuthModal";

const BasicChatProvider = ({ bussinessProfile }) => {
  const { loggedIn } = useContext(AuthContext);
  const [messageSent, setMessageSent] = useState(false);
  const [chat, setChat] = useState("");
  const translate = useLocalization();
  //const myid = getCurrentLoginUserId();
  const { locale = "en" } = useParams();

  const [isLoading, setisLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [conversationId, setConversationId] = useState("");
  //const groupChatId = getGroupChatId(myid, bussinessProfile.uid);


  const [showModal, setShowModal] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const getTranslationMap = (message) => {
    var appLanguages = languagesList;
    let data = {};
    for (var language in appLanguages) {
      if (locale === language) {
        data[language] = message;
        continue;
      } else {
        data[language] = null;
      }
    }
    return data;
  };

  const SendMessage = async (event) => {
    event.preventDefault();


    const chatId = await generateChatId();
    setConversationId(chatId);


    var myid = await findUserId(email);


    if (myid == null) myid = await signUpWhileConversation(email, firstName);


    // signUpWhileConversation


    const type = 0;

    setChat("");

    var messageData = {
      id: "",
      idFrom: myid,
      idTo: bussinessProfile.uid,
      timestamp: getServerTimestamp(),
      content: message,
      type: type,
      senderName: firstName,
      receiverToken: bussinessProfile.pushToken,
      translated_message: type === 0 ? getTranslationMap(message) : null,
      base_language: locale,
    };
    messageData[myid] = "unread";
    messageData[bussinessProfile.uid] = "unread";

    await sendMessage(messageData, chatId, [myid, bussinessProfile.uid]);
    setMessage("");
    setFirstName("");
    setEmail("");
    setMessageSent(true);
    setChat("");
  };


  return (
    <>
    <div className="provider__col">
      <div className="provider">
        <div className="provider-header">
          <h3 className="provider-heading" style={{ textTransform: "none"}}>{translate(labels.chat)}</h3>
            <ChatIcon
              width={30}
              height={30}
              className="provider-header-icon svg-icons"
            />
        </div>



    <form onSubmit={SendMessage} className="primary-form auth-form">
      <div className="form__group">
        <div className="form__group">
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.email)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "5px", paddingBottom: "5px" }}
          >
            <input
              name="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="primary__input-content"
              type="email"
              placeholder={translate(labels.enterEmail)}
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </div>
        </div>
        <div className="form__group">
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.firstName)}
          </label>
          <div
            className="primary__input"
            style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "5px", paddingBottom: "5px" }}
          >
            <input
              name="firstName"
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
              className="primary__input-content"
              placeholder={translate(labels.enterFirstName)}
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </div>
        </div>
            <div className="text-field">
          <label htmlFor="mobile-input" className="primary-label">
            {translate(labels.message)}
          </label>

              <textarea
                rows="3"
                value={message}
                placeholder={translate(labels.typeMessageHere)}
                className="profile__form-textarea primary__textarea"
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>

            </div>




      </div>
                <input
            type="submit"
            className="primary__btn"
            style={{ padding: "10px", fontSize: "16px", width: "200px", border: "none" }}
            value={translate(labels.send)}
          ></input>
    </form>

    </div>
          </div>
      {messageSent && (
        <MessageSentModal
          onChange={async (v) => {
              setMessageSent(false);
            }}
            link = {"https://hauzy.app/" + locale + "/booking-chat/" + conversationId}
        />
      )}
    </>
  );
};

export default BasicChatProvider;
