import React from "react";
import RegistrationForm from "../RegistrationForm";
import Navbar from "../Navbar";
import Footer from "../Footer";

const RegistrationOne = ({ title }) => {
  // const history = useHistory();
  // const { locale = "en" } = useParams();

  // const { loggedIn } = useContext(AuthContext);

  // if (loggedIn) {
  //   history.push(`/${locale}/client`);
  //   return <></>;
  // } else {
  return (
    <div className="page-container">
      <Navbar />
      <div className="registration page-content">
        <RegistrationForm title={title || "Create an account"} />
      </div>
      <Footer />
    </div>
  );
  // }
};

export default RegistrationOne;
