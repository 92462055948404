import React from "react";

// import { getUserData } from "../../../../services/auth_service";
import ProfileCompleteForm from "./ProfileCompleteForm";

const YourDetail = () => {
  // const user = getUserData();
  // const userDetails = user;
  // const [addresses, setAddresses] = useState([]);
  // const translate = useLocalization();

  // const addAddress = () => {
  //   setAddresses([...addresses, { name: "", isEditing: true }]);
  // };

  // const removeAddress = (index) => {
  //   const newAddresses = [...addresses];
  //   newAddresses.splice(index, 1);
  //   setAddresses(newAddresses);
  // };

  // const toggleEdit = (index) => {
  //   const newAddresses = [...addresses];
  //   newAddresses[index].isEditing = !newAddresses[index].isEditing;
  //   setAddresses(newAddresses);
  // };

  // const saveAddress = (index) => {
  //   const newAddresses = [...addresses];
  //   newAddresses[index].isEditing = false;
  //   setAddresses(newAddresses);
  // };

  // const handleAddressChange = (event, index) => {
  //   const newAddresses = [...addresses];
  //   newAddresses[index].name = event.target.value;
  //   setAddresses(newAddresses);
  // };

  // const initialAddressesState = userDetails?.address?.map((address) => {
  //   const parsedAddress = JSON.parse(address);
  //   return { name: parsedAddress.address, isEditing: false };
  // });

  // Set the initial state of the addresses array to the parsed initial addresses
  // useState(() => setAddresses(initialAddressesState));
  return (
    <section className="client-details">
      <ProfileCompleteForm
        onSubmitSuccess={(v) => {
          if (v) {
            // setshowProfileCompleteDialog(false);
          }
        }}
      />
    </section>
  );
  // return (
  //   <section className="address-sections">
  //     {userDetails?.uid && (
  //       <Fragment>
  //         {addresses?.map((address, index) => (
  //           <div className="flex-justify-between input-section" key={index}>
  //             {address.isEditing ? (
  //               <Fragment>
  //                 <textarea
  //                   type="text"
  //                   value={address.name}
  //                   onChange={(e) => handleAddressChange(e, index)}
  //                   placeholder="Address"
  //                   rows={3}
  //                 />
  //                 <button
  //                   className="primary__btn save-button"
  //                   onClick={() => saveAddress(index)}
  //                 >
  //                   {translate(labels.save)}
  //                 </button>
  //               </Fragment>
  //             ) : (
  //               <Fragment>
  //                 <span className="address-text">{address.name}</span>
  //                 <div className="save-edit-icon">
  //                   <span onClick={() => toggleEdit(index)}>
  //                     <Icon
  //                       name="edit"
  //                       className="mg-right-small edit-icon pointer"
  //                       style={{ verticalAlign: "super" }}
  //                     />
  //                   </span>

  //                   <CrossIcon
  //                     width={28}
  //                     height={28}
  //                     onClick={() => removeAddress(index)}
  //                     className="pointer"
  //                   />
  //                 </div>
  //               </Fragment>
  //             )}
  //           </div>
  //         ))}

  //         <button className="primary__btn mt-4" onClick={addAddress}>
  //           {translate(labels.addAddress)}
  //         </button>
  //       </Fragment>
  //     )}
  //   </section>
  // );
};

export default YourDetail;
