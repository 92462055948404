import React, { useContext, useEffect, useState } from "react";
import Icon from "../Icon";
import {
  currentLocalDate,
  formatDate,
  getTwoDatesDifferenceinSeconds,
  minutuestoHoursString,
} from "../../utils/function";
import {
  getbookingStutusLocaleText,
  getreminderLocaleText,
} from "../../utils/locale_functions";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";

import { errorAlert, successAlert } from "../../utils/alert";
import Loader from "../Loader";
import {
  changeBookingStatusAndNotify,
  getGoogleEventsData,
} from "../../services/booking_service";
import { getUserData } from "../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BookingContext } from "../../context/booking";
import AddReviewModal from "./AddReviewModal";
import { checkAlreadyReviewed } from "../../services/review_service";

const SimpleBookingPreviewModal = ({ setbookingPreviewDialog, booking }) => {
  console.log(booking);
  const translate = useLocalization();

  const currentStatus = booking?.status ?? "";
  const date = formatDate(booking.startDate, "dddd, MMMM Do YYYY");
  const time = formatDate(booking.startDate, "HH:mm a");
  const address = booking?.userAddress ?? "";

  const { bussinessUserProfile } = booking;
  const [bussinesProfileLoading, setbussinesProfileLoading] = useState(false);
  // const [bussinessUserProfile, setbussinessUserProfile] = useState(null);
  const [canEditBooking, setcanEditBooking] = useState(false);
  const currentLoginUser = getUserData();
  const bookingService = JSON.parse(booking.service);
  const { locale = "en" } = useParams();
  const { getBookings } = useContext(BookingContext);
  const [showAddReviewDialog, setshowAddReviewDialog] = useState(null);

  const bookingChangeHours =
    bussinessUserProfile?.booking_settings?.allowBookingChange ?? 0;

  var bookingChangeHoursinSeconds = bookingChangeHours * 3600;

  if (booking?.status !== null) {
    var diff = getTwoDatesDifferenceinSeconds(
      booking.startDate,
      currentLocalDate()
    );
    console.log("diff", diff);
    if (bookingChangeHoursinSeconds >= diff && currentStatus === "Confirmed") {
      setcanEditBooking(true);
    }
  }

  const changeStatus = async (newStatus) => {
    try {
      const notificationData = {
        id: booking?.notificationId,
        content: `Booking ${newStatus}`,
        idTo: bussinessUserProfile.uid,
        receiverToken: bussinessUserProfile.pushToken,
        senderName:
          currentLoginUser?.firstName + " " + currentLoginUser?.lastName,
        status: newStatus,
        senderPic: currentLoginUser?.profilePic,
        bookingId: booking.bookingId,
      };
      setbussinesProfileLoading(true);
      await changeBookingStatusAndNotify(
        booking.bookingId,
        newStatus,
        notificationData
      );

      setbussinesProfileLoading(false);
      setbookingPreviewDialog(null);
      successAlert(translate(labels.statusChanged));
      getBookings(true);
    } catch (e) {
      errorAlert(e);
    }
  };

  const [alreadyReviewed, setalreadyReviewed] = useState(true);
  useEffect(() => {
    (async () => {
      if (currentStatus === "Completed") {
        const value = await checkAlreadyReviewed(booking.bookingId);
        if (value === false) {
          setalreadyReviewed(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="booking-preview-modal">
      <div className="booking-preview-modal-content">
        <span
          className="booking-preview-modal-close-icon"
          onClick={() => setbookingPreviewDialog(null)}
        >
          <Icon name="cancel" />
        </span>

        {bussinesProfileLoading ? (
          <Loader marginTop={"20vh"} />
        ) : bussinessUserProfile === null ? (
          <></>
        ) : (
          <section className="content-section flex-column">
            <div className="profile_wrapper flex-row">
              <span className="flex-row align-item-center">
                <img
                  src={bussinessUserProfile?.profilePic}
                  alt="Guest"
                  className="guest__image mg-right-small"
                />
                <div className="flex-column">
                  <label>
                    {(bussinessUserProfile?.firstName ?? "") +
                      " " +
                      (bussinessUserProfile.lastName ?? "")}
                  </label>
                  <div className="status-container">
                    {getbookingStutusLocaleText(translate, currentStatus)}
                  </div>
                </div>
              </span>
              <div className="calender-ic">
                <Icon name="message" className="ic" />
              </div>
            </div>
            <div className="flex-column">
              <label>{translate(labels.date_time)}</label>
              <span>{date}</span>
              <span>{time}</span>
            </div>
            <div className="flex-column">
              <label>{translate(labels.clientAddress)}</label>
              <span>{address}</span>
            </div>

            <div className="flex-column">
              <label>{translate(labels.reminder)}</label>
              <span>
                {getreminderLocaleText(translate, booking?.userReminder)}
              </span>
            </div>
            <div className="flex-column">
              <label>{translate(labels.service)}</label>
              <span>
                {bookingService.title[locale]} -{" "}
                {minutuestoHoursString(bookingService.time, translate)} -{" "}
                {bookingService.price} zł
              </span>
            </div>
            <div className="button-wrapper">
              {currentStatus === "Pending" ? null : currentStatus ===
                "Confirmed" ? (
                <button
                  onClick={() => {
                    getGoogleEventsData(booking);
                  }}
                  className="primary__btn"
                >
                  {translate(labels.addtoGoogleCalender)}
                </button>
              ) : currentStatus === "Completed" && !alreadyReviewed ? (
                <button
                  onClick={() => setshowAddReviewDialog(booking)}
                  className="primary__btn"
                >
                  {translate(labels.addReview)}
                </button>
              ) : currentStatus === "Waiting" ? (
                <button
                  onClick={() => changeStatus("Accepted")}
                  className="primary__btn"
                >
                  {translate(labels.accept)}
                </button>
              ) : null}

              {canEditBooking ? (
                <button className="primary__btn">
                  {translate(labels.changeBooking)}
                </button>
              ) : null}

              {currentStatus === "Waiting" ? (
                <button
                  onClick={() => changeStatus("Canceled")}
                  className="primary__btn"
                >
                  {translate(labels.cancelBooking)}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setbookingPreviewDialog(null);
                  }}
                  className="primary__btn"
                >
                  {translate(labels.close)}
                </button>
              )}
            </div>
          </section>
        )}
      </div>
      {showAddReviewDialog !== null ? (
        <AddReviewModal
          setshowAddReviewDialog={setshowAddReviewDialog}
          booking={booking}
          onDone={(v) => {
            if (v === true) {
              setbookingPreviewDialog(null);
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default SimpleBookingPreviewModal;
