export const clientPrimaryColor = "#0AC5B8";
export const businessPrimaryColor = "#233b88";

export const businessButtonDisabled = "#4b689e";
export const clientButtonDisabled = "#4b9e49";

export const clientButtonBackground =
  "linear-gradient(-29deg, rgb(14, 135, 127) 0%, rgb(10, 197, 184) 100%)";

export const businessButtonBackground =
  "linear-gradient( -29deg, rgb(65, 99, 209) 0%, rgb(35, 59, 136) 100%)";
