import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { userBussinessCollection } from "../../../utils/collections";
import { getUserFullName } from "../../../utils/function";

const ChatRoomHeader = ({ bussinessProfile, setChatBoxActive }) => {
  const { locale = "en" } = useParams();
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    // setbussinessProfile(null);

    const db = getFirestore();
    const docRef = doc(db, userBussinessCollection, bussinessProfile.uid);

    const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
      setisActive(querySnapshot.data()["presence"]);
    });

    return () => unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bussinessProfile.uid]);
  return (
    <div className="chatroom__header">
      <img
        src={require("../../../assets/svg icons/left-caret.svg").default}
        style={{ width: "2rem", cursor: "pointer" }}
        className="chat__exit-btn mg-right-small"
        alt="caret"
        onClick={() => setChatBoxActive(false)}
      />

      {bussinessProfile === null ? (
        <img
          className="sidebar_icon"
          src={require("../../../assets/svg icons/user.svg").default}
          alt="profile"
        />
      ) : (
        <img
          className="sidebar_icon"
          src={bussinessProfile.profilePic}
          alt="profile"
        />
      )}

      <NavLink
        to={"/" + locale + "/serviceprovider/" + bussinessProfile.uid}
        className="chatroom__headerinfoleft"
      >
        <h3>
          {getUserFullName(bussinessProfile)}
          {/* {message.filter(({ idFrom }) => idFrom !== myid)?.[0]?.senderName} */}
        </h3>
        <span>{isActive ? "online" : ""}</span>
      </NavLink>
    </div>
  );
};

export default ChatRoomHeader;
