import Chats from "./Chats";

import React from "react";

const ChatList = (props) => {
  return (
    <div className="sidebar">
      <div className="sidebar__chhatsGroups">
        {props.rooms?.map((room) => {
          return (
            room?.id && (
              <Chats key={room?.id} room={room} onSelect={props.onSelect} />
            )
          );
        })}
      </div>
    </div>
  );
};

export default ChatList;
