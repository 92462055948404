import React, { useContext, useEffect, useState } from "react";

import YourDetail from "./components/YourDetail";
import YourBooking from "./components/YourBooking";
import YourFavourite from "./components/YourFavourite";
import YourReviews from "./components/YourReviews";
import YourSetting from "./components/YourSetting";

import Navbar from "../../../components/Navbar";
import SideUserNav from "../../../components/SideUserNav";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import ProfileCompleteModal from "./components/ProfileCompleteModal";
import Loader from "../../../components/Loader";
import ChatScreen from "../../../components/Chat/ChatScreen";
import { AuthContext } from "../../../context/auth";

// let isFirst = true;
const ClientPage = () => {
  const history = useHistory();
  const { locale = "en" } = useParams();
  const { path } = useRouteMatch();
  const { isProfileCompleted, loggedIn, authloading } = useContext(AuthContext);

  const [showProfileCompleteDialog, setshowProfileCompleteDialog] =
    useState(false);

  useEffect(() => {
    if (!loggedIn && authloading === false) {
      history.push(`/${locale}/signin`);
    }
  }, [authloading, history, locale, loggedIn]);
  const [activeScreen, setActiveScreen] = useState("details");

  if (authloading) {
    return <Loader />;
  }

  return (
    <div className="main">
      <>
        <Navbar loggedIn={true} setActiveScreen={setActiveScreen} />
        <section className="client-main">
          <SideUserNav
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
            // setshowProfileCompleteDialog={setshowProfileCompleteDialog}
            openProfileDialog={() => {
              setshowProfileCompleteDialog(true);
            }}
          />

          <Switch>
            <Route exact path={path} component={() => <YourDetail />} />
            <Route path={`${path}/messages`} component={() => <ChatScreen />} />
            <Route
              path={`${path}/bookings`}
              component={() => <YourBooking />}
            />
            <Route path={`${path}/favs`} component={() => <YourFavourite />} />
            <Route
              path={`${path}/opinions`}
              component={() => <YourReviews />}
            />
            <Route
              path={`${path}/settings`}
              component={() => <YourSetting />}
            />
          </Switch>

          {/* {screens.find(({ name }) => name === activeScreen).component} */}
        </section>
      </>

      {!isProfileCompleted || showProfileCompleteDialog ? (
        <ProfileCompleteModal
          setshowProfileCompleteDialog={setshowProfileCompleteDialog}
          showClose={!isProfileCompleted ? false : true}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClientPage;
