import React from "react";
import InfoSection from "../../../components/InfoSection";
import { useLocalization } from "../../../helpers/localization";
import { labels } from "../../../utils/locale";

const MakeBooking = () => {
  const translate = useLocalization();
  return (
    <div id="about_us">
    <InfoSection
      slogan1={translate(labels.headerSlogan1)}
      slogan2={translate(labels.headerSlogan2)}
      para={translate(labels.headerSlogan3)}
      image={require("../../../assets/Hauzy_graphic.svg").default}
      imageAlt="Hauzy graphic"
    />
    </div>
  );
};

export default MakeBooking;
