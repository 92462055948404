import React, { useEffect, useState } from "react";
import { getBussinessProfile } from "../../../services/bussiness_service";
import { getCurrentLoginUserId } from "../../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatLocalTime, formatTimeAgo } from "../../../utils/function";
import { ShimmerCircularImage, ShimmerText } from "react-shimmer-effects";
function Chats({ room, onSelect }) {
  const [bussinessProfile, setbussinessProfile] = useState(null);
  const chat = room.lastMessage;
  const userIds = room.users ?? [];
  const myid = getCurrentLoginUserId();
  const userId =
    userIds[0] === myid ? userIds[1] : userIds[1] === myid ? userIds[0] : "";
  const translatedMessage = chat["translated_message"];
  const timestamp = chat.timestamp;
  const { locale = "en" } = useParams();
  const lastMessage = chat.content ?? "";

  let message = "";

  if (translatedMessage == null) {
    message = lastMessage ?? "";
  } else {
    if (locale in translatedMessage) {
      message = translatedMessage[locale] ?? lastMessage ?? "";
    } else {
      message = lastMessage ?? "";
    }
  }
  // const myId =
  //   userIds[0] !== myid ? userIds[1] : userIds[1] !== myid ? userIds[0] : "";

  // const time = lastMessage["timestamp"];
  // const groupChatId = room.groupChatid;
  // const translatedMessage = lastMessage["translated_message"];

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    const profile = await getBussinessProfile(userId);
    setbussinessProfile(profile);
  };

  // if (bussinessProfile === null) {
  //   return <></>;
  // }
  return (
    <div
      key={chat.id}
      className={`chats ${chat.idFrom === chat.idTo && "chats__selected"} `}
      onClick={() => {
        if (bussinessProfile !== null) {
          onSelect(bussinessProfile);
        }
      }}
    >
      <div className="chat__avatar">
        {bussinessProfile === null ? (
          <ShimmerCircularImage size={35} />
        ) : (
          <img
            className="chat__avatar-image"
            src={bussinessProfile.profilePic}
            alt="profile"
          />
        )}

        {/* <div className="active-circle"></div> */}
      </div>

      <div className="chats__info">
        <div className="chat__sub">
          {bussinessProfile === null ? (
            <div
              style={{
                width: "120px",
              }}
            >
              <ShimmerText line={1} />
            </div>
          ) : (
            <h3>
              {bussinessProfile?.firstName ?? " "}
              {" " + bussinessProfile?.lastName ?? ""}
            </h3>
          )}

          <span className="chatroom__messagetimestamp">
            {timestamp === null || timestamp === undefined
              ? formatLocalTime(new Date())
              : formatTimeAgo(timestamp.toDate())}
          </span>
        </div>
        <p>
          {message.includes("firebasestorage.googleapis.com")
            ? "Image"
            : message.length > 25
            ? message.substring(0, 25) + "..."
            : message}
        </p>
      </div>
    </div>
  );
}

export default Chats;
