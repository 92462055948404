import React from "react";

const Image = ({ src, width, height, alt, className, onClick }) => {
  return (
    <img
      onClick={onClick}
      src={src}
      width={width}
      height={height}
      alt={alt}
      className={className}
      loading="lazy"
    />
  );
};

export default Image;
