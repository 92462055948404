import { notificationCollection } from "../utils/collections";
import { getCurrentLoginUserId } from "./auth_service";
import { getServerTimestamp, updateFirestoreDoc } from "./firestore_service";

export const updateNotification = async (data) => {
  try {
    const myId = getCurrentLoginUserId();
    data["idFrom"] = myId;
    data[myId] = true;
    data[data.idTo] = false;
    data["time"] = getServerTimestamp();
    await updateFirestoreDoc(notificationCollection, data.id, data);
  } catch (e) {
    throw Error(e);
  }
};
