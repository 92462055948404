import React, { useEffect } from "react";

import Icon from "../../../../components/Icon";

import { labels } from "../../../../utils/locale";
import { useLocalization } from "../../../../helpers/localization";
import ProfileCompleteForm from "./ProfileCompleteForm";

const ProfileCompleteModal = ({ setshowProfileCompleteDialog, showClose }) => {
  const translate = useLocalization();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-complete-modal">
      <div className="profile-complete-modal-content">
        {showClose ? (
          <span
            className="profile-complete-modal-close-icon"
            onClick={() => setshowProfileCompleteDialog(false)}
          >
            <Icon name="cancel" />
          </span>
        ) : null}
        <div className="profile-detail-intro">
          <img
            className="nav__logo"
            src={require("../../../../assets/client-logo.svg").default}
            alt="main indivisual logo"
          />
          <h2>{translate(labels.profileDetails)}</h2>
          <p>{translate(labels.pleasecompleteprofileDetails)}</p>
        </div>
        <ProfileCompleteForm
          onSubmitSuccess={(v) => {
            if (v) {
              setshowProfileCompleteDialog(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ProfileCompleteModal;
