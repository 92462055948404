import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ChatIcon } from "../../assets/svg icons/chat-green.svg";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";
import { languagesList } from "../../utils/function";
import {
  getCurrentLoginUserId,
  getUserData,
} from "../../services/auth_service";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  collection,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { getServerTimestamp } from "../../services/firestore_service";

import Loader from "../Loader";
import MessageTile from "./BasicMessageTile";
import BasicChatFooter from "./BasicChatFooter";
import BasicChatHeader from "./BasicChatHeader";
import { getGroupChatId, sendMessage } from "../../services/chat_service";

import AuthModal from "../Registration/AuthModal";
import ChatBookingTile from "../booking/ChatBookingTile"


const BasicChat = ({ bussinessProfile, regularProfile, booking, groupChatId }) => {
  console.log("booking booking booking")
  console.log(booking);
  console.log(groupChatId);
  const [chat, setChat] = useState("");
  const translate = useLocalization();
  const { locale = "en" } = useParams();
  const myProfile = getUserData();
  const [isLoading, setisLoading] = useState(false);

  const [message, setmessages] = useState([]);

  //const groupChatId = getGroupChatId(regularProfile.uid, bussinessProfile.uid);
  const [showModal, setShowModal] = useState(null);

  const getTranslationMap = (message) => {
    var appLanguages = languagesList;
    let data = {};
    for (var language in appLanguages) {
      if (locale === language) {
        data[language] = message;
        continue;
      } else {
        data[language] = null;
      }
    }
    return data;
  };

  const SendMessage = async (event) => {
    event.preventDefault();


    const type = 0;

    setChat("");

    var messageData = {
      id: "",
      idFrom: regularProfile.uid,
      idTo: bussinessProfile.uid,
      timestamp: getServerTimestamp(),
      content: chat,
      type: type,
      senderName: myProfile?.firstName + " " + myProfile?.lastName,
      receiverToken: bussinessProfile.pushToken,
      translated_message: type === 0 ? getTranslationMap(chat) : null,
      base_language: locale,
    };
    messageData[regularProfile.uid] = "unread";
    messageData[bussinessProfile.uid] = "unread";

    await sendMessage(messageData, groupChatId, [regularProfile.uid, bussinessProfile.uid]);

    setChat("");
  };

  useEffect(() => {

      setmessages([]);
      setisLoading(true);


      const db = getFirestore();
      const docRef = doc(db, "Conversations", groupChatId);
      const colRef = collection(docRef, groupChatId);

      const q = query(colRef, orderBy("timestamp", "desc"), limit(50));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let messages = [];
        querySnapshot.forEach((doc) => {
          messages.push({ ...doc.data(), id: doc.id });
        });
        setisLoading(false);
        setmessages(messages);
      });
      return () => unsubscribe;
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bussinessProfile]);
  if (isLoading || booking == undefined) {
    return <Loader />;
  } 
  return (
    <div className="provider__col">
      <div className="provider">

    { JSON.stringify(booking) === '{}' ? <BasicChatHeader bussinessProfile={bussinessProfile} /> : <ChatBookingTile booking={booking} bussinessProfile={bussinessProfile}/>  }


        <div className="chat__box">
          {isLoading ? (
            <Loader />
          ) : message.length === 0 ? (
            <div className="chat__box-text">
              {translate(labels.anyQuestions)}
              <ChatIcon
                width={30}
                height={30}
                className="provider-header-icon svg-icons"
              />
            </div>
          ) : (
            <div className="chatroom__body">
              {message.map((chat, i) => (
                <MessageTile key={i} chat={chat} myid={regularProfile.uid} />
              ))}
            </div>
          )}
        </div>
        <BasicChatFooter bussinessProfile={bussinessProfile} regularProfile={regularProfile} groupChatId={groupChatId}/>
      </div>

    </div>
  );
};

export default BasicChat;
