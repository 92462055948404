import { doc, getDoc, getFirestore, where } from "firebase/firestore";
import { userBussinessCollection } from "../utils/collections";
import { getFirestoreDocs } from "./firestore_service";
import { getDistanceFromLatLonInKm } from "../utils/function";
import { httpsCallable } from "firebase/functions";
import { cloudFunction } from "../firebase/firebase.config";
import { errorAlert } from "../utils/alert";

let loadedBussinessProfile = [];
function diffMinutes(date1, date2) {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  return Math.round((d2 - d1) / 60000); // Can use Math.floor or Math.ceil depends up to you
}
const cacheBussinessSlot = (slot, id) => {
  let d = new Date();
  d.setTime(d.getTime() + 30 * 60000);
  localStorage.setItem("slotsExipreTime", d);
  const localDbData = localStorage.getItem(
    "getBussinessUserEmptySlotsMultipleDays"
  );

  let data = {};
  if (localDbData !== null) {
    const parseData = JSON.parse(localDbData);
    parseData[id] = slot;
    data = parseData;
  } else {
    data[id] = slot;
  }
  localStorage.setItem(
    "getBussinessUserEmptySlotsMultipleDays",
    JSON.stringify(data)
  );
};
const getcacheBussinessSlot = (id) => {
  const now = new Date();
  const slotExpireTime = localStorage.getItem("slotsExipreTime");
  console.log(diffMinutes(now, slotExpireTime));
  const exipreDiff = diffMinutes(now, slotExpireTime);
  if (exipreDiff <= 0) {
    localStorage.removeItem("getBussinessUserEmptySlotsMultipleDays");
  }
  const localDbData = localStorage.getItem(
    "getBussinessUserEmptySlotsMultipleDays"
  );
  if (localDbData === null) {
    return null;
  }

  const parseData = JSON.parse(localDbData);
  if (id in parseData) {
    return parseData[id];
  }
  return null;
};

export const getBussinessSlotinRange = async (
  id,
  refresh,
  days,
  customstartUtc,
  customendUtc
) => {
  try {
    if (refresh) {
      localStorage.removeItem("getBussinessUserEmptySlotsMultipleDays");
    }
    const localData = getcacheBussinessSlot(id);
    if (localData !== null) {
      return localData ?? [];
    }
    const callable = httpsCallable(
      cloudFunction,
      "getBussinessUserEmptySlotsMultipleDays"
    );

    const endDateUtc = new Date();

    const maxDays = days ?? 13;
    endDateUtc.setDate(endDateUtc.getDate() + maxDays);
    const startDate = new Date();

    console.log("eee", customstartUtc, customendUtc);
    const input = {
      bussinessUserId: id,
      startDateUtc: customstartUtc ?? startDate.toISOString(),
      endDateUtc: customendUtc ?? endDateUtc.toISOString(),
      currentTime: new Date().toISOString(),
    };
    console.log(input);
    const result = await callable(input);
    const slots = result.data ?? [];
    console.log("Sloty..... ");
    console.log(slots);

    var slots_to_remove = 0;

    for (var i = 0; i < slots.length; i++) {
      if (typeof slots[i].slots !== 'undefined' && slots[i].slots.length > 0) {
        slots_to_remove = i;
        break;
      } 
    }

    if (slots_to_remove > 0)
    {
      for (var i = slots_to_remove - 1; i >= 0; i--) {
        slots.splice(i, 1);
      }
    }

    



    cacheBussinessSlot(slots, id);
    return slots;
  } catch (e) {
    throw Error(e);
  }
};

const checkBussinessProfileAlreadyAdded = (id) => {
  const check = loadedBussinessProfile.find((element) => element.uid === id);
  if (check === undefined || check === null) {
    return false;
  }
  return true;
};
const getLoadedBussinessProfile = (id) => {
  const getprofile = loadedBussinessProfile.find(
    (element) => element.uid === id
  );

  return getprofile;
};
const addtoloaded = (profile) => {
  if (!checkBussinessProfileAlreadyAdded(profile.uid)) {
    loadedBussinessProfile.push(profile);
  } else {
    const index = loadedBussinessProfile.findIndex(
      (element) => element.uid === profile.uid
    );

    if (index >= 0) {
      loadedBussinessProfile[index] = profile;
    }
  }
  //   console.log(loadedBussinessProfile);
};

export const getBussinessProfile = async (id) => {
  if (checkBussinessProfileAlreadyAdded(id)) {
    return getLoadedBussinessProfile(id);
  } else {
    console.log("firestore");
    const db = getFirestore();

    const docRef = doc(db, userBussinessCollection, id);
    const docSnap = await getDoc(docRef);
    const profile = docSnap.data();
    console.log("profile", profile);
    addtoloaded(profile);
    return profile;
  }
};

export const getAllBussinessUsers = async () => {
  try {
    var businesses = await getFirestoreDocs(userBussinessCollection, [
      where("isVisible", "==", true),
    ]);
    return businesses;
  } catch (e) {
    throw Error(e);
  }
};
// export const getInitailBusinessesUserWithInKM = async () => {
//   try {
//     const userProfile = getUserData();
//     let curentLongitude;
//     let curentLatitude;

//     if (
//       userProfile !== null &&
//       userProfile.address !== undefined &&
//       userProfile.address !== null &&
//       userProfile.address.length > 0
//     ) {
//       const firstLocation = JSON.parse(userProfile.address.at(-1));
//       curentLongitude = firstLocation.longitude;
//       curentLatitude = firstLocation.latitude;
//     } else {
//       const currentLoation = await getUserLocation();
//       console.log("currentLoation", currentLoation);
//       curentLongitude = currentLoation.coords.longitude;
//       curentLatitude = currentLoation.coords.latitude;
//     }
//     let list = [];
//     if (curentLatitude === undefined || curentLongitude === undefined) {
//       list = await getAllBussinessUsers();
//     } else {
//       list = await getBussinessUseWithInKM(curentLongitude, curentLatitude);
//     }

//     return list;
//   } catch (e) {
//     throw Error(e);
//   }
// };

export const getBussinessByCategories = async (category) => {
  try {
    var businesses = [];
    if (category.toString().toLowerCase() === "all") {
      businesses = await getFirestoreDocs(userBussinessCollection, [
        where("isVisible", "==", true),
      ]);
    } else {
      businesses = await getFirestoreDocs(userBussinessCollection, [
        where("isVisible", "==", true),
        where("category", "==", category),
      ]);
    }
    return businesses;
  } catch (e) {
    throw Error(e);
  }
};

export const getBussinessUseWithInKM = async (longitude, latitude) => {
  try {
    let kmRange = 50;

    var businesses = await getFirestoreDocs(userBussinessCollection, [
      where("isVisible", "==", true),
    ]);

    let list = [];
    for (var business of businesses) {
      const { location, booking_settings } = business;

      if (
        location === undefined ||
        location === null ||
        booking_settings === null
      ) {
        return;
      }
      kmRange =
        booking_settings.areaWhereServiceProvided === 0
          ? 50
          : booking_settings.areaWhereServiceProvided;

      var bussinesslocation = JSON.parse(location);

      const businesslat = Number.parseFloat(bussinesslocation["latitude"]);
      const businesslng = Number.parseFloat(bussinesslocation["longitude"]);
      const distance = getDistanceFromLatLonInKm(
        latitude,
        longitude,
        businesslat,
        businesslng
      );

      if (distance <= kmRange) {
        list.push(business);
      }
    }

    return list;
  } catch (e) {
    throw Error(e);
  }
};

export async function getUserLocation() {
  return new Promise(function (resolve, reject) {
    const successCallback = (position) => {
  resolve(position);
};

const errorCallback = (error) => {
            errorAlert(
              "Please allow location access from settings. To see users near by you"
            );
            resolve(null);
};
    // Promisifying the geolocation API
    if ("geolocation" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => resolve(position),
              (error) => reject(error),
              { enableHighAccuracy: true, maximumAge: 10000 }
            );
          } else if (result.state === "prompt") {
            // reject("Please Give as Location Permission");
            //errorAlert(
             // "Please allow location access from settings. To see users near by you"
           // );
           // resolve(null);
           navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
          } else if (result.state === "denied") {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
           // errorAlert(
            //  "Please allow location access from settings. To see users near by you"
            //);
            //resolve(null);
            // reject("Please Give as Location Permission");
          }
        });
    }
  });
}
