import { useEffect, useContext } from "react";
import RecommendedCard from "../../../../components/RecommendedCard";
import { FavouritesContext } from "../../../../context/favourites";

const YourFavourite = () => {
  // const [favouritesUsers, setFavouritesUsers] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const userId = getCurrentLoginUserId();

  // const getCollectionData = async (userId) => {
  //   setLoading(true);
  //   const docRef = doc(db, "users", userId);
  //   const collections = collection(docRef, "Favourites");
  //   const querySnapshot = query(collections);
  //   const favouritesListSnapshot = querySnapshot;
  //   getDocs(favouritesListSnapshot)
  //     .then((querySnapshot) => {
  //       const lists = [];
  //       querySnapshot.forEach((doc) => {
  //         lists.push(doc.id);
  //       });
  //       setFavouritesUsers(lists);
  //       !lists.length && setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log("Error", error);
  //     });
  // };

  // const getUsersBusinesData = async (favouritesUsers) => {
  //   const bussinessDocs = await getCollection("users_business");
  //   setUsersBusiness(
  //     bussinessDocs.filter(
  //       ({ isVisible, uid }) => isVisible && favouritesUsers?.includes(uid)
  //     )
  //   );
  //   setLoading(false);
  // // };
  // useEffect(() => {
  //   if (userId) {
  //     getCollectionData(userId);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userId]);

  // useEffect(() => {
  //   if (favouritesUsers?.length) {
  //     getUsersBusinesData(favouritesUsers);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [favouritesUsers?.length]);

  const { loading, myFavoutiesProfileList, getMyAllFavouriteWithProfile } =
    useContext(FavouritesContext);
  useEffect(() => {
    getMyAllFavouriteWithProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <div className="loader"></div>;

  return (
    <section className="favourites-section">
      <div className="favourites">
        <div className="favourites__catalog">
          <div className="recommended">
            {myFavoutiesProfileList?.map((userBusiness) => {
              return (
                <RecommendedCard
                  key={userBusiness.bussinessUserProfile.uid}
                  userBusiness={userBusiness.bussinessUserProfile}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default YourFavourite;
