import axios from "axios";

export const getPlaceAddress = async (lat, lng) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP_KEY}`;

  const responce = await axios.get(url);
  console.log("responce", responce);
  if (responce.status === 200) {
    const body = responce.data;
    console.log("error_message" in body);
    if ("error_message" in body) {
      throw Error("Error Occurs");
    }
    const result = body["results"];
    if (result.length > 0) {
      return result[0]["formatted_address"];
    }
  }
};

export const getLatitudeLongitude = async (street, houseNo, postalCode, city, country) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${street} ${houseNo} ${postalCode} ${city} ${country}&key=${process.env.REACT_APP_MAP_KEY}`;

  const responce = await axios.get(url);
  //console.log("responce", responce);
  if (responce.status === 200) {
    const body = responce.data;
    console.log("error_message" in body);
    if ("error_message" in body) {
      throw Error("Error Occurs");
    }
    const result = body["results"];
    if (result.length > 0) {
      return result[0]["geometry"]["location"];
    }
  }
};