import React from "react";
import { NavLink } from "react-router-dom";

import { useLocalization } from "../helpers/localization";
import { labels } from "../utils/locale";
import { useParams } from "react-router-dom";

import AuthForm from "../components/Registration/AuthForm";
const RegistrationForm = (props) => {
  const { locale = "en" } = useParams();

  const translate = useLocalization();

  return (
    <div className="registration" style={props.style}>
      <AuthForm isRegister={true}>
        {props.title ? (
          <h3 className="secondary-heading mg-bottom-small">{props.title}</h3>
        ) : null}
        <p className="alert-text">
          {translate(labels.alreadyRegistered)}
          <NavLink to={`/${locale}/signin`}>{translate(labels.signIn)}</NavLink>
        </p>
      </AuthForm>
    </div>
  );
};

export default RegistrationForm;
