import { useState } from "react";
import { useLocalization } from "../../helpers/localization";
import { formatDate, minutuestoHoursString } from "../../utils/function";
import { labels } from "../../utils/locale";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ChatBookingTile = ({ booking, bussinessProfile}) => {

//  const { bookingId, startDate, service, type, name } = booking;
console.log("booking w chat booking");
console.log(booking);
  const date = formatDate(booking.startDate, "DD.MM.YYYY");
  const time = formatDate(booking.startDate, "HH:mm");
  const [openBooking, setopenBooking] = useState(null);
  const translate = useLocalization();

  const bookingService =
    booking.service !== undefined && booking.service !== null ? JSON.parse(booking.service) : null;
  const { locale = "en" } = useParams();
  console.log("booking tutaj: ");
  console.log(booking);
  // if(userProfile !== null){
  //   return null;
  // }
  return (
    <>
      <div
        className="booking-tile flex-row justify-space-between align-item-center"
        // style={{ padding: "10px" }}
      >
        {/* <CheckboxInput id={`booking__checkbox-${bookingId}`} /> */}

        {booking.type === 1 || booking.type === 2 ? (
          <div
            className="flex-row justify-space-between align-item-center"
            style={{ padding: "0" }}
          >
            <img
              src={require("../../assets/app-icon.png")}
              alt="Logo Hauzy Bussiness"
              className="guest__image mg-right-small"
            />
            <div className="guest__meta">
              {/* <span className="guest__link guest__link-blue">{bookingId}</span> */}
              <span className="guest__name">{booking.name}</span>
              <span className="booking-date">
                {date}, {time}
              </span>
              {bookingService !== null && (
                <span className="service-detail">
                  {bookingService.title[locale]} <span className="dot"></span>
                  {minutuestoHoursString(bookingService.time, translate)}{" "}
                  <span className="dot"></span>
                  {bookingService.price} zł
                </span>
              )}
            </div>
          </div>
        ) : bussinessProfile === undefined || bussinessProfile === null ? (
          <div
            className="flex-row justify-space-between align-item-center"
            style={{ padding: "0" }}
          >
            <img
              src={require("../../assets/app-icon.png")}
              alt="Logo Hauzy Bussiness"
              className="guest__image mg-right-small"
            />
            <div className="guest__meta">
              {/* <span className="guest__link guest__link-blue">{bookingId}</span> */}
              <span className="guest__name">{booking.name}</span>
              <span className="booking-date">
                {date}, {time}
              </span>
              {bookingService !== null && (
                <span className="service-detail">
                  {bookingService.title[locale]} <span className="dot"></span>
                  {minutuestoHoursString(bookingService.time, translate)}{" "}
                  <span className="dot"></span>
                  {bookingService.price} zł
                </span>
              )}
            </div>
          </div>
        ) : (
          <div
            className="flex-row justify-space-between align-item-center"
            style={{ padding: "0" }}
          >
          { bussinessProfile?.profilePic !== null ?
            <img
              src={bussinessProfile?.profilePic}
              alt="Guest"
              className="guest__image mg-right-small"
            />
            : 
            <img
              src={require("../../assets/app-icon.png")}
              alt="Logo Hauzy Bussiness"
              className="guest__image mg-right-small"
            />
          }
            <div className="guest__meta">
              {/* <span className="guest__link guest__link-blue">{bookingId}</span> */}
              <span className="guest__name">
                {(bussinessProfile?.firstName ?? "") +
                  " " +
                  (bussinessProfile?.lastName ?? "")}
              </span>
              <span className="booking-date">
                {date}, {time}
              </span>
              {bookingService !== null && (
                <span className="service-detail">
                  {bookingService.title[locale]} <span className="dot"></span>
                  {minutuestoHoursString(bookingService.time, translate)}{" "}
                  <span className="dot"></span>
                  {bookingService.price} zł
                </span>
              )}
            </div>
          </div>
        )}
        <div>
          <span className={"guest__link"}>
            {booking?.status === "Waiting"
              ? translate(labels.waiting)
              : booking?.status === "Confirmed"
              ? translate(labels.confirmed)
              : booking?.status === "Pending"
              ? translate(labels.pending)
              : booking?.status === "Cancelled"
              ? translate(labels.cancelled)
              : booking?.status === "Completed"
              ? translate(labels.completed)
              : ""
            }
          </span>
        </div>

        {/* <Icon
        onClick={() => {
          setopenBooking(booking);
        }}
        name="ellipsis"
        className="opts-icon"
      /> */}
        {/* <button
        onClick={() => {
          setopenBooking(booking);
        }}
        className="preview-btn"
      >
        {translate(labels.preview)}
      </button> */}
      </div>
    </>
  );
};

export default ChatBookingTile;
