import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { usersCollection } from "../../utils/collections";
import { getUserFullName } from "../../utils/function";
import Ratings from "../Ratings";

const BasicChatHeader = ({ bussinessProfile }) => {
  const { locale = "en" } = useParams();


  return (
    <div className="chatroom__header">

      {bussinessProfile.profilePic === null ? (
        <img
          className="sidebar_icon"
          src={require("../../assets/svg icons/user.svg").default}
          alt="profile"
        />
      ) : (
        <img
          className="sidebar_icon"
          src={bussinessProfile.profilePic}
          alt="profile"
        />
      )}

      <NavLink
        to={"/" + locale + "/serviceprovider/" + bussinessProfile.uid}
        className="chatroom__headerinfoleft"
      >

              <h3 className="recommended__title" style={{fontSize: "2.2rem"}}>{`${
                bussinessProfile?.firstName || "Matylda"
              } ${bussinessProfile?.lastName || "Ewanowska"}`}</h3>

            <h3 className="recommended__spec" style={{fontSize: "1.6rem"}}>
              {bussinessProfile?.category?.name[locale] || "Opiekun do dzieci"}
              <span> &#8226; </span>
              {bussinessProfile?.location?.address || "Poznań"}
            </h3>
            {/* <h3>{bussinessProfile?.location?.address || "Poznań"}</h3> */}
            <div className="recommended__ratings">
              <Ratings
                className="recommended-icon"
                filled={!isNaN(bussinessProfile?.rating) ? bussinessProfile?.rating : 5}
              />
              <span className="opinions__number" style={{ fontSize: "1.4rem", marginLeft: "0.5rem", marginRight: "0.5rem", marginTop: "0.2rem"}}>
                {!isNaN(bussinessProfile?.rating) ? bussinessProfile?.rating : "5.0"}
              </span>
            </div>
      </NavLink>
    </div>
  );
};

export default BasicChatHeader;
