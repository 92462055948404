import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/auth";
import AuthModal from "../Registration/AuthModal";
import BookingModel from "../../pages/Individual/Home/components/BookingModel";
import { labels } from "../../utils/locale";
import { useLocalization } from "../../helpers/localization";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getDurationsText } from "../../utils/function";

const ServiceList = ({ userBusiness, addOrRemoveFavourite, markFav, pr }) => {
    console.log("ServiceList : userBusiness");
  console.log(userBusiness);
  //const { loggedIn } = useContext(AuthContext);
  const translate = useLocalization();
  const { locale = "en" } = useParams();
  const services = userBusiness?.services ?? [];
  const [servicesList, setservicesList] = useState(services.slice(0, 2));
  const [showModal, setShowModal] = useState(null);

  const showMoreServices = () => {
    if (services.length === 0) {
      return;
    }
    if (services.length !== servicesList.length) {
      setservicesList(services.slice(0, servicesList.length + 1));
    }
  };
  return (
    <>
      {servicesList.map((service, index) => {
        const title = service.title[locale];
        return (
          <div key={index} className="services">
            <ul className="service-small-tile">
              <li>{title.charAt(0).toUpperCase() + title.slice(1)}</li>
              {!pr && <br />}
              {/* <br /> */}
              {/* {title.length > 15 ? <br></br> : null} */}
              <li>
                {pr && <span className="full-stop">&#8226;</span>}
                {`${service.price.toFixed(1)} zł`}
              </li>
              <li>
                <span className="full-stop">&#8226;</span>{" "}
                {`${getDurationsText(service.time)}  `}
              </li>
            </ul>
            {/*             
            <h3 className="services-name-price">
              {service.title.en.charAt(0).toUpperCase() +
                service.title.en.slice(1)}{" "}
              <span className="full-stop">&#8226;</span>
              <span>{`${service.time} hours  `}</span>
              <span className="full-stop">&#8226;</span>
              <span>{`  ${service.price.toFixed(1)} zł`}</span>
            </h3> */}

            <button
              onClick={() => {
                setShowModal("booking");
              }}
              className="book-button"
            >
              {translate(labels.book)}
            </button>
          </div>
        );
      })}
      {servicesList.length === services.length ? null : services.length ===
        0 ? (
        <p className="nav__bottom-link show_more">
          {translate(labels.no_service_found)}
        </p>
      ) : (
        // : services.length !== servicesList.length ? (
        <p onClick={showMoreServices} className="nav__bottom-link show_more">
          {services.length === 0
            ? translate(labels.no_service_found)
            : translate(labels.showMoreDetails)}
        </p>
      )}
      {showModal === "signin" ? (
        <AuthModal
          onDone={(v) => {
            if (v && markFav) {
              addOrRemoveFavourite();
              setShowModal(null);
              return;
            } else if (v) {
              setShowModal("booking");
              return;
            }
          }}
          setShowModal={setShowModal}
        />
      ) : null}
      {showModal === "booking" ? (
        <BookingModel setShowModal={setShowModal} userBusiness={userBusiness} />
      ) : null}
    </>
  );
};

export default ServiceList;
