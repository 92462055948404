import React, { useContext, useEffect, useState } from "react";
import Icon from "../Icon";
import {
  currentLocalDate,
  formatDate,
  getTwoDatesDifferenceinSeconds,
  minutuestoHoursString,
} from "../../utils/function";
import {
  getbookingStutusLocaleText,
  getreminderLocaleText,
  getbookingStatusLocaleSubheader,
} from "../../utils/locale_functions";
import { useLocalization } from "../../helpers/localization";
import { labels } from "../../utils/locale";

import { errorAlert, successAlert } from "../../utils/alert";
import Loader from "../Loader";
import {
  changeBookingStatusAndNotify,
  getGoogleEventsData,
} from "../../services/booking_service";
import { getUserData } from "../../services/auth_service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BookingContext } from "../../context/booking";
import AddReviewModal from "./AddReviewModal";
import { checkAlreadyReviewed } from "../../services/review_service";
import { getBooking } from "../../services/booking_service";
import { ActionState } from "../../utils/enum";
import { ReactComponent as CheckIconRed } from "../../assets/svg icons/check_red.svg";
import { ReactComponent as CheckIconGrey } from "../../assets/svg icons/check_grey.svg";
import { ReactComponent as CheckIconGreen } from "../../assets/svg icons/check_green.svg";
import { ReactComponent as GoogleCalendarIcon } from "../../assets/svg icons/Google_Calendar_icon.svg";

const BookingContent = ({ booking,  bussinessUser}) => {
  console.log("Boooking w content");
  console.log(booking);


  const translate = useLocalization();





  const currentStatus = booking?.status ?? "";
  const date = formatDate(booking.startDate, "DD/MM/YYYY");
  const time = formatDate(booking.startDate, "H:MM");
  const address = booking?.userAddress ?? "";


  const [bussinesProfileLoading, setbussinesProfileLoading] = useState(false);
  // const [bussinessUserProfile, setbussinessUserProfile] = useState(null);


  const { locale = "en" } = useParams();


  const bookingChangeHours =
    bussinessUser?.booking_settings?.allowBookingChange ?? 0;

  var bookingChangeHoursinSeconds = bookingChangeHours * 3600;



  return (

          <div className="booking_details_container">
              {currentStatus == "Pending" ? 
                <CheckIconGrey
                   className="guest__image mg-right-small booking_check_icon "
                /> : 
                 (
                   currentStatus == "Accepted" || currentStatus == "Confirmed"  || currentStatus == "Completed" ?  
                <CheckIconGreen
                   className="guest__image mg-right-small booking_check_icon"
                />  :
                <CheckIconRed
                   className="guest__image mg-right-small booking_check_icon"
                />
                  )}
  
                 <div className="booking_header">
                 {getbookingStutusLocaleText(translate, currentStatus)}!
                 </div>
                 <div className="booking_subheader">
                 {getbookingStatusLocaleSubheader(translate, currentStatus)}

                 </div> 
                 <div className="booking_details_card">         
               { bussinessUser?.profilePic !== null ?  <img
                  src={bussinessUser?.profilePic}
                  alt="Guest"
                  className="guest__image mg-right-small"
                /> :
                <></>}

                <div className="flex-column ">
                  <label className="booking_black_text">
                    {(bussinessUser?.firstName ?? "") +
                      " " +
                      (bussinessUser?.lastName ?? "")}
                  </label>
                  <label className="booking_grey_text">
                   {(bussinessUser?.category ?? "")}
                  </label>
                </div>
              { booking.service !== null ? <div className="flex-column">
              <label className="booking_grey_text">{translate(labels.service)}</label>
              <span className="booking_black_text">{JSON.parse(booking.service).title[locale]}</span>
            </div>
            : <></> }

            <div className="flex-column">
              <label className="booking_grey_text">{translate(labels.date_time)}</label>
              <span className="booking_black_text">{date}</span>
              <span className="booking_grey_text">{time}</span>
            </div>
            <div className="flex-column">
              <label className="booking_grey_text">{translate(labels.addressHeading)}</label>
              <span className="booking_black_text">{address}</span>
            </div>


          </div>

                                <button
                  onClick={() => {
                    getGoogleEventsData(booking, translate(labels.addedToGoogleCalendar));
                  }}
                  className="primary__btn"
                  style={{ marginTop: "3rem" }}
                >
                  {translate(labels.addtoGoogleCalender)}
                </button>
          </div>

  );
};

export default BookingContent;
