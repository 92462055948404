import React, { useEffect, useState } from "react";
import { useLocalization } from "../../../../helpers/localization";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { labels } from "../../../../utils/locale";
import Icon from "../../../../components/Icon";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon
} from "react-share";

const ShareModel = ({ setShowModal, userBusiness }) => {


  const { locale = "en" } = useParams();
  const translate = useLocalization();

  var shareUrl = "google.com";
  var title = "Let's share it"
  console.log("userBusiness");
  console.log(userBusiness);
  return (
    <div className="calendar_modal">
      <div className="calendar_modal-content" style={{height: "300px"}}>
        <span
          className="calendar_modal-close-icon"
          onClick={() => setShowModal(false)}
        >
          <Icon name="cancel" />
        </span>
        <h2 className="book-visit">{translate(labels.bookYourVisit)} Share profile</h2>

      <div className="share_popup_content">
      <p>Share this link via</p>
      <ul class="share_popup_icons">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <FacebookMessengerShareButton
            url={shareUrl}
            appId="521270401588372"
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>  
          <WhatsappShareButton
            url={shareUrl}
            quote={title}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>  
      </ul>
       <p>Or copy link</p>
      <div class="share_popup_field">
        <i class="url-icon uil uil-link"></i>
        <input type="text" readonly value="https://codepen.io/coding_dev_"/>
        <button>Copy</button>
      </div>     
              
      </div>

      </div>
    </div>    
  );
};

export default ShareModel;
